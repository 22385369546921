import React from "react";

type Props = {};

const CMCNextGenerationLogo = (props: Props) => {
  return (
    <svg
      width="60"
      height="60"
      viewBox="0 0 60 60"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g filter="url(#filter0_dd_1178_504)">
        <path
          transform="matrix(1,0,0,1,-0.3,-0.4)"
          d="M 0.7 13.1 C 0.7 6.5 6 1.2 12.6 1.2 H 36.7 C 43.3 1.2 48.7 6.5 48.7 13.1 V 37.2 C 48.7 43.8 43.3 49.2 36.7 49.2 H 12.6 C 6 49.2 0.7 43.8 0.7 37.2 V 13.1 Z"
          fill="#323c59"
        />
        <path
          transform="matrix(1,0,0,1,-0.3,-0.4)"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M 17.2 38.7 C 18 38.7 18.7 38 18.7 37.1 L 18.7 21.5 L 18.7 21.5 V 19.1 C 18.7 16.8 17.5 15.6 15.2 14.9 C 14.3 14.7 13.5 14.4 12.7 14.3 L 12.7 38.7 H 17.2 Z M 26.1 38.7 C 27 38.7 27.7 38 27.7 37.1 V 21.5 L 27.7 21.5 V 19.1 C 27.7 16.8 26.4 15.6 24.2 14.9 C 23.3 14.7 22.4 14.4 21.6 14.3 V 38.7 H 26.1 Z M 36.7 37.1 V 11.7 C 35.8 11.8 35 12 34.1 12.3 C 31.8 13 30.6 14.2 30.6 16.5 V 18.9 L 30.6 18.9 V 38.7 H 35.1 C 35.9 38.7 36.7 38 36.7 37.1 Z"
          fill="white"
        />
      </g>
    </svg>
  );
};

export default CMCNextGenerationLogo;
