import { createContext, useContext, useEffect, useState } from "react";

export type PreviewToolsContextType = {
  // ? Component Details
  componentDetails: any;
  setComponentDetails: (id: string | null) => void;
  componentDetailsModal: boolean;
  setComponentDetailsModalOpen: (open: boolean) => void;
};
const PreviewToolsContext = createContext<PreviewToolsContextType>({
  componentDetails: null,
  setComponentDetails: () => {},
  componentDetailsModal: false,
  setComponentDetailsModalOpen: () => {},
});

export const PreviewToolsProvider = ({ children }: any) => {
  // Component details modal
  const [componentDetails, setComponentDetails] = useState<string | null>(null);
  const [componentDetailsModal, setComponentDetailsModalOpen] = useState<boolean>(false);

  useEffect(() => {}, []);

  return (
    <PreviewToolsContext.Provider
      value={{
        setComponentDetails,
        componentDetails,
        setComponentDetailsModalOpen,
        componentDetailsModal,
      }}
    >
      {children}
    </PreviewToolsContext.Provider>
  );
};

export const usePreviewToolsStore = () => {
  const context = useContext(PreviewToolsContext);
  if (context === undefined) {
    throw new Error("usePreviewTools must be used within a PreviewToolsProvider");
  }
  return context;
};
