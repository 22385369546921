import HeadlessFetcher from "@/services/fetchers/headless-fetcher";
import { getRouteDetails } from "@/utils/helpers";
import { IconButton, Paragraph, TextInput } from "@contentful/f36-components";
import { Entry } from "contentful";
import debounce from "lodash/debounce";
import { X } from "lucide-react";
import { useRouter } from "next/router";
import { useCallback, useEffect, useState } from "react";
import DIDFinderCard from "./did-finder-card";

export default function DIDFinder() {
  const [search, setSearch] = useState("");
  const [results, setResults] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const router = useRouter();
  const { locale } = getRouteDetails(router);

  // Create a debounced search function
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedSearch = useCallback(
    debounce(async (searchTerm: string) => {
      if (searchTerm.length < 3) {
        setResults([]);
        return;
      }

      setIsLoading(true);
      try {
        const data = await HeadlessFetcher.getEntries("preview", {
          content_type: "nDid",
          "fields.internalName[match]": searchTerm,
          locale,
        });
        setResults(data?.items as any);
      } catch (error) {
        console.error("Error fetching results:", error);
        setResults([]);
      } finally {
        setIsLoading(false);
      }
    }, 300),
    []
  );

  useEffect(() => {
    debouncedSearch(search);

    // Cleanup function
    return () => {
      debouncedSearch.cancel();
    };
  }, [search, debouncedSearch]);

  // Reset state when modal closes
  // useEffect(() => {
  //   if (!isShown) {
  //     setSearch("");
  //     setResults([]);
  //   }
  // }, [isShown]);

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value);
  };

  const resetSearch = () => {
    setSearch("");
    setResults([]);
  };

  return (
    <>
      <div className="mb-2 flex gap-x-2 sticky">
        {results.length > 0 && (
          <IconButton
            size="small"
            variant="secondary"
            aria-label="Go back"
            icon={<X className="w-4 h-4" />}
            onClick={resetSearch}
          />
        )}
        <TextInput
          value={search}
          onChange={handleSearchChange}
          placeholder="Search DIDs (minimum 3 characters)"
        />
      </div>
      <div className="space-y-2 overflow-y-auto h-[500px]">
        {!isLoading && search.length < 3 && (
          <Paragraph>Enter at least 3 characters to search</Paragraph>
        )}

        {isLoading && <Paragraph>Loading...</Paragraph>}

        {!isLoading &&
          results.map((result: Entry<any>) => (
            <DIDFinderCard result={result} key={result.sys.id} />
          ))}
      </div>
    </>
  );
}
