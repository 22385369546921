import {
  Ban,
  CircleAlert,
  CircleArrowRight,
  CircleCheck,
  CircleMinus,
} from "lucide-react";

const bulletAttributes = {
  width: 25,
  height: 25,
  className: "accent-bullet flex-shrink-0",
  strokeWidth: 1,
};

export const bullets = new Map([
  ["alert", <CircleAlert key="alert" {...bulletAttributes} />],
  ["arrow", <CircleArrowRight key="arrow" {...bulletAttributes} />],
  ["ban", <Ban key="ban" {...bulletAttributes} />],
  ["minus", <CircleMinus key="minus" {...bulletAttributes} />],
  ["tick", <CircleCheck key="tick" {...bulletAttributes} />],
]);
