// Render video inside rich text editor paragraphs
import React from "react";
import ReactPlayer from "react-player";

export const renderVideoFromShortcode = (textNode: { value: string }) => {
  const videoModalRegex = /\{\{\{ VIDEO-([^}]+) \}\}\}/;
  const isVideo = textNode?.value?.match(videoModalRegex);
  let videoModalURL = isVideo && isVideo[1];

  if (isVideo) {
    // Add no-cookie to youtube links
    if (videoModalURL?.includes("youtube") && !videoModalURL?.includes("-nocookie")) {
      videoModalURL = videoModalURL.replace("youtube", "youtube-nocookie");
    }

    return (
      <div className="video-wrapper">
        <ReactPlayer
          controls
          url={videoModalURL as string}
          width={"100%"}
          height={"max-content"}
          className="video-container"
        />
      </div>
    );
  }

  return null;
};
