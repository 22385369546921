import {ISO3166_Alpha2_Codes} from "@/types/country-codes";

export const countries_fr: Record<ISO3166_Alpha2_Codes, string> = {
  AW: "Aruba",
  AF: "Afghanistan",
  AO: "Angola",
  AI: "Anguilla",
  AX: "Îles Åland",
  AL: "Albanie",
  AD: "Andorre",
  AN: "Antilles néerlandaises",
  AE: "Émirats Arabes Unis",
  AR: "Argentine",
  AM: "Arménie",
  AS: "Samoa américaines",
  AQ: "Antarctique",
  TF: "Terres australes françaises",
  AG: "Antigua-et-Barbuda",
  AU: "Australie",
  AT: "Autriche",
  AZ: "Azerbaïdjan",
  BI: "Burundi",
  BE: "Belgique",
  BJ: "Bénin",
  BQ: "Bonaire, Saint-Eustache et Saba",
  BF: "Burkina Faso",
  BD: "Bangladesh",
  BG: "Bulgarie",
  BH: "Bahreïn",
  BS: "Bahamas",
  BA: "Bosnie-Herzégovine",
  BL: "Saint Barthélemy",
  BY: "Biélorussie",
  BZ: "Belize",
  BM: "Bermudes",
  BO: "Bolivie (État plurinational de)",
  BR: "Brésil",
  BB: "Barbade",
  BN: "Brunei Darussalam",
  BT: "Bhoutan",
  BV: "Île Bouvet",
  BW: "Botswana",
  CF: "République centrafricaine",
  CA: "Canada",
  CC: "Îles Cocos (Keeling)",
  CH: "Suisse",
  CL: "Chili",
  CN: "Chine",
  CI: "Côte d'Ivoire",
  CM: "Cameroun",
  CD: "Congo (République démocratique du)",
  CG: "Congo",
  CK: "Îles Cook",
  CO: "Colombie",
  KM: "Comores",
  CV: "Cabo Verde",
  CR: "Costa Rica",
  CU: "Cuba",
  CW: "Curacao",
  CX: "Île Christmas",
  KY: "Îles Caïmans",
  CY: "Chypre",
  CZ: "République Tchèque",
  DE: "Allemagne",
  DJ: "Djibouti",
  DM: "Dominique",
  DK: "Danemark",
  DO: "République Dominicaine",
  DZ: "Algérie",
  EC: "Équateur",
  EG: "Égypte",
  ER: "Érythrée",
  EH: "Sahara occidental",
  ES: "Espagne",
  EE: "Estonie",
  ET: "Éthiopie",
  FI: "Finlande",
  FJ: "Fidji",
  FK: "Îles Falkland (Malouines)",
  FR: "France",
  FO: "Îles Féroé",
  FM: "Micronésie",
  GA: "Gabon",
  GB: "Royaume-Uni",
  GE: "Géorgie",
  GG: "Guernesey",
  GH: "Ghana",
  GI: "Gibraltar",
  GN: "Guinée",
  GP: "Guadeloupe",
  GM: "Gambie",
  GW: "Guinée-Bissau",
  GQ: "Guinée Équatoriale",
  GR: "Grèce",
  GD: "Grenade",
  GL: "Groenland",
  GT: "Guatemala",
  GF: "Guyane française",
  GU: "Guam",
  GY: "Guyane",
  HK: "Hong Kong",
  HM: "Îles Heard et McDonald",
  HN: "Honduras",
  HR: "Croatie",
  HT: "Haïti",
  HU: "Hongrie",
  ID: "Indonésie",
  IM: "Île de Man",
  IN: "Inde",
  IO: "Territoire britannique de l'océan Indien",
  IE: "Irlande",
  IR: "Iran",
  IQ: "Irak",
  IS: "Islande",
  IL: "Israël",
  IT: "Italie",
  JM: "Jamaïque",
  JE: "Jersey",
  JO: "Jordan",
  JP: "Japon",
  KZ: "Kazakhstan",
  KE: "Kenya",
  KG: "Kirghizistan",
  KH: "Cambodge",
  KI: "Kiribati",
  KN: "Saint-Christophe-et-Niévès",
  KR: "Corée du Sud",
  KW: "Koweït",
  LA: "Laos",
  LB: "Liban",
  LR: "Liberia",
  LY: "Libye",
  LC: "Sainte-Lucie",
  LI: "Liechtenstein",
  LK: "Sri Lanka",
  LS: "Lesotho",
  LT: "Lituanie",
  LU: "Luxembourg",
  LV: "Lettonie",
  MO: "Macao",
  MF: "Saint Martin",
  MA: "Maroc",
  MC: "Monaco",
  MD: "Moldova (République de)",
  MG: "Madagascar",
  MV: "Maldives",
  MX: "Mexique",
  MH: "Îles Marshall",
  MK: "Macédoine",
  ML: "Mali",
  MT: "Malte",
  MM: "Myanmar",
  ME: "Monténégro",
  MN: "Mongolie",
  MP: "Îles Mariannes du Nord",
  MZ: "Mozambique",
  MR: "Mauritanie",
  MS: "Montserrat",
  MQ: "Martinique",
  MU: "Île Maurice",
  MW: "Malawi",
  MY: "Malaisie",
  YT: "Mayotte",
  NA: "Namibie",
  NC: "Nouvelle Calédonie",
  NE: "Niger",
  NF: "Île de Norfolk",
  NG: "Nigeria",
  NI: "Nicaragua",
  NU: "Niue",
  NL: "Pays-Bas",
  NO: "Norvège",
  NP: "Népal",
  NR: "Nauru",
  NZ: "Nouvelle-Zélande",
  OM: "Oman",
  PK: "Pakistan",
  PA: "Panama",
  PN: "Pitcairn",
  PE: "Pérou",
  PH: "Philippines",
  PW: "Palau",
  PG: "Papouasie Nouvelle Guinée",
  PL: "Pologne",
  PR: "Porto Rico",
  KP: "Corée du Nord",
  PT: "Portugal",
  PY: "Paraguay",
  PS: "Palestine, État de",
  PF: "Polynésie française",
  QA: "Qatar",
  RE: "Réunion",
  RO: "Roumanie",
  RU: "Russie",
  RW: "Rwanda",
  SA: "Arabie Saoudite",
  SD: "Soudan",
  SN: "Sénégal",
  SG: "Singapour",
  GS: "Géorgie du Sud et îles Sandwich du Sud",
  SH: "Sainte-Hélène",
  SJ: "Svalbard et Jan Mayen",
  SB: "Les îles Salomon",
  SL: "Sierra Leone",
  SV: "Salvador",
  SM: "Saint Marin",
  SO: "Somalie",
  PM: "Saint-Pierre-et-Miquelon",
  RS: "Serbie",
  SS: "Soudan du sud",
  ST: "Sao Tomé et Principe",
  SR: "Suriname",
  SK: "Slovaquie",
  SI: "Slovénie",
  SE: "Suède",
  SZ: "Eswatini",
  SX: "Sint Maarten (partie néerlandaise)",
  SC: "Seychelles",
  SY: "République arabe syrienne",
  TC: "Îles Turques-et-Caïques",
  TD: "Tchad",
  TG: "Aller",
  TH: "Thaïlande",
  TJ: "Tadjikistan",
  TK: "Tokelau",
  TM: "Turkménistan",
  TL: "Timor-Leste",
  TO: "Tonga",
  TT: "Trinité-et-Tobago",
  TN: "Tunisie",
  TR: "Turquie",
  TV: "Tuvalu",
  TW: "Taïwan",
  TZ: "Tanzanie, République-Unie de",
  UG: "Ouganda",
  UA: "Ukraine",
  UM: "Îles mineures éloignées (États-Unis)",
  UY: "Uruguay",
  US: "États-unis d'Amérique",
  UZ: "Ouzbékistan",
  VA: "Vatican",
  VC: "Saint-Vincent-et-les-Grenadines",
  VE: "Venezuela",
  VG: "Îles Vierges (britanniques)",
  VI: "Îles Vierges (États-Unis)",
  VN: "Vietnam",
  VU: "Vanuatu",
  WF: "Wallis et Futuna",
  WS: "Samoa",
  XK: "Kosovo",
  YE: "Yémen",
  ZA: "Afrique du Sud",
  ZM: "Zambie",
  ZW: "Zimbabwe",
};
