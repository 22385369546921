import { Note } from "@contentful/f36-components";
import { AlertCircle } from "lucide-react";
import React from "react";

export default function HelpDialogError() {
  return (
    <div>
      <Note variant="negative" icon={<AlertCircle className="h-6 w-6 stroke-red-600" />}>
        There was a problem loading the help docs. Please contact the web dev team.
      </Note>
    </div>
  );
}
