/**
 * Alcatraz is MT4/MT5 but ONLY for Alcatraz regions
 */

import { ISO3166_Alpha2_Codes, ISO3166_Alpha2_Countries } from "@/types/country-codes";

export type Locales =
  // Core Pillar
  | "pillar"

  // Retail
  | "en" // Core Pillar
  | "en-GB" // English (UK)
  | "en-AU" // English (Australia)
  | "en-CA" // English (Canada)
  | "fr-CA" // French (Canada)
  | "en-SG" // English (Singapore)
  | "en-NZ" // English (New Zealand)
  | "en-IE" // English (Ireland)
  | "en-DE" // English (Germany)
  // | "en-BM" // English (Bermuda) // !! No longer in use
  | "de-DE" // German (Germany)
  | "de-AT" // German (Austria)
  | "it-IT" // Italian (Italy)
  | "es-ES" // Spanish (Spain)
  | "zh-AU" // Chinese (Australia)
  | "fr-FR" // French (France)
  | "zh-SG" // Chinese (Singapore)
  | "zh-NZ" // Chinese (New Zealand)
  | "zh-CA" // Chinese (Canada)
  | "sv-SE" // Swedish (Sweden)
  | "nb-NO" // Norwegian (Norway)
  | "pl-PL" // Polish (Poland)

  // Alcatraz
  | "eo" // Alcatraz Pillar
  | "ar" // Arabic
  | "bn" // Bengali
  | "tl" // Tagalog
  | "fr" // French
  | "hi" // Hindi
  | "id" // Indonesian
  | "ko" // Korean
  | "ms" // Malay
  | "pt" // Portuguese
  | "es" // Spanish
  | "th" // Thai
  | "tr" // Turkish
  | "vi" // Vietnamese;
  | "zh" // Chinese
  | "zh-HK" // Chinese (Hong Kong)
  | "zh-MO" // Chinese (Macau)
  | "zh-TW"; // Chinese (Taiwan)

export type RetailLocales = Extract<
  Locales,
  | "en" // Core Pillar
  | "en-GB" // English (UK)
  | "en-AU" // English (Australia)
  | "en-CA" // English (Canada)
  | "en-SG" // English (Singapore)
  | "en-NZ" // English (New Zealand)
  | "en-IE" // English (Ireland)
  | "en-DE" // English (Germany)
  | "en-BM" // English (Bermuda)
  | "de-DE" // German (Germany)
  | "de-AT" // German (Austria)
  | "it-IT" // Italian (Italy)
  | "es-ES" // Spanish (Spain)
  | "zh-AU" // Chinese (Australia)
  | "fr-FR" // French (France)
  | "zh-CA" // Chinese (Canada)
  | "zh-SG" // Chinese (Singapore)
  | "zh-NZ" // Chinese (New Zealand)
  | "sv-SE" // Swedish (Sweden)
  | "nb-NO" // Norwegian (Norway)
  | "pl-PL" // Polish (Poland)
>;

export type AlcatrazLocales = Extract<
  Locales,
  | "ar"
  | "bn"
  | "eo"
  | "en"
  | "es"
  | "fr"
  | "hi"
  | "id"
  | "ko"
  | "ms"
  | "pt"
  | "th"
  | "tl"
  | "tr"
  | "vi"
  | "zh"
  | "zh-HK"
  | "zh-MO"
  | "zh-TW"
>;

// "https://client-portal.cmcmarkets.com/Home/SignUp" is always Alcatraz

export type Country = {
  name?: string;
  code: ISO3166_Alpha2_Codes;
  code_3: any;
  flag: string;
  locale: Locales; // For non-alcatraz regions its the locale, for alcatraz its the closest supported language
  jurisdiction: "local" | "eea" | "alcatraz" | "unsupported";
  nextgen: "unsupported" | "homepage" | string; // Alcatraz is always "homepage"
  metatrader4:
    | "unsupported"
    | "homepage"
    | "https://client-portal.cmcmarkets.com/Home/SignUp"
    | string; // Alcatraz is always "homepage"
  phaseTwo?: boolean; // Some locales will go out later
};

export const rtlLocales: Locales[] = ["ar"];

export const alcatrazLocales: AlcatrazLocales[] = [
  "en", // English
  "eo", // English override
  "th", // Thai
  "tl", // Tagalog
  "vi", // Vietnamese
  "ar", // Arabic
  "bn", // Bengali
  "fr", // French
  "hi", // Hindi
  "id", // Indonesian
  "ko", // Korean
  "ms", // Malay
  "pt", // Portuguese
  "es", // Spanish
  "tr", // Turkish
  "zh", // Chinese
  "zh-HK", // Chinese (Hong Kong)
  "zh-MO", // Chinese (Macau)
  "zh-TW", // Chinese (Taiwan)
];

export const alcatrazCodes = [
  "AL", // Albania
  "DZ", // Algeria
  "AS", // American Samoa
  "AD", // Andorra
  "AI", // Anguilla
  "AQ", // Antigua and Barbuda
  "AR", // Argentina
  "AM", // Armenia
  "AZ", // Azerbaijan
  "BS", // Bahamas
  "BH", // Bahrain
  "BD", // Bangladesh
  "BB", // Barbados
  "BZ", // Belize
  "BJ", // Benin
  "BM", // Bermuda
  "BT", // Bhutan
  "BO", // Bolivia
  "BR", // Brazil
  "VG", // British Virgin Islands
  "BF", // Burkina Faso
  "CM", // Cameroon
  "CL", // Chile
  "CO", // Colombia
  "CR", // Costa Rica
  "DJ", // Djibouti
  "DM", // Dominica
  "DO", // Dominican Republic
  "EC", // Ecuador
  "SV", // El Salvador
  "FJ", // Fiji
  "GM", // Gambia
  "GE", // Georgia
  "GD", // Grenada
  "GU", // Guam
  "GT", // Guatemala
  "GG", // Guernsey
  "GY", // Guyana
  "HK", // Hong Kong Region
  "IN", // India
  "ID", // Indonesia
  "IE", // Ireland
  "IL", // Israel
  "JP", // Japan
  "JE", // Jersey
  "KE", // Kenya
  "KI", // Kiribati
  "KW", // Kuwait
  "KG", // Kyrgyzstan
  "LA", // Laos
  "LS", // Lesotho
  "MO", // Macao Region
  "MG", // Madagascar
  "MW", // Malawi
  "MY", // Malaysia
  "MH", // Marshall Islands
  "MU", // Mauritius
  "MX", // Mexico
  "FM", // Micronesia
  "MD", // Moldova
  "ME", // Montenegro
  "MA", // Morocco
  "MZ", // Mozambique
  "NA", // Namibia
  "NR", // Nauru
  "NP", // Nepal
  "NU", // Niue
  "NF", // Norfolk Island
  "MP", // Northern Mariana Islands
  "OM", // Oman
  "PW", // Palau
  "PG", // Papua New Guinea
  "PY", // Paraguay
  "PE", // Peru
  "PH", // Philippines
  "QA", // Qatar
  "RW", // Rwanda
  "SH", // Saint Helena
  "KN", // Saint Kitts and Nevis
  "LC", // Saint Lucia
  "SM", // San Marino
  "SA", // Saudi Arabia
  "SN", // Senegal
  "SC", // Seychelles
  "SX", // Sint Maarten
  "SB", // Solomon Islands
  "ZA", // South Africa
  "SR", // Suriname
  "TW", // Taiwan Region
  "TZ", // Tanzania
  "TH", // Thailand
  "TK", // Tokelau
  "TO", // Tonga
  "TT", // Trinidad and Tobago
  "TR", // Turkey
  "TV", // Tuvalu
  "AE", // United Arab Emirates
  "UY", // Uruguay
  "UZ", // Uzbekistan
  "VU", // Vanuatu
  "VN", // Vietnam
  "ZM", // Zambia
];

export const retailLocales: Locales[] = [
  "en-GB", // English (UK)
  "en-AU", // English (Australia)
  "en-CA", // English (Canada)
  "fr-CA", // French (Canada)
  "zh-CA", // Chinese (Canada)
  "en-SG", // English (Singapore)
  "en-NZ", // English (New Zealand)
  "en-IE", // English (Ireland)
  "en-DE", // English (Germany)
  // "en-BM", // English (Bermuda) // Not in use
  "de-DE", // German (Germany)
  "de-AT", // German (Austria)
  "it-IT", // Italian (Italy)
  "es-ES", // Spanish (Spain)
  // "zh-AU", // Chinese (Australia) // Not in use
  "fr-FR", // French (France)
  "zh-SG", // Chinese (Singapore)
  // "zh-NZ", // Chinese (New Zealand) // Not in use
  "sv-SE", // Swedish (Sweden)
  "nb-NO", // Norwegian (Norway)
  "pl-PL", // Polish (Poland)
];

export const nonAlcatrazCodes = [
  "AU", // Australia
  "AT", // Austria
  "BE", // Belgium
  "BG", // Bulgaria
  "CA", // Canada
  "KY", // Cayman Islands
  "HR", // Croatia
  "CY", // Cyprus
  "CZ", // Czech Republic
  "DK", // Denmark
  "EE", // Estonia
  "FI", // Finland
  "FR", // France
  "DE", // Germany
  "GI", // Gibraltar
  "GR", // Greece
  "HU", // Hungary
  "IS", // Iceland
  "IM", // Isle of Man
  "IT", // Italy
  "JO", // Jordan
  "LV", // Latvia
  "LI", // Liechtenstein
  "LU", // Luxembourg
  "MT", // Malta
  "MC", // Monaco
  "NL", // Netherlands
  "NZ", // New Zealand
  "NO", // Norway
  "PL", // Poland
  "PT", // Portugal
  "RO", // Romania
  "SG", // Singapore
  "SK", // Slovakia
  "SI", // Slovenia
  "ES", // Spain
  "SE", // Sweden
  "CH", // Switzerland
  "GB", // United Kingdom
];

export const supportedCountries_v2: Record<ISO3166_Alpha2_Countries, Country> = {
  Afghanistan: {
    code: "AF",
    code_3: "AFG",
    flag: "🇦🇫",
    locale: "en",
    jurisdiction: "unsupported",
    nextgen: "unsupported",
    metatrader4: "unsupported",
  },
  Aland_Islands: {
    code: "AX",
    code_3: "ALA",
    flag: "🇦🇽",
    locale: "en",
    jurisdiction: "alcatraz",
    nextgen: "homepage",
    metatrader4: "https://client-portal.cmcmarkets.com/Home/SignUp",
  },
  Albania: {
    code: "AL",
    code_3: "ALB",
    flag: "🇦🇱",
    locale: "en",
    jurisdiction: "unsupported",
    nextgen: "unsupported",
    metatrader4: "unsupported",
  },
  Algeria: {
    code: "DZ",
    code_3: "DZA",
    flag: "🇩🇿",
    locale: "ar",
    jurisdiction: "alcatraz",
    nextgen: "homepage",
    metatrader4: "https://client-portal.cmcmarkets.com/Home/SignUp",
  },
  American_Samoa: {
    code: "AS",
    code_3: "ASM",
    flag: "🇦🇸",
    locale: "en",
    jurisdiction: "unsupported",
    nextgen: "unsupported",
    metatrader4: "unsupported",
  },
  Andorra: {
    code: "AD",
    code_3: "AND",
    flag: "🇦🇩",
    locale: "es",
    jurisdiction: "alcatraz",
    nextgen: "homepage",
    metatrader4: "https://client-portal.cmcmarkets.com/Home/SignUp",
  },
  Angola: {
    code: "AO",
    code_3: "AGO",
    flag: "🇦🇴",
    locale: "en",
    jurisdiction: "alcatraz",
    nextgen: "homepage",
    metatrader4: "https://client-portal.cmcmarkets.com/Home/SignUp",
  },
  Anguilla: {
    code: "AI",
    code_3: "AIA",
    flag: "🇦🇮",
    locale: "en",
    jurisdiction: "alcatraz",
    nextgen: "homepage",
    metatrader4: "https://client-portal.cmcmarkets.com/Home/SignUp",
  },
  Antarctica: {
    code: "AQ",
    code_3: "ATA",
    flag: "🇦🇶",
    locale: "en",
    jurisdiction: "unsupported",
    nextgen: "unsupported",
    metatrader4: "unsupported",
  },
  Antigua_And_Barbuda: {
    code: "AG",
    code_3: "ATG",
    flag: "🇦🇬",
    locale: "en",
    jurisdiction: "alcatraz",
    nextgen: "homepage",
    metatrader4: "https://client-portal.cmcmarkets.com/Home/SignUp",
  },
  Argentina: {
    code: "AR",
    code_3: "ARG",
    flag: "🇦🇷",
    locale: "es",
    jurisdiction: "alcatraz",
    nextgen: "homepage",
    metatrader4: "https://client-portal.cmcmarkets.com/Home/SignUp",
  },
  Armenia: {
    code: "AM",
    code_3: "ARM",
    flag: "🇦🇲",
    locale: "en",
    jurisdiction: "unsupported",
    nextgen: "unsupported",
    metatrader4: "unsupported",
  },
  Aruba: {
    code: "AW",
    code_3: "ABW",
    flag: "🇦🇼",
    locale: "en",
    jurisdiction: "alcatraz",
    nextgen: "homepage",
    metatrader4: "https://client-portal.cmcmarkets.com/Home/SignUp",
  },
  Australia: {
    code: "AU",
    code_3: "AUS",
    flag: "🇦🇺",
    locale: "en-AU",
    jurisdiction: "local",
    nextgen: "cfd/platform/cmc-platform",
    metatrader4: "cfd/platform/mt4",
  },
  Austria: {
    code: "AT",
    code_3: "AUT",
    flag: "🇦🇹",
    locale: "de-AT",
    jurisdiction: "local",
    nextgen: "handelsplattform",
    metatrader4: "handelsplattform/mt4",
  },
  Azerbaijan: {
    code: "AZ",
    code_3: "AZE",
    flag: "🇦🇿",
    locale: "en",
    jurisdiction: "alcatraz",
    nextgen: "homepage",
    metatrader4: "https://client-portal.cmcmarkets.com/Home/SignUp",
  },
  Bahamas: {
    code: "BS",
    code_3: "BHS",
    flag: "🇧🇸",
    locale: "en",
    jurisdiction: "alcatraz",
    nextgen: "homepage",
    metatrader4: "https://client-portal.cmcmarkets.com/Home/SignUp",
    phaseTwo: true,
  },
  Bahrain: {
    code: "BH",
    code_3: "BHR",
    flag: "🇧🇭",
    locale: "ar",
    jurisdiction: "alcatraz",
    nextgen: "homepage",
    metatrader4: "https://client-portal.cmcmarkets.com/Home/SignUp",
    phaseTwo: true,
  },
  Bangladesh: {
    code: "BD",
    code_3: "BGD",
    flag: "🇧🇩",
    locale: "bn",
    jurisdiction: "alcatraz",
    nextgen: "homepage",
    metatrader4: "https://client-portal.cmcmarkets.com/Home/SignUp",
  },
  Barbados: {
    code: "BB",
    code_3: "BRB",
    flag: "🇧🇧",
    locale: "en",
    jurisdiction: "alcatraz",
    nextgen: "homepage",
    metatrader4: "https://client-portal.cmcmarkets.com/Home/SignUp",
  },
  Belarus: {
    code: "BY",
    code_3: "BLR",
    flag: "🇧🇾",
    locale: "en",
    jurisdiction: "unsupported",
    nextgen: "unsupported",
    metatrader4: "unsupported",
  },
  Belgium: {
    code: "BE",
    code_3: "BEL",
    flag: "🇧🇪",
    locale: "en",
    jurisdiction: "unsupported",
    nextgen: "unsupported",
    metatrader4: "unsupported",
  },
  Belize: {
    code: "BZ",
    code_3: "BLZ",
    flag: "🇧🇿",
    locale: "en",
    jurisdiction: "alcatraz",
    nextgen: "homepage",
    metatrader4: "https://client-portal.cmcmarkets.com/Home/SignUp",
  },
  Benin: {
    code: "BJ",
    code_3: "BEN",
    flag: "🇧🇯",
    locale: "fr",
    jurisdiction: "alcatraz",
    nextgen: "homepage",
    metatrader4: "https://client-portal.cmcmarkets.com/Home/SignUp",
  },
  Bermuda: {
    code: "BM",
    code_3: "BMU",
    flag: "🇧🇲",
    locale: "en",
    jurisdiction: "unsupported",
    nextgen: "unsupported",
    metatrader4: "unsupported",
  },
  Bhutan: {
    code: "BT",
    code_3: "BTN",
    flag: "🇧🇹",
    locale: "en",
    jurisdiction: "alcatraz",
    nextgen: "homepage",
    metatrader4: "https://client-portal.cmcmarkets.com/Home/SignUp",
  },
  Bolivia: {
    code: "BO",
    code_3: "BOL",
    flag: "🇧🇴",
    locale: "es",
    jurisdiction: "alcatraz",
    nextgen: "homepage",
    metatrader4: "https://client-portal.cmcmarkets.com/Home/SignUp",
  },
  Bonaire_Sint_Eustatius_Saba: {
    code: "BQ",
    code_3: "BES",
    flag: "🇧🇶",
    locale: "en",
    jurisdiction: "alcatraz",
    nextgen: "homepage",
    metatrader4: "https://client-portal.cmcmarkets.com/Home/SignUp",
  },
  Bosnia_And_Herzegovina: {
    code: "BA",
    code_3: "BIH",
    flag: "🇧🇦",
    locale: "en",
    jurisdiction: "unsupported",
    nextgen: "unsupported",
    metatrader4: "unsupported",
  },
  Botswana: {
    code: "BW",
    code_3: "BWA",
    flag: "🇧🇼",
    locale: "en",
    jurisdiction: "alcatraz",
    nextgen: "homepage",
    metatrader4: "https://client-portal.cmcmarkets.com/Home/SignUp",
  },
  Bouvet_Island: {
    code: "BV",
    code_3: "BVT",
    flag: "",
    locale: "en",
    jurisdiction: "unsupported",
    nextgen: "unsupported",
    metatrader4: "unsupported",
  },
  Brazil: {
    code: "BR",
    code_3: "BRA",
    flag: "🇧🇷",
    locale: "pt",
    jurisdiction: "alcatraz",
    nextgen: "homepage",
    metatrader4: "https://client-portal.cmcmarkets.com/Home/SignUp",
  },
  British_Indian_Ocean_Territory: {
    code: "IO",
    code_3: "IOT",
    flag: "🇮🇴",
    locale: "en",
    jurisdiction: "alcatraz",
    nextgen: "homepage",
    metatrader4: "https://client-portal.cmcmarkets.com/Home/SignUp",
  },
  Virgin_Islands_British: {
    code: "VG",
    code_3: "VGB",
    flag: "🇻🇬",
    locale: "en",
    jurisdiction: "alcatraz",
    nextgen: "homepage",
    metatrader4: "https://client-portal.cmcmarkets.com/Home/SignUp",
    phaseTwo: true,
  },
  Virgin_Islands_US: {
    code: "VI",
    code_3: "VIR",
    flag: "🇻🇮",
    locale: "en",
    jurisdiction: "unsupported",
    nextgen: "unsupported",
    metatrader4: "unsupported",
  },
  Brunei_Darussalam: {
    code: "BN",
    code_3: "BRN",
    flag: "🇧🇳",
    locale: "en",
    jurisdiction: "alcatraz",
    nextgen: "homepage",
    metatrader4: "https://client-portal.cmcmarkets.com/Home/SignUp",
  },
  Bulgaria: {
    code: "BG",
    code_3: "BGR",
    flag: "🇧🇬",
    jurisdiction: "eea",
    locale: "en-DE",
    nextgen: "trading-platforms/web-trading-platform",
    metatrader4: "trading-platforms/metatrader-4",
  },
  Burkina_Faso: {
    code: "BF",
    code_3: "BFA",
    flag: "🇧🇫",
    locale: "fr",
    jurisdiction: "alcatraz",
    nextgen: "homepage",
    metatrader4: "https://client-portal.cmcmarkets.com/Home/SignUp",
  },
  Burundi: {
    code: "BI",
    code_3: "BDI",
    flag: "🇧🇮",
    locale: "fr",
    jurisdiction: "unsupported",
    nextgen: "unsupported",
    metatrader4: "unsupported",
  },
  Cambodia: {
    code: "KH",
    code_3: "KHM",
    flag: "🇰🇭",
    locale: "en",
    jurisdiction: "alcatraz",
    nextgen: "homepage",
    metatrader4: "https://client-portal.cmcmarkets.com/Home/SignUp",
  },
  Cameroon: {
    code: "CM",
    code_3: "CMR",
    flag: "🇨🇲",
    locale: "en",
    jurisdiction: "alcatraz",
    nextgen: "homepage",
    metatrader4: "https://client-portal.cmcmarkets.com/Home/SignUp",
  },
  Canada: {
    code: "CA",
    code_3: "CAN",
    flag: "🇨🇦",
    locale: "en-CA",
    jurisdiction: "local",
    nextgen: "platform",
    metatrader4: "platform/mt4",
  },
  Cape_Verde: {
    code: "CV",
    code_3: "CPV",
    flag: "🇨🇻",
    locale: "pt",
    jurisdiction: "alcatraz",
    nextgen: "homepage",
    metatrader4: "https://client-portal.cmcmarkets.com/Home/SignUp",
  },
  Cayman_Islands: {
    code: "KY",
    code_3: "CYM",
    flag: "🇰🇾",
    locale: "en",
    jurisdiction: "alcatraz",
    nextgen: "homepage",
    metatrader4: "https://client-portal.cmcmarkets.com/Home/SignUp",
    phaseTwo: true,
  },
  Central_African_Republic: {
    code: "CF",
    code_3: "CAF",
    flag: "🇨🇫",
    locale: "fr",
    jurisdiction: "unsupported",
    nextgen: "unsupported",
    metatrader4: "unsupported",
  },
  Chad: {
    code: "TD",
    code_3: "TCD",
    flag: "🇹🇩",
    locale: "fr",
    jurisdiction: "alcatraz",
    nextgen: "homepage",
    metatrader4: "https://client-portal.cmcmarkets.com/Home/SignUp",
  },
  Chile: {
    code: "CL",
    code_3: "CHL",
    flag: "🇨🇱",
    locale: "es",
    jurisdiction: "alcatraz",
    nextgen: "homepage",
    metatrader4: "https://client-portal.cmcmarkets.com/Home/SignUp",
    phaseTwo: true,
  },
  China: {
    code: "CN",
    code_3: "CHN",
    flag: "🇨🇳",
    locale: "zh-HK",
    jurisdiction: "alcatraz",
    nextgen: "homepage",
    metatrader4: "https://client-portal.cmcmarkets.com/Home/SignUp",
  },
  Christmas_Island: {
    code: "CX",
    code_3: "CXR",
    flag: "🇨🇽",
    locale: "en",
    jurisdiction: "alcatraz",
    nextgen: "homepage",
    metatrader4: "https://client-portal.cmcmarkets.com/Home/SignUp",
  },
  Cocos_Keeling_Islands: {
    code: "CC",
    code_3: "CCK",
    flag: "🇨🇨",
    locale: "en",
    jurisdiction: "alcatraz",
    nextgen: "homepage",
    metatrader4: "https://client-portal.cmcmarkets.com/Home/SignUp",
  },
  Colombia: {
    code: "CO",
    code_3: "COL",
    flag: "🇨🇴",
    locale: "es",
    jurisdiction: "alcatraz",
    nextgen: "homepage",
    metatrader4: "https://client-portal.cmcmarkets.com/Home/SignUp",
  },
  Comoros: {
    code: "KM",
    code_3: "COM",
    flag: "🇰🇲",
    locale: "fr",
    jurisdiction: "alcatraz",
    nextgen: "homepage",
    metatrader4: "https://client-portal.cmcmarkets.com/Home/SignUp",
  },
  Congo: {
    code: "CG",
    code_3: "COG",
    flag: "🇨🇬",
    locale: "fr",
    jurisdiction: "alcatraz",
    nextgen: "homepage",
    metatrader4: "https://client-portal.cmcmarkets.com/Home/SignUp",
  },
  Cook_Islands: {
    code: "CK",
    code_3: "COK",
    flag: "🇨🇰",
    locale: "en",
    jurisdiction: "alcatraz",
    nextgen: "homepage",
    metatrader4: "https://client-portal.cmcmarkets.com/Home/SignUp",
  },
  Costa_Rica: {
    code: "CR",
    code_3: "CRI",
    flag: "🇨🇷",
    locale: "es",
    jurisdiction: "alcatraz",
    nextgen: "homepage",
    metatrader4: "https://client-portal.cmcmarkets.com/Home/SignUp",
  },
  "Cote_D'ivoire": {
    code: "CI",
    code_3: "CIV",
    flag: "🇨🇮",
    locale: "fr",
    jurisdiction: "alcatraz",
    nextgen: "homepage",
    metatrader4: "https://client-portal.cmcmarkets.com/Home/SignUp",
  },
  Croatia: {
    code: "HR",
    code_3: "HRV",
    flag: "🇭🇷",
    jurisdiction: "eea",
    locale: "en-DE",
    nextgen: "trading-platforms/web-trading-platform",
    metatrader4: "trading-platforms/metatrader-4",
  },
  Cuba: {
    code: "CU",
    code_3: "CUB",
    flag: "🇨🇺",
    locale: "es",
    jurisdiction: "unsupported",
    nextgen: "unsupported",
    metatrader4: "unsupported",
  },
  Curaçao: {
    code: "CW",
    code_3: "CUW",
    flag: "🇨🇼",
    locale: "en",
    jurisdiction: "alcatraz",
    nextgen: "homepage",
    metatrader4: "https://client-portal.cmcmarkets.com/Home/SignUp",
  },
  Cyprus: {
    code: "CY",
    code_3: "CYP",
    flag: "🇨🇾",
    jurisdiction: "eea",
    locale: "en-DE",
    nextgen: "trading-platforms/web-trading-platform",
    metatrader4: "trading-platforms/metatrader-4",
  },
  Czech_Republic: {
    code: "CZ",
    code_3: "CZE",
    flag: "🇨🇿",
    jurisdiction: "eea",
    locale: "en-DE",
    nextgen: "trading-platforms/web-trading-platform",
    metatrader4: "trading-platforms/metatrader-4",
  },
  Congo_Democratic_Republic: {
    code: "CD",
    code_3: "COD",
    flag: "🇨🇩",
    locale: "en",
    jurisdiction: "unsupported",
    nextgen: "unsupported",
    metatrader4: "unsupported",
  },
  "Democratic_People's_Republic_of_Korea_(North Korea)": {
    code: "KP",
    code_3: "PRK",
    flag: "🇰🇵",
    locale: "en",
    jurisdiction: "unsupported",
    nextgen: "unsupported",
    metatrader4: "unsupported",
  },
  Denmark: {
    code: "DK",
    code_3: "DNK",
    flag: "🇩🇰",
    jurisdiction: "eea",
    locale: "en-DE",
    nextgen: "trading-platforms/web-trading-platform",
    metatrader4: "trading-platforms/metatrader-4",
  },
  Djibouti: {
    code: "DJ",
    code_3: "DJI",
    flag: "🇩🇯",
    locale: "ar",
    jurisdiction: "alcatraz",
    nextgen: "homepage",
    metatrader4: "https://client-portal.cmcmarkets.com/Home/SignUp",
  },
  Dominica: {
    code: "DM",
    code_3: "DMA",
    flag: "🇩🇲",
    locale: "en",
    jurisdiction: "alcatraz",
    nextgen: "homepage",
    metatrader4: "https://client-portal.cmcmarkets.com/Home/SignUp",
  },
  Dominican_Republic: {
    code: "DO",
    code_3: "DOM",
    flag: "🇩🇴",
    locale: "es",
    jurisdiction: "alcatraz",
    nextgen: "homepage",
    metatrader4: "https://client-portal.cmcmarkets.com/Home/SignUp",
  },
  Ecuador: {
    code: "EC",
    code_3: "ECU",
    flag: "🇪🇨",
    locale: "es",
    jurisdiction: "alcatraz",
    nextgen: "homepage",
    metatrader4: "https://client-portal.cmcmarkets.com/Home/SignUp",
  },
  Egypt: {
    code: "EG",
    code_3: "EGY",
    flag: "🇪🇬",
    locale: "ar",
    jurisdiction: "alcatraz",
    nextgen: "homepage",
    metatrader4: "https://client-portal.cmcmarkets.com/Home/SignUp",
  },
  El_Salvador: {
    code: "SV",
    code_3: "SLV",
    flag: "🇸🇻",
    locale: "es",
    jurisdiction: "alcatraz",
    nextgen: "homepage",
    metatrader4: "https://client-portal.cmcmarkets.com/Home/SignUp",
  },
  Equatorial_Guinea: {
    code: "GQ",
    code_3: "GNQ",
    flag: "🇬🇶",
    locale: "es",
    jurisdiction: "alcatraz",
    nextgen: "homepage",
    metatrader4: "https://client-portal.cmcmarkets.com/Home/SignUp",
  },
  Eritrea: {
    code: "ER",
    code_3: "ERI",
    flag: "🇪🇷",
    locale: "en",
    jurisdiction: "alcatraz",
    nextgen: "homepage",
    metatrader4: "https://client-portal.cmcmarkets.com/Home/SignUp",
  },
  Estonia: {
    code: "EE",
    code_3: "EST",
    flag: "🇪🇪",
    jurisdiction: "eea",
    locale: "en-DE",
    nextgen: "trading-platforms/web-trading-platform",
    metatrader4: "trading-platforms/metatrader-4",
  },
  Ethiopia: {
    code: "ET",
    code_3: "ETH",
    flag: "🇪🇹",
    locale: "en",
    jurisdiction: "alcatraz",
    nextgen: "homepage",
    metatrader4: "https://client-portal.cmcmarkets.com/Home/SignUp",
  },
  Falkland_Islands: {
    code: "FK",
    code_3: "FLK",
    flag: "🇫🇰",
    locale: "en",
    jurisdiction: "unsupported",
    nextgen: "unsupported",
    metatrader4: "unsupported",
  },
  Faroe_Islands: {
    code: "FO",
    code_3: "FRO",
    flag: "🇫🇴",
    locale: "en",
    jurisdiction: "alcatraz",
    nextgen: "homepage",
    metatrader4: "https://client-portal.cmcmarkets.com/Home/SignUp",
  },
  Fiji: {
    code: "FJ",
    code_3: "FJI",
    flag: "🇫🇯",
    locale: "en",
    jurisdiction: "alcatraz",
    nextgen: "homepage",
    metatrader4: "https://client-portal.cmcmarkets.com/Home/SignUp",
  },
  Finland: {
    code: "FI",
    code_3: "FIN",
    flag: "🇫🇮",
    jurisdiction: "eea",
    locale: "en-DE",
    nextgen: "trading-platforms/web-trading-platform",
    metatrader4: "trading-platforms/metatrader-4",
  },
  France: {
    code: "FR",
    code_3: "FRA",
    flag: "🇫🇷",
    locale: "fr-FR",
    jurisdiction: "local",
    nextgen: "plateforme",
    metatrader4: "plateforme/mt4",
  },
  French_Guiana: {
    code: "GF",
    code_3: "GUF",
    flag: "🇬🇫",
    locale: "fr",
    jurisdiction: "unsupported",
    nextgen: "unsupported",
    metatrader4: "unsupported",
  },
  French_Polynesia: {
    code: "PF",
    code_3: "PYF",
    flag: "🇵🇫",
    locale: "fr",
    jurisdiction: "alcatraz",
    nextgen: "homepage",
    metatrader4: "https://client-portal.cmcmarkets.com/Home/SignUp",
  },
  French_Southern_Territories: {
    code: "TF",
    code_3: "ATF",
    flag: "",
    locale: "fr",
    jurisdiction: "unsupported",
    nextgen: "unsupported",
    metatrader4: "unsupported",
  },
  Gabon: {
    code: "GA",
    code_3: "GAB",
    flag: "🇬🇦",
    locale: "fr",
    jurisdiction: "alcatraz",
    nextgen: "homepage",
    metatrader4: "https://client-portal.cmcmarkets.com/Home/SignUp",
  },
  Gambia: {
    code: "GM",
    code_3: "GMB",
    flag: "🇬🇲",
    locale: "en",
    jurisdiction: "alcatraz",
    nextgen: "homepage",
    metatrader4: "https://client-portal.cmcmarkets.com/Home/SignUp",
  },
  Georgia: {
    code: "GE",
    code_3: "GEO",
    flag: "🇬🇪",
    locale: "en",
    jurisdiction: "unsupported",
    nextgen: "unsupported",
    metatrader4: "unsupported",
  },
  Germany: {
    code: "DE",
    code_3: "DEU",
    flag: "🇩🇪",
    jurisdiction: "local",
    locale: "de-DE",
    nextgen: "handelsplattform",
    metatrader4: "handelsplattform/mt4",
  },
  Ghana: {
    code: "GH",
    code_3: "GHA",
    flag: "🇬🇭",
    locale: "en",
    jurisdiction: "alcatraz",
    nextgen: "homepage",
    metatrader4: "https://client-portal.cmcmarkets.com/Home/SignUp",
  },
  Gibraltar: {
    code: "GI",
    code_3: "GIB",
    flag: "🇬🇮",
    locale: "en",
    jurisdiction: "alcatraz",
    nextgen: "homepage",
    metatrader4: "https://client-portal.cmcmarkets.com/Home/SignUp",
    phaseTwo: true,
  },
  Greece: {
    code: "GR",
    code_3: "GRC",
    flag: "🇬🇷",
    jurisdiction: "eea",
    locale: "en-DE",
    nextgen: "trading-platforms/web-trading-platform",
    metatrader4: "trading-platforms/metatrader-4",
  },
  Greenland: {
    code: "GL",
    code_3: "GRL",
    flag: "🇬🇱",
    locale: "en",
    jurisdiction: "unsupported",
    nextgen: "unsupported",
    metatrader4: "unsupported",
  },
  Grenada: {
    code: "GD",
    code_3: "GRD",
    flag: "🇬🇩",
    locale: "en",
    jurisdiction: "alcatraz",
    nextgen: "homepage",
    metatrader4: "https://client-portal.cmcmarkets.com/Home/SignUp",
  },
  Guadeloupe: {
    code: "GP",
    code_3: "GLP",
    flag: "🇬🇵",
    locale: "fr",
    jurisdiction: "unsupported",
    nextgen: "unsupported",
    metatrader4: "unsupported",
  },
  Guam: {
    code: "GU",
    code_3: "GUM",
    flag: "🇬🇺",
    locale: "en",
    jurisdiction: "alcatraz",
    nextgen: "homepage",
    metatrader4: "https://client-portal.cmcmarkets.com/Home/SignUp",
  },
  Guatemala: {
    code: "GT",
    code_3: "GTM",
    flag: "🇬🇹",
    locale: "en",
    jurisdiction: "unsupported",
    nextgen: "unsupported",
    metatrader4: "unsupported",
  },
  Guernsey: {
    code: "GG",
    code_3: "GGY",
    flag: "🇬🇬",
    locale: "en",
    jurisdiction: "unsupported",
    nextgen: "unsupported",
    metatrader4: "unsupported",
  },
  Guinea: {
    code: "GN",
    code_3: "GIN",
    flag: "🇬🇳",
    locale: "fr",
    jurisdiction: "unsupported",
    nextgen: "unsupported",
    metatrader4: "unsupported",
  },
  Guinea_Bissau: {
    code: "GW",
    code_3: "GNB",
    flag: "🇬🇼",
    locale: "pt",
    jurisdiction: "unsupported",
    nextgen: "unsupported",
    metatrader4: "unsupported",
  },
  Guyana: {
    code: "GY",
    code_3: "GUY",
    flag: "🇬🇾",
    locale: "en",
    jurisdiction: "alcatraz",
    nextgen: "homepage",
    metatrader4: "https://client-portal.cmcmarkets.com/Home/SignUp",
  },
  Haiti: {
    code: "HT",
    code_3: "HTI",
    flag: "🇭🇹",
    locale: "fr",
    jurisdiction: "unsupported",
    nextgen: "unsupported",
    metatrader4: "unsupported",
  },
  Heard_Island_Mcdonald_Islands: {
    code: "HM",
    code_3: "HMD",
    flag: "",
    locale: "en",
    jurisdiction: "unsupported",
    nextgen: "unsupported",
    metatrader4: "unsupported",
  },
  Honduras: {
    code: "HN",
    code_3: "HND",
    flag: "🇭🇳",
    locale: "es",
    jurisdiction: "alcatraz",
    nextgen: "homepage",
    metatrader4: "https://client-portal.cmcmarkets.com/Home/SignUp",
  },
  Hong_Kong: {
    code: "HK",
    code_3: "HKG",
    flag: "🇭🇰",
    locale: "zh-HK", // !! To be reviewed - ZH-HK potentially
    jurisdiction: "alcatraz",
    nextgen: "homepage",
    metatrader4: "https://client-portal.cmcmarkets.com/Home/SignUp",
    phaseTwo: true,
  },
  Hungary: {
    code: "HU",
    code_3: "HUN",
    flag: "🇭🇺",
    jurisdiction: "eea",
    locale: "en-DE",
    nextgen: "trading-platforms/web-trading-platform",
    metatrader4: "trading-platforms/metatrader-4",
  },
  Iceland: {
    code: "IS",
    code_3: "ISL",
    flag: "🇮🇸",
    jurisdiction: "eea",
    locale: "en-DE",
    nextgen: "trading-platforms/web-trading-platform",
    metatrader4: "trading-platforms/metatrader-4",
  },
  India: {
    code: "IN",
    code_3: "IND",
    flag: "🇮🇳",
    locale: "hi",
    jurisdiction: "alcatraz",
    nextgen: "homepage",
    metatrader4: "https://client-portal.cmcmarkets.com/Home/SignUp",
  },
  Indonesia: {
    code: "ID",
    code_3: "IDN",
    flag: "🇮🇩",
    locale: "id",
    jurisdiction: "alcatraz",
    nextgen: "homepage",
    metatrader4: "https://client-portal.cmcmarkets.com/Home/SignUp",
  },
  Iran: {
    code: "IR",
    code_3: "IRN",
    flag: "🇮🇷",
    locale: "ar",
    jurisdiction: "unsupported",
    nextgen: "unsupported",
    metatrader4: "unsupported",
  },
  Iraq: {
    code: "IQ",
    code_3: "IRQ",
    flag: "🇮🇶",
    locale: "ar",
    jurisdiction: "unsupported",
    nextgen: "unsupported",
    metatrader4: "unsupported",
  },
  Ireland: {
    code: "IE",
    code_3: "IRL",
    flag: "🇮🇪",
    locale: "en",
    jurisdiction: "unsupported",
    nextgen: "unsupported",
    metatrader4: "unsupported",
  },
  Isle_of_Man: {
    code: "IM",
    code_3: "IMN",
    flag: "🇮🇲",
    locale: "en-GB",
    jurisdiction: "local",
    nextgen: "trading-platforms/web-trading-platform",
    metatrader4: "trading-platforms/mt4",
  },
  Israel: {
    code: "IL",
    code_3: "ISR",
    flag: "🇮🇱",
    locale: "en",
    jurisdiction: "unsupported",
    nextgen: "unsupported",
    metatrader4: "unsupported",
  },
  Italy: {
    code: "IT",
    code_3: "ITA",
    flag: "🇮🇹",
    locale: "it-IT",
    jurisdiction: "local",
    nextgen: "piattaforma",
    metatrader4: "piattaforma/mt4",
  },
  Jamaica: {
    code: "JM",
    code_3: "JAM",
    flag: "🇯🇲",
    locale: "en",
    jurisdiction: "alcatraz",
    nextgen: "homepage",
    metatrader4: "https://client-portal.cmcmarkets.com/Home/SignUp",
  },
  Japan: {
    code: "JP",
    code_3: "JPN",
    flag: "🇯🇵",
    locale: "en",
    jurisdiction: "unsupported",
    nextgen: "unsupported",
    metatrader4: "unsupported",
  },
  Jersey: {
    code: "JE",
    code_3: "JEY",
    flag: "🇯🇪",
    locale: "en",
    jurisdiction: "unsupported",
    nextgen: "unsupported",
    metatrader4: "unsupported",
  },
  Jordan: {
    code: "JO",
    code_3: "JOR",
    flag: "🇯🇴",
    locale: "ar",
    jurisdiction: "alcatraz",
    nextgen: "homepage",
    metatrader4: "https://client-portal.cmcmarkets.com/Home/SignUp",
  },
  Kazakhstan: {
    code: "KZ",
    code_3: "KAZ",
    flag: "🇰🇿",
    locale: "en",
    jurisdiction: "alcatraz",
    nextgen: "homepage",
    metatrader4: "https://client-portal.cmcmarkets.com/Home/SignUp",
  },
  Kenya: {
    code: "KE",
    code_3: "KEN",
    flag: "🇰🇪",
    locale: "en",
    jurisdiction: "alcatraz",
    nextgen: "homepage",
    metatrader4: "https://client-portal.cmcmarkets.com/Home/SignUp",
  },
  Kiribati: {
    code: "KI",
    code_3: "KIR",
    flag: "🇰🇮",
    locale: "en",
    jurisdiction: "alcatraz",
    nextgen: "homepage",
    metatrader4: "https://client-portal.cmcmarkets.com/Home/SignUp",
  },
  Kuwait: {
    code: "KW",
    code_3: "KWT",
    flag: "🇰🇼",
    locale: "ar",
    jurisdiction: "alcatraz",
    nextgen: "homepage",
    metatrader4: "https://client-portal.cmcmarkets.com/Home/SignUp",
  },
  Kyrgyzstan: {
    code: "KG",
    code_3: "KGZ",
    flag: "🇰🇬",
    locale: "en",
    jurisdiction: "alcatraz",
    nextgen: "homepage",
    metatrader4: "https://client-portal.cmcmarkets.com/Home/SignUp",
  },
  Kosovo: {
    code: "XK",
    code_3: "XKX",
    flag: "🇽🇰",
    locale: "en",
    jurisdiction: "alcatraz",
    nextgen: "homepage",
    metatrader4: "https://client-portal.cmcmarkets.com/Home/SignUp",
  },
  Laos: {
    code: "LA",
    code_3: "LAO",
    flag: "🇱🇦",
    locale: "en",
    jurisdiction: "alcatraz",
    nextgen: "homepage",
    metatrader4: "https://client-portal.cmcmarkets.com/Home/SignUp",
  },
  Latvia: {
    code: "LV",
    code_3: "LVA",
    flag: "🇱🇻",
    jurisdiction: "eea",
    locale: "en-DE",
    nextgen: "trading-platforms/web-trading-platform",
    metatrader4: "trading-platforms/metatrader-4",
  },
  Lebanon: {
    code: "LB",
    code_3: "LBN",
    flag: "🇱🇧",
    locale: "ar",
    jurisdiction: "unsupported",
    nextgen: "unsupported",
    metatrader4: "unsupported",
  },
  Lesotho: {
    code: "LS",
    code_3: "LSO",
    flag: "🇱🇸",
    locale: "en",
    jurisdiction: "alcatraz",
    nextgen: "homepage",
    metatrader4: "https://client-portal.cmcmarkets.com/Home/SignUp",
  },
  Liberia: {
    code: "LR",
    code_3: "LBR",
    flag: "🇱🇷",
    locale: "en",
    jurisdiction: "alcatraz",
    nextgen: "homepage",
    metatrader4: "https://client-portal.cmcmarkets.com/Home/SignUp",
  },
  Libya: {
    code: "LY",
    code_3: "LBY",
    flag: "🇱🇾",
    locale: "ar",
    jurisdiction: "unsupported",
    nextgen: "unsupported",
    metatrader4: "unsupported",
  },
  Liechtenstein: {
    code: "LI",
    code_3: "LIE",
    flag: "🇱🇮",
    jurisdiction: "eea",
    locale: "en-DE",
    nextgen: "trading-platforms/web-trading-platform",
    metatrader4: "trading-platforms/metatrader-4",
  },
  Lithuania: {
    code: "LT",
    code_3: "LTU",
    flag: "🇱🇹",
    locale: "en-DE",
    jurisdiction: "eea",
    nextgen: "trading-platforms/web-trading-platform",
    metatrader4: "trading-platforms/metatrader-4",
  },
  Luxembourg: {
    code: "LU",
    code_3: "LUX",
    flag: "🇱🇺",
    jurisdiction: "eea",
    locale: "en-DE",
    nextgen: "trading-platforms/web-trading-platform",
    metatrader4: "trading-platforms/metatrader-4",
  },
  Macao: {
    code: "MO",
    code_3: "MAC",
    flag: "🇲🇴",
    locale: "zh-MO",
    jurisdiction: "alcatraz",
    nextgen: "homepage",
    metatrader4: "https://client-portal.cmcmarkets.com/Home/SignUp",
  },
  Macedonia: {
    code: "MK",
    code_3: "MKD",
    flag: "🇲🇰",
    locale: "en",
    jurisdiction: "unsupported",
    nextgen: "unsupported",
    metatrader4: "unsupported",
  },
  Madagascar: {
    code: "MG",
    code_3: "MDG",
    flag: "🇲🇬",
    locale: "fr",
    jurisdiction: "alcatraz",
    nextgen: "homepage",
    metatrader4: "https://client-portal.cmcmarkets.com/Home/SignUp",
  },
  Malawi: {
    code: "MW",
    code_3: "MWI",
    flag: "🇲🇼",
    locale: "en",
    jurisdiction: "alcatraz",
    nextgen: "homepage",
    metatrader4: "https://client-portal.cmcmarkets.com/Home/SignUp",
  },
  Malaysia: {
    code: "MY",
    code_3: "MYS",
    flag: "🇲🇾",
    locale: "ms",
    jurisdiction: "alcatraz",
    nextgen: "homepage",
    metatrader4: "https://client-portal.cmcmarkets.com/Home/SignUp",
  },
  Maldives: {
    code: "MV",
    code_3: "MDV",
    flag: "🇲🇻",
    locale: "en",
    jurisdiction: "alcatraz",
    nextgen: "homepage",
    metatrader4: "https://client-portal.cmcmarkets.com/Home/SignUp",
  },
  Mali: {
    code: "ML",
    code_3: "MLI",
    flag: "🇲🇱",
    locale: "fr",
    jurisdiction: "unsupported",
    nextgen: "unsupported",
    metatrader4: "unsupported",
  },
  Malta: {
    code: "MT",
    code_3: "MLT",
    flag: "🇲🇹",
    jurisdiction: "eea",
    locale: "en-DE",
    nextgen: "trading-platforms/web-trading-platform",
    metatrader4: "trading-platforms/metatrader-4",
  },
  Marshall_Islands: {
    code: "MH",
    code_3: "MHL",
    flag: "🇲🇭",
    locale: "en",
    jurisdiction: "unsupported",
    nextgen: "unsupported",
    metatrader4: "unsupported",
  },
  Martinique: {
    code: "MQ",
    code_3: "MTQ",
    flag: "🇲🇶",
    locale: "fr",
    jurisdiction: "unsupported",
    nextgen: "unsupported",
    metatrader4: "unsupported",
  },
  Mauritania: {
    code: "MR",
    code_3: "MRT",
    flag: "🇲🇷",
    locale: "fr",
    jurisdiction: "alcatraz",
    nextgen: "homepage",
    metatrader4: "https://client-portal.cmcmarkets.com/Home/SignUp",
  },
  Mauritius: {
    code: "MU",
    code_3: "MUS",
    flag: "🇲🇺",
    locale: "en",
    jurisdiction: "alcatraz",
    nextgen: "homepage",
    metatrader4: "https://client-portal.cmcmarkets.com/Home/SignUp",
  },
  Mayotte: {
    code: "YT",
    code_3: "MYT",
    flag: "🇾🇹",
    locale: "fr",
    jurisdiction: "unsupported",
    nextgen: "unsupported",
    metatrader4: "unsupported",
  },
  Mexico: {
    code: "MX",
    code_3: "MEX",
    flag: "🇲🇽",
    locale: "es",
    jurisdiction: "alcatraz",
    nextgen: "homepage",
    metatrader4: "https://client-portal.cmcmarkets.com/Home/SignUp",
  },
  Micronesia: {
    code: "FM",
    code_3: "FSM",
    flag: "🇫🇲",
    locale: "en",
    jurisdiction: "alcatraz",
    nextgen: "homepage",
    metatrader4: "https://client-portal.cmcmarkets.com/Home/SignUp",
  },
  Moldova: {
    code: "MD",
    code_3: "MDA",
    flag: "🇲🇩",
    locale: "en",
    jurisdiction: "unsupported",
    nextgen: "unsupported",
    metatrader4: "unsupported",
  },
  Monaco: {
    code: "MC",
    code_3: "MCO",
    flag: "🇲🇨",
    locale: "fr",
    jurisdiction: "alcatraz",
    nextgen: "homepage",
    metatrader4: "https://client-portal.cmcmarkets.com/Home/SignUp",
    phaseTwo: true,
  },
  Mongolia: {
    code: "MN",
    code_3: "MNG",
    flag: "🇲🇳",
    locale: "en",
    jurisdiction: "alcatraz",
    nextgen: "homepage",
    metatrader4: "https://client-portal.cmcmarkets.com/Home/SignUp",
  },
  Montenegro: {
    code: "ME",
    code_3: "MNE",
    flag: "🇲🇪",
    locale: "en",
    jurisdiction: "alcatraz",
    nextgen: "homepage",
    metatrader4: "https://client-portal.cmcmarkets.com/Home/SignUp",
  },
  Montserrat: {
    code: "MS",
    code_3: "MSR",
    flag: "🇲🇸",
    locale: "en",
    jurisdiction: "alcatraz",
    nextgen: "homepage",
    metatrader4: "https://client-portal.cmcmarkets.com/Home/SignUp",
  },
  Morocco: {
    code: "MA",
    code_3: "MAR",
    flag: "🇲🇦",
    locale: "ar",
    jurisdiction: "alcatraz",
    nextgen: "homepage",
    metatrader4: "https://client-portal.cmcmarkets.com/Home/SignUp",
  },
  Mozambique: {
    code: "MZ",
    code_3: "MOZ",
    flag: "🇲🇿",
    locale: "pt",
    jurisdiction: "alcatraz",
    nextgen: "homepage",
    metatrader4: "https://client-portal.cmcmarkets.com/Home/SignUp",
  },
  Myanmar: {
    code: "MM",
    code_3: "MMR",
    flag: "🇲🇲",
    locale: "en",
    jurisdiction: "unsupported",
    nextgen: "unsupported",
    metatrader4: "unsupported",
  },
  Namibia: {
    code: "NA",
    code_3: "NAM",
    flag: "🇳🇦",
    locale: "en",
    jurisdiction: "alcatraz",
    nextgen: "homepage",
    metatrader4: "https://client-portal.cmcmarkets.com/Home/SignUp",
  },
  Nauru: {
    code: "NR",
    code_3: "NRU",
    flag: "🇳🇷",
    locale: "en",
    jurisdiction: "alcatraz",
    nextgen: "homepage",
    metatrader4: "https://client-portal.cmcmarkets.com/Home/SignUp",
  },
  Nepal: {
    code: "NP",
    code_3: "NPL",
    flag: "🇳🇵",
    locale: "en",
    jurisdiction: "alcatraz",
    nextgen: "homepage",
    metatrader4: "https://client-portal.cmcmarkets.com/Home/SignUp",
  },
  Netherlands: {
    code: "NL",
    code_3: "NLD",
    flag: "🇳🇱",
    jurisdiction: "eea",
    locale: "en-DE",
    nextgen: "trading-platforms/web-trading-platform",
    metatrader4: "trading-platforms/metatrader-4",
  },
  Netherlands_Antilles: {
    code: "AN",
    code_3: "ANT",
    flag: "",
    jurisdiction: "unsupported",
    locale: "en",
    nextgen: "unsupported",
    metatrader4: "unsupported",
  },
  New_Caledonia: {
    code: "NC",
    code_3: "NCL",
    flag: "🇳🇨",
    locale: "fr",
    jurisdiction: "alcatraz",
    nextgen: "homepage",
    metatrader4: "https://client-portal.cmcmarkets.com/Home/SignUp",
  },
  New_Zealand: {
    code: "NZ",
    code_3: "NZL",
    flag: "🇳🇿",
    locale: "en-NZ",
    jurisdiction: "local",
    nextgen: "cfd/platform/cmc-platform",
    metatrader4: "cfd/platform/mt4",
  },
  Nicaragua: {
    code: "NI",
    code_3: "NIC",
    flag: "🇳🇮",
    locale: "es",
    jurisdiction: "unsupported",
    nextgen: "unsupported",
    metatrader4: "unsupported",
  },
  Niger: {
    code: "NE",
    code_3: "NER",
    flag: "🇳🇪",
    locale: "fr",
    jurisdiction: "unsupported",
    nextgen: "unsupported",
    metatrader4: "unsupported",
  },
  Nigeria: {
    code: "NG",
    code_3: "NGA",
    flag: "🇳🇬",
    locale: "en",
    jurisdiction: "alcatraz",
    nextgen: "homepage",
    metatrader4: "https://client-portal.cmcmarkets.com/Home/SignUp",
  },
  Niue: {
    code: "NU",
    code_3: "NIU",
    flag: "🇳🇺",
    locale: "en",
    jurisdiction: "alcatraz",
    nextgen: "homepage",
    metatrader4: "https://client-portal.cmcmarkets.com/Home/SignUp",
  },
  Norfolk_Island: {
    code: "NF",
    code_3: "NFK",
    flag: "🇳🇫",
    locale: "en",
    jurisdiction: "alcatraz",
    nextgen: "homepage",
    metatrader4: "https://client-portal.cmcmarkets.com/Home/SignUp",
  },
  Northern_Mariana_Islands: {
    code: "MP",
    code_3: "MNP",
    flag: "🇲🇵",
    locale: "en",
    jurisdiction: "unsupported",
    nextgen: "unsupported",
    metatrader4: "unsupported",
  },
  Norway: {
    code: "NO",
    code_3: "NOR",
    flag: "🇳🇴",
    locale: "nb-NO",
    jurisdiction: "local",
    nextgen: "tradingplattform",
    metatrader4: "mt4",
  },
  Oman: {
    code: "OM",
    code_3: "OMN",
    flag: "🇴🇲",
    locale: "ar",
    jurisdiction: "alcatraz",
    nextgen: "homepage",
    metatrader4: "https://client-portal.cmcmarkets.com/Home/SignUp",
    phaseTwo: true,
  },
  Pakistan: {
    code: "PK",
    code_3: "PAK",
    flag: "🇵🇰",
    locale: "hi",
    jurisdiction: "alcatraz",
    nextgen: "homepage",
    metatrader4: "https://client-portal.cmcmarkets.com/Home/SignUp",
  },
  Palau: {
    code: "PW",
    code_3: "PLW",
    flag: "🇵🇼",
    locale: "en",
    jurisdiction: "alcatraz",
    nextgen: "homepage",
    metatrader4: "https://client-portal.cmcmarkets.com/Home/SignUp",
  },
  Panama: {
    code: "PA",
    code_3: "PAN",
    flag: "🇵🇦",
    locale: "es",
    jurisdiction: "alcatraz",
    nextgen: "homepage",
    metatrader4: "https://client-portal.cmcmarkets.com/Home/SignUp",
  },
  Papua_New_Guinea: {
    code: "PG",
    code_3: "PNG",
    flag: "🇵🇬",
    locale: "en",
    jurisdiction: "alcatraz",
    nextgen: "homepage",
    metatrader4: "https://client-portal.cmcmarkets.com/Home/SignUp",
  },
  Paraguay: {
    code: "PY",
    code_3: "PRY",
    flag: "🇵🇾",
    locale: "es",
    jurisdiction: "alcatraz",
    nextgen: "homepage",
    metatrader4: "https://client-portal.cmcmarkets.com/Home/SignUp",
  },
  Peru: {
    code: "PE",
    code_3: "PER",
    flag: "🇵🇪",
    locale: "es",
    jurisdiction: "alcatraz",
    nextgen: "homepage",
    metatrader4: "https://client-portal.cmcmarkets.com/Home/SignUp",
    phaseTwo: true,
  },
  Philippines: {
    code: "PH",
    code_3: "PHL",
    flag: "🇵🇭",
    locale: "tl",
    jurisdiction: "alcatraz",
    nextgen: "homepage",
    metatrader4: "https://client-portal.cmcmarkets.com/Home/SignUp",
    phaseTwo: true,
  },
  Pitcairn: {
    code: "PN",
    code_3: "PCN",
    flag: "🇵🇳",
    locale: "en",
    jurisdiction: "alcatraz",
    nextgen: "homepage",
    metatrader4: "https://client-portal.cmcmarkets.com/Home/SignUp",
  },
  Poland: {
    code: "PL",
    code_3: "POL",
    flag: "🇵🇱",
    locale: "pl-PL",
    jurisdiction: "local",
    nextgen: "platforma",
    metatrader4: "platforma/mt4",
  },
  Portugal: {
    code: "PT",
    code_3: "PRT",
    flag: "🇵🇹",
    jurisdiction: "eea",
    locale: "en-DE",
    nextgen: "trading-platforms/web-trading-platform",
    metatrader4: "trading-platforms/metatrader-4",
  },
  Puerto_Rico: {
    code: "PR",
    code_3: "PRI",
    flag: "🇵🇷",
    locale: "es",
    jurisdiction: "unsupported",
    nextgen: "unsupported",
    metatrader4: "unsupported",
  },
  Qatar: {
    code: "QA",
    code_3: "QAT",
    flag: "🇶🇦",
    locale: "ar",
    jurisdiction: "alcatraz",
    nextgen: "homepage",
    metatrader4: "https://client-portal.cmcmarkets.com/Home/SignUp",
    phaseTwo: true,
  },
  Reunion: {
    code: "RE",
    code_3: "REU",
    flag: "🇷🇪",
    locale: "fr",
    jurisdiction: "unsupported",
    nextgen: "unsupported",
    metatrader4: "unsupported",
  },
  Romania: {
    code: "RO",
    code_3: "ROU",
    flag: "🇷🇴",
    jurisdiction: "eea",
    locale: "en-DE",
    nextgen: "trading-platforms/web-trading-platform",
    metatrader4: "trading-platforms/metatrader-4",
  },
  Russian_Federation: {
    code: "RU",
    code_3: "RUS",
    flag: "🇷🇺",
    locale: "en",
    jurisdiction: "unsupported",
    nextgen: "unsupported",
    metatrader4: "unsupported",
  },
  Rwanda: {
    code: "RW",
    code_3: "RWA",
    flag: "🇷🇼",
    locale: "en",
    jurisdiction: "alcatraz",
    nextgen: "homepage",
    metatrader4: "https://client-portal.cmcmarkets.com/Home/SignUp",
  },
  Saint_Barthélemy: {
    code: "BL",
    code_3: "BLM",
    flag: "🇧🇱",
    locale: "fr",
    jurisdiction: "alcatraz",
    nextgen: "homepage",
    metatrader4: "https://client-portal.cmcmarkets.com/Home/SignUp",
  },
  Saint_Helena: {
    code: "SH",
    code_3: "SHN",
    flag: "🇸🇭",
    locale: "en",
    jurisdiction: "alcatraz",
    nextgen: "homepage",
    metatrader4: "https://client-portal.cmcmarkets.com/Home/SignUp",
  },
  Saint_Kitts_and_Nevis: {
    code: "KN",
    code_3: "KNA",
    flag: "🇰🇳",
    locale: "en",
    jurisdiction: "alcatraz",
    nextgen: "homepage",
    metatrader4: "https://client-portal.cmcmarkets.com/Home/SignUp",
  },
  Saint_Lucia: {
    code: "LC",
    code_3: "LCA",
    flag: "🇱🇨",
    locale: "en",
    jurisdiction: "alcatraz",
    nextgen: "homepage",
    metatrader4: "https://client-portal.cmcmarkets.com/Home/SignUp",
  },
  Saint_Martin: {
    code: "MF",
    code_3: "MAF",
    flag: "🇲🇫",
    locale: "fr",
    jurisdiction: "unsupported",
    nextgen: "unsupported",
    metatrader4: "unsupported",
  },
  Saint_Pierre_and_Miquelon: {
    code: "PM",
    code_3: "SPM",
    flag: "🇵🇲",
    locale: "fr",
    jurisdiction: "alcatraz",
    nextgen: "homepage",
    metatrader4: "https://client-portal.cmcmarkets.com/Home/SignUp",
  },
  Saint_Vincent_and_Grenadines: {
    code: "VC",
    code_3: "VCT",
    flag: "🇻🇨",
    locale: "en",
    jurisdiction: "alcatraz",
    nextgen: "homepage",
    metatrader4: "https://client-portal.cmcmarkets.com/Home/SignUp",
  },
  Samoa: {
    code: "WS",
    code_3: "WSM",
    flag: "🇼🇸",
    locale: "en",
    jurisdiction: "alcatraz",
    nextgen: "homepage",
    metatrader4: "https://client-portal.cmcmarkets.com/Home/SignUp",
  },
  San_Marino: {
    code: "SM",
    code_3: "SMR",
    flag: "🇸🇲",
    locale: "en",
    jurisdiction: "alcatraz",
    nextgen: "homepage",
    metatrader4: "https://client-portal.cmcmarkets.com/Home/SignUp",
  },
  Saudi_Arabia: {
    code: "SA",
    code_3: "SAU",
    flag: "🇸🇦",
    locale: "ar",
    jurisdiction: "alcatraz",
    nextgen: "homepage",
    metatrader4: "https://client-portal.cmcmarkets.com/Home/SignUp",
    phaseTwo: true,
  },
  Senegal: {
    code: "SN",
    code_3: "SEN",
    flag: "🇸🇳",
    locale: "fr",
    jurisdiction: "alcatraz",
    nextgen: "homepage",
    metatrader4: "https://client-portal.cmcmarkets.com/Home/SignUp",
  },
  Serbia: {
    code: "RS",
    code_3: "SRB",
    flag: "🇷🇸",
    locale: "en",
    jurisdiction: "unsupported",
    nextgen: "unsupported",
    metatrader4: "unsupported",
  },
  Seychelles: {
    code: "SC",
    code_3: "SYC",
    flag: "🇸🇨",
    locale: "en",
    jurisdiction: "alcatraz",
    nextgen: "homepage",
    metatrader4: "https://client-portal.cmcmarkets.com/Home/SignUp",
    phaseTwo: true,
  },
  Sierra_Leone: {
    code: "SL",
    code_3: "SLE",
    flag: "🇸🇱",
    locale: "en",
    jurisdiction: "alcatraz",
    nextgen: "homepage",
    metatrader4: "https://client-portal.cmcmarkets.com/Home/SignUp",
  },
  Singapore: {
    code: "SG",
    code_3: "SGP",
    flag: "🇸🇬",
    locale: "en-SG",
    jurisdiction: "local",
    nextgen: "platform",
    metatrader4: "platform/mt4",
  },
  Sint_Maarten: {
    code: "SX",
    code_3: "SXM",
    flag: "🇸🇽",
    locale: "en",
    jurisdiction: "alcatraz",
    nextgen: "homepage",
    metatrader4: "https://client-portal.cmcmarkets.com/Home/SignUp",
  },
  Slovakia: {
    code: "SK",
    code_3: "SVK",
    flag: "🇸🇰",
    jurisdiction: "eea",
    locale: "en-DE",
    nextgen: "trading-platforms/web-trading-platform",
    metatrader4: "trading-platforms/metatrader-4",
  },
  Slovenia: {
    code: "SI",
    code_3: "SVN",
    flag: "🇸🇮",
    jurisdiction: "eea",
    locale: "en-DE",
    nextgen: "trading-platforms/web-trading-platform",
    metatrader4: "trading-platforms/metatrader-4",
  },
  Solomon_Islands: {
    code: "SB",
    code_3: "SLB",
    flag: "🇸🇧",
    locale: "en",
    jurisdiction: "alcatraz",
    nextgen: "homepage",
    metatrader4: "https://client-portal.cmcmarkets.com/Home/SignUp",
  },
  Somalia: {
    code: "SO",
    code_3: "SOM",
    flag: "🇸🇴",
    locale: "en",
    jurisdiction: "unsupported",
    nextgen: "unsupported",
    metatrader4: "unsupported",
  },
  South_Africa: {
    code: "ZA",
    code_3: "ZAF",
    flag: "🇿🇦",
    locale: "en",
    jurisdiction: "alcatraz",
    nextgen: "homepage",
    metatrader4: "https://client-portal.cmcmarkets.com/Home/SignUp",
  },
  South_Georgia_and_The_South_Sandwich_Islands: {
    code: "GS",
    code_3: "SGS",
    flag: "🇬🇸",
    locale: "en",
    jurisdiction: "unsupported",
    nextgen: "unsupported",
    metatrader4: "unsupported",
  },
  South_Korea: {
    code: "KR",
    code_3: "KOR",
    flag: "🇰🇷",
    locale: "ko",
    jurisdiction: "alcatraz",
    nextgen: "homepage",
    metatrader4: "https://client-portal.cmcmarkets.com/Home/SignUp",
    phaseTwo: true,
  },
  South_Sudan: {
    code: "SS",
    code_3: "SSD",
    flag: "🇸🇸",
    locale: "en",
    jurisdiction: "unsupported",
    nextgen: "unsupported",
    metatrader4: "unsupported",
  },
  Spain: {
    code: "ES",
    code_3: "ESP",
    flag: "🇪🇸",
    locale: "es-ES",
    jurisdiction: "local",
    nextgen: "plataforma",
    metatrader4: "plataforma/mt4",
  },
  Sri_Lanka: {
    code: "LK",
    code_3: "LKA",
    flag: "🇱🇰",
    locale: "en",
    jurisdiction: "alcatraz",
    nextgen: "homepage",
    metatrader4: "https://client-portal.cmcmarkets.com/Home/SignUp",
  },
  Palestinian_Territory: {
    code: "PS",
    code_3: "PSE",
    flag: "🇵🇸",
    locale: "ar",
    jurisdiction: "unsupported",
    nextgen: "unsupported",
    metatrader4: "unsupported",
  },
  Sudan: {
    code: "SD",
    code_3: "SDN",
    flag: "🇸🇩",
    locale: "en",
    jurisdiction: "unsupported",
    nextgen: "unsupported",
    metatrader4: "unsupported",
  },
  Suriname: {
    code: "SR",
    code_3: "SUR",
    flag: "🇸🇷",
    locale: "en",
    jurisdiction: "alcatraz",
    nextgen: "homepage",
    metatrader4: "https://client-portal.cmcmarkets.com/Home/SignUp",
  },
  Svalbard_and_Jan_Mayen: {
    code: "SJ",
    code_3: "SJM",
    flag: "🇸🇯",
    locale: "en",
    jurisdiction: "alcatraz",
    nextgen: "homepage",
    metatrader4: "https://client-portal.cmcmarkets.com/Home/SignUp",
  },
  Swaziland: {
    code: "SZ",
    code_3: "SWZ",
    flag: "🇸🇿",
    locale: "en",
    jurisdiction: "alcatraz",
    nextgen: "homepage",
    metatrader4: "https://client-portal.cmcmarkets.com/Home/SignUp",
  },
  Sweden: {
    code: "SE",
    code_3: "SWE",
    flag: "🇸🇪",
    locale: "sv-SE",
    jurisdiction: "local",
    nextgen: "handelsplattform",
    metatrader4: "handelsplattform/mt4",
  },
  Switzerland: {
    code: "CH",
    code_3: "CHE",
    flag: "🇨🇭",
    locale: "en",
    jurisdiction: "alcatraz",
    nextgen: "homepage",
    metatrader4: "https://client-portal.cmcmarkets.com/Home/SignUp",
    phaseTwo: true,
  },
  Syria: {
    code: "SY",
    code_3: "SYR",
    flag: "🇸🇾",
    locale: "ar",
    jurisdiction: "unsupported",
    nextgen: "unsupported",
    metatrader4: "unsupported",
  },
  São_Tomé_and_Príncipe: {
    code: "ST",
    code_3: "STP",
    flag: "🇸🇹",
    locale: "en",
    jurisdiction: "alcatraz",
    nextgen: "homepage",
    metatrader4: "https://client-portal.cmcmarkets.com/Home/SignUp",
  },
  Taiwan: {
    code: "TW",
    code_3: "TWN",
    flag: "🇹🇼",
    locale: "zh-HK",
    jurisdiction: "alcatraz",
    nextgen: "homepage",
    metatrader4: "https://client-portal.cmcmarkets.com/Home/SignUp",
    phaseTwo: true,
  },
  Tajikistan: {
    code: "TJ",
    code_3: "TJK",
    flag: "🇹🇯",
    locale: "en",
    jurisdiction: "alcatraz",
    nextgen: "homepage",
    metatrader4: "https://client-portal.cmcmarkets.com/Home/SignUp",
  },
  Tanzania: {
    code: "TZ",
    code_3: "TZA",
    flag: "🇹🇿",
    locale: "en",
    jurisdiction: "alcatraz",
    nextgen: "homepage",
    metatrader4: "https://client-portal.cmcmarkets.com/Home/SignUp",
  },
  Thailand: {
    code: "TH",
    code_3: "THA",
    flag: "🇹🇭",
    locale: "th",
    jurisdiction: "alcatraz",
    nextgen: "homepage",
    metatrader4: "https://client-portal.cmcmarkets.com/Home/SignUp",
    phaseTwo: true,
  },
  "Timor-leste": {
    code: "TL",
    code_3: "TLS",
    flag: "🇹🇱",
    locale: "en",
    jurisdiction: "alcatraz",
    nextgen: "homepage",
    metatrader4: "https://client-portal.cmcmarkets.com/Home/SignUp",
  },
  Togo: {
    code: "TG",
    code_3: "TGO",
    flag: "🇹🇬",
    locale: "fr",
    jurisdiction: "alcatraz",
    nextgen: "homepage",
    metatrader4: "https://client-portal.cmcmarkets.com/Home/SignUp",
  },
  Tokelau: {
    code: "TK",
    code_3: "TKL",
    flag: "🇹🇰",
    locale: "en",
    jurisdiction: "alcatraz",
    nextgen: "homepage",
    metatrader4: "https://client-portal.cmcmarkets.com/Home/SignUp",
  },
  Tonga: {
    code: "TO",
    code_3: "TON",
    flag: "🇹🇴",
    locale: "en",
    jurisdiction: "alcatraz",
    nextgen: "homepage",
    metatrader4: "https://client-portal.cmcmarkets.com/Home/SignUp",
  },
  Trinidad_and_Tobago: {
    code: "TT",
    code_3: "TTO",
    flag: "🇹🇹",
    locale: "en",
    jurisdiction: "alcatraz",
    nextgen: "homepage",
    metatrader4: "https://client-portal.cmcmarkets.com/Home/SignUp",
  },
  Tunisia: {
    code: "TN",
    code_3: "TUN",
    flag: "🇹🇳",
    locale: "ar",
    jurisdiction: "unsupported",
    nextgen: "unsupported",
    metatrader4: "unsupported",
  },
  Turkey: {
    code: "TR",
    code_3: "TUR",
    flag: "🇹🇷",
    locale: "tr",
    jurisdiction: "alcatraz",
    nextgen: "homepage",
    metatrader4: "https://client-portal.cmcmarkets.com/Home/SignUp",
  },
  Turkmenistan: {
    code: "TM",
    code_3: "TKM",
    flag: "🇹🇲",
    locale: "en",
    jurisdiction: "alcatraz",
    nextgen: "homepage",
    metatrader4: "https://client-portal.cmcmarkets.com/Home/SignUp",
  },
  Turks_and_Caicos_Islands: {
    code: "TC",
    code_3: "TCA",
    flag: "🇹🇨",
    locale: "en",
    jurisdiction: "alcatraz",
    nextgen: "homepage",
    metatrader4: "https://client-portal.cmcmarkets.com/Home/SignUp",
  },
  Tuvalu: {
    code: "TV",
    code_3: "TUV",
    flag: "🇹🇻",
    locale: "en",
    jurisdiction: "alcatraz",
    nextgen: "homepage",
    metatrader4: "https://client-portal.cmcmarkets.com/Home/SignUp",
  },
  Uganda: {
    code: "UG",
    code_3: "UGA",
    flag: "🇺🇬",
    locale: "en",
    jurisdiction: "alcatraz",
    nextgen: "homepage",
    metatrader4: "https://client-portal.cmcmarkets.com/Home/SignUp",
  },
  Ukraine: {
    code: "UA",
    code_3: "UKR",
    flag: "🇺🇦",
    locale: "en",
    jurisdiction: "unsupported",
    nextgen: "unsupported",
    metatrader4: "unsupported",
  },
  United_Arab_Emirates: {
    code: "AE",
    code_3: "ARE",
    flag: "🇦🇪",
    locale: "en-GB",
    jurisdiction: "local",
    nextgen: "trading-platforms/web-trading-platform",
    metatrader4: "trading-platforms/mt4",
  },
  United_Kingdom: {
    code: "GB",
    code_3: "GBR",
    flag: "🇬🇧",
    locale: "en-GB",
    jurisdiction: "local",
    nextgen: "trading-platforms/web-trading-platform",
    metatrader4: "trading-platforms/mt4",
  },
  United_States_of_America: {
    code: "US",
    code_3: "USA",
    flag: "🇺🇸",
    locale: "en",
    jurisdiction: "unsupported",
    nextgen: "unsupported",
    metatrader4: "unsupported",
  },
  United_States_Outlying_Islands: {
    code: "UM",
    code_3: "UMI",
    flag: "🇺🇲",
    locale: "en",
    jurisdiction: "unsupported",
    nextgen: "unsupported",
    metatrader4: "unsupported",
  },
  Uruguay: {
    code: "UY",
    code_3: "URY",
    flag: "🇺🇾",
    locale: "es",
    jurisdiction: "alcatraz",
    nextgen: "homepage",
    metatrader4: "https://client-portal.cmcmarkets.com/Home/SignUp",
  },
  Uzbekistan: {
    code: "UZ",
    code_3: "UZB",
    flag: "🇺🇿",
    locale: "en",
    jurisdiction: "alcatraz",
    nextgen: "homepage",
    metatrader4: "https://client-portal.cmcmarkets.com/Home/SignUp",
  },
  Vanuatu: {
    code: "VU",
    code_3: "VUT",
    flag: "🇻🇺",
    locale: "en",
    jurisdiction: "alcatraz",
    nextgen: "homepage",
    metatrader4: "https://client-portal.cmcmarkets.com/Home/SignUp",
  },
  Holy_See_Vatican_City_State: {
    code: "VA",
    code_3: "VAT",
    flag: "🇻🇦",
    locale: "en",
    jurisdiction: "unsupported",
    nextgen: "unsupported",
    metatrader4: "unsupported",
  },
  Venezuela: {
    code: "VE",
    code_3: "VEN",
    flag: "🇻🇪",
    locale: "es",
    jurisdiction: "unsupported",
    nextgen: "unsupported",
    metatrader4: "unsupported",
  },
  Vietnam: {
    code: "VN",
    code_3: "VNM",
    flag: "🇻🇳",
    locale: "vi",
    jurisdiction: "alcatraz",
    nextgen: "homepage",
    metatrader4: "https://client-portal.cmcmarkets.com/Home/SignUp",
    phaseTwo: true,
  },
  Wallis_and_Futuna: {
    code: "WF",
    code_3: "WLF",
    flag: "🇼🇫",
    locale: "fr",
    jurisdiction: "alcatraz",
    nextgen: "homepage",
    metatrader4: "https://client-portal.cmcmarkets.com/Home/SignUp",
  },
  Western_Sahara: {
    code: "EH",
    code_3: "ESH",
    flag: "🇪🇭",
    locale: "en",
    jurisdiction: "alcatraz",
    nextgen: "homepage",
    metatrader4: "https://client-portal.cmcmarkets.com/Home/SignUp",
  },
  Yemen: {
    code: "YE",
    code_3: "YEM",
    flag: "🇾🇪",
    locale: "ar",
    jurisdiction: "unsupported",
    nextgen: "unsupported",
    metatrader4: "unsupported",
  },
  Zambia: {
    code: "ZM",
    code_3: "ZMB",
    flag: "🇿🇲",
    locale: "en",
    jurisdiction: "alcatraz",
    nextgen: "homepage",
    metatrader4: "https://client-portal.cmcmarkets.com/Home/SignUp",
  },
  Zimbabwe: {
    code: "ZW",
    code_3: "ZWE",
    flag: "🇿🇼",
    locale: "en",
    jurisdiction: "unsupported",
    nextgen: "unsupported",
    metatrader4: "unsupported",
  },
};

export const supportedCountries_v2Arr = Object.entries(supportedCountries_v2).map(
  ([name, country]) => ({
    name: name.replaceAll("_", " "),
    ...country,
  })
);
