import { ISO3166_Alpha2_Codes } from "@/types/country-codes";

export const countries_no: Record<ISO3166_Alpha2_Codes, string> = {
  AD: "Andorra",
  AE: "De forente arabiske emirater",
  AF: "Afganistan",
  AG: "Antigua og Barbuda",
  AI: "Anguilla",
  AL: "Albania",
  AM: "Armenia",
  AN: "De nederlandske Antillene",
  AO: "Angola",
  AQ: "Antarktis",
  AR: "Argentina",
  AS: "Amerikansk Samoa",
  AT: "Østerrike",
  AU: "Australia",
  AW: "Aruba",
  AX: "Åland",
  AZ: "Aserbajdsjan",
  BA: "Bosnia og Herzegovina",
  BB: "Barbados",
  BD: "Bangladesh",
  BE: "Belgia",
  BF: "Burkina Faso",
  BG: "Bulgaria",
  BH: "Bahrain",
  BI: "Burundi",
  BJ: "Benin",
  BL: "Saint Barth ¿lemy",
  BM: "Bermuda",
  BN: "Brunei Darussalam",
  BO: "Bolivia",
  BQ: "Bonaire, Sint Eustatius and Saba",
  BR: "Brasil",
  BS: "Bahamas",
  BT: "Bhutan",
  BV: "Bouvetøya",
  BW: "Botswana",
  BY: "Hviterussland",
  BZ: "Belize",
  CA: "Canada",
  CC: "Cocos (Keeling) Islands",
  CD: "Den demokratiske republikken Kongo",
  CF: "Den sentralafrikanske republikk",
  CG: "Kongo",
  CH: "Sveits",
  CI: "Côte d'Ivoire",
  CK: "Cook Islands",
  CL: "Chile",
  CM: "Kamerun",
  CN: "Kina",
  CO: "Colombia",
  CR: "Costa Rica",
  CU: "Cuba",
  CV: "Kapp Verde",
  CW: "Curaçao",
  CX: "Christmas Island",
  CY: "Kypros",
  CZ: "Tsjekkiske republikk",
  DE: "Tyskland",
  DJ: "Djibouti",
  DK: "Danmark",
  DM: "Dominica",
  DO: "Den dominikanske republikk",
  DZ: "Algerie",
  EC: "Ecuador",
  EE: "Estland",
  EG: "Egypt",
  EH: "Vest-Sahara",
  ER: "Eritrea",
  ES: "Spania",
  ET: "Etiopia",
  FI: "Finland",
  FJ: "Fiji",
  FK: "Falklandsøyene",
  FM: "Mikronesiaføderasjonen",
  FO: "Færøyene",
  FR: "Frankrike",
  GA: "Gabon",
  GB: "Storbritannia",
  GD: "Grenada",
  GE: "Georgia",
  GF: "Fransk Guyana",
  GG: "Guernsey",
  GH: "Ghana",
  GI: "Gibraltar",
  GL: "Grønland",
  GM: "Gambia",
  GN: "Guinea",
  GP: "Guadeloupe",
  GQ: "Ekvatorial-Guinea",
  GR: "Hellas",
  GS: "Sør-Georgia og Sør-Sandwichøyene",
  GT: "Guatemala",
  GU: "Guam",
  GW: "Guinea-Bissau",
  GY: "Guyana",
  HK: "Hong Kong",
  HM: "Heard Island og McDonald Islands",
  HN: "Honduras",
  HR: "Kroatia",
  HT: "Haiti",
  HU: "Ungarn",
  ID: "Indonesia",
  IE: "Irland",
  IL: "Israel",
  IM: "Isle of Man",
  IN: "India",
  IO: "Det britiske territoriet i Indiahavet",
  IQ: "Irak",
  IR: "Iranske islamistiske republikk",
  IS: "Island",
  IT: "Italia",
  JE: "Jersey",
  JM: "Jamaica",
  JO: "Jordan",
  JP: "Japan",
  KE: "Kenya",
  KG: "Kirgisistan",
  KH: "Kambodsja",
  KI: "Kiribati",
  KM: "Komorene",
  KN: "Saint Kitts og Nevis",
  KP: "Den demokratiske folkerepublikken Korea",
  KR: "Republikken Korea",
  KW: "Kuwait",
  KY: "Cayman Islands",
  KZ: "Kasakhstan",
  LA: "Lao Folkets demokratiske republikk",
  LB: "Libanon",
  LC: "Saint Lucia",
  LI: "Liechtenstein",
  LK: "Sri Lanka",
  LR: "Liberia",
  LS: "Lesotho",
  LT: "Litauen",
  LU: "Luxembourg",
  LV: "Latvia",
  LY: "Libya",
  MA: "Marokko",
  MC: "Monaco",
  MD: "Republikken Moldova",
  ME: "Montenegro",
  MF: "Saint Martin (fransk del)",
  MG: "Madagaskar",
  MH: "Marshalløyene",
  MK: "Den tidligere jugoslaviske republikken Makedonia",
  ML: "Mali",
  MM: "Myanmar",
  MN: "Mongolia",
  MO: "Macao",
  MP: "Nord-Marianene",
  MQ: "Martinique",
  MR: "Mauritania",
  MS: "Montserrat",
  MT: "Malta",
  MU: "Mauritsius",
  MV: "Maldivene",
  MW: "Malawi",
  MX: "Mexico",
  MY: "Malaysia",
  MZ: "Mosambik",
  NA: "Namibia",
  NC: "ny-Caledonia",
  NE: "Niger",
  NF: "Norfolk Island",
  NG: "Nigeria",
  NI: "Nicaragua",
  NL: "Nederland",
  NO: "Norge",
  NP: "Nepal",
  NR: "Nauru",
  NU: "Niue",
  NZ: "New Zealand",
  OM: "Oman",
  PA: "Panama",
  PE: "Peru",
  PF: "Fransk Polynesia",
  PG: "Papua Ny-Guinea",
  PH: "Filippinene",
  PL: "Polen",
  PM: "Saint Pierre og Miquelon",
  PN: "Pitcairn",
  PR: "Puerto Rico",
  PS: "Okkuperte palestinske området",
  PT: "Portugal",
  PW: "Palau",
  PY: "Paraguay",
  QA: "Qatar",
  RE: "Réunion",
  RO: "Romania",
  RS: "Serbia",
  RU: "Russiske føderasjon",
  RW: "Rwanda",
  SA: "Saudi-Arabia",
  SB: "Salomonøyene",
  SC: "Seychellene",
  SD: "Sudan",
  SE: "Sverige",
  SG: "Singapore",
  SH: "Saint Helena",
  SI: "Slovenia",
  SJ: "Svalbard og Jan Mayen",
  SK: "Slovakia",
  SL: "Sierra Leone",
  SM: "San Marino",
  SN: "Senegal",
  SO: "Somalia",
  SR: "Surinam",
  SS: "Sør-Sudan",
  ST: "São Tomé og Príncipe",
  SV: "El Salvador",
  SX: "Sint Maarten (Dutch part)",
  SY: "Syria",
  SZ: "Swaziland",
  TC: "Turks-og Caicosøyene",
  TD: "Tsjad",
  TF: "Franske sørlige territorier",
  TG: "Togo",
  TH: "Thailand",
  TJ: "Tadsjikistan",
  TK: "Tokelau",
  TL: "Øst-Timor",
  TM: "Turkmenistan",
  TN: "Tunisia",
  TO: "Tonga",
  TR: "Tyrkia",
  TT: "Trinidad og Tobago",
  TV: "Tuvalu",
  TW: "Provinsen i Kina Taiwan",
  TZ: "Tanzania, Den forente republikken",
  UA: "Ukraina",
  UG: "Uganda",
  UM: "United States Minor Outlying Islands",
  US: "USA",
  UY: "Uruguay",
  UZ: "Usbekistan",
  VA: "Den hellige stol (Vatikanstaten)",
  VC: "Saint Vincent og Grenadinene",
  VE: "Bolivarianske republikken Venezuela",
  VG: "Bristish Virgin Islands",
  VI: "US Virgin Islands",
  VN: "Vietnam",
  VU: "Vanuatu",
  WF: "Wallis og Futuna",
  WS: "Samoa",
  YE: "Jemen",
  YT: "Mayotte",
  ZA: "Sør-Afrika",
  ZM: "Zambia",
  ZW: "Zimbabwe",
  XK: "Kosovo",
  PK: "Pakistan",
};
