import { MessageContent } from "@/contexts/notification-context";
import Page from "@/utils/system/page";

export default class Notifications {
  private data: any;

  public notifications: MessageContent[];

  static settings = {
    error: {
      withClose: false,
      duration: 0,
    },

    warning: {
      withClose: true,
      duration: 10000,
    },

    info: {
      withClose: true,
      duration: 5000,
    },

    success: {
      withClose: true,
      duration: 5000,
    },
  };

  constructor(data: any) {
    this.data = data;
    this.notifications = [];

    this.checkForNotifications();
  }

  getMessages() {
    return this.notifications.length ? this.notifications : null;
  }

  removeMessage(id: string) {
    this.notifications = this.notifications.filter(
      (message) => message.settings.id !== id
    );
  }

  private checkForNotifications() {
    if (!this.data) return;

    const component = this?.data?.sys?.contentType?.sys?.id;

    if (component === "nGrid") this.grid();
    if (component === "nTable") this.table();
    if (component === "nChip") this.chip();
    if (component === "nDialog") this.dialog();
  }

  private grid() {
    if (!this.data) return;

    const slots = this.data.fields.slots ?? [];

    const row1Columns = this.data.fields.row1Columns ?? 0;
    const row2Columns = this.data.fields.row2Columns ?? 0;
    const row3Columns = this.data.fields.row3Columns ?? 0;
    const row4Columns = this.data.fields.row4Columns ?? 0;
    const row5Columns = this.data.fields.row5Columns ?? 0;

    const row1End = row1Columns;
    const row2End = row1End + row2Columns;
    const row3End = row2End + row3Columns;
    const row4End = row3End + row4Columns;
    const row5End = row4End + row5Columns;

    const remainingSlots = slots.slice(row5End).length;
    const rowColumnsTotal =
      row1Columns + row2Columns + row3Columns + row4Columns + row5Columns;
    const excessSlots = rowColumnsTotal - slots.length;

    // Check for unused slots
    let unusedSlotsAdded = false;
    if (remainingSlots > 0 && !unusedSlotsAdded) {
      const ERROR_CODE = "unused-slots";

      this.notifications.push({
        type: "error",
        message: `You have ${remainingSlots} unused columns. Please add more slots or remove the unused columns.`,
        settings: {
          title: "Unused slots",
          id: this.data.sys.id + "_" + ERROR_CODE,
          cta: {
            label: "Fix",
            textLinkProps: {
              variant: "positive",
              onClick: () => {
                Page.editField(this.data, "row1Columns");
              },
            },
          },
          ...Notifications.settings.error,
        },
      });

      unusedSlotsAdded = true;
    }

    // Check for missing/excess slots
    let missingSlotsAdded = false;
    if (excessSlots > 0) {
      const ERROR_CODE = "excess-slots";

      this.notifications.push({
        type: "error",
        message: `You have ${excessSlots} empty ${
          excessSlots === 1 ? "slot" : "slots"
        }. Adjust the grid columns accordingly to accommodate all cells.`,
        settings: {
          id: this.data.sys.id + "_" + ERROR_CODE,
          title: "Excess slots",
          cta: {
            label: "Fix",
            textLinkProps: {
              variant: "negative",
              onClick: () => {
                Page.editField(this.data, "row1Columns");
              },
            },
          },
          ...Notifications.settings,
        },
      });

      missingSlotsAdded = true;
    }

    // Check if loadMoreIncrement has a value but loadMoreThreshold is missing
    if (
      this.data.fields.loadMoreThreshold <= 0 ||
      (this.data.fields.loadMoreThreshold === undefined &&
        this.data.fields.loadMoreIncrement > 0)
    ) {
      const ERROR_CODE = "load-more";

      this.notifications.push({
        type: "warning",
        message: `You have set a load more increment without a load more threshold. Please set a load more threshold to enable the load more functionality.`,
        settings: {
          title: "Missing Items Threshold",
          id: this.data.sys.id + "_" + ERROR_CODE,
          cta: {
            label: "Fix",
            textLinkProps: {
              variant: "positive",
              onClick: () => {
                Page.editField(this.data, "loadMoreIncrement");
              },
            },
          },
        },
      });
    }

    // Check if loadMoreIncrement value is higher than slots length
    if (
      this.data.fields.loadMoreIncrement &&
      this.data.fields.loadMoreIncrement > slots.length
    ) {
      const ERROR_CODE = "load-more";

      this.notifications.push({
        type: "warning",
        message: `You have set a load more increment higher than the total number of items. Please adjust the load more increment value.`,
        settings: {
          title: "Invalid Load More Increment",
          id: this.data.sys.id + "_" + ERROR_CODE,
          cta: {
            label: "Fix",
            textLinkProps: {
              variant: "positive",
              onClick: () => {
                Page.editField(this.data, "loadMoreIncrement");
              },
            },
          },
        },
      });
    }
  }

  private chip() {
    if (!this.data) return;

    return;
  }

  private dialog() {
    if (!this.data) return;

    if (this.data.fields?.slots === undefined) {
      const ERROR_CODE = "empty-dialog";

      this.notifications.push({
        type: "warning",
        message: "Your dialog has no content!",
        settings: {
          title: "Dialog Empty",
          id: this.data.sys.id + "_" + ERROR_CODE,
          cta: {
            label: "Fix",
            textLinkProps: {
              variant: "positive",
              onClick: () => {
                Page.editField(this.data, "slots");
              },
            },
          },
        },
      });
    }
  }

  private table() {
    return;

    if (!this.data) return;

    // this.messages.push({
    //   type: "warning",
    //   title: "Test warning",
    //   message: `lorem ipsum dolor sit amet`,
    //   entry: this.data,
    //   // field: ""
    // });
  }
}
