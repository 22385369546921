import { ISO3166_Alpha2_Codes } from "@/types/country-codes";

export const countries_zh: Record<ISO3166_Alpha2_Codes, string> = {
  AW: "阿鲁巴岛",
  AF: "阿富汗",
  AO: "安哥拉",
  AI: "安圭拉岛",
  AX: "奥兰群岛",
  AL: "阿尔巴尼亚",
  AD: "安道尔",
  AN: "荷属安的列斯",
  AE: "阿拉伯联合酋长国",
  AR: "阿根廷",
  AM: "亚美尼亚",
  AS: "美属萨摩亚",
  AQ: "南极洲",
  TF: "法属南部领地",
  AG: "安提瓜和巴布达",
  AU: "澳大利亚",
  AT: "奥地利",
  AZ: "阿塞拜疆",
  BI: "布隆迪",
  BE: "比利时",
  BJ: "贝宁",
  BQ: "博内尔，圣尤斯特歇斯和萨巴",
  BF: "布基纳法索",
  BD: "孟加拉国",
  BG: "保加利亚",
  BH: "巴林",
  BS: "巴哈马",
  BA: "波斯尼亚和黑塞哥维那",
  BL: "圣巴泰勒米",
  BY: "白俄罗斯",
  BZ: "伯利兹",
  BM: "百慕大",
  BO: "玻利维亚（多民族国）",
  BR: "巴西",
  BB: "巴巴多斯",
  BN: "文莱达鲁萨兰国",
  BT: "不丹",
  BV: "布维岛",
  BW: "博茨瓦纳",
  CF: "中非共和国",
  CA: "加拿大",
  CC: "科科斯（基林）群岛",
  CH: "瑞士",
  CL: "智利",
  CN: "中国",
  CI: "科特迪瓦",
  CM: "喀麦隆",
  CD: "刚果（民主共和国）",
  CG: "刚果",
  CK: "库克群岛",
  CO: "哥伦比亚",
  KM: "科摩罗",
  CV: "佛得角",
  CR: "哥斯达黎加",
  CU: "古巴",
  CW: "库拉索",
  CX: "圣诞岛",
  KY: "开曼群岛",
  CY: "塞浦路斯",
  CZ: "捷克共和国",
  DE: "德国",
  DJ: "吉布提",
  DM: "多米尼加",
  DK: "丹麦",
  DO: "多米尼加共和国",
  DZ: "阿尔及利亚",
  EC: "厄瓜多尔",
  EG: "埃及",
  ER: "厄立特里亚",
  EH: "西撒哈拉",
  ES: "西班牙",
  EE: "爱沙尼亚",
  ET: "埃塞俄比亚",
  FI: "芬兰",
  FJ: "斐济",
  FK: "福克兰群岛（马尔维纳斯）",
  FR: "法国",
  FO: "法罗群岛",
  FM: "密克罗尼西亚",
  GA: "加蓬",
  GB: "英国",
  GE: "格鲁吉亚",
  GG: "根西岛",
  GH: "加纳",
  GI: "直布罗陀",
  GN: "几内亚",
  GP: "瓜德罗普岛",
  GM: "冈比亚",
  GW: "几内亚比绍",
  GQ: "赤道几内亚",
  GR: "希腊",
  GD: "格林纳达",
  GL: "格陵兰",
  GT: "危地马拉",
  GF: "法属圭亚那",
  GU: "关岛",
  GY: "圭亚那",
  HK: "香港",
  HM: "赫德岛和麦克唐纳群岛",
  HN: "洪都拉斯",
  HR: "克罗地亚",
  HT: "海地",
  HU: "匈牙利",
  ID: "印度尼西亚",
  IM: "马恩岛",
  IN: "印度",
  IO: "英属印度洋领地",
  IE: "爱尔兰",
  IR: "伊朗",
  IQ: "伊拉克",
  IS: "冰岛",
  IL: "以色列",
  IT: "意大利",
  JM: "牙买加",
  JE: "泽西岛",
  JO: "约旦",
  JP: "日本",
  KZ: "哈萨克斯坦",
  KE: "肯尼亚",
  KG: "吉尔吉斯斯坦",
  KH: "柬埔寨",
  KI: "基里巴斯",
  KN: "圣基茨和尼维斯",
  KR: "韩国",
  KW: "科威特",
  LA: "老挝",
  LB: "黎巴嫩",
  LR: "利比里亚",
  LY: "利比亚",
  LC: "圣卢西亚",
  LI: "列支敦士登",
  LK: "斯里兰卡",
  LS: "莱索托",
  LT: "立陶宛",
  LU: "卢森堡",
  LV: "拉脱维亚",
  MO: "澳门",
  MF: "圣马丁",
  MA: "摩洛哥",
  MC: "摩纳哥",
  MD: "摩尔多瓦（共和国）",
  MG: "马达加斯加",
  MV: "马尔代夫",
  MX: "墨西哥",
  MH: "马绍尔群岛",
  MK: "马其顿",
  ML: "马里",
  MT: "马耳他",
  MM: "缅甸",
  ME: "黑山共和国",
  MN: "蒙古",
  MP: "北马里亚纳群岛",
  MZ: "莫桑比克",
  MR: "毛里塔尼亚",
  MS: "蒙塞拉特岛",
  MQ: "马提尼克岛",
  MU: "毛里求斯",
  MW: "马拉维",
  MY: "马来西亚",
  YT: "马约特岛",
  NA: "纳米比亚",
  NC: "新喀里多尼亚",
  NE: "尼日尔",
  NF: "诺福克岛",
  NG: "尼日利亚",
  NI: "尼加拉瓜",
  NU: "纽埃",
  NL: "荷兰",
  NO: "挪威",
  NP: "尼泊尔",
  NR: "瑙鲁",
  NZ: "新西兰",
  OM: "阿曼",
  PK: "巴基斯坦",
  PA: "巴拿马",
  PN: "皮特凯恩",
  PE: "秘鲁",
  PH: "菲律宾",
  PW: "帕劳",
  PG: "巴布亚新几内亚",
  PL: "波兰",
  PR: "波多黎各",
  KP: "朝鲜",
  PT: "葡萄牙",
  PY: "巴拉圭",
  PS: "巴勒斯坦国",
  PF: "法属波利尼西亚",
  QA: "卡塔尔",
  RE: "留尼汪",
  RO: "罗马尼亚",
  RU: "俄罗斯",
  RW: "卢旺达",
  SA: "沙特阿拉伯",
  SD: "苏丹",
  SN: "塞内加尔",
  SG: "新加坡",
  GS: "南乔治亚岛和南桑威奇群岛",
  SH: "圣赫勒拿岛",
  SJ: "斯瓦巴和扬马延",
  SB: "所罗门群岛",
  SL: "塞拉利昂",
  SV: "萨尔瓦多",
  SM: "圣马力诺",
  SO: "索马里",
  PM: "圣皮埃尔和密克隆群岛",
  RS: "塞尔维亚",
  SS: "南苏丹",
  ST: "圣多美和普林西比",
  SR: "苏里南",
  SK: "斯洛伐克",
  SI: "斯洛文尼亚",
  SE: "瑞典",
  SZ: "斯威士兰",
  SX: "荷属圣马丁",
  SC: "塞舌尔",
  SY: "阿拉伯叙利亚共和国",
  TC: "特克斯和凯科斯群岛",
  TD: "乍得",
  TG: "多哥",
  TH: "泰国",
  TJ: "塔吉克斯坦",
  TK: "托克劳",
  TM: "土库曼斯坦",
  TL: "东帝汶",
  TO: "汤加",
  TT: "特立尼达和多巴哥",
  TN: "突尼斯",
  TR: "土耳其",
  TV: "图瓦卢",
  TW: "台湾",
  TZ: "坦桑尼亚联合共和国",
  UG: "乌干达",
  UA: "乌克兰",
  UM: "美国本土外小岛屿",
  UY: "乌拉圭",
  US: "美国",
  UZ: "乌兹别克斯坦",
  VA: "梵蒂冈",
  VC: "圣文森特和格林纳丁斯",
  VE: "委内瑞拉",
  VG: "英属维尔京群岛",
  VI: "美属维尔京群岛",
  VN: "越南",
  VU: "瓦努阿图",
  WF: "瓦利斯和富图纳群岛",
  WS: "萨摩亚",
  XK: "科索沃",
  YE: "也门",
  ZA: "南非",
  ZM: "赞比亚",
  ZW: "津巴布韦",
};
