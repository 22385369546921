// ? Detects when an element is the main element in view. Requires a single id string or an array of id strings. Useful for scroll spies.
// * Returns the id of the element that is currently in view.

import { useEffect, useState } from "react";

// Takes a single id string or an array of id strings and returns the one that is currently in view
const useIsHighlightedView = (ids: string | string[], offset: number) => {
  const [isHighlightedViewId, setIsHighlightedViewId] = useState<string | null>(null);
  useEffect(() => {
    // Convert ids to an array if it's a string
    const idsArray = Array.isArray(ids) ? ids : [ids];

    const handleScroll = () => {
      for (const id of idsArray) {
        const element = document.getElementById(id);

        if (!element) {
          console.warn(`Element with id "${id}" not found`);
          continue;
        }

        const rect = element.getBoundingClientRect();
        if (rect.top <= offset && rect.bottom > offset) {
          setIsHighlightedViewId(id);
          break;
        } else {
          setIsHighlightedViewId(null);
        }
      }
    };

    // Attach the scroll event listener
    window.addEventListener("scroll", handleScroll);

    // Initial check in case the element is already in view
    handleScroll();

    // Clean up the event listener on unmount
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [ids, offset]);

  return isHighlightedViewId;
};

export default useIsHighlightedView;
