import { ISO3166_Alpha2_Codes } from "@/types/country-codes";

export const countries_ct: Record<ISO3166_Alpha2_Codes, string> = {
  AW: "阿魯巴島",
  AF: "阿富汗",
  AO: "安哥拉",
  AI: "安圭拉島",
  AX: "奧蘭群島",
  AL: "阿爾巴尼亞",
  AD: "安道爾",
  AN: "荷屬安地列斯",
  AE: "阿拉伯聯合大公國",
  AR: "阿根廷",
  AM: "亞美尼亞",
  AS: "美屬薩摩亞",
  AQ: "南極洲",
  TF: "法屬南部領地",
  AG: "安地卡及巴布達",
  AU: "澳洲",
  AT: "奧地利",
  AZ: "亞塞拜然",
  BI: "蒲隆地",
  BE: "比利時",
  BJ: "貝南",
  BQ: "波奈、聖佑達修斯和荷屬沙巴",
  BF: "布吉納法索",
  BD: "孟加拉",
  BG: "保加利亞",
  BH: "巴林",
  BS: "巴哈馬",
  BA: "波士尼亞與赫塞哥維納",
  BL: "聖巴瑟米",
  BY: "白俄羅斯",
  BZ: "伯利茲",
  BM: "百慕達",
  BO: "玻利維亞（多民族國）",
  BR: "巴西",
  BB: "巴貝多",
  BN: "汶萊達魯薩蘭國",
  BT: "不丹",
  BV: "布威島",
  BW: "波札那",
  CF: "中非共和國",
  CA: "加拿大",
  CC: "科科斯（基林）群島",
  CH: "瑞士",
  CL: "智利",
  CN: "中國",
  CI: "象牙海岸",
  CM: "喀麥隆共和國",
  CD: "剛果（民主共和國）",
  CG: "剛果",
  CK: "庫克群島",
  CO: "哥倫比亞",
  KM: "葛摩",
  CV: "佛得角",
  CR: "哥斯大黎加",
  CU: "古巴",
  CW: "古拉索",
  CX: "聖誕島",
  KY: "開曼群島",
  CY: "賽普勒斯",
  CZ: "捷克共和國",
  DE: "德國",
  DJ: "吉布地",
  DM: "多明尼加",
  DK: "丹麥",
  DO: "多明尼加共和國",
  DZ: "阿爾及利亞",
  EC: "厄瓜多爾",
  EG: "埃及",
  ER: "厄利垂亞",
  EH: "西撒哈拉",
  ES: "西班牙",
  EE: "愛沙尼亞",
  ET: "衣索比亞",
  FI: "芬蘭",
  FJ: "斐濟",
  FK: "福克蘭群島（馬爾維納斯）",
  FR: "法國",
  FO: "法羅群島",
  FM: "密克羅尼西亞",
  GA: "加彭",
  GB: "英國",
  GE: "喬治亞",
  GG: "根西",
  GH: "迦納",
  GI: "直布羅陀",
  GN: "幾內亞",
  GP: "瓜地洛普",
  GM: "甘比亞",
  GW: "幾內亞比索",
  GQ: "赤道幾內亞",
  GR: "希臘",
  GD: "格瑞那達",
  GL: "格陵蘭",
  GT: "瓜地馬拉",
  GF: "法屬圭亞那",
  GU: "關島",
  GY: "蓋亞那",
  HK: "香港",
  HM: "赫德島和麥克唐納群島",
  HN: "宏都拉斯",
  HR: "克羅埃西亞",
  HT: "海地",
  HU: "匈牙利",
  ID: "印尼",
  IM: "曼島",
  IN: "印度",
  IO: "英屬印度洋領地",
  IE: "愛爾蘭",
  IR: "伊朗",
  IQ: "伊拉克",
  IS: "冰島",
  IL: "以色列",
  IT: "意大利",
  JM: "牙買加",
  JE: "澤西島",
  JO: "約旦",
  JP: "日本",
  KZ: "哈薩克",
  KE: "肯亞",
  KG: "吉爾吉斯",
  KH: "柬埔寨",
  KI: "吉里巴斯",
  KN: "聖克里斯多福及尼維斯",
  KR: "南韓",
  KW: "科威特",
  LA: "寮國",
  LB: "黎巴嫩",
  LR: "賴比瑞亞",
  LY: "利比亞",
  LC: "聖露西亞",
  LI: "列支敦斯登",
  LK: "斯里蘭卡",
  LS: "賴索托",
  LT: "立陶宛",
  LU: "盧森堡",
  LV: "拉脫維亞",
  MO: "澳門",
  MF: "荷屬聖馬丁",
  MA: "摩洛哥",
  MC: "摩納哥",
  MD: "摩爾多瓦（共和國）",
  MG: "馬達加斯加",
  MV: "馬爾地夫",
  MX: "墨西哥",
  MH: "馬紹爾群島",
  MK: "馬其頓 ",
  ML: "馬利",
  MT: "馬爾他",
  MM: "緬甸",
  ME: "蒙特內哥羅",
  MN: "蒙古",
  MP: "北馬利安納群島",
  MZ: "莫三比克",
  MR: "茅利塔尼亞",
  MS: "蒙哲臘",
  MQ: "馬丁尼克",
  MU: "模里西斯",
  MW: "馬拉威",
  MY: "馬來西亞",
  YT: "馬約特島",
  NA: "納米比亞",
  NC: "新喀裡多尼亞",
  NE: "尼日",
  NF: "諾福克島",
  NG: "奈及利亞",
  NI: "尼加拉瓜",
  NU: "紐埃島",
  NL: "荷蘭",
  NO: "挪威",
  NP: "尼泊爾",
  NR: "諾魯",
  NZ: "紐西蘭",
  OM: "阿曼",
  PK: "巴基斯坦",
  PA: "巴拿馬",
  PN: "皮特肯",
  PE: "秘魯",
  PH: "菲律賓",
  PW: "帛琉",
  PG: "巴布亞紐幾內亞",
  PL: "波蘭",
  PR: "波多黎各",
  KP: "北韓",
  PT: "葡萄牙",
  PY: "巴拉圭",
  PS: "巴勒斯坦國",
  PF: "法屬玻里尼西亞",
  QA: "卡達",
  RE: "留尼旺",
  RO: "羅馬尼亞",
  RU: "俄羅斯",
  RW: "盧安達",
  SA: "沙烏地阿拉伯",
  SD: "蘇丹",
  SN: "塞內加爾",
  SG: "新加坡",
  GS: "南喬治亞與南桑威奇群島",
  SH: "聖赫勒拿",
  SJ: "斯瓦巴和揚馬延",
  SB: "索羅門群島",
  SL: "獅子山",
  SV: "薩爾瓦多",
  SM: "聖馬利諾",
  SO: "索馬利亞",
  PM: "聖皮埃與密克隆群島",
  RS: "塞爾維亞",
  SS: "南蘇丹",
  ST: "聖多美普林西比",
  SR: "蘇利南",
  SK: "斯洛伐克",
  SI: "斯洛維尼亞",
  SE: "瑞典",
  SZ: "史瓦帝尼",
  SX: "聖馬丁（荷屬）",
  SC: "塞席爾",
  SY: "敘利亞阿拉伯共和國",
  TC: "土克斯及開科斯群島",
  TD: "查德",
  TG: "多哥",
  TH: "泰國",
  TJ: "塔吉克",
  TK: "托克勞",
  TM: "土庫曼",
  TL: "東帝汶",
  TO: "東加",
  TT: "千里達及托巴哥",
  TN: "突尼西亞",
  TR: "土耳其",
  TV: "吐瓦魯",
  TW: "台灣",
  TZ: "坦尚尼亞聯合共和國",
  UG: "烏干達",
  UA: "烏克蘭",
  UM: "美國本土外小島嶼",
  UY: "烏拉圭",
  US: "美國",
  UZ: "烏茲別克",
  VA: "梵蒂岡",
  VC: "聖文森及格瑞那丁",
  VE: "委內瑞拉",
  VG: "維爾京群島（英屬）",
  VI: "維爾京群島（美屬）",
  VN: "越南",
  VU: "萬那杜",
  WF: "瓦利斯和富圖那",
  WS: "薩摩亞",
  XK: "科索沃",
  YE: "葉門",
  ZA: "南非",
  ZM: "尚比亞",
  ZW: "辛巴威",
};
