import {
  Button,
  Card,
  ModalConfirm,
  Note,
  Select,
  Table,
  Text,
  TextInput,
  ToggleButton,
  Tooltip,
} from "@contentful/f36-components";

import { useNotificationStore } from "@/contexts/notification-context";
import { cn } from "@/utils/helpers";
import { ArrowDown, ArrowUp, Trash2, X } from "lucide-react";
import React, { useEffect, useRef, useState } from "react";

type Instrument = {
  name: string;
  apiCode: string;
  apiCodeFormatted: string;
  class: string;
};

type Header = {
  name: string;
  isSortable: boolean;
};

type DropdownOption = {
  label: string;
  value: string;
};

const headersList: Array<Header> = [
  { name: "NAME", isSortable: true },
  { name: "MIN SPREAD", isSortable: true },
  { name: "MARGIN RATE", isSortable: true },
  { name: "PRICE", isSortable: true },
  { name: "DAY", isSortable: true },
  { name: "WEEK", isSortable: true },
  { name: "MONTH", isSortable: true },
  { name: "YEAR", isSortable: true },
  { name: "HOLDING COST BUY", isSortable: true },
  { name: "HOLDING COST SELL", isSortable: true },
  { name: "HOLDING COST BUY AO", isSortable: true },
  { name: "HOLDING COST SELL AO", isSortable: true },
  { name: "TREND", isSortable: false },
  { name: "Commission", isSortable: false },
  { name: "INFO", isSortable: false },
];

const filterByOptions: Array<DropdownOption> = [
  { label: "All", value: "" },
  { label: "Commodities", value: "commodities" },
  { label: "ETFs", value: "etfs" },
  { label: "Forex", value: "forex indices" },
  { label: "Indices", value: "indices" },
  { label: "Shares", value: "shares" },
  { label: "Share Basket", value: "share baskets" },
  { label: "Treasuries", value: "treasuries" },
];

const localesList: Array<DropdownOption> = [
  { label: "DE-AT", value: "at" },
  { label: "DE-DE / EN-DE", value: "de" },
  { label: "EN-AU", value: "au" },
  { label: "EN-CA", value: "ca" },
  { label: "EN-GB", value: "gb" },
  { label: "EN / EN-IE", value: "ie" }, // ? Using IE for EN is intentional
  { label: "EN-NZ", value: "nz" },
  { label: "EN-SG", value: "sg" },
  { label: "ES-ES", value: "es" },
  { label: "FR-FR", value: "fr" },
  { label: "IT-IT", value: "it" },
  { label: "NB-NO", value: "no" },
  { label: "PL-PL", value: "pl" },
  { label: "SV-SE", value: "se" },
  { label: "ZH", value: "zh" },
];

export default function InstrumentTableBuilder() {
  const { addMessage } = useNotificationStore();

  const [allInstruments, setAllInstruments] = useState<Instrument[]>([]);
  const [instruments, setInstruments] = useState<Instrument[]>([]);
  const [selectedInstruments, setSelectedInstruments] = useState<Instrument[]>([]);
  const [selectedHeaders, setSelectedHeaders] = useState<Header[]>([headersList[0]]);
  const [resetIsShown, setResetIsShown] = useState<boolean>(false);

  // Search
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [searchDropdownOpen, setSearchDropdownOpen] = useState<boolean>(false);
  const [filterSearchBy, setFilterSearchBy] = useState<string>("");

  // Table row triggered on hover
  const [hoveredRowIndex, setHoveredRowIndex] = useState<number | null>(null);

  // Settings
  const [instrumentLocale, setInstrumentLocale] = useState<string>("gb");

  // Refs
  const searchDropdownRef = useRef<HTMLDivElement>(null);
  const searchDropdownItemRefs = useRef<Array<HTMLLIElement | null>>([]);
  const tableRef = useRef<HTMLTableElement>(null);

  // Index for keyboard navigation
  const [highlightedIndex, setHighlightedIndex] = useState<number | null>(null);

  const fetchInstruments = async () => {
    const endpoint = `https://ws.cmcmarkets.com/json/instruments_${instrumentLocale}.json`;
    const response = await fetch(endpoint);
    const data = await response.json();
    const parsedData = data
      .filter((item: any) => item.apiCode !== "X-AAQGC") // ? This apicode causes dodgy search behaviour... :S
      .map((item: any) => ({
        name: item.name,
        apiCode: item.apiCode,
        apiCodeFormatted: "{{{ " + item.apiCode + " }}} ",
        class: item.class,
      }));
    setAllInstruments(parsedData);
    setInstruments(parsedData);
  };

  const filterInstruments = () => {
    if (filterSearchBy) {
      const filteredInstruments = allInstruments.filter((instrument) =>
        instrument.class.toLowerCase().includes(filterSearchBy.toLowerCase())
      );
      setInstruments(filteredInstruments);
      return;
    }

    // Reset instruments if no filter
    setInstruments(allInstruments);
  };

  useEffect(() => {
    instrumentLocale && fetchInstruments();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [instrumentLocale]);

  useEffect(() => {
    filterInstruments();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterSearchBy, allInstruments]);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        searchDropdownRef.current &&
        !searchDropdownRef.current.contains(event.target as Node)
      ) {
        setSearchDropdownOpen(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    if (highlightedIndex !== null && searchDropdownOpen) {
      const currentItem = searchDropdownItemRefs.current[highlightedIndex];
      if (currentItem) {
        currentItem.scrollIntoView({ block: "nearest", inline: "nearest" });
      }
    }
  }, [highlightedIndex, searchDropdownOpen]);

  const filteredInstruments = instruments.filter((instrument) =>
    instrument.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  useEffect(() => {
    searchDropdownItemRefs.current = searchDropdownItemRefs.current.slice(
      0,
      filteredInstruments.length
    );
  }, [filteredInstruments]);

  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value);
    setSearchDropdownOpen(true);
    setHighlightedIndex(null);
  };

  const handleSelectInstrument = (instrument: Instrument) => {
    if (!selectedInstruments.find((item) => item.apiCode === instrument.apiCode)) {
      setSelectedInstruments([...selectedInstruments, instrument]);
    }
    setSearchDropdownOpen(false);
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (!searchDropdownOpen) return;

    switch (event.key) {
      case "ArrowDown":
        event.preventDefault();
        setHighlightedIndex((prevIndex) =>
          prevIndex === null || prevIndex === filteredInstruments.length - 1
            ? 0
            : prevIndex + 1
        );
        break;
      case "ArrowUp":
        event.preventDefault();
        setHighlightedIndex((prevIndex) =>
          prevIndex === null || prevIndex === 0
            ? filteredInstruments.length - 1
            : prevIndex - 1
        );
        break;
      case "Enter":
        event.preventDefault();
        if (highlightedIndex !== null && filteredInstruments[highlightedIndex]) {
          handleSelectInstrument(filteredInstruments[highlightedIndex]);
          setSearchTerm("");
        }
        break;
      case "Escape":
        setSearchDropdownOpen(false);
        setHighlightedIndex(null);
        break;
    }
  };

  const toggleHeader = (header: Header) => {
    setSelectedHeaders((prevHeaders) => {
      if (prevHeaders.includes(header)) {
        return prevHeaders.filter((h) => h !== header);
      } else {
        return [...prevHeaders, header];
      }
    });
  };

  const headerNameText = (header: Header) => {
    const sortableSuffix = header.isSortable ? " {{{ SORTABLE }}}" : "";
    return (
      header.name +
      " {{{ " +
      header?.name?.toUpperCase().replace(/ /g, "-") +
      " }}}" +
      sortableSuffix
    );
  };

  const moveInstrument = (index: number, direction: "up" | "down") => {
    const newSelectedInstruments = [...selectedInstruments];
    const [removed] = newSelectedInstruments.splice(index, 1);
    if (direction === "up") {
      newSelectedInstruments.splice(index - 1, 0, removed);
    } else {
      newSelectedInstruments.splice(index + 1, 0, removed);
    }
    setSelectedInstruments(newSelectedInstruments);
  };

  const deleteInstrument = (index: number) => {
    const newSelectedInstruments = selectedInstruments.filter((_, i) => i !== index);
    setSelectedInstruments(newSelectedInstruments);
  };

  const copyTableToClipboard = () => {
    const el = tableRef.current;
    if (!el) return;

    let range: Range;
    let sel: Selection;

    if (document.createRange && window.getSelection) {
      range = document.createRange();
      sel = window.getSelection()!;
      sel.removeAllRanges();
      try {
        range.selectNodeContents(el);
        sel.addRange(range);
      } catch (e) {
        range.selectNode(el);
        sel.addRange(range);
      }
    } else if ((document.body as any).createTextRange) {
      range = (document.body as any).createTextRange();
      // @ts-ignore
      range.moveToElementText(el);
      // @ts-ignore
      range.select();
    }

    document.execCommand("Copy");
    addMessage({
      type: "info",
      message: "Table highlighted. Ready to copy to clipboard.",
      settings: {
        id: `table-copied-${Date.now()}`,
      },
    });
  };

  // Remove all instruments and headers
  const resetTable = () => {
    setSelectedInstruments([]);
    setSelectedHeaders([headersList[0]]);
    setSearchTerm("");
    setResetIsShown(false);
  };

  return (
    <>
      <div className="theme-white-100">
        <div className="pb-32">
          <div className="mb-4">
            <Note variant="primary">
              This tool helps you build an instruments table for the Contentful rich text
              editor. Search for an instrument, click to add it to the table, and
              customize as needed. You can toggle headers, hover over rows for more
              options, delete or move rows, and copy the finished table to your clipboard
              with a single click to paste into Contentful.
            </Note>
          </div>
          <Card className="mb-4">
            <h2 className="mb-4 text-xl">Settings</h2>
            <div className="grid grid-cols-3 gap-6">
              <div>
                <label
                  className="mb-2 block text-sm font-medium text-gray-900"
                  htmlFor="locale"
                >
                  Locale
                </label>
                <Select
                  id="locale"
                  value={instrumentLocale}
                  onChange={(e) => setInstrumentLocale(e.target.value)}
                >
                  {localesList.map((option: any) => (
                    <Select.Option key={option.value} value={option.value}>
                      {option.label}
                    </Select.Option>
                  ))}
                </Select>
              </div>
              <div>
                <label
                  className="mb-2 block text-sm font-medium text-gray-900"
                  htmlFor="filterSearchBy"
                >
                  Filter search by
                </label>
                <Select
                  id="filterSearchBy"
                  value={filterSearchBy ?? ""}
                  onChange={(e) => setFilterSearchBy(e.target.value)}
                >
                  {filterByOptions.map((option: any) => (
                    <Select.Option key={option.value} value={option.value}>
                      {option.label}
                    </Select.Option>
                  ))}
                </Select>
              </div>
              <div className="col-span-3 grid grid-cols-3 pb-4 border-b border-b-gray-100">
                <div className="relative col-span-2" ref={searchDropdownRef}>
                  <label className="mb-2 block text-sm font-medium text-gray-900">
                    Instrument Search
                  </label>
                  <TextInput
                    type="text"
                    value={searchTerm}
                    onChange={handleSearch}
                    placeholder="Search for an instrument e.g. 'Apple'"
                    className="w-full p-2"
                    onClick={() => setSearchDropdownOpen(true)}
                    onKeyDown={handleKeyDown}
                  />
                  <div className="absolute right-0 top-7 flex justify-end">
                    <button onClick={() => setSearchDropdownOpen(false)} className="p-2">
                      <X
                        className={cn("h-6 w-6 text-navy-70", [
                          !searchDropdownOpen && "hidden",
                        ])}
                      />
                    </button>
                  </div>
                  {searchDropdownOpen && (
                    <div className="absolute left-0 z-50 w-full">
                      <ul className="max-h-72 overflow-auto rounded-t-lg border bg-white p-2">
                        {filteredInstruments.map((instrument, index) => (
                          <li
                            key={instrument.apiCode}
                            onClick={() => handleSelectInstrument(instrument)}
                            className={`cursor-pointer p-2 hover:bg-gray-100 ${
                              highlightedIndex === index ? "bg-gray-200" : "bg-white"
                            }`}
                            // @ts-ignore
                            ref={(el) => (searchDropdownItemRefs.current[index] = el)}
                          >
                            {instrument.name}
                          </li>
                        ))}
                        {filteredInstruments.length === 0 && (
                          <li className="p-2 text-gray-500">No results found</li>
                        )}
                      </ul>
                    </div>
                  )}
                </div>
                <div className="col-span-1 flex h-full justify-end gap-x-2">
                  <div className="mt-auto">
                    <Tooltip
                      placement="top"
                      content="After clicking, copy to your clipboard via ctrl+c or right click and copy."
                    >
                      <Button variant="primary" onClick={copyTableToClipboard}>
                        Highlight to copy
                      </Button>
                    </Tooltip>
                  </div>
                  <Button
                    variant="negative"
                    onClick={() => setResetIsShown(true)}
                    className="mt-auto"
                  >
                    Reset Table
                  </Button>
                </div>
              </div>
              <div className="col-span-full flex flex-wrap gap-2">
                <label className="mb-2 block w-full text-sm font-medium text-gray-900">
                  Columns enabled
                </label>
                {headersList.map((header) => (
                  <ToggleButton
                    key={header.name}
                    size="small"
                    onToggle={() => toggleHeader(header)}
                    isActive={selectedHeaders.some(
                      (selectedHeader) => selectedHeader.name === header.name
                    )}
                  >
                    {header.name}
                  </ToggleButton>
                ))}
              </div>
            </div>
          </Card>

          <div className="max-w-[1280px] w-full overflow-y-auto">
            <Table ref={tableRef}>
              <Table.Head>
                <Table.Row>
                  {selectedHeaders.map((header) => (
                    <Table.Cell scope="col" className="px-6 py-3" key={header.name}>
                      {headerNameText(header)}
                    </Table.Cell>
                  ))}
                </Table.Row>
              </Table.Head>
              <Table.Body>
                {selectedInstruments.map((instrument, index) => (
                  <Table.Row
                    className="relative border-b bg-white hover:bg-gray-100"
                    key={instrument.apiCode}
                    onMouseEnter={() => setHoveredRowIndex(index)}
                    onMouseLeave={() => setHoveredRowIndex(null)}
                  >
                    <Table.Cell className="px-6 py-4">
                      {instrument.apiCodeFormatted}
                      {hoveredRowIndex === index && (
                        <div className="absolute right-0 top-0 z-50 flex h-full w-full items-center justify-end space-x-2">
                          <span>{instrument.name}</span>
                          <button onClick={() => deleteInstrument(index)} className="p-1">
                            <Trash2 className="h-6 w-6 text-red-500" />
                          </button>
                          {index > 0 && (
                            <button
                              onClick={() => moveInstrument(index, "up")}
                              className="p-1"
                            >
                              <ArrowUp className="h-6 w-6 text-gray-500" />
                            </button>
                          )}
                          {index < selectedInstruments.length - 1 && (
                            <button
                              onClick={() => moveInstrument(index, "down")}
                              className="p-1"
                            >
                              <ArrowDown className="h-6 w-6 text-gray-500" />
                            </button>
                          )}
                        </div>
                      )}
                    </Table.Cell>
                    {selectedHeaders.slice(1).map((header) => (
                      <Table.Cell className="px-6 py-4" key={header.name}></Table.Cell>
                    ))}
                  </Table.Row>
                ))}
              </Table.Body>
            </Table>
          </div>
        </div>
      </div>

      <ModalConfirm
        intent="primary"
        isShown={resetIsShown}
        onCancel={() => {
          setResetIsShown(false);
        }}
        onConfirm={() => {
          resetTable();
        }}
      >
        <Text>Do you really want reset the table?</Text>
      </ModalConfirm>
    </>
  );
}
