import { useNotificationStore } from "@/contexts/notification-context";
import useGetHeaderHeight from "@/hooks/useGetHeaderHeight";
import {
  cn,
  getContentfulEnvironment,
  isLivePreview,
  scrollToSection,
} from "@/utils/helpers";
import Page from "@/utils/system/page";
import { Notification } from "@contentful/f36-components";
import {
  AlignVerticalSpaceAround,
  BoxSelect,
  Braces,
  Edit,
  FileDigit,
  FileImage,
  Heading,
  LayoutPanelTop,
  Palette,
  Settings2,
  Square,
  StretchHorizontal,
  TriangleAlert,
} from "lucide-react";
import { useRouter } from "next/router";
import { useCallback, useEffect, useState } from "react";
import "react-json-view-lite/dist/index.css";
import Notifications from "../../services/notification-service";
import { usePreviewToolsStore } from "@/contexts/preview-tools-context";

export type DebugProps = {
  [key: string]: any;
};

export const DidDebugger = ({ data }: any) => {
  return (
    <>
      <span
        className={cn(
          "bottom-0 left-0 right-0 border-[1px] border-solid -ml-[3px] -mt-[3px] h-[calc(100%+4px)] w-[calc(100%+4px)] absolute rounded-md group-hover:border-[3px]",
          `level-${data?.fields.depth}-border`
        )}
      ></span>
      <span
        className={cn(
          "hidden absolute group-hover:block -mt-[27px] -mr-[2px] text-sm right-0 px-1 rounded-md text-black",
          `level-${data?.fields.depth}-bg`
        )}
      >
        nDid
      </span>
    </>
  );
};

/**
 * Wrapper to prevent the debugger from rendering outside of live-preview
 */
const ComponentDebugger = ({ data, className, style, ...props }: any) => {
  const router = useRouter();
  const { isLivePreview } = getContentfulEnvironment(router);

  if (isLivePreview)
    return (
      <ComponentDebuggerBody
        data={data}
        className={className}
        style={style}
        isLivePreview={isLivePreview}
        {...props}
      />
    );
};

// Disable outside of live-preview
const ComponentDebuggerBody = ({
  data,
  className,
  style,
  isLivePreview,
  ...props
}: any) => {
  const componentName = data?.sys?.contentType?.sys?.id;
  const depth = data?.fields?.depth || 99;

  const [openTooltip, setOpenTooltip] = useState(false);
  const [borders, setBorders] = useState(true);

  const [isBeingEdited, setIsBeingEdited] = useState(false);

  const router = useRouter();

  const toggleBorders = useCallback(() => {
    let next;

    const url = new URL(window.location.href);

    const params = new URLSearchParams(url.search);

    if (params.get("frames") === "true") {
      next = false;
    } else if (params.get("frames") === "false") {
      next = true;
    } else {
      next = false;
    }

    params.set("frames", next.toString());

    url.search = params.toString();

    router.push(url.toString(), undefined, { shallow: true });
  }, [data]);

  useEffect(() => {
    // Notification.success("Entry is ready!");

    const url = new URL(window.location.href);

    const frames = url.searchParams.get("frames");

    if (frames === "true") setBorders(true);
    if (frames === "false") setBorders(false);

    const currentlyEditing = url.searchParams.get("currentlyEditing");

    if (currentlyEditing === data.sys.id) {
      setIsBeingEdited(true);
      componentName !== "nTemplate" && scrollToSection(data.sys.id, 350);
      componentName !== "nDocument" && scrollToSection(data.sys.id, 350);
    } else {
      setIsBeingEdited(false);
    }
  }, [router.asPath]);

  // ? Temporary solution
  const numberOfIcons = () => {
    let count = isLivePreview ? 3 : 2; // JSON and BORDER

    if (data?.fields?.verticalSpacing && isLivePreview) count++;
    if (data?.fields?.row1Columns && isLivePreview) count++;
    if (data?.fields?.title && isLivePreview) count++;
    if (data?.fields?.slots && isLivePreview) count++;
    if (data?.fields?.theme && isLivePreview) count++;
    if (data?.fields?.image || (data?.fields?.icon && isLivePreview)) count++;

    return count * 34;
  };

  const { messages, addMessage, removeMessage } = useNotificationStore();
  const { setComponentDetails, setComponentDetailsModalOpen } = usePreviewToolsStore();
  const [hasErrors, setHasErrors] = useState(false);
  const [hasInfo, setHasInfo] = useState(false);
  const [rendered, setRendered] = useState<string[]>([]);

  useEffect(() => {
    const notifs = new Notifications(data).getMessages();

    const newNotifs = notifs?.filter(
      (n) => !rendered.includes(n?.settings?.id) || n.settings.duration === 0
    );

    newNotifs?.forEach((n) => {
      if (!rendered.includes(n?.settings?.id) || n.settings.duration !== 0) addMessage(n);
      if (n.settings.duration !== 0) setRendered((prev) => [...prev, n?.settings?.id]);
    });

    messages.forEach((m) => {
      if (
        m.settings.id.includes(data.sys.id) &&
        !notifs?.some((n) => n.settings.id === m.settings.id)
      ) {
        Notification.close(m.settings.id);
        removeMessage(m.settings.id);
      }
    });

    const notifHasErrors = notifs?.some((n) => n.type === "error") ?? false;
    setHasErrors(notifHasErrors);
  }, [data]);

  const headerHeight = useGetHeaderHeight();

  if (data?.disableDebugger) return null;

  return (
    <>
      <div
        className={cn(
          "pointer-events-none absolute right-0 top-0 h-full w-full",
          [`level-${depth}`],
          className
        )}
        style={style}
        id="debugger"
        data-component-id={data.sys.id}
        data-depth={depth}
        {...props}
      >
        {hasErrors ? (
          <div
            className={cn(
              "relative z-[40] flex h-full w-full items-center justify-center text-2xl"
            )}
          >
            <TriangleAlert
              width={50}
              height={50}
              className={cn(
                "duration-1500 relative z-10 max-h-[50%] max-w-[50%] stroke-red-600 opacity-100 ease-in"
              )}
            />
          </div>
        ) : null}

        <div
          className={cn(
            "group pointer-events-none absolute z-[39] bg-none text-start text-navy-100 hover:opacity-100",
            openTooltip || isBeingEdited ? "z-[39] opacity-100" : "opacity-50",
            `level-${depth}-container`,
            hasErrors && "pointer-events-auto cursor-not-allowed opacity-100"
          )}
          onClick={() => Page.editField(data)}
        >
          {/* Title */}
          {componentName?.startsWith("n") && (
            <div
              className={cn(
                "group absolute right-0 z-[9999999] -mt-6 hidden h-min whitespace-nowrap rounded-md px-2 text-sm group-hover:flex",
                `level-${depth}-bg`,
                isBeingEdited && "flex"
              )}
            >{`${componentName} ${depth !== 99 ? `(Level: ${depth})` : ""}`}</div>
          )}
          <div
            onMouseLeave={() => setOpenTooltip(false)}
            className={cn(
              "group relative flex h-full w-full justify-end rounded-md p-1.5 transition-all duration-75",
              hasInfo && "info duration-1500 border-[5px] border-solid border-blue-500",
              hasErrors && "error duration-1500 border-[5px] border-solid border-red-500",
              isBeingEdited && !hasErrors && `level-${depth}-border border-[5px]`,
              !hasInfo && !hasErrors && `level-${depth}-border`,
              borders &&
                !hasInfo &&
                !hasErrors &&
                !isBeingEdited &&
                "border-[2px] border-dashed hover:border-[3px] hover:p-[5px] hover:shadow-xl"
            )}
          >
            {/* Icon */}
            <div
              onMouseEnter={() => setOpenTooltip(true)}
              style={
                componentName === "nTemplate" ||
                componentName === "nDocument" ||
                componentName === "nLearnArticle"
                  ? {
                      top: `${headerHeight}px`,
                      position: "sticky",
                      right: "0",
                      marginRight: "-15px",
                      marginTop: "-12px",
                    }
                  : {}
              }
              className={cn(
                "gap-1. group  pointer-events-auto absolute -right-[8px] -top-[8px] z-[38] flex h-[36px] w-[36px] cursor-pointer items-center justify-center p-1 transition-all duration-300 hover:opacity-100",
                !borders && "hidden"
              )}
            >
              {!openTooltip && (
                <Settings2
                  className={cn(`level-${depth}-stroke relative max-h-[25px]`)}
                  height={17}
                  width={17}
                />
              )}
            </div>
            <div
              className="absolute z-[39] h-min w-min"
              onMouseLeave={() => setOpenTooltip(false)}
              style={
                componentName === "nTemplate" || componentName === "nDocument"
                  ? { top: `${headerHeight + 10}px`, position: "sticky" }
                  : {}
              }
            >
              <div
                style={{
                  width: openTooltip ? `${numberOfIcons()}px` : "0px",
                  zIndex: openTooltip ? 9999 : 0,
                }}
                className={cn(
                  "pointer-events-auto left-0 top-0 -mr-1.5 -mt-1.5 flex w-auto flex-col items-center justify-center overflow-hidden rounded-md border  px-1.5 pb-1 pt-0.5 text-xxs leading-4 no-underline shadow-md transition-all duration-500",
                  `level-${depth}-border`,
                  `level-${depth}-bg-light`,
                  openTooltip
                    ? `pointer-events-auto z-50 opacity-100`
                    : `pointer-events-none opacity-0`
                )}
              >
                <div className="flex w-full justify-end gap-2">
                  {/* JSON CONTROL */}
                  <div
                    title="Log the component data in the console"
                    className="cursor-pointer"
                  >
                    <Braces
                      className={cn(
                        "h-full min-h-[24px] w-auto pt-[1.5px]",
                        `level-${depth}-stroke`
                      )}
                      height={24}
                      width={24}
                      onClick={() => console.log(data)}
                    />
                  </div>

                  {/* BORDER CONTROL */}
                  <div
                    className="cursor-pointer"
                    title={`${borders ? "Hide frames" : "Show frames"}`}
                  >
                    {borders ? (
                      <BoxSelect
                        onClick={() => toggleBorders()}
                        className={cn(
                          "h-full min-h-[25px] w-auto cursor-pointer",
                          `level-${depth}-stroke`
                        )}
                        height={20}
                        width={20}
                      />
                    ) : (
                      <Square
                        onClick={() => toggleBorders()}
                        className={cn(
                          "h-full min-h-[25px] w-auto cursor-pointer",
                          `level-${depth}-stroke`
                        )}
                        height={25}
                        width={25}
                      />
                    )}
                  </div>

                  {/* TITLE CONTROL */}
                  {isLivePreview && data?.fields?.title && (
                    <div className="cursor-pointer" title="Edit title header">
                      <Heading
                        className={cn(
                          "h-full min-h-[24px] w-auto cursor-pointer pt-[1.5px]",
                          `level-${depth}-stroke`
                        )}
                        height={24}
                        width={24}
                        onClick={() => Page.editField(data, "title")}
                      />
                    </div>
                  )}

                  {/* SPACING CONTROL */}
                  {isLivePreview && data?.fields?.verticalSpacing && (
                    <div className="cursor-pointer" title="Edit section vertical spacing">
                      <AlignVerticalSpaceAround
                        className={cn(
                          "h-full min-h-[24px] w-auto cursor-pointer pt-[1.5px]",
                          `level-${depth}-stroke`
                        )}
                        height={24}
                        width={24}
                        onClick={() => Page.editField(data, "verticalSpacing")}
                      />
                    </div>
                  )}

                  {/* SLOTS CONTROL */}
                  {isLivePreview && data?.fields?.slots && (
                    <div className="cursor-pointer" title="Edit slots">
                      <StretchHorizontal
                        className={cn(
                          "h-full min-h-[24px] w-auto pt-[1.5px]",
                          `level-${depth}-stroke`
                        )}
                        height={24}
                        width={24}
                        onClick={() => Page.editField(data, "slots")}
                      />
                    </div>
                  )}

                  {/* GRID LAYOUT CONTROL */}
                  {isLivePreview && data?.fields?.row1Columns && (
                    <div className="cursor-pointer" title="Edit grid layout">
                      <LayoutPanelTop
                        className={cn(
                          "h-full min-h-[24px] w-auto cursor-pointer pt-[1.5px]",
                          `level-${depth}-stroke`
                        )}
                        height={24}
                        width={24}
                        onClick={() => Page.editField(data, "row1Columns")}
                      />
                    </div>
                  )}

                  {/* THEME CONTROL */}
                  {isLivePreview && (data?.fields?.image || data?.fields?.icon) && (
                    <div className="cursor-pointer" title="Edit asset">
                      <FileImage
                        className={cn(
                          "h-full min-h-[24px] w-auto cursor-pointer pt-[1.5px]",
                          `level-${depth}-stroke`
                        )}
                        height={24}
                        width={24}
                        onClick={() =>
                          Page.editField(data, data.fields.image ? "image" : "icon")
                        }
                      />
                    </div>
                  )}

                  {/* IMAGE CONTROL */}
                  {isLivePreview && data?.fields?.theme && (
                    <div
                      className="cursor-pointer"
                      title={`Edit theme (${data?.fields?.theme ?? "None"})`}
                    >
                      <Palette
                        className={cn(
                          "h-full min-h-[24px] w-auto cursor-pointer pt-[1.5px]",
                          `level-${depth}-stroke`
                        )}
                        height={24}
                        width={24}
                        onClick={() => Page.editField(data, "theme")}
                      />
                    </div>
                  )}

                  <div className="cursor-pointer" title={data?.sys?.id}>
                    <FileDigit
                      className={cn(
                        "h-full min-h-[25px] w-auto cursor-pointer",
                        `level-${depth}-stroke`
                      )}
                      // On click - add to clipboard
                      onClick={() => {
                        console.log("triggering..");
                        setComponentDetails(data);
                        setComponentDetailsModalOpen(true);
                      }}
                      height={24}
                      width={24}
                    />
                  </div>

                  {/* EDIT CONTROL */}
                  {isLivePreview && (
                    <div className="cursor-pointer" title="Go to field in editor">
                      <Edit
                        className={cn(
                          "h-full min-h-[25px] w-auto cursor-pointer",
                          `level-${depth}-stroke`
                        )}
                        onClick={() => {
                          Page.editField(data);
                        }}
                        height={24}
                        width={24}
                      />
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ComponentDebugger;
