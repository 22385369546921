//   PLATFORM_BRANCH: "b",
//   API_ERROR: "err",
//   LANGUAGE: "l",
//   REGION: "r",
//   INTENT: "i",
//   PRODUCT: "p",
//   AVAILABLE_PRODUCTS: "ap",
//   IN_APP: "inApp",
//   CHANNEL: "ch",
//   DOMAIN: "d",
//   EMAIL_ADDRESS: "e",
//   INTRODUCED_BY: "iaid",
//   LEAD_SOURCE: "lsrc",
//   SCREEN: "s",
//   REDUCED_PATH: "rp",
//   SUPPRESS_FINAL_SCREEN: "sfs",
//   TAD_ID: "tadid",
//   IB_ID: "ibid",
//   ACCOUNT_TYPE: "accountType",
//   PLATFORMS: "pl",
//   DEFAULT_PLATFORM: "pld",
//   CMC_AVAILABLE_PRODUCTS: "ap_cmc",
//   MT4_AVAILABLE_PRODUCTS: "ap_mt4",
//   CMC_DEFAULT_PRODUCT: "p_cmc",
//   MT4_DEFAULT_PRODUCT: "p_mt4",
//   JOURNEY_ID: "jid",

// TODO: !!! force empty params such as iaid and email even if they are not present in the URL !!!

import { getCookie, setCookie } from "@/utils/helpers";
import { useEffect, useRef, useState } from "react";

// Use this function to trigger onboarding modal from anywhere in the app
export const triggerOnboardingModal = (onboardingUrl: string) => {
  const event = new CustomEvent("OnboardingModal", {
    detail: { onboardingUrl: onboardingUrl },
  });

  window.dispatchEvent(event);
};

// Use this function to prefetch onboarding modal from anywhere in the app
export const triggerOnboardingModalPrefetch = (onboardingUrl: string) => {
  const event = new CustomEvent("OnboardingModalPrefetch", {
    detail: { onboardingUrl: onboardingUrl },
  });

  window.dispatchEvent(event);
};

const onboardingOrigins = [
  "https://signup.cmcmarkets.com",
  "https://uat-signup.cmcmarkets.com",
  "https://hdev3-signup.cmcmarkets.com",
];

const OnboardingModalLayer = () => {
  const [isClient, setIsClient] = useState(false);
  const [isOverlaying, setIsOverlaying] = useState(false);
  const [iframeSrc, setIframeSrc] = useState("");
  const iframe = useRef<HTMLIFrameElement>(null);

  const getEmailParam = (): string | null => {
    const currentUrl = new URL(window.location.href);
    return currentUrl.searchParams.get("email") || "";
  };

  const getIaidParam = (): string | null => {
    const currentUrl = new URL(window.location.href);
    const iaidFromCookie = getCookie("IAIDCookie");
    const iaidFromUrl = currentUrl.searchParams.get("iaid");

    if (iaidFromUrl) {
      setCookie("IAIDCookie", iaidFromUrl, 365);
      return iaidFromUrl;
    }

    if (iaidFromCookie) {
      return iaidFromCookie;
    }

    return null;
  };

  const updateIframeSrc = (url: string) => {
    // Remove iframe src because swapping iframe src when url contains a hash doesn't work
    setIframeSrc("");

    // Structure to add additional parameters if needed
    const additionalParams = new Map<string, string>();

    const emailParam = getEmailParam();
    if (emailParam) {
      additionalParams.set("e", emailParam || "");
    }

    const iaidParam = getIaidParam();
    if (iaidParam) {
      additionalParams.set("iaid", iaidParam || "");
    }

    // Append additional parameters to the URL
    url = `${url}&${Array.from(additionalParams.entries())
      .map(([key, value]) => `${key}=${value}`)
      .join("&")}`;

    setTimeout(() => {
      setIframeSrc(url);
    }, 100);
  };

  // Will be used as a prefetch when the modal button is hovered
  const prefetchData = async (event: Event) => {
    return;
    // currently disabled
    const customEvent = event as CustomEvent;

    // If no onboardingUrl is provided, return
    if (!customEvent.detail.onboardingUrl) {
      return;
    }

    console.log("openModal", customEvent.detail.onboardingUrl);
    console.log("current iframe src", iframe.current?.src);

    if (customEvent.detail.onboardingUrl) {
      try {
        updateIframeSrc(customEvent.detail.onboardingUrl);
      } catch (e) {
        console.log(e);
      }
    }
  };

  const openModal = async (event: Event) => {
    const customEvent = event as CustomEvent;

    if (!customEvent.detail.onboardingUrl) {
      return;
    }

    if (customEvent.detail.onboardingUrl) {
      updateIframeSrc(customEvent.detail.onboardingUrl);
      setIsOverlaying(true);
    }
  };

  const closeModal = () => {
    setIsOverlaying(false);
  };

  useEffect(() => {
    setIsClient(true);
    if (typeof window !== "undefined") {
      window.addEventListener("OnboardingModal", openModal);
      window.addEventListener("OnboardingModalPrefetch", prefetchData);

      window.addEventListener("message", function (e) {
        if (onboardingOrigins.includes(e.origin)) {
          // Close the modal if close message is received
          if (e.data.closeApplicationForm === true) {
            closeModal();
          }

          // Push to dataLayer if pushToDataLayer is true & dataLayer is available
          if (e.data.pushToDataLayer && window.dataLayer) {
            window.dataLayer.push(e.data.pushToDataLayer.data);
          }

          // Redirect to a new page if url is provided
          if (e.data.url) {
            window.location.href = e.data.url;
            // TODO: router push
          }
        }
      });

      return () => {
        window.removeEventListener("OnboardingModal", openModal);
        window.removeEventListener("OnboardingModalPrefetch", prefetchData);
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Lock page scrolling whilst onboarding is open
  useEffect(() => {
    if (isOverlaying) {
      document.body.classList.add("overflow-hidden");
    } else {
      document.body.classList.remove("overflow-hidden");
    }
  }, [isOverlaying]);

  if (!isClient) {
    return null;
  }

  // Prevents flash of iframe when removing iframe src and setting it again
  if (!iframeSrc) {
    return null;
  }

  return (
    <iframe
      className={`onboarding-iframe-v2 fixed bottom-0 left-0 right-0 top-0 z-[999999] m-0 h-full w-full overflow-hidden border-none p-0 ${
        isOverlaying ? "block" : "hidden"
      }`}
      data-ot-ignore="true"
      ref={iframe}
      src={iframeSrc}
    ></iframe>
  );
};

export default OnboardingModalLayer;
