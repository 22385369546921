import TableSortNoneSVG from "@/components/icons/static/table-sort-none";
import { cn, getRouteDetails } from "@/utils/helpers";
import { ComponentError } from "@/utils/system/component-error";
import { useRouter } from "next/router";
import { localeMap } from "../molecules/table";

// List of columns that have multiple sub-columns
const columnsWithSubColumns = [
  { name: "HOLDING-COST-BUY-AO", subs: ["annual", "overnight"] },
  { name: "HOLDING-COST-SELL-AO", subs: ["annual", "overnight"] },
];

const Head = ({ head, thisTable }: any) => {

  const router = useRouter();
  const { locale } = getRouteDetails(router);

  try {
    const children = head?.props?.children?.[0]?.props?.children?.[0]
    
    // Match all the keywords in the table header (ie {{{ NAME }}}, {{{ PRICE }}} etc)
    const matches = typeof children === "string" && children.match(
      /\{\{\{\s*(.*?)\s*\}\}\}/g
    );

    // If no functionality/keyword is found, return the header as is
    if (!matches) return head;

    const isSortable = matches.includes("{{{ SORTABLE }}}");

    // Trim keyword (ie {{{ NAME }}} to NAME)
    const currentColumn = matches[0].replaceAll(/\{\{\{\s*(.*?)\s*\}\}\}/g, "$1");
    
    // Get config for column if it has multiple sub-columns
    const subColumns = columnsWithSubColumns.find((col) => col.name === currentColumn);

    // Trim out the actual header text
    const text = head.props.children[0].props.children[0].replaceAll(
      /\{\{\{.*?\}\}\}/g,
      ""
    );

    let asc = true;

    const sortTable = () => {
      if (!isSortable) return;

      const body = thisTable.current?.querySelector("tbody");

      if (!body) return; // Exit if tbody is not found

      const rows = Array.from(body.children);
      const sortedRows = rows.sort((a: any, b: any) => {
        // If its the show-more button row, put back at the end
        if (a.id === "show-more") return true;

        const aValue =
          a.querySelector(`[data-cell="${currentColumn}"]`)?.innerText || "";
        const bValue =
          b.querySelector(`[data-cell="${currentColumn}"]`)?.innerText || "";

        // Check if the values are numeric
        const aValueNumeric = parseFloat(aValue.replace(/,/g, ""));
        const bValueNumeric = parseFloat(bValue.replace(/,/g, ""));
        const isNumeric = !isNaN(aValueNumeric) && !isNaN(bValueNumeric);

        if (isNumeric) {
          return asc
            ? aValueNumeric - bValueNumeric
            : bValueNumeric - aValueNumeric;
        } else {
          return asc
            ? aValue.localeCompare(bValue)
            : bValue.localeCompare(aValue);
        }
      });

      // Clear tbody
      while (body.firstChild) {
        body.removeChild(body.firstChild);
      }

      // Append sorted rows back to tbody
      sortedRows.forEach((row) => {
        body.appendChild(row);
      });

      asc = !asc;
    };


    return (
      <th
        data-column-header={currentColumn}
        className={cn(isSortable && "sortable")}
        onClick={sortTable}
        colSpan={subColumns ? subColumns.subs.length : 1}
      >
        <div className="th-container">
          <span>{text}</span>
          {isSortable &&
            <TableSortNoneSVG className="self-auto" width={10} height={10} />
          }
        </div>
        {subColumns && (
          <div className="flex pt-1 justify-center text-xxs capitalize">
            {subColumns.subs.map((sub) => (
              <span key={sub} className="flex-1">{localeMap.get(locale)?.[sub as "overnight" | "annual"] || sub}</span>
            ))}
          </div>  
        )}  
      </th>
    );
  } catch (error) {
    return <ComponentError error={error} data={head} />;
  }
};

export default Head;
