import { Notification } from "@contentful/f36-components";
import { createContext, useContext, useEffect, useState } from "react";

export type MessageContent = {
  type: "error" | "warning" | "info" | "success";
  message: string;
  settings: {
    id: string;
    duration?: number;
    withClose?: boolean;
    title?: string;
    cta?: {
      label: string;
      textLinkProps?: {
        variant: "primary" | "secondary" | "negative" | "positive";
        onClick: () => void;
      };
    };
  };
};

export type NotificationsContextType = {
  messages: MessageContent[];
  addMessage: (notification: MessageContent) => void;
  removeMessage: (id: string) => void;
};
const NotificationsContext = createContext<NotificationsContextType>({
  messages: [],
  addMessage: () => {},
  removeMessage: () => {},
});

export const NotificationsProvider = ({ children }: any) => {
  const [messages, setMessages] = useState<MessageContent[]>([]);

  const addMessage = (notification: MessageContent) => {
    if (!messages.some((msg) => msg.settings.id === notification.settings.id)) {
      setMessages((prev) => [...prev, notification]);
    }
  };

  const removeMessage = (id: string) => {
    const filtered = messages.filter((message) => message.settings.id !== id);

    setMessages(filtered);
  };
  useEffect(() => {
    messages.forEach((value) => {
      if (value.type === "error") {
        Notification.error(value.message, value.settings);

        if (value.settings.duration) removeMessage(value.settings.id);
      }

      if (value.type === "warning") {
        Notification.warning(value.message, value.settings);

        if (value.settings.duration) removeMessage(value.settings.id);
      }

      if (value.type === "info") {
        Notification.info(value.message, value.settings);

        if (value.settings.duration) {
          removeMessage(value.settings.id);
        }
      }

      if (value.type === "success") {
        Notification.success(value.message, value.settings);

        if (value.settings.duration) removeMessage(value.settings.id);
      }
    });
  }, [messages, messages.length]);

  return (
    <NotificationsContext.Provider
      value={{
        messages,
        addMessage,
        removeMessage,
      }}
    >
      {children}
    </NotificationsContext.Provider>
  );
};

export const useNotificationStore = () => {
  const context = useContext(NotificationsContext);
  if (context === undefined) {
    throw new Error(
      "useNotifications must be used within a NotificationsProvider"
    );
  }
  return context;
};
