import { useRouter } from "next/router";
import { TextKey } from "@/config/i18n";
import { useEffect, useState, useMemo } from "react";
import { getContentfulEnvironment, getRouteDetails } from "@/utils/helpers";
import { Locales } from "@/config/supported-countries";

type LocalisationFile = Record<TextKey, string>;

// Cache for loaded language files
const languageCache = new Map<Locales, LocalisationFile>();
// Cache for in-progress loading promises
const loadingPromises = new Map<Locales, Promise<LocalisationFile>>();

const loadLanguageFile = (filename: Locales): Promise<LocalisationFile> => {
  // Return cached file if available
  if (languageCache.has(filename)) {
    return Promise.resolve(languageCache.get(filename) || ({} as LocalisationFile));
  }

  // Return existing loading promise if this file is already being loaded
  if (loadingPromises.has(filename)) {
    return loadingPromises.get(filename) as Promise<LocalisationFile>;
  }

  // Create and cache the loading promise
  const loadPromise: Promise<LocalisationFile> = import(`@/config/maps/${filename}`)
    .then((response: { default: LocalisationFile }) => {
      const loadedFile = response.default;
      languageCache.set(filename, loadedFile);
      loadingPromises.delete(filename);
      return loadedFile;
    })
    .catch((e) => {
      console.error(e);
      console.error("Falling back to English");
      loadingPromises.delete(filename);

      // Handle English fallback with same caching logic
      if (filename !== "en") {
        return loadLanguageFile("en");
      }

      // If even English fails, return empty object
      console.error("Failed to load English fallback");
      return {} as LocalisationFile;
    });

  loadingPromises.set(filename, loadPromise);
  return loadPromise;
};

const useLocalizedText = (key: TextKey) => {
  const [file, setFile] = useState<LocalisationFile | null>(null);
  const router = useRouter();

  const { locale, language } = useMemo(() => {
    const { isLivePreview } = getContentfulEnvironment(router);
    return getRouteDetails(router, isLivePreview);
  }, [router]);

  const filename: Locales = useMemo(() => {
    // Special cases:
    // - eo | pillar should use the en file
    // - zh-MO|TW|HK should use the zh-TW file
    // - all other zh should use the zh file
    if (language === "eo" || language === "pillar") {
      return "en";
    } else if (language === "zh") {
      const tradChineseLocales: Locales[] = ["zh-MO", "zh-TW", "zh-HK"];
      return tradChineseLocales.includes(locale) ? "zh-TW" : "zh";
    }
    return language as Locales;
  }, [language, locale]);

  useEffect(() => {
    let isMounted = true;

    loadLanguageFile(filename).then((loadedFile) => {
      if (isMounted) {
        setFile(loadedFile);
      }
    });

    return () => {
      isMounted = false;
    };
  }, [filename]);

  return file?.[key] ?? "";
};

export default useLocalizedText;
