import { ThemesG } from "@/types/design-system-types";
import { cn } from "@/utils/helpers";
import Page from "@/utils/system/page";
import { ChevronDown, X } from "lucide-react";
import { MouseEvent, useEffect, useMemo, useRef, useState } from "react";

/**
 *
 * @param initial Initial field value
 * @param options Array of dropdown options
 * @param callback Function to be executed on option select
 * @param reset Boolean value that resets selection if set to ```true```
 *
 * @example
 * <Dropdown
      initial="Please select value"
      options={["Option1", "Option2", "Option3", "Option4"]}
      callback={addDataToForm}
      reset={!formOpen}
    />
 */
function Dropdown({
  initial,
  options,
  callback,
  reset,
  className,
  id,
  theme = "white-100",
  type = "select",
}: {
  initial: string;
  options: string[];
  callback: (id: string, item: any) => void;
  reset?: boolean;
  className?: string;
  id?: string; // This is the key where form data gets saved to
  theme?: ThemesG;
  type?: "autocomplete" | "select";
}) {
  const dropdownRef = useRef<HTMLDivElement>(null);

  const [isOpen, setIsOpen] = useState(false);
  const [selected, setSelected] = useState(initial);
  const [inputValue, setInputValue] = useState("");

  // Filter out the selected option from the dropdown & map the index
  const filteredOptions = useMemo(
    () =>
      options
        .map((option, index) => ({ value: option, index }))
        .filter(
          ({ value }) =>
            value.toLowerCase().includes(inputValue.toLowerCase()) && value !== selected
        ),
    [options, selected, inputValue]
  );

  const themeBrightness = Page.getThemeBrightness(theme);

  const toggleDropdown = (e: MouseEvent) => {
    e.preventDefault();
    setIsOpen(!isOpen);
  };

  const handleItemClick = (item: string, index: number) => {
    setSelected(item);
    setInputValue(item);
    callback(id, item);
    setIsOpen(false);
  };

  const handleClickOutside: any = (event: MouseEvent) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    if (reset) {
      setSelected(initial);
      setInputValue("");
    }
  }, [reset, initial]);

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  // TODO: Review if we need themes.
  const themes = {
    dark: {
      hover: "hover:bg-navy-40 hover:text-white-100",
      open: {
        bg: "bg-navy-20",
        text: "text-white-100",
        placeholder: "placeholder:text-navy-20",
        stroke: "stroke-white-100",
        border: "border-navy-100 shadow-lg",
      },
      closed: {
        bg: "bg-navy-40",
        text: "text-white-100",
        placeholder: "placeholder:text-white",
        stroke: "stroke-white-100",
        border: "border-navy-100",
      },
    },
    light: {
      hover: "text-navy-100 hover:bg-white-95 hover:text-navy-100 focus:bg-white-90",
      open: {
        bg: "bg-white-100",
        text: "text-navy-100",
        placeholder: "",
        stroke: "stroke-navy-100",
        border: "border-navy-100 shadow-lg",
      },
      closed: {
        bg: "bg-white-100",
        text: "text-navy-100",
        placeholder: "",
        stroke: "stroke-navy-100",
        border: "border-navy-20",
      },
    },
  };

  return (
    <div
      ref={dropdownRef}
      className={`dropdown grid-center-parent relative flex h-full max-h-[36px] min-h-[36px] w-full min-w-full max-w-full justify-center ${className} z-[99999]`}
    >
      <div
        onClick={(e) => toggleDropdown(e)}
        className="relative my-auto flex w-full max-w-[calc(100%-10px)] cursor-pointer justify-between bg-transparent ps-[10px] pt-[1px]"
      >
        <input
          className={cn(
            "dropdown-toggle focus:bg-cmc-gray-10 w-full max-w-[calc(100%-20px)] cursor-pointer bg-transparent pl-1 text-start text-sm placeholder:text-sm focus:outline-none",
            isOpen
              ? `${themes[themeBrightness].open.text} ${themes[themeBrightness].open.placeholder}`
              : `${themes[themeBrightness].closed.text} ${themes[themeBrightness].closed.placeholder}`
          )}
          id={id ? id : "region"}
          value={inputValue}
          placeholder={initial}
          onClick={() => {
            if (type === "select") {
              setInputValue("");
              setSelected("");
            }
          }}
          onChange={(e) => type === "autocomplete" && setInputValue(e.target.value)}
        />
        {inputValue ? (
          <X
            width={24}
            onClick={() => {
              setInputValue("");

              if (type === "select") {
                setSelected("");
                id && callback(id, "");
              }
            }}
          />
        ) : (
          <ChevronDown
            width={24}
            className={cn(
              "transition-all duration-300",
              isOpen
                ? "rotate-180 " + themes[themeBrightness].open.stroke
                : themes[themeBrightness].closed.stroke
            )}
          />
        )}
      </div>
      <div
        className={cn(
          "top-0 h-min w-full overflow-hidden rounded-md border border-solid pt-[36px] ",
          isOpen
            ? `rounded-t-md ${themes[themeBrightness].open.bg} ${themes[themeBrightness].open.border}`
            : `rounded-md ${themes[themeBrightness].closed.bg} ${themes[themeBrightness].closed.border}`
        )}
        style={{
          maxHeight: isOpen ? "1000px" : "0px",
        }}
      >
        {
          <ul className="dropdown-menu flex flex-col max-h-[200px] overflow-scroll">
            {filteredOptions?.map((item, index) => (
              <li
                id={`${index}`}
                className={cn(
                  "mb-0 cursor-pointer px-3 py-1 text-sm ",
                  themes[themeBrightness].hover
                )}
                key={index}
                onClick={() => handleItemClick(item.value, item.index)}
              >
                {item.value}
              </li>
            ))}
          </ul>
        }
      </div>
    </div>
  );
}

export default Dropdown;
// import { ThemesG } from "@/types/design-system-types";
// import { cn } from "@/utils/helpers";
// import Page from "@/utils/system/page";
// import { ChevronDown } from "lucide-react";
// import { MouseEvent, useEffect, useMemo, useRef, useState } from "react";

// /**
//  *
//  * @param initial Initial field value
//  * @param options Array of dropdown options
//  * @param callback Function to be executed on option select
//  * @param reset Boolean value that resets selection if set to ```true```
//  *
//  * @example
//  * <Dropdown
//       initial="Please select value"
//       options={["Option1", "Option2", "Option3", "Option4"]}
//       callback={addDataToForm}
//       reset={!formOpen}
//     />
//  */
// function Dropdown({
//   initial,
//   options,
//   callback,
//   reset,
//   className,
//   id,
//   theme = "white-100",
// }: {
//   initial: string;
//   options: string[];
//   callback: (item: any, index: any) => void;
//   reset?: boolean;
//   className?: string;
//   id?: string;
//   theme?: ThemesG;
// }) {
//   const dropdownRef = useRef<HTMLDivElement>(null);

//   const [isOpen, setIsOpen] = useState(false);
//   const [selected, setSelected] = useState(initial);

//   // Filter out the selected option from the dropdown & map the index
//   const filteredOptions = useMemo(
//     () =>
//       options
//         .map((option, index) => ({ value: option, index }))
//         .filter(({ value }) => value !== selected),
//     [options, selected]
//   );

//   const themeBrightness = Page.getThemeBrightness(theme);

//   const toggleDropdown = (e: MouseEvent) => {
//     e.preventDefault();
//     setIsOpen(!isOpen);
//   };

//   const handleItemClick = (item: string, index: number) => {
//     console.log("clicked", item);
//     setSelected(item);
//     callback(item, index);
//     setIsOpen(false);
//   };

//   const handleClickOutside: any = (event: MouseEvent) => {
//     if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
//       setIsOpen(false);
//     }
//   };

//   useEffect(() => {
//     if (reset) setSelected(initial);
//   }, [reset, initial]);

//   useEffect(() => {
//     document.addEventListener("mousedown", handleClickOutside);
//     return () => {
//       document.removeEventListener("mousedown", handleClickOutside);
//     };
//   }, []);

//   // TODO: Review if we need themes.
//   const themes = {
//     dark: {
//       hover: "hover:bg-navy-40 hover:text-white-100",
//       open: {
//         bg: "bg-navy-20",
//         text: "text-white-100",
//         placeholder: "placeholder:text-navy-20",
//         stroke: "stroke-white-100",
//         border: "border-navy-100 shadow-lg",
//       },
//       closed: {
//         bg: "bg-navy-40",
//         text: "text-white-100",
//         placeholder: "placeholder:text-white",
//         stroke: "stroke-white-100",
//         border: "border-navy-100",
//       },
//     },
//     light: {
//       hover: "text-navy-100 hover:bg-white-95 hover:text-navy-100 focus:bg-white-90",
//       open: {
//         bg: "bg-white-100",
//         text: "text-navy-100",
//         placeholder: "",
//         stroke: "stroke-navy-100",
//         border: "border-navy-100 shadow-lg",
//       },
//       closed: {
//         bg: "bg-white-100",
//         text: "text-navy-100",
//         placeholder: "",
//         stroke: "stroke-navy-100",
//         border: "border-navy-20",
//       },
//     },
//   };

//   return (
//     <div
//       ref={dropdownRef}
//       className={`dropdown grid-center-parent relative flex h-full max-h-[36px] min-h-[36px] w-full min-w-full max-w-full justify-center ${className} z-[99999]`}
//     >
//       <div
//         onClick={(e) => toggleDropdown(e)}
//         className="relative my-auto flex w-full max-w-[calc(100%-10px)] cursor-pointer justify-between bg-transparent pl-[10px] pt-[1px]"
//       >
//         <input
//           className={cn(
//             "dropdown-toggle focus:bg-cmc-gray-10 w-full max-w-[calc(100%-20px)] cursor-pointer bg-transparent pl-1 text-start text-sm placeholder:text-sm focus:outline-none",
//             isOpen
//               ? `${themes[themeBrightness].open.text} ${themes[themeBrightness].open.placeholder}`
//               : `${themes[themeBrightness].closed.text} ${themes[themeBrightness].closed.placeholder}`
//           )}
//           id={id ? id : "region"}
//           value={selected}
//           placeholder={initial}
//           readOnly
//         />
//         <ChevronDown
//           width={24}
//           className={cn(
//             "transition-all duration-300",
//             isOpen
//               ? "rotate-180 " + themes[themeBrightness].open.stroke
//               : themes[themeBrightness].closed.stroke
//           )}
//         />
//       </div>
//       <div
//         className={cn(
//           "top-0 h-min w-full overflow-hidden rounded-md border border-solid pt-[36px] ",
//           isOpen
//             ? `rounded-t-md ${themes[themeBrightness].open.bg} ${themes[themeBrightness].open.border}`
//             : `rounded-md ${themes[themeBrightness].closed.bg} ${themes[themeBrightness].closed.border}`
//         )}
//         style={{
//           maxHeight: isOpen ? "1000px" : "0px",
//         }}
//       >
//         {
//           <ul className="dropdown-menu flex flex-col max-h-[200px] overflow-scroll">
//             {filteredOptions?.map((item, index) => (
//               <li
//                 id={`${index}`}
//                 className={cn(
//                   "mb-0 cursor-pointer px-3 py-1 text-sm ",
//                   themes[themeBrightness].hover
//                 )}
//                 key={index}
//                 onClick={() => handleItemClick(item.value, item.index)}
//               >
//                 {item.value}
//               </li>
//             ))}
//           </ul>
//         }
//       </div>
//     </div>
//   );
// }

// export default Dropdown;
