import { useState, useEffect, useRef, useCallback } from "react";
import debounce from "lodash.debounce";

type OverflowStatus = {
  horizontally: boolean;
  vertically: boolean;
};

const useIsOverflowing = (): [React.RefObject<HTMLDivElement>, OverflowStatus] => {
  const [isOverflowing, setIsOverflowing] = useState<OverflowStatus>({
    horizontally: false,
    vertically: false,
  });
  const ref = useRef<HTMLDivElement>(null);

  const checkOverflow = useCallback(
    debounce(() => {
      if (ref.current) {
        const overflowX = ref.current.scrollWidth > ref.current.clientWidth;
        const overflowY = ref.current.scrollHeight > ref.current.clientHeight;
        setIsOverflowing({ horizontally: overflowX, vertically: overflowY });
      }
    }, 100), // Adjust the debounce delay as needed
    []
  );

  useEffect(() => {
    checkOverflow();

    window.addEventListener("resize", checkOverflow);
    return () => window.removeEventListener("resize", checkOverflow);
  }, [checkOverflow]);

  return [ref, isOverflowing];
};

export default useIsOverflowing;

export const useIsRefOverflowing = (
  ref: React.RefObject<HTMLDivElement>
): OverflowStatus => {
  const [isOverflowing, setIsOverflowing] = useState<OverflowStatus>({
    horizontally: false,
    vertically: false,
  });

  const checkOverflow = useCallback(
    debounce(() => {
      if (!ref.current) return;

      const element = ref.current;
      const horizontally = element.scrollWidth > element.clientWidth;
      const vertically = element.scrollHeight > element.clientHeight;

      setIsOverflowing({ horizontally, vertically });
    }, 100), // Adjust the debounce delay as needed
    [ref]
  );

  useEffect(() => {
    // Initial check and setup to listen for window resize
    checkOverflow();
    window.addEventListener("resize", checkOverflow);

    // Set up a resize observer to listen for changes in size of the ref element
    const resizeObserver = new ResizeObserver(checkOverflow);
    if (ref.current) {
      resizeObserver.observe(ref.current);
    }

    // Clean up observer and window resize event listener on component unmount
    return () => {
      window.removeEventListener("resize", checkOverflow);
      if (ref.current) {
        resizeObserver.unobserve(ref.current);
      }
    };
  }, [ref, checkOverflow]);

  return isOverflowing;
};
