import dynamic from "next/dynamic";
import ComponentDebugger from "./component-debugger";
import { useRouter } from "next/router";
import { ErrorBoundary } from "react-error-boundary";
import { ComponentError } from "./component-error";
import { getContentfulEnvironment } from "../helpers";


// !!! PLEASE DO NOT CHANGE THE FORMATTING ON THIS FILE - LONG LINES ARE MORE CLEAR FOR IMPORTS !!!

// Generic
const componentPaths = new Map();
componentPaths.set("nSidecarAdapter", dynamic(() => import("@/components/core/custom/sidecar-adapter")));
componentPaths.set("nMiniBanner", dynamic(() => import("@/components/core/organisms/mini-banner")));
componentPaths.set("nAssetWrapper",dynamic(() => import("@/components/core/atoms/asset-wrapper")));
componentPaths.set("nAccordion", dynamic(() => import("@/components/core/molecules/accordion")));
componentPaths.set("nGridSpacer", dynamic(() => import("@/components/core/atoms/grid-spacer")));
componentPaths.set("nSection", dynamic(() => import("@/components/core/organisms/section")));
componentPaths.set("nFaqStack", dynamic(() => import("@/components/core/organisms/stack")));
componentPaths.set("nRichText", dynamic(() => import("@/components/core/atoms/rich-text")));
componentPaths.set("nBanner", dynamic(() => import("@/components/core/organisms/banner")));
componentPaths.set("nDialog", dynamic(() => import("@/components/core/organisms/dialog")));
componentPaths.set("nTemplate", dynamic(() => import("@/components/templates/template")));
componentPaths.set("nTitle", dynamic(() => import("@/components/core/molecules/title")));
componentPaths.set("nTable", dynamic(() => import("@/components/core/molecules/table"))); // !! deprecated
componentPaths.set("nStack", dynamic(() => import("@/components/core/organisms/stack")));
componentPaths.set("nDocument", dynamic(() => import("@/components/utility/document")));
componentPaths.set("nButton", dynamic(() => import("@/components/core/atoms/button")));
componentPaths.set("nTile", dynamic(() => import("@/components/core/molecules/tile")));
componentPaths.set("nChip", dynamic(() => import("@/components/core/molecules/chip")));
componentPaths.set("nCard", dynamic(() => import("@/components/core/molecules/card")));
componentPaths.set("nGrid", dynamic(() => import("@/components/core/organisms/grid")));
componentPaths.set("nNews", dynamic(() => import("@/components/core/organisms/news"))); // !! deprecated
componentPaths.set("nTabs", dynamic(() => import("@/components/core/molecules/tabs")));
componentPaths.set("nForm", dynamic(() => import("@/components/core/form/form-body")));
componentPaths.set("nDid", dynamic(() => import("@/components/core/molecules/did")));
componentPaths.set("nIcons", dynamic(() => import("@/components/icons/icons")));
componentPaths.set("nFaq", dynamic(() => import("@/components/utility/faq")));

// Template pages
componentPaths.set("nLearnArticle", dynamic(() => import("@/components/templates/learn-article")));
// componentPaths.set("nNewsArticle", dynamic(() => import("@/components/templates/news-article")));

// Custom
const customComponentPaths = new Map();
customComponentPaths.set("TrustpilotCarouselWidget", dynamic(() => import("@/components/core/custom/trustpilot-carousel-widget")));
customComponentPaths.set("PricePlusCalculator", dynamic(() => import("@/components/core/custom/price-plus-calculator")));
customComponentPaths.set("ShareBasketsChart", dynamic(() => import("@/components/core/custom/share-baskets-chart")));
customComponentPaths.set("AppStoreQRCode", dynamic(() => import("@/components/core/custom/app-store-qr-code")));
customComponentPaths.set("InstrumentCards", dynamic(() => import("@/components/core/custom/instrument-cards")));
customComponentPaths.set("AlcatrazLogin", dynamic(() => import("@/components/core/custom/alcatraz-login")));
customComponentPaths.set("KnowledgeHub", dynamic(() => import("@/components/core/custom/knowledge-hub")));
customComponentPaths.set("GoogleMaps", dynamic(() => import("@/components/core/custom/google-maps")));
customComponentPaths.set("NewsCard", dynamic(() => import("@/components/core/custom/news-card")));
customComponentPaths.set("Glossary", dynamic(() => import("@/components/core/custom/glossary")));
customComponentPaths.set("iframe", dynamic(() => import("@/components/core/custom/iframe")));

// Experimental
customComponentPaths.set("HTMLInjecter", dynamic(() => import("@/components/core/custom/html-injecter")));

// Template Navigation
customComponentPaths.set("TemplateNavigation-Document", dynamic(() => import("@/components/core/custom/template-navigation/sidenav-document")));
customComponentPaths.set("TemplateNavigation-Glossary", dynamic(() => import("@/components/core/custom/template-navigation/sidenav-glossary")));
customComponentPaths.set("TemplateNavigation-FAQsHub", dynamic(() => import("@/components/core/custom/template-navigation/sidenav-faqs-hub")));

// !TEMPORARY
customComponentPaths.set("FAQsManager", dynamic(() => import("@/components/core/custom/faqs-manager")));

// Widgets
customComponentPaths.set("AdaptiveMT4DownloadButton", dynamic(() => import("@/components/core/widgets/adaptive-mt4-download-button")));
customComponentPaths.set("AdaptiveMT5DownloadButton", dynamic(() => import("@/components/core/widgets/adaptive-mt5-download-button")));
customComponentPaths.set("OnetrustPreferencesButton", dynamic(() => import("@/components/core/widgets/onetrust-preferences-button")));
customComponentPaths.set("AdaptiveMetaTraderQRCode", dynamic(() => import("@/components/core/widgets/adaptive-metatrader-qr-code")));
customComponentPaths.set("AdaptiveAppStoreLink", dynamic(() => import("@/components/core/widgets/adaptive-app-store-link")));
customComponentPaths.set("AppleAppStoreButton", dynamic(() => import("@/components/core/widgets/apple-app-store-button")));
customComponentPaths.set("GooglePlayStoreButton", dynamic(() => import("@/components/core/widgets/google-play-button")));
customComponentPaths.set("InstrumentsSearch", dynamic(() => import("@/components/core/widgets/instruments-search")));
customComponentPaths.set("TrustpilotMicro", dynamic(() => import("@/components/core/widgets/trustpilot-micro")));

export const ComponentResolver = (props: any) => {

  const router = useRouter();
  const { isLive, isLivePreview } = getContentfulEnvironment(router)

  const componentName = props.componentName || props.data?.sys?.contentType?.sys?.id; // (eg nTile, nComponent, etc.)
  const componentId = props.data?.sys?.id; // (eg 123456)

  const customComponentName = props.data?.fields?.component?.replaceAll(" ", ""); // (eg TrustpilotCarouselWidget, Instrument Table etc.)
  const componentMenuLabel = props.data?.fields?.scrollSpy
    ?.toLowerCase()
    .replaceAll(" ", "-"); // (eg "Menu Label -> "menu-label")

  // Default
  let Component: any = <div>An error occured while trying to render the component.</div>;

  // If no component name found return error
  if (!componentName && !customComponentName && !isLive)
    return <div>Could not find a component name in the Contentful data.</div>;

  // If no component found return error
  if (
    !customComponentPaths.get(customComponentName) && // ! <----
    !componentPaths.get(componentName)// ! <----
  ) {
    // If custom but not found
    if (customComponentName && !isLive)
      return <div> No custom component with the name &apos;{customComponentName}&apos; found.</div>

    // If generic but not found
    return !isLive && <div>No component with the name &apos;{componentName}&apos; found.</div>;
  }

  // Get the component
  Component =
    customComponentPaths.get(customComponentName) || componentPaths.get(componentName);

  // Does the component need a dir="ltr" attribute
  const hasLTRTag = props.data?.metadata?.tags?.some((tag: any) => tag.sys.id === "ltr");

  return (
    // Add logging here
    <ErrorBoundary FallbackComponent={ComponentError} onError={() => {}}>
      <Component
        component-name={componentName}
        component-id={componentId}
        id={componentMenuLabel}
        data={props?.data?.fields}
        className={props?.className}
        debug={isLivePreview && !props.data.disableDebugger && <ComponentDebugger data={props.data} />}
        suppressHydrationWarning={true}
        dir={hasLTRTag ? "ltr" : null}
        {...props}
      />
    </ErrorBoundary>
  );
};

export default ComponentResolver;
