import { ISO3166_Alpha2_Codes } from "@/types/country-codes";

export const countries_ko: Record<ISO3166_Alpha2_Codes, string> = {
  AW: "아루바",
  AF: "아프가니스탄",
  AO: "앙골라",
  AI: "앵귈라",
  AX: "올란드 제도",
  AL: "알바니아",
  AD: "안도라",
  AN: "네덜란드령 앤틸리스",
  AE: "아랍에미리트",
  AR: "아르헨티나",
  AM: "아르메니아",
  AS: "아메리칸사모아",
  AQ: "남극",
  TF: "프랑스 남부 지역",
  AG: "앤티가 바부다",
  AU: "오스트레일리아",
  AT: "오스트리아",
  AZ: "아제르바이잔",
  BI: "부룬디",
  BE: "벨기에",
  BJ: "베냉",
  BQ: "보네르, 신트 유스타티우스, 사바",
  BF: "부르키나파소",
  BD: "방글라데시",
  BG: "불가리아",
  BH: "바레인",
  BS: "바하마",
  BA: "보스니아 헤르체고비나",
  BL: "생바르텔레미",
  BY: "벨라루스",
  BZ: "벨리즈",
  BM: "버뮤다",
  BO: "볼리비아",
  BR: "브라질",
  BB: "바베이도스",
  BN: "브루나이",
  BT: "부탄",
  BV: "부베 섬",
  BW: "보츠와나",
  CF: "중앙아프리카 공화국",
  CA: "캐나다",
  CC: "코코스 제도",
  CH: "스위스",
  CL: "칠레",
  CN: "중국",
  CI: "코트디부아르",
  CM: "카메룬",
  CD: "콩고 민주 공화국",
  CG: "콩고",
  CK: "쿡 제도",
  CO: "콜롬비아",
  KM: "코모로",
  CV: "카보베르데",
  CR: "코스타리카",
  CU: "쿠바",
  CW: "큐라소",
  CX: "크리스마스 섬",
  KY: "케이맨 제도",
  CY: "키프로스",
  CZ: "체코",
  DE: "독일",
  DJ: "지부티",
  DM: "도미니카",
  DK: "덴마크",
  DO: "도미니카 공화국",
  DZ: "알제리",
  EC: "에콰도르",
  EG: "이집트",
  ER: "에리트레아",
  EH: "서사하라",
  ES: "스페인",
  EE: "에스토니아",
  ET: "에티오피아",
  FI: "핀란드",
  FJ: "피지",
  FK: "포클랜드 제도(말비나스)",
  FR: "프랑스",
  FO: "페로 제도",
  FM: "미크로네시아",
  GA: "가봉",
  GB: "영국",
  GE: "조지아",
  GG: "건지",
  GH: "가나",
  GI: "지브롤터",
  GN: "기니",
  GP: "과들루프",
  GM: "감비아",
  GW: "기니비사우",
  GQ: "적도 기니",
  GR: "그리스",
  GD: "그레나다",
  GL: "그린란드",
  GT: "과테말라",
  GF: "프랑스령 기아나",
  GU: "괌",
  GY: "가이아나",
  HK: "홍콩",
  HM: "허드 맥도널드 제도",
  HN: "온두라스",
  HR: "크로아티아",
  HT: "아이티",
  HU: "헝가리",
  ID: "인도네시아",
  IM: "맨 섬",
  IN: "인도",
  IO: "영국령 인도양 지역",
  IE: "아일랜드",
  IR: "이란",
  IQ: "이라크",
  IS: "아이슬란드",
  IL: "이스라엘",
  IT: "이탈리아",
  JM: "자메이카",
  JE: "저지 섬",
  JO: "요르단",
  JP: "일본",
  KZ: "카자흐스탄",
  KE: "케냐",
  KG: "키르기스스탄",
  KH: "캄보디아",
  KI: "키리바시",
  KN: "세인트 키츠 네비스",
  KR: "대한민국",
  KW: "쿠웨이트",
  LA: "라오스",
  LB: "레바논",
  LR: "라이베리아",
  LY: "리비아",
  LC: "세인트루시아",
  LI: "리히텐슈타인",
  LK: "스리랑카",
  LS: "레소토",
  LT: "리투아니아",
  LU: "룩셈부르크",
  LV: "라트비아",
  MO: "마카오",
  MF: "생마르탱",
  MA: "모로코",
  MC: "모나코",
  MD: "몰도바",
  MG: "마다가스카르",
  MV: "몰디브",
  MX: "멕시코",
  MH: "마셜 제도",
  MK: "마케도니아",
  ML: "말리",
  MT: "몰타",
  MM: "미얀마",
  ME: "몬테네그로",
  MN: "몽골",
  MP: "북마리아나 제도",
  MZ: "모잠비크",
  MR: "모리타니아",
  MS: "몬트세랫",
  MQ: "마르티니크",
  MU: "모리셔스",
  MW: "말라위",
  MY: "말레이시아",
  YT: "마요트",
  NA: "나미비아",
  NC: "누벨칼레도니",
  NE: "니제르",
  NF: "노퍽 섬",
  NG: "나이지리아",
  NI: "니카라과",
  NU: "니우에",
  NL: "네덜란드",
  NO: "노르웨이",
  NP: "네팔",
  NR: "나우루",
  NZ: "뉴질랜드",
  OM: "오만",
  PK: "파키스탄",
  PA: "파나마",
  PN: "핏케언",
  PE: "페루",
  PH: "필리핀",
  PW: "팔라우",
  PG: "파푸아뉴기니",
  PL: "폴란드",
  PR: "푸에르토리코",
  KP: "북한",
  PT: "포르투갈",
  PY: "파라과이",
  PS: "팔레스타인",
  PF: "프랑스령 폴리네시아",
  QA: "카타르",
  RE: "레위니옹",
  RO: "루마니아",
  RU: "러시아",
  RW: "르완다",
  SA: "사우디아라비아",
  SD: "수단",
  SN: "세네갈",
  SG: "싱가포르",
  GS: "사우스조지아 사우스샌드위치 제도",
  SH: "세인트헬레나",
  SJ: "스발바르 얀마옌",
  SB: "솔로몬 제도",
  SL: "시에라리온",
  SV: "엘살바도르",
  SM: "산마리노",
  SO: "소말리아",
  PM: "생피에르 미클롱",
  RS: "세르비아",
  SS: "남수단",
  ST: "상투메 프린시페",
  SR: "수리남",
  SK: "슬로바키아",
  SI: "슬로베니아",
  SE: "스웨덴",
  SZ: "에스와티니",
  SX: "신트마르턴(네덜란드령 지역)",
  SC: "세이셸",
  SY: "시리아",
  TC: "터크스 케이커스 제도",
  TD: "차드",
  TG: "토고",
  TH: "태국",
  TJ: "타지키스탄",
  TK: "토켈라우",
  TM: "투르크메니스탄",
  TL: "동티모르",
  TO: "통가",
  TT: "트리니다드 토바고",
  TN: "튀니지",
  TR: "터키",
  TV: "투발루",
  TW: "대만",
  TZ: "탄자니아",
  UG: "우간다",
  UA: "우크라이나",
  UM: "미국령 군소 제도",
  UY: "우루과이",
  US: "미국",
  UZ: "우즈베키스탄",
  VA: "바티칸",
  VC: "세인트 빈센트 그레나딘",
  VE: "베네수엘라",
  VG: "영국령 버진아일랜드",
  VI: "미국령 버진아일랜드",
  VN: "베트남",
  VU: "바누아투",
  WF: "왈리스 푸투나",
  WS: "사모아",
  XK: "코소보",
  YE: "예멘",
  ZA: "남아프리카 공화국",
  ZM: "잠비아",
  ZW: "짐바브웨",
};
