import { cn } from "@/utils/helpers";
import React, { useEffect, useState } from "react";

const MobileMenuSpacer = () => {
  const [menuOnPage, setMenuOnPage] = useState(false);

  useEffect(() => {
    const menu = document.getElementById("mobile-nav-menu");

    if (menu) setMenuOnPage(true);
  }, []);

  return (
    <div
      className={cn(
        "pointer-events-none top-[200px] -z-[2] h-[56px] w-0 lg:hidden",
        menuOnPage ? "block" : "hidden"
      )}
    ></div>
  );
};

export default MobileMenuSpacer;
