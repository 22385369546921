import { BLCLinkCheckResult } from "@/types/broken-link-checker";
import { Badge, Box, List, Popover } from "@contentful/f36-components";

export default function LinkCheckerTableErrorTooltip({
  result,
  setResults,
}: {
  result: BLCLinkCheckResult;
  setResults: any;
}) {
  if (!result.errors || result.errors.length === 0) return <></>;

  return (
    <Popover
      placement="top"
      isOpen={result.errorTooltipOpen}
      onClose={() => {
        setResults((prev: BLCLinkCheckResult[]) => {
          return prev.map((r) => {
            if (r.id === result.id) {
              return {
                ...r,
                errorTooltipOpen: false,
              };
            }
            return r;
          });
        });
      }}
    >
      <Popover.Trigger>
        <Badge
          className="cursor-help"
          size="small"
          variant="negative"
          onMouseEnter={() => {
            setResults((prev: BLCLinkCheckResult[]) => {
              return prev.map((r) => {
                if (r.id === result.id) {
                  return {
                    ...r,
                    errorTooltipOpen: !r.errorTooltipOpen,
                  };
                }
                return r;
              });
            });
          }}
          onMouseLeave={() => {
            setResults((prev: BLCLinkCheckResult[]) => {
              return prev.map((r) => {
                if (r.id === result.id) {
                  return {
                    ...r,
                    errorTooltipOpen: false,
                  };
                }
                return r;
              });
            });
          }}
        >
          Problem found
        </Badge>
      </Popover.Trigger>
      <Popover.Content>
        <Box padding="spacingM">
          <List className="list-disc text-red-700 max-w-xs pl-4" as="ul">
            {result.errors?.map((error) => (
              <List.Item key={error.type}>{error.message}</List.Item>
            ))}
          </List>
        </Box>
      </Popover.Content>
    </Popover>
  );
}
