import { isProd } from "@/constants";
import { ButtonT } from "@/types/design-system-types";
import { getRouteDetails, isAlcatraz } from "@/utils/helpers";
import { NextRouter } from "next/router";

type OnboardingType = NonNullable<ButtonT["onboardingType"]>;
type OnboardingProduct = NonNullable<ButtonT["onboardingProduct"]>;

type SignUpParams = Record<OnboardingType, Record<OnboardingProduct, string>>;

type OnboardingParams = {
  onboardingDataParams: {
    "data-iframe-params"?: string;
    "data-function"?: OnboardingType;
    "data-url"?: string;
    "data-label"?: string;
  };
  onboardingUrl: string | null;
};

class OnboardingService {
  private routeDetails: any;

  constructor(router: NextRouter) {
    this.routeDetails = getRouteDetails(router);
  }

  private getSignUpParams(
    onboardingType: OnboardingType,
    onboardingProduct: OnboardingProduct
  ): string {
    const signUpParams: SignUpParams | null =
      attributeMap.get(this.routeDetails.locale) || null;
    return (signUpParams && signUpParams[onboardingType]?.[onboardingProduct]) || "";
  }

  public getOnboardingData(
    onboardingType: OnboardingType,
    onboardingProduct: OnboardingProduct
  ): OnboardingParams {
    let onboardingParams = this.getSignUpParams(onboardingType, onboardingProduct);

    // I hate this solution but never had a case where iaid or params are different between environments...
    // TODO: Come up with way to intercept onboarding params when env is preview
    if (!isProd && onboardingParams.includes("820296")) {
      onboardingParams = onboardingParams.replace("820296", "271131"); // Swap iaid to *hdev3 iaid* for preview
    }
    // Prepend environment onboarding url
    const prependedSignupUrl = isProd ? "signup" : "hdev3-signup";

    // Determine if onboarding is retail or alcatraz
    const appendedSignupUrl = isAlcatraz(this.routeDetails.locale)
      ? "internationalCountrySelection"
      : "emailRegistration";

    // Build onboarding url
    const onboardingUrl = `https://${prependedSignupUrl}.cmcmarkets.com/#/${appendedSignupUrl}?${onboardingParams}`;

    const onboardingDataParams = {
      "data-iframe-params": `?${onboardingParams}`,
      "data-function": onboardingType,
      "data-url": `/${this.routeDetails.locale}/onboarding`,
      "data-label": "Create Account",
    };

    return { onboardingDataParams, onboardingUrl };
  }
}

export default OnboardingService;

const attributeMap = new Map<string, SignUpParams>();

// ? Retail locales

/* Pillar (these endpoints won't be used but is helpful when testing on the pillar) */
attributeMap.set("pillar", {
  live: {
    sb: "",
    pro: "",
    cfd: "l=en&p=CFD&i=live&inApp=0&ch=4&lsrc=0",
    mt4: "l=en&p=MT4&i=live&inApp=0&ch=4&lsrc=0",
    tv: "l=en&r=ca&iaid=820296&jid=gtv1",
    bm: "",
  },
  demo: {
    sb: "",
    pro: "",
    cfd: "l=en&p=CFD&i=demo&inApp=0&ch=4&lsrc=0",
    mt4: "l=en&p=MT4&i=demo&inApp=0&ch=4&lsrc=0",
    tv: "l=en&r=ca&iaid=820296&jid=gtv1",
    bm: "",
  },
});

/* en */
attributeMap.set("en", {
  live: {
    sb: "",
    pro: "",
    cfd: "l=en&p=CFD&i=live&inApp=0&ch=4&lsrc=0",
    mt4: "l=en&p=MT4&i=live&inApp=0&ch=4&lsrc=0",
    tv: "l=en&r=ca&iaid=820296&jid=gtv1",
    bm: "",
  },
  demo: {
    sb: "",
    pro: "",
    cfd: "l=en&p=CFD&i=demo&inApp=0&ch=4&lsrc=0",
    mt4: "l=en&p=MT4&i=demo&inApp=0&ch=4&lsrc=0",
    tv: "l=en&r=ca&iaid=820296&jid=gtv1",
    bm: "",
  },
});

/* en-DE */
attributeMap.set("en-DE", {
  live: {
    sb: "",
    cfd: "jid=eu1&iaid=null&lsrc=1",
    mt4: "?jid=eu2&iaid=null&lsrc=1",
    pro: "l=en&jid=eu1&e=",
    tv: "l=en&r=de&iaid=820296&jid=gtv1",
    bm: "",
  },
  demo: {
    sb: "",
    pro: "",
    cfd: "jid=eu1&iaid=null&lsrc=1",
    mt4: "?jid=eu2&iaid=null&lsrc=1",
    tv: "l=en&r=de&iaid=820296&jid=gtv1",
    bm: "",
  },
});

/* en-GB */
attributeMap.set("en-GB", {
  live: {
    pro: "",
    cfd: "jid=gb1&amp;lsrc=1", //removed &amp;iaid=null
    mt4: "jid=gb2&amp;lsrc=0",
    sb: "jid=gb1&amp;lsrc=1", // no different parameters provided for SB so using CFD parameters. Update this if anything changes for SB
    tv: "l=en&r=gb&iaid=820296&jid=gtv1",
    bm: "",
  },
  demo: {
    pro: "",
    cfd: "jid=gb3&amp;lsrc=1", //removed &amp;iaid=null
    mt4: "jid=gb4&amp;lsrc=0",
    sb: "jid=gb3&amp;lsrc=1", // no different parameters provided for SB so using CFD parameters. Update this if anything changes for SB
    tv: "l=en&r=gb&iaid=820296&jid=gtv1",
    bm: "",
  },
});

/* en-IE */
attributeMap.set("en-IE", {
  live: {
    sb: "",
    pro: "",
    cfd: "",
    mt4: "l=en&r=ie&p=MT4&ap=MT4&i=live&inApp=0&ch=4&lsrc=0",
    tv: "l=en&r=ie&iaid=820296&jid=gtv1",
    bm: "",
  },
  demo: {
    sb: "",
    pro: "",
    cfd: "",
    mt4: "l=en&r=ie&p=MT4&ap=MT4&i=demo&inApp=0&ch=4&lsrc=0",
    tv: "l=en&r=ie&iaid=820296&jid=gtv1",
    bm: "",
  },
});

/* de */
attributeMap.set("de", {
  live: {
    sb: "",
    pro: "",
    cfd: "",
    mt4: "l=de&r=&p=MT4&ap=MT4&i=live&inApp=0&ch=4&lsrc=0",
    tv: "l=en&r=de&iaid=820296&jid=gtv1",
    bm: "",
  },
  demo: {
    sb: "",
    pro: "",
    cfd: "",
    mt4: "l=de&r=&p=MT4&ap=MT4&i=demo&inApp=0&ch=4&lsrc=0",
    tv: "l=en&r=de&iaid=820296&jid=gtv1",
    bm: "",
  },
});

/* de-AT */
attributeMap.set("de-AT", {
  live: {
    sb: "",
    pro: "",
    cfd: "l=de&r=at&p=CFD&i=live&sc=false&d=CMC&lsrc=1&rp=false&ap=CFD_MT4",
    mt4: "l=de&r=at&p=MT4&ap=MT4&i=live&inApp=0&ch=4&lsrc=0",
    tv: "l=de&r=at&iaid=820296&jid=gtv1",
    bm: "",
  },
  demo: {
    sb: "",
    pro: "",
    cfd: "l=de&r=at&p=CFD&i=demo&sc=false&d=CMC&lsrc=1&rp=false&ap=CFD_MT4",
    mt4: "l=de&r=at&p=MT4&ap=MT4&i=demo&inApp=0&ch=4&lsrc=0",
    tv: "l=de&r=at&iaid=820296&jid=gtv1",
    bm: "",
  },
});

/* de-DE */
attributeMap.set("de-DE", {
  live: {
    sb: "",
    pro: "",
    cfd: "l=de&r=de&i=live&sc=false&d=CMC&lsrc=1&rp=false&p=CFD&ap=CFD_MT4",
    mt4: "l=de&r=de&p=MT4&ap=MT4&i=live&inApp=0&ch=4&lsrc=0",
    tv: "l=de&r=de&iaid=820296&jid=gtv1",
    bm: "",
  },
  demo: {
    sb: "",
    pro: "",
    cfd: "l=de&r=de&i=demo&sc=false&d=CMC&lsrc=1&rp=false&p=CFD&ap=CFD_MT4",
    mt4: "l=de&r=de&p=MT4&ap=MT4&i=demo&inApp=0&ch=4&lsrc=0",
    tv: "l=de&r=de&iaid=820296&jid=gtv1",
    bm: "",
  },
});

/* fr-FR */
attributeMap.set("fr-FR", {
  live: {
    sb: "",
    pro: "",
    cfd: "l=fr&r=fr&p=CFD&i=live&sc=false&d=CMC&lsrc=1&rp=false&ap=CFD_MT4",
    mt4: "l=fr&r=fr&p=MT4&i=live&sc=false&d=CMC&lsrc=1&rp=false",
    tv: "l=fr&r=fr&iaid=820296&jid=gtv1",
    bm: "",
  },
  demo: {
    sb: "",
    pro: "",
    cfd: "l=fr&r=fr&p=CFD&i=demo&sc=false&d=CMC&lsrc=1&rp=false&ap=CFD_MT4",
    mt4: "l=fr&r=fr&p=MT4&i=demo&sc=false&d=CMC&lsrc=1&rp=false",
    tv: "l=fr&r=fr&iaid=820296&jid=gtv1",
    bm: "",
  },
});

/* it-IT */
attributeMap.set("it-IT", {
  live: {
    sb: "",
    pro: "",
    cfd: "l=it&r=it&p=CFD&i=live&sc=false&d=CMC&lsrc=1&rp=false&ap=CFD_MT4",
    mt4: "l=it&r=it&p=MT4&i=live&sc=false&d=CMC&lsrc=1&rp=false",
    tv: "l=it&r=it&iaid=820296&jid=gtv1",
    bm: "",
  },
  demo: {
    sb: "",
    pro: "",
    cfd: "l=it&r=it&p=CFD&i=demo&sc=false&d=CMC&lsrc=1&rp=false&ap=CFD_MT4",
    mt4: "l=it&r=it&p=MT4&i=demo&sc=false&d=CMC&lsrc=1&rp=false",
    tv: "l=it&r=it&iaid=820296&jid=gtv1",
    bm: "",
  },
});

/* es-ES */
attributeMap.set("es-ES", {
  live: {
    sb: "",
    pro: "",
    cfd: "l=es&r=es&p=CFD&i=live&sc=false&d=CMC&lsrc=1&rp=false&ap=CFD_MT4",
    mt4: "l=es&r=es&p=MT4&ap=MT4&i=live&inApp=0&ch=4&lsrc=0",
    tv: "l=es&r=es&iaid=820296&jid=gtv1",
    bm: "",
  },
  demo: {
    sb: "",
    pro: "",
    cfd: "l=es&r=es&p=CFD&i=demo&sc=false&d=CMC&lsrc=1&rp=false&ap=CFD_MT4",
    mt4: "l=es&r=es&p=MT4&ap=MT4&i=demo&inApp=0&ch=4&lsrc=0",
    tv: "l=es&r=es&iaid=820296&jid=gtv1",
    bm: "",
  },
});

/* nb-NO */
attributeMap.set("nb-NO", {
  live: {
    sb: "",
    pro: "",
    cfd: "l=no&r=no&p=CFD&i=live&sc=false&d=CMC&lsrc=1&rp=false&ap=CFD_MT4",
    mt4: "l=no&r=no&p=MT4&ap=MT4&i=live&inApp=0&ch=4&lsrc=0",
    tv: "l=no&r=no&iaid=820296&jid=gtv1",
    bm: "",
  },
  demo: {
    sb: "",
    pro: "",
    cfd: "l=no&r=no&p=CFD&i=demo&sc=false&d=CMC&lsrc=1&rp=false&ap=CFD_MT4",
    mt4: "l=no&r=no&p=MT4&ap=MT4&i=demo&inApp=0&ch=4&lsrc=0",
    tv: "l=no&r=no&iaid=820296&jid=gtv1",
    bm: "",
  },
});

/* sv-SE */
attributeMap.set("sv-SE", {
  live: {
    sb: "",
    pro: "",
    cfd: "l=sv&r=se&p=CFD&i=live&sc=false&d=CMC&lsrc=1&rp=false&ap=CFD_MT4",
    mt4: "l=sv&r=se&p=MT4&ap=MT4&i=live&inApp=0&ch=4&lsrc=0",
    tv: "l=sv&r=se&iaid=820296&jid=gtv1",
    bm: "",
  },
  demo: {
    sb: "",
    pro: "",
    cfd: "l=sv&r=se&p=CFD&i=demo&sc=false&d=CMC&lsrc=1&rp=false&ap=CFD_MT4",
    mt4: "l=sv&r=se&p=MT4&ap=MT4&i=demo&inApp=0&ch=4&lsrc=0",
    tv: "l=sv&r=se&iaid=820296&jid=gtv1",
    bm: "",
  },
});

/* pl-PL */
attributeMap.set("pl-PL", {
  live: {
    sb: "",
    pro: "",
    cfd: "l=pl&r=pl&p=CFD&i=live&sc=false&d=CMC&lsrc=1&rp=false&ap=CFD_MT4",
    mt4: "l=pl&r=pl&p=MT4&ap=MT4&i=live&inApp=0&ch=4&lsrc=0",
    tv: "l=pl&r=pl&iaid=820296&jid=gtv1",
    bm: "",
  },
  demo: {
    sb: "",
    pro: "",
    cfd: "l=pl&r=pl&p=CFD&i=demo&sc=false&d=CMC&lsrc=1&rp=false&ap=CFD_MT4",
    mt4: "l=pl&r=pl&p=MT4&ap=MT4&i=demo&inApp=0&ch=4&lsrc=0",
    tv: "l=pl&r=pl&iaid=820296&jid=gtv1",
    bm: "",
  },
});

/* en-AU */
attributeMap.set("en-AU", {
  live: {
    sb: "",
    pro: "",
    cfd: "jid=au1&amp;lsrc=",
    mt4: "jid=au3&amp;lsrc=0",
    tv: "l=en&r=au&iaid=820296&jid=gtv1",
    bm: "",
  },
  demo: {
    sb: "",
    pro: "",
    cfd: "jid=au5&amp;lsrc=",
    mt4: "jid=au3&amp;lsrc=0",
    tv: "l=en&r=au&iaid=820296&jid=gtv1",
    bm: "",
  },
});

/* en-NZ */
attributeMap.set("en-NZ", {
  live: {
    sb: "",
    pro: "",
    cfd: "jid=nz1&amp;lsrc=",
    mt4: "jid=nz3&amp;lsrc=0",
    tv: "l=en&r=nz&iaid=820296&jid=gtv1",
    bm: "",
  },
  demo: {
    sb: "",
    pro: "",
    cfd: "jid=nz5&amp;lsrc=",
    mt4: "jid=nz5&amp;lsrc=",
    tv: "l=en&r=nz&iaid=820296&jid=gtv1",
    bm: "",
  },
});

/* en-SG */
attributeMap.set("en-SG", {
  live: {
    sb: "",
    pro: "",
    cfd: "jid=sg1&amp;lsrc=",
    mt4: "jid=sg3&amp;lsrc=0",
    tv: "l=en&r=sg&iaid=820296&jid=gtv1",
    bm: "",
  },
  demo: {
    sb: "",
    pro: "",
    cfd: "jid=sg5&amp;lsrc=",
    mt4: "jid=sg5&amp;lsrc=",
    tv: "l=en&r=sg&iaid=820296&jid=gtv1",
    bm: "",
  },
});

/* en-CA */
attributeMap.set("en-CA", {
  live: {
    sb: "",
    pro: "",
    cfd: "jid=ca1&amp;lsrc=0",
    mt4: "jid=ca4&amp;lsrc=0",
    tv: "l=en&r=ca&iaid=820296&jid=catv1", // live & demo trading view are same (for now)
    bm: "",
  },
  demo: {
    sb: "",
    pro: "",
    cfd: "jid=ca7&amp;lsrc=0",
    mt4: "jid=ca10&amp;lsrc=0",
    tv: "l=en&r=ca&iaid=820296&jid=catv1",
    bm: "jid=bm1",
  },
});

/* fr-CA */
attributeMap.set("fr-CA", {
  live: {
    sb: "",
    pro: "",
    cfd: "jid=ca2&amp;lsrc=0",
    mt4: "jid=ca5&amp;lsrc=0",
    tv: "l=fr&r=ca&iaid=820296&jid=catv2",
    bm: "",
  },
  demo: {
    sb: "",
    pro: "",
    cfd: "jid=ca8&amp;lsrc=0",
    mt4: "jid=ca11&amp;lsrc=0",
    tv: "l=fr&r=ca&iaid=820296&jid=catv2",
    bm: "",
  },
});

/* zh-CA */
attributeMap.set("zh-CA", {
  live: {
    sb: "",
    pro: "",
    cfd: "jid=ca3&amp;lsrc=0",
    mt4: "jid=ca6&amp;lsrc=0",
    tv: "l=zh&r=ca&iaid=820296&jid=catv3",
    bm: "",
  },
  demo: {
    sb: "",
    pro: "",
    cfd: "jid=ca9&amp;lsrc=0",
    mt4: "jid=ca12&amp;lsrc=0",
    tv: "l=zh&r=ca&iaid=820296&jid=catv3",
    bm: "",
  },
});

/* zh */
attributeMap.set("zh", {
  live: {
    sb: "",
    pro: "",
    cfd: "l=zh&p=CFD&i=live&inApp=0&ch=4&lsrc=0",
    mt4: "l=zh&p=MT4&i=live&inApp=0&ch=4&lsrc=0",
    tv: "l=zh&r=zh&iaid=820296&jid=gtv1",
    bm: "",
  },
  demo: {
    sb: "",
    pro: "",
    cfd: "l=zh&p=CFD&i=demo&inApp=0&ch=4&lsrc=0",
    mt4: "l=zh&p=MT4&i=demo&inApp=0&ch=4&lsrc=0",
    tv: "l=zh&r=zh&iaid=820296&jid=gtv1",
    bm: "",
  },
});

/* zh-AU */
attributeMap.set("zh-AU", {
  live: {
    sb: "",
    pro: "",
    cfd: "jid=au2&amp;lsrc=",
    mt4: "jid=au4&amp;lsrc=0",
    tv: "l=zh&r=au&iaid=820296&jid=gtv1",
    bm: "",
  },
  demo: {
    sb: "",
    pro: "",
    cfd: "jid=au6&amp;lsrc=",
    mt4: "jid=au8&amp;lsrc=",
    tv: "l=zh&r=au&iaid=820296&jid=gtv1",
    bm: "",
  },
});

/* zh-NZ */
attributeMap.set("zh-NZ", {
  live: {
    sb: "",
    pro: "",
    cfd: "jid=nz2&amp;lsrc=",
    mt4: "jid=nz4&amp;lsrc=0",
    tv: "l=zh&r=nz&iaid=820296&jid=gtv1",
    bm: "",
  },
  demo: {
    sb: "",
    pro: "",
    cfd: "jid=nz6&amp;lsrc=",
    mt4: "jid=nz8&amp;lsrc=",
    tv: "l=zh&r=nz&iaid=820296&jid=gtv1",
    bm: "",
  },
});

/* zh-SG */
attributeMap.set("zh-SG", {
  live: {
    sb: "",
    pro: "",
    cfd: "jid=sg2&amp;lsrc=",
    mt4: "jid=sg4&amp;lsrc=0",
    tv: "l=zh&r=sg&iaid=820296&jid=gtv1",
    bm: "",
  },
  demo: {
    sb: "",
    pro: "",
    cfd: "jid=sg6&amp;lsrc=",
    mt4: "jid=sg8&amp;lsrc=",
    tv: "l=zh&r=sg&iaid=820296&jid=gtv1",
    bm: "",
  },
});

// ? Alcatraz locales

attributeMap.set("eo", {
  live: {
    sb: "",
    pro: "",
    cfd: "",
    mt4: "",
    tv: "l=eo&iaid=820296&jid=gtv1",
    bm: "jid=bm1&l=en",
  },
  demo: {
    sb: "",
    pro: "",
    cfd: "",
    mt4: "",
    tv: "l=eo&r=ca&iaid=820296&jid=gtv1",
    bm: "jid=bm1&l=en",
  },
});

attributeMap.set("ar", {
  live: {
    sb: "",
    pro: "",
    cfd: "",
    mt4: "",
    tv: "l=ar&iaid=820296&jid=gtv1",
    bm: "jid=bm1&l=ar",
  },
  demo: {
    sb: "",
    pro: "",
    cfd: "",
    mt4: "",
    tv: "l=ar&iaid=820296&jid=gtv1",
    bm: "jid=bm1&l=ar",
  },
});

attributeMap.set("bn", {
  live: {
    sb: "",
    pro: "",
    cfd: "",
    mt4: "",
    tv: "l=bn&iaid=820296&jid=gtv1",
    bm: "jid=bm1&l=bn",
  },
  demo: {
    sb: "",
    pro: "",
    cfd: "",
    mt4: "",
    tv: "l=bn&iaid=820296&jid=gtv1",
    bm: "jid=bm1&l=bn",
  },
});

attributeMap.set("ct", {
  live: {
    sb: "",
    pro: "",
    cfd: "",
    mt4: "",
    tv: "l=ct&iaid=820296&jid=gtv1",
    bm: "jid=bm1&l=ct",
  },
  demo: {
    sb: "",
    pro: "",
    cfd: "",
    mt4: "",
    tv: "l=ct&iaid=820296&jid=gtv1",
    bm: "jid=bm1&l=ct",
  },
});

attributeMap.set("tl", {
  live: {
    sb: "",
    pro: "",
    cfd: "",
    mt4: "",
    tv: "l=tl&iaid=820296&jid=gtv1",
    bm: "jid=bm1&l=tl",
  },
  demo: {
    sb: "",
    pro: "",
    cfd: "",
    mt4: "",
    tv: "l=tl&iaid=820296&jid=gtv1",
    bm: "jid=bm1&l=tl",
  },
});

attributeMap.set("fr", {
  live: {
    sb: "",
    pro: "",
    cfd: "",
    mt4: "",
    tv: "l=fr&iaid=820296&jid=gtv1",
    bm: "jid=bm1&l=fr",
  },
  demo: {
    sb: "",
    pro: "",
    cfd: "",
    mt4: "",
    tv: "l=fr&iaid=820296&jid=gtv1",
    bm: "jid=bm1&l=fr",
  },
});

attributeMap.set("hi", {
  live: {
    sb: "",
    pro: "",
    cfd: "",
    mt4: "",
    tv: "l=hi&iaid=820296&jid=gtv1",
    bm: "jid=bm1&l=hi",
  },
  demo: {
    sb: "",
    pro: "",
    cfd: "",
    mt4: "",
    tv: "l=hi&iaid=820296&jid=gtv1",
    bm: "jid=bm1&l=hi",
  },
});

attributeMap.set("id", {
  live: {
    sb: "",
    pro: "",
    cfd: "",
    mt4: "",
    tv: "l=id&iaid=820296&jid=gtv1",
    bm: "jid=bm1&l=id",
  },
  demo: {
    sb: "",
    pro: "",
    cfd: "",
    mt4: "",
    tv: "l=id&iaid=820296&jid=gtv1",
    bm: "jid=bm1&l=id",
  },
});

attributeMap.set("ko", {
  live: {
    sb: "",
    pro: "",
    cfd: "",
    mt4: "",
    tv: "l=ko&iaid=820296&jid=gtv1",
    bm: "jid=bm1&l=ko",
  },
  demo: {
    sb: "",
    pro: "",
    cfd: "",
    mt4: "",
    tv: "l=ko&iaid=820296&jid=gtv1",
    bm: "jid=bm1&l=ko",
  },
});

attributeMap.set("ms", {
  live: {
    sb: "",
    pro: "",
    cfd: "",
    mt4: "",
    tv: "l=ms&iaid=820296&jid=gtv1",
    bm: "jid=bm1&l=ms",
  },
  demo: {
    sb: "",
    pro: "",
    cfd: "",
    mt4: "",
    tv: "l=ms&iaid=820296&jid=gtv1",
    bm: "jid=bm1&l=ms",
  },
});

attributeMap.set("pt", {
  live: {
    sb: "",
    pro: "",
    cfd: "",
    mt4: "",
    tv: "l=pt&iaid=820296&jid=gtv1",
    bm: "jid=bm1&l=pt",
  },
  demo: {
    sb: "",
    pro: "",
    cfd: "",
    mt4: "",
    tv: "l=pt&iaid=820296&jid=gtv1",
    bm: "jid=bm1&l=pt",
  },
});

attributeMap.set("es", {
  live: {
    sb: "",
    pro: "",
    cfd: "",
    mt4: "",
    tv: "l=es&iaid=820296&jid=gtv1",
    bm: "jid=bm1&l=es",
  },
  demo: {
    sb: "",
    pro: "",
    cfd: "",
    mt4: "",
    tv: "l=es&iaid=820296&jid=gtv1",
    bm: "jid=bm1&l=es",
  },
});

attributeMap.set("th", {
  live: {
    sb: "",
    pro: "",
    cfd: "",
    mt4: "",
    tv: "l=th&iaid=820296&jid=gtv1",
    bm: "jid=bm1&l=th",
  },
  demo: {
    sb: "",
    pro: "",
    cfd: "",
    mt4: "",
    tv: "l=th&iaid=820296&jid=gtv1",
    bm: "jid=bm1&l=th",
  },
});

attributeMap.set("tr", {
  live: {
    sb: "",
    pro: "",
    cfd: "",
    mt4: "",
    tv: "l=tr&iaid=820296&jid=gtv1",
    bm: "jid=bm1&l=tr",
  },
  demo: {
    sb: "",
    pro: "",
    cfd: "",
    mt4: "",
    tv: "l=tr&iaid=820296&jid=gtv1",
    bm: "jid=bm1&l=tr",
  },
});

attributeMap.set("vi", {
  live: {
    sb: "",
    pro: "",
    cfd: "",
    mt4: "",
    tv: "l=vi&iaid=820296&jid=gtv1",
    bm: "jid=bm1&l=vi",
  },
  demo: {
    sb: "",
    pro: "",
    cfd: "",
    mt4: "",
    tv: "l=vi&iaid=820296&jid=gtv1",
    bm: "jid=bm1&l=vi",
  },
});

attributeMap.set("zh", {
  live: {
    sb: "",
    pro: "",
    cfd: "",
    mt4: "",
    tv: "l=zh&iaid=820296&jid=gtv1",
    bm: "jid=bm1&l=zh",
  },
  demo: {
    sb: "",
    pro: "",
    cfd: "",
    mt4: "",
    tv: "l=zh&iaid=820296&jid=gtv1",
    bm: "jid=bm1&l=zh",
  },
});

attributeMap.set("zh-TW", {
  live: {
    sb: "",
    pro: "",
    cfd: "",
    mt4: "",
    tv: "l=zh&r=tw&iaid=820296&jid=gtv1",
    bm: "jid=bm1&l=zh",
  },
  demo: {
    sb: "",
    pro: "",
    cfd: "",
    mt4: "",
    tv: "l=zh&r=tw&iaid=820296&jid=gtv1",
    bm: "jid=bm1&l=zh",
  },
});

attributeMap.set("zh-HK", {
  live: {
    sb: "",
    pro: "",
    cfd: "",
    mt4: "",
    tv: "l=zh&r=hk&iaid=820296&jid=gtv1",
    bm: "jid=bm1&l=zh",
  },
  demo: {
    sb: "",
    pro: "",
    cfd: "",
    mt4: "",
    tv: "l=zh&r=hk&iaid=820296&jid=gtv1",
    bm: "jid=bm1&l=zh",
  },
});

attributeMap.set("zh-MY", {
  live: {
    sb: "",
    pro: "",
    cfd: "",
    mt4: "",
    tv: "l=zh&r=my&iaid=820296&jid=gtv1",
    bm: "jid=bm1&l=zh",
  },
  demo: {
    sb: "",
    pro: "",
    cfd: "",
    mt4: "",
    tv: "l=zh&r=my&iaid=820296&jid=gtv1",
    bm: "jid=bm1&l=zh",
  },
});
