import { ISO3166_Alpha2_Codes } from "@/types/country-codes";

export const countries_ar: Record<ISO3166_Alpha2_Codes, string> = {
  AW: "أروبا",
  AF: "أفغانستان",
  AO: "أنغولا",
  AI: "أنغيلا",
  AX: "جزر آلاند",
  AL: "ألبانيا",
  AD: "أندورا",
  AN: "جزر الأنتيل الهولندية",
  AE: "الإمارات العربية المتحدة",
  AR: "الأرجنتين",
  AM: "أرمينيا",
  AS: "ساموا الأمريكية",
  AQ: "القارة القطبية الجنوبية",
  TF: "الأراضي الفرنسية الجنوبية",
  AG: "أنتيغوا وباربودا",
  AU: "أستراليا",
  AT: "النمسا",
  AZ: "أذربيجان",
  BI: "بوروندي",
  BE: "بلجيكا",
  BJ: "بنين",
  BQ: "بونير وسانت أوستاتيوس وسابا",
  BF: "بوركينا فاسو",
  BD: "بنغلاديش",
  BG: "بلغاريا",
  BH: "البحرين",
  BS: "جزر البهاما",
  BA: "البوسنة والهرسك",
  BL: "سانت بارتيليمي",
  BY: "بيلاروسيا",
  BZ: "بليز",
  BM: "برمودا",
  BO: "بوليفيا (دولة متعددة القوميات)",
  BR: "البرازيل",
  BB: "بربادوس",
  BN: "بروناي دار السلام",
  BT: "بوتان",
  BV: "جزيرة بوفيت",
  BW: "بوتسوانا",
  CF: "جمهورية أفريقيا الوسطى",
  CA: "كندا",
  CC: "جزر كوكس (كيلينغ)",
  CH: "سويسرا",
  CL: "تشيلي",
  CN: "الصين",
  CI: "ساحل العاج",
  CM: "الكاميرون",
  CD: "الكونغو (جمهورية الديمقراطية)",
  CG: "الكونغو",
  CK: "جزر كوك",
  CO: "كولومبيا",
  KM: "جزر القمر",
  CV: "الرأس الأخضر",
  CR: "كوستا ريكا",
  CU: "كوبا",
  CW: "كوراساو",
  CX: "جزيرة الكريسماس",
  KY: "جزر كايمان",
  CY: "قبرص",
  CZ: "الجمهورية التشيكية",
  DE: "ألمانيا",
  DJ: "جيبوتي",
  DM: "دومينيكا",
  DK: "الدنمارك",
  DO: "جمهورية الدومينيكان",
  DZ: "الجزائر",
  EC: "الإكوادور",
  EG: "مصر",
  ER: "إريتريا",
  EH: "الصحراء الغربية",
  ES: "إسبانيا",
  EE: "إستونيا",
  ET: "إثيوبيا",
  FI: "فنلندا",
  FJ: "فيجي",
  FK: "جزر فوكلاند (مالفيناس)",
  FR: "فرنسا",
  FO: "جزر فاروس",
  FM: "ميكرونيسيا",
  GA: "الجابون",
  GB: "المملكة المتحدة",
  GE: "جورجيا",
  GG: "غيرنسي",
  GH: "غانا",
  GI: "جبل طارق",
  GN: "غينيا",
  GP: "غوادلوب",
  GM: "غامبيا",
  GW: "غينيا بيساو",
  GQ: "غينيا الاستوائية",
  GR: "اليونان",
  GD: "غرينادا",
  GL: "جرينلاند",
  GT: "غواتيمالا",
  GF: "غيانا الفرنسية",
  GU: "غوام",
  GY: "غيانا",
  HK: "هونج كونج",
  HM: "جزيرة هيرد وجزر ماكدونالد",
  HN: "هندوراس",
  HR: "كرواتيا",
  HT: "هايتي",
  HU: "المجر",
  ID: "إندونيسيا",
  IM: "جزيرة مان",
  IN: "الهند",
  IO: "إقليم المحيط البريطاني الهندي",
  IE: "أيرلندا",
  IR: "إيران",
  IQ: "العراق",
  IS: "أيسلندا",
  IL: "إسرائيل",
  IT: "إيطاليا",
  JM: "جامايكا",
  JE: "جيرسي",
  JO: "الأردن",
  JP: "اليابان",
  KZ: "كازاخستان",
  KE: "كينيا",
  KG: "قيرغيزستان",
  KH: "كمبوديا",
  KI: "كيريباتي",
  KN: "سانت كيتس ونيفيس",
  KR: "كوريا الجنوبية",
  KW: "الكويت",
  LA: "لاوس",
  LB: "لبنان",
  LR: "ليبيريا",
  LY: "ليبيا",
  LC: "سانت لوسيا",
  LI: "ليختنشتاين",
  LK: "سريلانكا",
  LS: "ليسوتو",
  LT: "ليتوانيا",
  LU: "لوكسمبورغ",
  LV: "لاتفيا",
  MO: "ماكاو",
  MF: "سانت مارتن",
  MA: "المغرب",
  MC: "موناكو",
  MD: "جمهورية مولدوفا",
  MG: "مدغشقر",
  MV: "جزر المالديف",
  MX: "المكسيك",
  MH: "جزر مارشال",
  MK: "مقدونيا ",
  ML: "مالي",
  MT: "مالطا",
  MM: "ميانمار",
  ME: "الجبل الأسود",
  MN: "منغوليا",
  MP: "جزر ماريانا الشمالية",
  MZ: "موزمبيق",
  MR: "موريتانيا",
  MS: "مونتسرات",
  MQ: "مارتينيك",
  MU: "موريشيوس",
  MW: "ملاوي",
  MY: "ماليزيا",
  YT: "مايوت",
  NA: "ناميبيا",
  NC: "كاليدونيا الجديدة",
  NE: "النيجر",
  NF: "جزيرة نورفولك",
  NG: "نيجيريا",
  NI: "نيكاراغوا",
  NU: "نيوي",
  NL: "هولندا",
  NO: "النرويج",
  NP: "نيبال",
  NR: "ناورو",
  NZ: "نيوزيلاندا",
  OM: "سلطنة عمان",
  PK: "باكستان",
  PA: "بنما",
  PN: "بيتكيرن",
  PE: "بيرو",
  PH: "الفلبين",
  PW: "بالاو",
  PG: "بابوا غينيا الجديدة",
  PL: "بولندا",
  PR: "بورتوريكو",
  KP: "كوريا الشمالية",
  PT: "البرتغال",
  PY: "باراغواي",
  PS: "دولة فلسطين",
  PF: "بولينيزيا الفرنسية",
  QA: "قطر",
  RE: "ريونيون",
  RO: "رومانيا",
  RU: "روسيا",
  RW: "رواندا",
  SA: "المملكة العربية السعودية",
  SD: "السودان",
  SN: "السنغال",
  SG: "سنغافورة",
  GS: "جورجيا الجنوبية وجزر ساندويتش الجنوبية",
  SH: "سانت هيلينا",
  SJ: "سفالبارد ويان ماين",
  SB: "جزر سليمان",
  SL: "سيراليون",
  SV: "السلفادور",
  SM: "سان مارينو",
  SO: "الصومال",
  PM: "سان بيار وميكلون",
  RS: "صربيا",
  SS: "جنوب السودان",
  ST: "ساو تومي وبرينسيب",
  SR: "سورينام",
  SK: "سلوفاكيا",
  SI: "سلوفينيا",
  SE: "السويد",
  SZ: "إيسواتيني",
  SX: "سانت مارتن (الجزء الهولندي)",
  SC: "سيشيل",
  SY: "الجمهورية العربية السورية",
  TC: "جزر توركس وكايكوس",
  TD: "تشاد",
  TG: "توجو",
  TH: "تايلاند",
  TJ: "طاجيكستان",
  TK: "توكيلاو",
  TM: "تركمانستان",
  TL: "تيمور ليشتي",
  TO: "تونغا",
  TT: "ترينداد وتوباغو",
  TN: "تونس",
  TR: "تركيا",
  TV: "توفالو",
  TW: "تايوان",
  TZ: "جمهورية تنزانيا المتحدة",
  UG: "أوغندا",
  UA: "أوكرانيا",
  UM: "الجزر الصغيرة النائية (الولايات المتحدة)",
  UY: "أوروغواي",
  US: "الولايات المتحدة الأمريكية",
  UZ: "أوزبكستان",
  VA: "الفاتيكان",
  VC: "سانت فنسنت وجزر غرينادين",
  VE: "فنزويلا",
  VG: "جزر العذراء (البريطانية)",
  VI: "جزر العذراء (الولايات المتحدة)",
  VN: "فيتنام",
  VU: "فانواتو",
  WF: "واليس وفوتونا",
  WS: "ساموا",
  XK: "كوسوفو",
  YE: "اليمن",
  ZA: "جنوب أفريقيا",
  ZM: "زامبيا",
  ZW: "زيمبابوي",
};
