import { TextKey } from "@/config/i18n";
import {
  alcatrazLocales,
  AlcatrazLocales,
  Country,
  supportedCountries_v2Arr,
} from "@/config/supported-countries";
import { isProd } from "@/constants";
import useLocalizedText from "@/hooks/useLocalisedText";
import {
  cn,
  getContentfulEnvironment,
  getLanguageFromLocale,
  getRouteDetails,
  isAlcatraz,
} from "@/utils/helpers";
import { Autocomplete } from "@contentful/f36-components";
import getFlag from "country-flag-icons/unicode";
import { X } from "lucide-react";
import { useRouter } from "next/router";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import Button from "../core/atoms/button";
import Tile from "../core/molecules/tile";
import CMCNextGenerationLogo from "../icons/static/cmc-nextgeneration";
import MetatraderLogo from "../icons/static/metatrader-logo";
import LanguageSelectorMenu from "../layout/language-selector-menu";
import { countries, CountryLocales } from "@/config/countries/countries";

type LocalisedCountry = Country & { localizedName: string };
type Platforms = "metatrader4" | "nextgen";

// Use this function to trigger onboarding modal from anywhere in the app
export const triggerAlcatrazOnboardingModal = (
  onboardingType: "Alcatraz MT4" | "Alcatraz NextGen" | "Alcatraz MT4 & NextGen"
) => {
  const event = new CustomEvent("AlcatrazOnboardingModal", {
    detail: { onboardingType },
  });

  window.dispatchEvent(event);
};

const AlcatrazOnboardingModalLayer = () => {
  const router = useRouter();
  const { locale } = getRouteDetails(router);

  if (alcatrazLocales.includes(locale as AlcatrazLocales))
    return <AlcatrazOnboardingModal />;

  return null;
};

const AlcatrazOnboardingModal = () => {
  let lang = "en";
  const [isInitialized, setIsInitialized] = useState(false);
  const [isOverlaying, setIsOverlaying] = useState(false);

  const cookieCountrySet = useRef(false);

  const router = useRouter();
  const { locale } = getRouteDetails(router);

  // Both Alcatraz pillars
  if (locale === "pillar") lang = "en";

  // Steps state and control
  const [step, setStep] = useState(1);
  const nextStep = useCallback(() => setStep(step + 1), [step]);
  const prevStep = useCallback(() => setStep(step - 1), [step]);

  // State for the onboarding process
  const [state, setState] = useState<{
    cn_code: string;
    platform: Platforms | ""; // metatrader4 | nextgen
    country: any;
    name: "";
    lang: string;
  }>({
    cn_code: "", // Country code from the Akamai cookie
    platform: "", // Platform selected by the user (metatrader4, nextgen)
    country: null, // Country object selected by the user
    name: "",
    lang, // Language that corresponds to the country
  });

  const updateState = (key: string, value: any) =>
    setState((prev) => ({ ...prev, [key]: value }));

  const openModal = useCallback(
    (event: Event) => {
      const customEvent = event as CustomEvent;

      if (!customEvent.detail.onboardingType) {
        return;
      }

      if (customEvent.detail.onboardingType === "Alcatraz MT4") {
        if (alcatrazLocales.includes(locale as AlcatrazLocales)) {
          window.open("https://client-portal.cmcmarkets.com/Home/SignUp", "_blank");
          return;
        }
      }

      if (customEvent.detail.onboardingType === "Alcatraz NextGen") {
        updateState("platform", "nextgen");
        updateState("lang", locale);
        setStep(2);
        setIsOverlaying(true);
        return;
      }

      setIsOverlaying(true);
    },
    [locale]
  );

  const closeModal = () => {
    setIsOverlaying(false);
    setStep(1);
    cookieCountrySet.current = false;

    setCountryViaCookie();
  };

  /**
   * Check the cookies for the Akamai cncode cookie and reset the state to that
   */
  const setCountryViaCookie = useCallback(() => {
    const cn_cookie = document.cookie
      .split("; ")
      .find((c) => c.startsWith("cncode="))
      ?.split("=")[1];

    if (cn_cookie && !cookieCountrySet.current) {
      const country = supportedCountries_v2Arr.find((c) => c.code === cn_cookie);

      updateState("cn_code", cn_cookie);
      updateState("country", country);

      cookieCountrySet.current = true;
    }
  }, []);

  useEffect(() => {
    // document.cookie = "cncode=BD"; // Spoof the country code cookie for testing

    setCountryViaCookie();
    setIsInitialized(true);

    if (typeof window !== "undefined") {
      window.addEventListener("AlcatrazOnboardingModal", openModal);

      return () => {
        window.removeEventListener("AlcatrazOnboardingModal", openModal);
      };
    }
  }, [prevStep, locale, lang, openModal, setCountryViaCookie]);

  if (!isInitialized) {
    return <div>Loading...</div>;
  }

  const StepSwitcher = () => {
    switch (step) {
      case 1:
        return (
          <Step_1
            nextStep={nextStep}
            state={state}
            currentLocale={locale}
            updateState={updateState}
            prevStep={prevStep}
          />
        );
      case 2:
        return (
          <Step_2
            nextStep={nextStep}
            state={state}
            currentLocale={locale}
            updateState={updateState}
            prevStep={prevStep}
          />
        );
      default:
        return null;
    }
  };

  return (
    <div
      className={cn(
        "top-0 flex h-screen w-screen items-center justify-center bg-black/20 px-4 backdrop-blur-md overflow-hidden",
        isOverlaying ? "fixed z-[999999]" : "-z-10 hidden"
      )}
    >
      <div className="relative flex w-full items-center justify-center overflow-hidden">
        <div
          className={`max-h-[90dvh] overflow-hidden theme-white-100 bg-white-100 w-full max-w-2xl overflow-y-auto overflow-x-hidden rounded-md  md:relative md:max-h-none`}
        >
          <div className="flex absolute pr-1 right-[9px] top-[10px] z-[999] items-center justify-center rtl:flex-row-reverse pt-0.5">
            {/* Only show the selector menu on the first step */}
            {step === 1 && <LanguageSelectorMenu border={false} />}
            <X
              size={30}
              className="cursor-pointer transition-transform duration-500 hover:scale-115 ml-auto"
              stroke="black"
              onClick={closeModal}
            />
          </div>
          <StepSwitcher />

          {step !== 1 && (
            <small
              onClick={prevStep}
              className="block cursor-pointer text-xxs w-full pb-1 text-center"
            >
              Restart
            </small>
          )}
        </div>
      </div>
    </div>
  );
};

/**
 * Step 1
 * --------------------------------
 *  - Select country
 *  - Select platform
 *  - Continue
 */
const Step_1 = ({ debug, nextStep, state, updateState }: any) => {
  const [country, setCountry] = useState<LocalisedCountry | null>(state.country);
  const [platform, setPlatform] = useState("");
  const [filteredCountries, setFilteredCountries] = useState<LocalisedCountry[]>([]);

  const router = useRouter();
  const { locale, language } = getRouteDetails(router);
  const { isPreview } = getContentfulEnvironment(router);

  const localizedCountries = useMemo(() => {
    const countryNames = countries[language as CountryLocales] || countries.en;

    return supportedCountries_v2Arr.map((country) => ({
      ...country,
      localizedName: countryNames?.[country.code] || country.name,
    }));
  }, [locale, language]);

  useEffect(() => {
    setFilteredCountries(localizedCountries);
  }, [localizedCountries]);

  const registerAccount = useLocalizedText(TextKey.RegisterAccount);
  const registerAccountDescription = useLocalizedText(TextKey.RegisterAccountDescription);
  const selectCountry = useLocalizedText(TextKey.SelectCountry);
  const metaTraderDescription = useLocalizedText(TextKey.MetaTraderDescription);
  const cmcNextGenerationDescription = useLocalizedText(
    TextKey.CmcNextGenerationDescription
  );
  const unsupportedCountry = useLocalizedText(TextKey.UnsupportedCountry);
  const continueText = useLocalizedText(TextKey.Continue);

  const isCountrySupported = (country: any, platform: Platforms) => {
    if (!country) return false;

    return country[platform] !== "unsupported";
  };

  const handleInputValueChange = (value: string) => {
    // Reset if empty or only unicode flag left
    if (value === "" || /^(?:\uD83C[\uDDE6-\uDDFF]){2}$/.test(value.trim())) {
      setCountry(null);
      setPlatform("");
    }

    // Remove flag from the value
    value = value.replace(/[\uD83C][\uDDE6-\uDDFF]/g, "").trim();

    const filtered = localizedCountries.filter(
      (c) =>
        c.localizedName.toLowerCase().includes(value.toLowerCase()) ||
        c.name.toLowerCase().includes(value.toLowerCase())
    );
    setFilteredCountries(filtered);
  };

  const handleContinue = () => {
    if (platform === "" || !country) return;

    /**
     * If the selected country locale is the same as the website locale
     * and the browser language is the same as the country language
     */
    const redirectToRetail = () => {
      // Base URL + env + country locale

      let url = isPreview
        ? `${process.env.BASE_URL}/preview/${country.locale}`
        : `https://www.cmcmarkets.com/${country.locale}`;

      // For retail countries append the exact page (Alcatraz locales redirect to the homepage)
      if (country.jurisdiction === "local" || country.jurisdiction === "eea") {
        if (platform === "nextgen") {
          url = url.concat(`/${country.nextgen}`);
        }

        if (platform === "metatrader4") {
          url = url.concat(`/${country.metatrader4}`);
        }
      }

      window.location.href = url.toLowerCase();
    };

    // If the country selected from the dropdown is not an alcatraz locale
    if (!isAlcatraz(country.locale)) {
      redirectToRetail();
      return;
    }

    if (platform === "nextgen") {
      updateState("platform", platform);
      updateState("country", country);
      nextStep();
      return;
    }

    if (platform === "metatrader4") {
      window.open(country.metatrader4, "_blank");
      // Close the dialog
      return;
    }

    return;
  };

  const handleSelect = (country: LocalisedCountry | null) => {
    setCountry(country);
    updateState("country", country);
  };

  return (
    <div className="relative min-h-[400px] flex items-center justify-center flex-col px-6 pt-12 pb-8">
      {debug}

      <div className="">
        <h3 className="headline-md pb-2 text-center">
          {/* Register for your account */}
          {registerAccount}
        </h3>
        <p className="pb-8 text-center">
          {/* Select your country and preferred platform for your account */}
          {registerAccountDescription}
        </p>
      </div>
      <div className="w-[calc(100vw-80px)] md:w-[450px]">
        <Autocomplete
          className="mb-4 w-full"
          id="country"
          // Select country
          placeholder={selectCountry}
          selectedItem={country}
          onFocus={() => setFilteredCountries(localizedCountries)}
          itemToString={(c: LocalisedCountry | null) =>
            c?.code && (c?.localizedName || c?.name)
              ? `${getFlag(c.code)} ${c.localizedName || c.name}`
              : ""
          }
          items={filteredCountries}
          renderItem={(c: LocalisedCountry | null) =>
            c?.code && (c?.localizedName || c?.name)
              ? `${getFlag(c.code)} ${c.localizedName || c.name}`
              : ""
          }
          onInputValueChange={handleInputValueChange}
          onSelectItem={handleSelect}
          listWidth="full"
        />
      </div>

      <div className="grid md:grid-cols-2 gap-4 mb-8">
        <Tile
          title="MetaTrader"
          theme="white-100"
          size="sm"
          image={<MetatraderLogo />}
          onClick={() => country && setPlatform("metatrader4")}
          className={cn(
            "hover:shadow-lg box-border h-full hover:cursor-pointer transition-all duration-500 hover:border-solid border-azure-10 border-2 hover:border-azure-40",
            platform === "metatrader4" && "border-azure-100 hover:border-azure-100",
            !isCountrySupported(country, "metatrader4") &&
              "opacity-50 hover:cursor-not-allowed hover:border-gray-100"
          )}
          // Trade on the world's most popular platform with our suite of free premium indicators and Expert Advisors.
          description={metaTraderDescription}
        />
        <Tile
          title="CMC Next Generation"
          theme="white-100"
          size="sm"
          image={<CMCNextGenerationLogo />}
          onClick={() => country && setPlatform("nextgen")}
          className={cn(
            "hover:shadow-lg box-border h-full hover:cursor-pointer transition-all duration-500 hover:border-solid border-azure-10 border-2 hover:border-azure-40",
            platform === "nextgen" && "border-azure-100 hover:border-azure-100",
            !isCountrySupported(country, "nextgen") &&
              "opacity-50 hover:cursor-not-allowed hover:border-gray-100"
          )}
          // We've invested over £100m into our award-winning platform creating pioneering technology that suits all styles of trading.
          description={cmcNextGenerationDescription}
        />
      </div>

      {country &&
      !isCountrySupported(country, "metatrader4") &&
      !isCountrySupported(country, "nextgen") ? (
        <p className="text-red-400 text-center">
          {
            // Unfortunately we are currently unable to process applications from residents of {country?.name}.
            unsupportedCountry + country?.localizedName || country?.name + "."
          }
        </p>
      ) : (
        <div className="min-h-[44px]">
          <Button
            // Continue
            title={continueText}
            size="sm"
            isDisabled={platform === ""}
            onClick={handleContinue}
            variant="primary"
            theme="navy-100"
          />
        </div>
      )}
    </div>
  );
};

/**
 * Step 2
 * --------------------------------
 * - Display iFrame with the onboarding modal inside it
 */
const Step_2 = ({ state, prevStep, currentLocale }: any) => {
  let lang = `&l=${getLanguageFromLocale(currentLocale)}`;
  const countryCode = state?.country?.code ? `&c=${state?.country?.code || "GB"}` : "";

  // Onboarding crashes without a valid language
  // so we return EN as default
  if (
    ![
      "en",
      "th",
      "tl",
      "vi",
      "ar",
      "bn",
      "fr",
      "hi",
      "id",
      "ko",
      "ms",
      "pt",
      "es",
      "tr",
      "zh",
    ].find((l) => lang === `&l=${l}`)
  ) {
    lang = `&l=en`;
  }
  // At onboarding Chinese (ZH) is actually mapped to "ct"
  else if (lang === "&l=zh") {
    lang = "&l=ct";
  }

  const onboardingUrl = isProd
    ? `https://signup.cmcmarkets.com/#/internationalCountrySelection?jid=bm1${lang}${countryCode}`
    : `https://hdev3-signup.cmcmarkets.com/#/internationalCountrySelection?jid=bm1${lang}${countryCode}`;

  return (
    <div className="flex items-center justify-center grid-center-parent overflow-hidden">
      <iframe src={onboardingUrl} className="w-full h-[740px]" />
      {/* <Undo2
        height={50}
        strokeWidth={2.5}
        className="h-min mb-auto z-[99999] ms-3.5 rtl:ms-auto rtl:me-3.5 cursor-pointer"
        onClick={prevStep}
      /> */}
    </div>
  );
};

export default AlcatrazOnboardingModalLayer;
