// "use client";
import useIsOverflowing from "@/hooks/useIsOverflowing";
import { useWindowSize } from "@/hooks/useWindowSize";
import { DesignSystemComponent, TabsT } from "@/types/design-system-types";
import { cn, getRouteDetails } from "@/utils/helpers";
import ComponentDebugger from "@/utils/system/component-debugger";
import ComponentResolver from "@/utils/system/component-resolver";
import Page from "@/utils/system/page";
import { ChevronDownIcon, ChevronLeft, ChevronRight } from "lucide-react";
import React, {
  MutableRefObject,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import Dropdown from "../../utility/dropdown";
import RichText from "../atoms/rich-text";

import { useRouter } from "next/router";

export const triggerUpdateTabHeight = (sysId: string) => {
  const event = new CustomEvent("updateTabHeight", {
    detail: { sysId },
  });
  window.dispatchEvent(event);
};

const Tabs = ({
  data,
  variant = data?.fields.variant ?? "default",
  theme,
  debug,
  ...props
}: DesignSystemComponent<TabsT>) => {
  const tabs = data?.fields.tabs;
  const nTabsRef: MutableRefObject<HTMLDivElement | null> = useRef(null); // ref for the nTabs component itself
  const refs = useRef<any>(tabs?.map(() => React.createRef())); // refs for each tab

  const [activeTab, setActiveTab] = useState<number | null>(0);
  const [tabHeight, setTabHeight] = useState<number | null>(null);
  const [clicked, setClicked] = useState(false);
  const [hasNestedTab, setHasNestedTab] = useState(false);
  const [isNestedTab, setIsNestedTab] = useState(false);

  const router = useRouter();
  const { isRTL } = getRouteDetails(router);

  useEffect(() => {
    if (nTabsRef.current && nTabsRef.current?.querySelector(".n-tabs")) {
      setHasNestedTab(true);
    } else {
      setHasNestedTab(false);
    }

    if (nTabsRef.current && nTabsRef.current.closest(".n-tabs")) {
      setIsNestedTab(true);
    } else [setIsNestedTab(false)];
  }, [activeTab]);

  // Check when there are too many tabs to fit in the container causing overflow
  const [desktopTabs, desktopTabsIsOverflowing] = useIsOverflowing();
  const themeClass = Page.getSecondaryThemeClass(theme);

  const calculateTotalHeight = useCallback((element: HTMLElement | null): number => {
    if (!element) return 0;

    // Get the computed styles for more accurate measurements
    const styles = window.getComputedStyle(element);

    // Calculate base content height including padding and borders
    let totalHeight = element.getBoundingClientRect().height;

    // Add vertical margins
    totalHeight += parseFloat(styles.marginTop) + parseFloat(styles.marginBottom);

    // Find all nested tabs within this element
    const nestedTabs = element.querySelectorAll(".n-tabs");

    nestedTabs.forEach((nestedTab: Element) => {
      const nestedTabElement = nestedTab as HTMLElement;
      const nestedStyles = window.getComputedStyle(nestedTabElement);

      // Get the active tab content within the nested tab
      const activeNestedContent = nestedTabElement.querySelector(
        '[data-component-id="nTab"]'
      ) as HTMLElement;

      if (activeNestedContent) {
        const activeStyles = window.getComputedStyle(activeNestedContent);

        let nestedHeight = activeNestedContent.getBoundingClientRect().height;

        // Add padding from the nested tab container
        nestedHeight +=
          parseFloat(nestedStyles.paddingTop) + parseFloat(nestedStyles.paddingBottom);

        // Add margins from both the nested tab container and its content
        nestedHeight +=
          parseFloat(nestedStyles.marginTop) + parseFloat(nestedStyles.marginBottom);
        nestedHeight +=
          parseFloat(activeStyles.marginTop) + parseFloat(activeStyles.marginBottom);

        // Account for any tabs navigation height
        const tabsNav = nestedTabElement.querySelector(".flex.gap-2.pt-2");
        if (tabsNav) {
          nestedHeight += (tabsNav as HTMLElement).getBoundingClientRect().height;
        }

        // Update total height if nested content is taller
        totalHeight = Math.max(totalHeight, nestedHeight);
      }
    });

    // Add a small buffer to prevent any minor cutoffs
    return totalHeight + 8;
  }, []);

  const updateTabHeight = useCallback(() => {
    const updateHeight = () => {
      if (activeTab === null || activeTab === undefined) return;

      const currentRef = refs.current?.[activeTab]?.current;
      if (!currentRef) return;

      // Update nested tabs first
      const nestedTabs = currentRef.querySelectorAll(".n-tabs");
      nestedTabs.forEach((nestedTab: Element) => {
        const event = new CustomEvent("updateTabHeight", {
          detail: { sysId: (nestedTab as HTMLElement).dataset.componentId },
        });
        window.dispatchEvent(event);
      });

      // Calculate total height
      const totalHeight = calculateTotalHeight(currentRef);
      if (totalHeight > 0) {
        setTabHeight(totalHeight);
      }
    };

    // Initial update
    updateHeight();

    // Series of delayed updates to catch dynamic content and animations
    const delays = [1];
    delays.forEach((delay) => {
      setTimeout(updateHeight, delay);
    });
  }, [refs, activeTab, calculateTotalHeight]);

  const directionFactor = useMemo(() => {
    return isRTL ? 1 : -1;
  }, [isRTL]);

  // Event listener for triggering tab height update from other components
  useEffect(() => {
    const handleUpdateTabHeight = (event: CustomEvent<{ sysId: string }>) => {
      if (event.detail.sysId === data?.sys.id) {
        updateTabHeight();
      }
    };

    window.addEventListener("updateTabHeight", handleUpdateTabHeight as EventListener);
    return () => {
      window.removeEventListener(
        "updateTabHeight",
        handleUpdateTabHeight as EventListener
      );
    };
  }, [updateTabHeight, data?.sys.id]);

  // Update height on tab changes and initial load
  useEffect(() => {
    updateTabHeight();
  }, [activeTab, clicked, updateTabHeight]);

  // Handle clickable accordions starting with no active tabs
  useEffect(() => {
    if (variant === "clickable accordions") {
      setActiveTab(null);
    }
  }, [variant]);

  // Select the active tab from URL query parameter
  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const tabName = urlParams.get("tab");

    const tabsNames = tabs?.map((tab: any) => tab?.fields?.title?.toLowerCase() || "");
    const tabIndex = tabsNames?.indexOf(tabName?.toLowerCase());

    if (tabIndex && tabIndex !== -1) {
      setActiveTab(tabIndex);
    }
  }, [tabs]);

  // Return appropriate variant
  if (variant === "accordions")
    return (
      <AccordionsTabs
        {...{
          props,
          tabs,
          activeTab,
          debug,
          setActiveTab,
          setClicked,
          refs,
          tabHeight,
          themeClass,
          directionFactor,
        }}
      />
    );
  if (variant === "clickable accordions")
    return (
      <ClickableAccordionsTabs
        {...{
          props,
          tabs,
          activeTab,
          debug,
          setActiveTab,
          setClicked,
          refs,
          tabHeight,
          themeClass,
          directionFactor,
        }}
      />
    );
  if (variant === "stepper")
    return (
      <StepperTabs
        {...{
          props,
          tabs,
          activeTab,
          debug,
          setActiveTab,
          setClicked,
          refs,
          tabHeight,
          directionFactor,
        }}
      />
    );
  if (variant === "carousel")
    return (
      <CarouselTabs
        {...{
          props,
          tabs,
          activeTab,
          debug,
          setActiveTab,
          setClicked,
          refs,
          tabHeight,
          directionFactor,
        }}
      />
    );

  // Default tabs & dropdown
  return (
    <div ref={nTabsRef} {...props} className="relative n-tabs">
      {debug}
      <div
        className="overflow-hidden"
        style={{
          maxHeight: tabHeight ? `${tabHeight + 60}px` : "auto",
          transition: "height 300ms cubic-bezier(0.4, 0, 0.2, 1)",
        }}
        onClick={() => updateTabHeight()}
      >
        {/* Desktop tabs */}
        <div
          onClick={() => updateTabHeight()}
          ref={desktopTabs}
          className={cn("flex gap-2 pt-2", [
            desktopTabsIsOverflowing.horizontally && "invisible h-0 select-none",
          ])}
        >
          {tabs?.map((tab: any, index: number) => (
            <button
              className={`btn btn-tab btn-sm ${activeTab === index ? "active" : ""}`}
              key={index}
              onClick={() => {
                setActiveTab(index);
                setClicked(true);
              }}
            >
              {tab.fields.title}
            </button>
          ))}
        </div>

        {/* Mobile dropdown */}
        {desktopTabsIsOverflowing.horizontally && (
          <div className="relative z-[20] flex w-full items-end justify-end">
            <Dropdown
              initial={tabs?.[0]?.fields.title}
              options={tabs?.map((tab: any) => tab.fields.title) as string[]}
              callback={(item, index) => {
                setActiveTab(index);
                setClicked(true);
              }}
              theme={theme}
            />
          </div>
        )}

        {/* Content */}
        <div className="relative min-h-min pt-5 transition-all duration-300 md:pt-0">
          <div
            style={{
              transform: `translateX(${directionFactor * 100 * (activeTab ?? 0)}%)`,
            }}
            className="relative flex h-min w-full max-w-[100vw] transition-all duration-300"
          >
            {tabs?.map((tab: any, index: number) => (
              <div
                data-component-id="nTab"
                ref={refs.current?.[index]}
                key={index}
                className="relative h-min w-full min-w-full"
              >
                {debug && activeTab !== null && activeTab !== undefined && (
                  <ComponentDebugger data={tabs?.[activeTab]} />
                )}
                <ComponentResolver
                  className={`mt-6 h-min w-full min-w-full ${
                    isNestedTab ? "mb-0" : "mb-16"
                  }`}
                  key={index}
                  debug={
                    debug &&
                    activeTab !== null &&
                    activeTab !== undefined && (
                      <ComponentDebugger data={tabs?.[activeTab].fields.content} />
                    )
                  }
                  data={tab.fields.content}
                />
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Tabs;

const CarouselTabs = ({
  props,
  tabs,
  activeTab,
  debug,
  setActiveTab,
  setClicked,
  refs,
  tabHeight,
  directionFactor = -1,
}: any) => {
  return (
    <div {...props} className="grid-center-parent relative">
      {debug}
      <div className="px-8">
        <div className="overflow-hidden">
          {/* Content */}
          <div
            style={{
              height: tabHeight ? tabHeight : "auto",
            }}
            className="min-h-min transition-all duration-300"
          >
            <div
              style={{ left: `${directionFactor * 100 * activeTab}%` }}
              className="relative flex h-min w-full transition-all duration-300"
            >
              {tabs?.map((tab: any, index: number) => (
                <div
                  ref={refs.current?.[index]}
                  key={index}
                  className="h-min w-full min-w-full"
                >
                  {debug && activeTab !== null && activeTab !== undefined && (
                    <ComponentDebugger data={tabs?.[activeTab]} />
                  )}
                  {tab?.fields?.content && (
                    <ComponentResolver
                      className="mt-5 h-min w-full min-w-full pb-4"
                      key={index}
                      data={tab.fields.content}
                    />
                  )}
                </div>
              ))}
            </div>
          </div>

          {/* Dots */}
          <div className="mx-auto flex w-min gap-2 rounded-full bg-navy-20 px-2 py-1">
            {tabs?.map((tab: any, index: number) => (
              <div
                className={`mb-0 h-[10px] w-[10px] cursor-pointer whitespace-nowrap rounded-md border border-solid border-navy-20 text-sm  ${
                  activeTab === index
                    ? "bg-navy-100 text-white hover:border-azure-40"
                    : "bg-white-85 text-navy-100 hover:border-azure-100 hover:text-black"
                }`}
                key={index}
                onClick={() => {
                  setActiveTab(index);
                  setClicked(true);
                }}
              ></div>
            ))}
          </div>
        </div>
      </div>

      <div className="pointer-events-none  flex h-full w-full min-w-full items-center self-center">
        <div className="flex h-full w-full justify-between">
          <div
            onClick={() =>
              tabs?.length &&
              setActiveTab((prev: number) => (prev - 1 + tabs.length) % tabs.length)
            }
            className="hover:bg-gradient-glass-to-right group pointer-events-auto relative z-[5] -ms-3 flex h-full w-[33%] items-center justify-start transition-all duration-500 hover:cursor-pointer"
          >
            <div className="absolute z-[999] flex h-[40px] w-[40px] items-center justify-center rounded-full transition-all duration-500 group-hover:bg-navy-20 rtl:-scale-x-[1]">
              <ChevronLeft className="stroke-navy-100 group-hover:stroke-white-100" />
            </div>
          </div>
          <div
            onClick={() =>
              tabs?.length && setActiveTab((prev: number) => (prev + 1) % tabs.length)
            }
            className="hover:bg-gradient-glass-to-left group pointer-events-auto relative z-[5] -me-3 flex h-full w-[33%] items-center justify-end transition-all duration-500 hover:cursor-pointer"
          >
            <div className="absolute z-[999] flex h-[40px] w-[40px] items-center justify-center rounded-full transition-all duration-500 group-hover:bg-navy-20 rtl:-scale-x-[1]">
              <ChevronRight />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const StepperTabs = ({
  props,
  tabs,
  activeTab,
  debug,
  setActiveTab,
  refs,
  tabHeight,
  directionFactor = -1,
}: any) => {
  return (
    <div {...props} className="grid-center-parent relative">
      {debug}
      <div className="px-8">
        <div className="overflow-hidden">
          {/* Content */}
          <div
            style={{
              height: tabHeight ? tabHeight : "auto",
            }}
            className="min-h-min transition-all duration-300"
          >
            <div
              style={{ left: `${directionFactor * 100 * activeTab}%` }}
              className="relative flex h-min w-full transition-all duration-300"
            >
              {tabs?.map((tab: any, index: number) => (
                <div
                  ref={refs.current?.[index]}
                  key={index}
                  className="h-min w-full min-w-full"
                >
                  {debug && activeTab !== null && activeTab !== undefined && (
                    <ComponentDebugger data={tabs?.[activeTab]} />
                  )}
                  {tab?.fields?.content && (
                    <ComponentResolver
                      className="mt-5 h-min w-full min-w-full pb-4"
                      key={index}
                      data={tab.fields.content}
                    />
                  )}
                </div>
              ))}
            </div>
          </div>

          {/* Dots */}
          <div className="mx-auto flex w-full items-end justify-between gap-2 px-2 py-1">
            <p className="mb-0">
              {activeTab + 1}/{tabs?.length}
            </p>

            <div className="flex h-[50px] items-center justify-center rounded-full border border-solid border-navy-40">
              <ChevronLeft
                onClick={() =>
                  tabs?.length &&
                  setActiveTab((prev: any) => (prev - 1 + tabs.length) % tabs.length)
                }
                className="h-full w-[50px] cursor-pointer rounded-full py-3 transition-all duration-500 hover:bg-navy-20 hover:stroke-white rtl:-scale-x-[1]"
              />
              <ChevronRight
                onClick={() =>
                  tabs?.length && setActiveTab((prev: any) => (prev + 1) % tabs.length)
                }
                className="h-full w-[50px] cursor-pointer rounded-full  py-3 transition-all duration-500 hover:bg-navy-20 hover:stroke-white rtl:-scale-x-[1]"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const AccordionsTabs = ({
  props,
  tabs,
  activeTab,
  debug,
  setActiveTab,
  setClicked,
  refs,
  tabHeight,
  themeClass,
  directionFactor = -1,
}: any) => {
  const { isMobile } = useWindowSize();

  return (
    <div {...props} data-tab-variant="accordion" className="grid grid-cols-3 relative">
      {debug}
      {/* Accordions */}
      <div className="flex h-full flex-col gap-2 px-2 py-1 col-span-3 lg:col-span-1">
        {tabs?.map((tab: any, index: number) => (
          <div
            style={{
              maxHeight: activeTab === index ? "1000px" : "min-content",
            }}
            className={`mb-0 flex h-min w-full cursor-pointer flex-col overflow-hidden rounded-md p-4 text-sm transition-[max-height] duration-500 ${
              activeTab === index ? "hover:border-azure-40" : "hover:border-azure-100"
            } ${themeClass}`}
            key={index}
            onMouseEnter={() => {
              setActiveTab(index);
              setClicked(true);
            }}
          >
            <p className="mb-0 text-xl font-semibold">{tab.fields.title}</p>
            <div
              className={`h-full w-full transition-[max-height] duration-500 ${
                activeTab === index ? "max-h-[1000px]" : "max-h-[0]"
              }`}
            >
              {/* <p className="pt-4">{tab.fields.description}</p> */}
              {tab.fields.description && (
                <RichText
                  data={tab.fields.description}
                  sys={tab.fields.sys}
                  fieldName="description"
                  className="pt-4"
                />
              )}

              {isMobile && (
                <div className="pointer-events-none ml-auto self-end">
                  <div className="overflow-hidden">
                    {/* Content */}
                    <div
                      style={{
                        height: tabHeight ? tabHeight : "auto",
                      }}
                      className="min-h-min transition-all duration-300"
                    >
                      <div
                        style={{ left: `${directionFactor * 100 * activeTab}%` }}
                        className="relative flex h-min w-full transition-all duration-300"
                      >
                        {tabs?.map((tab: any, index: number) => (
                          <div
                            ref={refs.current?.[index]}
                            key={index}
                            className="h-min w-full min-w-full"
                          >
                            {debug && activeTab !== null && activeTab !== undefined && (
                              <ComponentDebugger data={tabs?.[activeTab]} />
                            )}
                            <ComponentResolver
                              className="mt-5 h-min w-full min-w-full pb-4"
                              key={index}
                              data={tab.fields.content}
                            />
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        ))}
      </div>

      {!isMobile && (
        <div className="pointer-events-none col-span-2 px-8">
          {debug && <ComponentDebugger className="absolute" data={tabs?.[activeTab]} />}
          <div className="overflow-hidden">
            {/* Content */}
            <div
              style={{
                height: tabHeight ? tabHeight : "auto",
              }}
              className="min-h-min transition-all duration-300"
            >
              <div
                style={{ left: `${directionFactor * 100 * activeTab}%` }}
                className="relative flex h-min w-full transition-all duration-300"
              >
                {tabs?.map((tab: any, index: number) => (
                  <div
                    ref={refs.current?.[index]}
                    key={index}
                    className="h-min w-full min-w-full"
                  >
                    {debug && activeTab !== null && activeTab !== undefined && (
                      <ComponentDebugger data={tabs?.[activeTab]} />
                    )}
                    <ComponentResolver
                      className="tab-img mt-5 h-min w-full min-w-full pb-4"
                      key={index}
                      data={tab.fields.content}
                    />
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

const ClickableAccordionsTabs = ({
  props,
  tabs,
  activeTab,
  debug,
  setActiveTab,
  setClicked,
  refs,
  tabHeight,
  themeClass,
  directionFactor = -1,
}: any) => {
  const { isMobile } = useWindowSize();

  return (
    <div
      {...props}
      data-tab-variant="clickable-accordion"
      className="grid grid-cols-3 relative"
    >
      {debug}
      {/* Clickable Accordions */}
      <div className="flex h-full flex-col gap-2 px-2 py-1 col-span-3 lg:col-span-1">
        {tabs?.map((tab: any, index: number) => (
          <div
            style={{
              maxHeight: activeTab === index ? "1000px" : "min-content",
            }}
            className={`mb-0 flex h-min w-full cursor-pointer flex-col overflow-hidden rounded-md p-4 text-sm transition-[max-height] duration-500 ${
              activeTab === index ? "hover:border-azure-40" : "hover:border-azure-100"
            } ${themeClass}`}
            key={index}
            onClick={(event) => {
              // Don't toggle tab if a button is clicked
              const target = event.target as HTMLElement;
              if (target.classList.contains("btn")) {
                return;
              }
              setActiveTab(index === activeTab ? null : index);
              setClicked(true);
            }}
          >
            <div className="flex gap-x-4 items-center justify-between">
              <p className="mb-0 text-xl font-semibold">{tab.fields.title}</p>
              <ChevronDownIcon
                className={`w-6 h-6 transition-transform ${
                  activeTab === index ? "rotate-180" : ""
                }`}
              />
            </div>
            <div
              className={`h-full w-full transition-[max-height] duration-500 ${
                activeTab === index ? "max-h-[1000px]" : "max-h-[0]"
              }`}
            >
              {tab.fields.description && (
                <RichText
                  data={tab.fields.description}
                  sys={tab.fields.sys}
                  fieldName="description"
                  className="pt-4"
                />
              )}

              {isMobile && (
                <div className="pointer-events-none ml-auto self-end">
                  <div className="overflow-hidden">
                    {/* Content */}
                    <div
                      style={{
                        height: tabHeight ? tabHeight : "auto",
                      }}
                      className="min-h-min transition-all duration-300"
                    >
                      <div
                        style={{ left: `${directionFactor * 100 * activeTab}%` }}
                        className="relative flex h-min w-full transition-all duration-300"
                      >
                        {tabs?.map((tab: any, index: number) => (
                          <div
                            ref={refs.current?.[index]}
                            key={index}
                            className="h-min w-full min-w-full"
                          >
                            {debug && activeTab !== null && activeTab !== undefined && (
                              <ComponentDebugger data={tabs?.[activeTab]} />
                            )}
                            <ComponentResolver
                              className="mt-5 h-min w-full min-w-full pb-4"
                              key={index}
                              data={tab.fields.content}
                            />
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        ))}
      </div>

      {!isMobile && (
        <div className="pointer-events-none col-span-2 px-8">
          <div className="overflow-hidden">
            {/* Content */}
            <div
              style={{
                height: tabHeight ? tabHeight : "auto",
              }}
              className="min-h-min transition-all duration-300"
            >
              <div
                style={{ left: `${directionFactor * 100 * activeTab}%` }}
                className="relative flex h-min w-full transition-all duration-300"
              >
                {tabs?.map((tab: any, index: number) => (
                  <div
                    ref={refs.current?.[index]}
                    key={index}
                    className="h-min w-full min-w-full"
                  >
                    {debug && activeTab !== null && activeTab !== undefined && (
                      <ComponentDebugger data={tabs?.[activeTab]} />
                    )}
                    <ComponentResolver
                      className="tab-img mt-5 h-min w-full min-w-full pb-4"
                      key={index}
                      data={tab.fields.content}
                    />
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
