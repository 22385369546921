import { cn } from "@/utils/helpers";
import { X } from "lucide-react";
import React, { useEffect, useRef, useState } from "react";
import ReactPlayer from "react-player";

export const triggerModal = (url: string) => {
  const sanitizedURL =
    url.includes("youtube") && !url.includes("-nocookie")
      ? url.replace("youtube", "youtube-nocookie")
      : url;

  const event = new CustomEvent("VideoModal", {
    detail: { url: sanitizedURL },
  });

  window.dispatchEvent(event);
};

const VideoModalLayer = () => {
  const [isOverlaying, setIsOverlaying] = useState(false);
  const [videoURL, setVideoURL] = useState("");
  const [isClient, setIsClient] = useState(false);
  const playerRef = useRef<ReactPlayer>(null);

  const openModal = (event: Event) => {
    const customEvent = event as CustomEvent;
    if (customEvent.detail.url) {
      setIsOverlaying(true);
      setVideoURL(customEvent.detail.url);
      document.body.classList.add("overflow-hidden");
    }
  };

  const closeModal = (event?: any) => {
    document.body.classList.remove("overflow-hidden");
    if (event?.key === "Escape") {
      playerRef.current?.seekTo(0);
      setIsOverlaying(false);
      setVideoURL("");
      return;
    }
    playerRef.current?.seekTo(0);
    setIsOverlaying(false);
    setVideoURL("");
    return;
  };

  useEffect(() => {
    setIsClient(true);
    if (typeof window !== "undefined") {
      window.addEventListener("VideoModal", openModal);
      window.addEventListener("keydown", closeModal);

      // Cleanup function to remove event listeners
      return () => {
        window.removeEventListener("VideoModal", openModal);
        window.removeEventListener("keydown", closeModal);
      };
    }
  }, []);

  if (!isClient) {
    return null;
  }

  return (
    <div
      className={cn(
        "top-0 flex h-screen w-screen bg-black",
        isOverlaying ? "sticky z-[999999]" : "-z-10 hidden"
      )}
    >
      <ReactPlayer
        ref={playerRef}
        url={videoURL}
        playing={isOverlaying}
        controls
        width="100%"
        height="100%"
        className="bottom-0 left-0 mt-auto max-h-[calc(100%-44px)]"
      />
      <div className="absolute flex h-[30px] w-full items-center justify-end top-2 right-2">
        <X
          size={30}
          className="cursor-pointer  hover:scale-110 transition-transform"
          stroke="white"
          onClick={closeModal}
        />
      </div>
    </div>
  );
};

export default VideoModalLayer;
