import Page from "@/utils/system/page";
import AlcatrazOnboardingModalLayer from "../modals/alcatraz-onboarding-modal-layer";
import GlobalModalLayer from "../modals/global-modal-layer";
import OnboardingModalLayer from "../modals/onboarding-modal-layer";
import RetailOnboardingModalLayer from "../modals/retail-onboarding-modal-layer";
import EnvironmentBar from "../utility/environment-bar";
import MobileMenuSpacer from "../utility/mobile-menu-spacer";
import VideoModalLayer from "../utility/video-modal-layer";
import Footer from "./footer";
import GeoRestrictionModal from "./geo-restrictional-modal";
import Menu from "./menu";
import ScrollSpyToolbar from "./scroll-spy-toolbar";

const Layout = ({ menu, footer, data, children, disableScrollSpies = false }: any) => {
  const scrollSpies = !disableScrollSpies && Page.scrollSpies(data);

  return (
    <>
      <VideoModalLayer />
      <GlobalModalLayer />
      <header>
        <EnvironmentBar data={data} />
        {menu && <Menu menu={menu} />}
        {scrollSpies && scrollSpies.length > 0 && (
          <ScrollSpyToolbar scrollSpies={scrollSpies} pageData={data} />
        )}
        <MobileMenuSpacer />
      </header>
      {children}
      {/* to be reworked */}
      {footer && <Footer footer={footer} />}

      <OnboardingModalLayer />
      <AlcatrazOnboardingModalLayer />
      <RetailOnboardingModalLayer />
      <GeoRestrictionModal />
    </>
  );
};

export default Layout;
