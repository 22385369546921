import RichText from "../core/atoms/rich-text";

const RiskWarning = ({ data }: any) => {
  return (
    <div
      id="risk-warning"
      style={{ background: "black" }}
      className="risk-warning sticky z-30 bg-black px-2 py-2 leading-3 tracking-wide text-white-85 lg:px-4 [&_p]:mb-1"
      data-nosnippet
    >
      <RichText
        alignment="center"
        className="h-min max-h-min text-[14px]"
        data={{ ...data }}
      />
    </div>
  );
};

export default RiskWarning;
