import { BLCAnchorSource, BLCLinkType } from "@/types/broken-link-checker";

export class BrokenLinkCheckerUtils {
  // Social media domains to identify social links
  private static SOCIAL_URLS = [
    "twitter.com",
    "linkedin.com",
    "facebook.com",
    "instagram.com",
    "youtube.com",
  ];

  // Component types that have headings
  private static COMPONENT_TYPES_WITH_HEADINGS = [
    "nTile",
    "nCard",
    "nTitle",
    "nFaq",
    "nBanner",
    "nStack",
    "nRichText",
  ];

  // Selector for all components that have headings
  private static COMPONENT_SELECTOR = this.COMPONENT_TYPES_WITH_HEADINGS.map(
    (type) => `[component-name="${type}"]`
  ).join(", ");

  // Map for link types containing descriptions for each one
  static LINK_TYPE_DESCRIPTIONS: Record<BLCLinkType, string> = {
    Onboarding: "HDEV3 & UAT links are converted to prod for this check.",
    Social: "Links to social media pages",
    Internal: "Links to internal CMC Markets pages",
    External: "Links to external websites",
    "Hyperlink URL": "Links to CMC Markets pages via URL",
    "Hyperlink Metadata": "Links to CMC Markets pages via page metadata",
    Chip: "Links using the n Chip component",
    Card: "Links using the n Card component",
    Button: "Links using the n Button component",
  };

  /**
   * Determines the types of a link for categorization and filtering
   *
   * @param anchor The HTML anchor element to analyze
   * @returns Array of link types that apply to this anchor
   */
  static getLinkTypes(anchor: HTMLAnchorElement) {
    const types: BLCLinkType[] = [];

    if (anchor.href.includes("signup.cmcmarkets.com")) {
      types.push("Onboarding");
    }

    if (this.SOCIAL_URLS.some((socialUrl) => anchor.href.includes(socialUrl))) {
      types.push("Social");
    }

    if (!this.isExternalUrl(anchor.href)) {
      types.push("Internal");
    }

    if (this.isExternalUrl(anchor.href)) {
      types.push("External");
    }

    if (anchor.dataset.anchorType === "url") {
      types.push("Hyperlink URL");
    }

    if (anchor.dataset.anchorType === "entry") {
      types.push("Hyperlink Metadata");
    }

    if (anchor.dataset.anchorType === "chip") {
      types.push("Chip");
    }

    if (anchor.dataset.anchorType === "card") {
      types.push("Card");
    }

    if (anchor.classList.contains("btn")) {
      types.push("Button");
    }

    return types as BLCAnchorSource["linkTypes"];
  }

  /**
   * Detects if an anchor points to a CMC Markets URL that isn't using metadata
   *
   * @param anchor The HTML anchor element to analyze
   * @returns True if the anchor is a CMC Markets URL without metadata
   */
  static getIsCMCUrlAnchor(anchor: HTMLAnchorElement) {
    const isInternalDomain =
      anchor.href.includes("cmcmarkets.com") || anchor.href.includes("localhost");

    const isAssetsDomain = anchor.href.includes("assets.cmcmarkets.com");

    return isInternalDomain && !isAssetsDomain && anchor.dataset.anchorType === "url";
  }

  /**
   * Determines if a URL is external to CMC Markets sites
   *
   * Internal URLs:
   * - localhost
   * - cmcmarkets.com (except signup.cmcmarkets and platform.cmcmarkets)
   * - webdev-global-site-nonprod.vercel.app
   * - webdev-global-site.vercel.app
   *
   * @param url The URL to check
   * @returns true if the URL is external, false otherwise
   */
  static isExternalUrl(url: string): boolean {
    // Internal domains
    const internalDomains = [
      "localhost",
      "webdev-global-site-nonprod.vercel.app",
      "webdev-global-site.vercel.app",
    ];

    // CMC domains that should be considered external
    const externalCmcDomains = ["signup.cmcmarkets", "platform.cmcmarkets"];

    // Check if URL contains any internal domain
    const isInternal = internalDomains.some((domain) => url.includes(domain));

    // Check if URL is a CMC site but should be considered external
    const isCmcExternal = externalCmcDomains.some((domain) => url.includes(domain));

    // Check if URL is a regular CMC site (which is internal)
    const isRegularCmc = url.includes("cmcmarkets.com/") && !isCmcExternal;

    return !isInternal && !isRegularCmc;
  }

  /**
   * Normalizes URLs for testing by replacing development domains with production ones
   * and removing environment prefixes
   *
   * @param url The URL to normalize
   * @returns The normalized URL
   */
  static normalizeUrl(url: string): string {
    let normalizedUrl = url;

    if (normalizedUrl.includes("webdev-global-site-nonprod.vercel.app")) {
      normalizedUrl = normalizedUrl.replace(
        "webdev-global-site-nonprod.vercel.app",
        "nonprod-www.cmcmarkets.com"
      );
    }

    if (normalizedUrl.includes("webdev-global-site.vercel.app")) {
      normalizedUrl = normalizedUrl.replace(
        "webdev-global-site.vercel.app",
        "www.cmcmarkets.com"
      );
    }

    if (normalizedUrl.includes("cmcmarkets.com")) {
      // Remove hdev3 and UAT from urls as Vercel doesn't have permission
      // to hit those domains, so we will directly hit prod
      if (normalizedUrl.includes("hdev3-")) {
        normalizedUrl = normalizedUrl.replace("hdev3-", "");
      }

      if (normalizedUrl.includes("uat-")) {
        normalizedUrl = normalizedUrl.replace("uat-", "");
      }
    }

    return normalizedUrl;
  }

  /**
   * Find the parent section of an anchor or span
   * @param element
   * @returns
   */
  static findParentSection = (element: HTMLAnchorElement | HTMLSpanElement) => {
    const parentSectionNode = element.closest("section");
    const parentSectionTitle =
      parentSectionNode?.querySelector("h1, h2")?.textContent || "";

    return {
      node: parentSectionNode,
      title: parentSectionTitle,
    };
  };

  /**
   * Find the parent component of an anchor or span
   * @param element
   * @returns
   */
  static findParentComponent = (element: HTMLAnchorElement | HTMLSpanElement) => {
    const parentComponentNode = element.closest(this.COMPONENT_SELECTOR);
    const parentComponentTitle =
      parentComponentNode?.querySelector(
        "h1, h2, h3, .n-accordion-title, .n-card-title, .n-tile-title"
      )?.textContent || "";

    return {
      node: parentComponentNode,
      title: parentComponentTitle,
    };
  };

  static generateId = () => Math.random().toString(36).substr(2, 9);
  static createTimestamp = () => new Date().toISOString();
}
