import { highlightText } from "@/utils/internal-tools";
import { Badge } from "@contentful/f36-components";
import React from "react";

export default function ScrollToFinderCard({ component }: { component: any }) {
  return (
    <div
      className="flex w-full border-b border-b-gray-200  justify-between py-4 px-2"
      key={component.sys.id}
    >
      <div>
        <p className="font-semibold flex gap-x-2 mb-2 items-center">
          <span>{component.fields.internalName}</span>
          <Badge variant="primary">
            <span className="uppercase">
              {component.sys.contentType.sys.id.toUpperCase()}
            </span>
          </Badge>
        </p>
        <p className="mb-2">
          Scroll Spy Text:{" "}
          <span className="font-bold"> {component.fields.scrollSpy}</span>
        </p>
        <p
          onClick={(e) => highlightText(e.target as HTMLElement)}
        >{`{{{ SCROLL-TO-${component.sys.id} }}}`}</p>
      </div>
    </div>
  );
}
