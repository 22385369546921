import Icon from "@/components/icons/icons";
import { renderVideoFromShortcode } from "@/components/utility/rich-text-options/render-video-from-shortcode";
import { BLOCKS_EMBEDDED_ASSET, BLOCKS_EMBEDDED_ENTRY, BLOCKS_TABLE, INLINES_ASSET_HYPERLINK, INLINES_EMBEDDED_ENTRY, INLINES_ENTRY_HYPERLINK, INLINES_HYPERLINK, RENDER_TEXT } from "@/components/utility/rich-text-options/rich-text-renderers";
import { DesignSystemComponent, RichTextT } from "@/types/design-system-types";
import { bullets } from "@/utils/bullets";
import { cn } from "@/utils/helpers";
import { ComponentError } from "@/utils/system/component-error";
import Page from "@/utils/system/page";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import { BLOCKS, INLINES, Node, NodeData, Text } from "@contentful/rich-text-types";
import { ReactNode } from "react";

const RichText = ({
  data, // All incoming Contentful data
  debug,

  // Contentful controlled Props
  alignment = data?.fields?.alignment ?? "start",

  // Controlled by parent
  theme = data?.fields?.theme,

  bulletPointType = data?.fields?.bulletPointType,
  bulletPointType_v2 = data?.fields?.bulletPointType_v2,

  // Internal props
  className,
  showDebug = false,
  fieldName = "text",
  // locale = "en", // used for the editField function
  sys,

  ...props
}: DesignSystemComponent<RichTextT>) => {
  try {
    // Check if its a rich text field inside another component or standalone component
    const isStandalone = data?.nodeType ? false : true;
    const renderableData = isStandalone ? data?.fields?.text : data;

    return (
      <div
        {...props}
        onClick={() => {
          if (sys) {
            Page.editField({ sys, data }, fieldName);
            return;
          }

          if (data?.sys) {
            Page.editField(data, fieldName);
            return;
          }

          console.log(
            "Cannot trigger edit. No sys property found in RichText component."
          );
        }}
        className={cn(
          "rich-text relative gap-2 text-inherit",
          className,
          `text-${alignment}`
        )}
      >
        {/* DEBUGGING INFO FOR LOCAL */}
        {(isStandalone || showDebug) && debug}

        {/* Convert rich text to React components */}
        {documentToReactComponents(renderableData as any, {
          renderNode: {
            // Shared renderers
            [BLOCKS.EMBEDDED_ASSET]: (node: any) => BLOCKS_EMBEDDED_ASSET(node),
            [BLOCKS.EMBEDDED_ENTRY]: (node: any) => BLOCKS_EMBEDDED_ENTRY(node, theme),
            [BLOCKS.TABLE]: (node: any) => BLOCKS_TABLE(node),
            [INLINES.EMBEDDED_ENTRY]: (node: any) => INLINES_EMBEDDED_ENTRY(node, theme),
            [INLINES.HYPERLINK]: (node: any, children: ReactNode) => INLINES_HYPERLINK(node, children),
            [INLINES.ENTRY_HYPERLINK]: (node: any, children: ReactNode) => INLINES_ENTRY_HYPERLINK(node, children),
            [INLINES.ASSET_HYPERLINK]: (node: any, children: ReactNode) => INLINES_ASSET_HYPERLINK(node, children),

            // Rich-text specific renderers
            [BLOCKS.PARAGRAPH]: (node: any, children: ReactNode) => {
              const textNode = node.content[0] as Text;

              // ? Stop hydration warnings by preventing <p> tags wrapping embedded entries
              const hasEmbeddedEntry = node.content.some(
                (contentNode: Node) =>
                  contentNode.nodeType === "embedded-entry-inline"
              );

              const nodesWithoutValue = node.content.filter(
                (node: any) => !node.value
              );

              const areEmbedsInline =
                hasEmbeddedEntry &&
                node.content?.length >= 2 &&
                nodesWithoutValue.length > 1;

              const hasSuperScript = node.content.some(
                (contentNode: NodeData) =>
                  contentNode.nodeType === "text" &&
                  contentNode?.marks.some(
                    (mark: any) => mark.type === "superscript"
                  )
              );

              // ? If there are embedded entries and superscript, render dedicated footnote styling
              if (areEmbedsInline && hasSuperScript)
                return (
                  <p
                    className={`with-sup flex h-min min-h-[25px] self-start justify-${alignment}`}
                  >
                    {children}
                  </p>
                );

              // Video (ie {{{ VIDEO-https://www.youtube.com/watch?v=5bbw8l7kl5 }}})
              const video = renderVideoFromShortcode(textNode);
              if (video) return video;

              return hasEmbeddedEntry ? (
                <span>{children}</span>
              ) : (
                <p>{children}</p>
              );
            },
            [BLOCKS.UL_LIST]: (node: any, children: any) => (
              <ul className={bulletPointType || bulletPointType_v2 ? "custom-bullets" : "list-disc"}>
                {children}
              </ul>
            ),
            [BLOCKS.LIST_ITEM]: (node: any, children: any) => (
              <li className={bulletPointType || bulletPointType_v2 ? "flex items-center gap-x-2" : ""}>
                {bulletPointType && bullets.get(bulletPointType)}
                {bulletPointType_v2 && <span><Icon name={bulletPointType_v2} /></span>}
                <span>{children}</span>
              </li>
            ),
          },
          renderText: (text) => RENDER_TEXT(text),
        })}
      </div>
    );
  } catch (error) {
    return <ComponentError error={error} data={data} />;
  }
};

export default RichText;
