const CMCLogoSVG = () => {
  return (
    <svg
      className="overflow-visible h-full"
      width="53"
      height="30"
      viewBox="0 0 53 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.9844 18.966C21.5766 18.966 22.0567 18.4882 22.0567 17.8994L22.0567 6.96743L22.055 6.96288V5.27994C22.055 3.62977 21.2241 2.83978 19.6735 2.33787C19.06 2.14806 18.473 1.99551 17.9057 1.87247L17.9057 18.966H20.9844ZM27.1092 18.966C27.701 18.966 28.1813 18.4882 28.1813 17.8994L28.1813 6.96743L28.1797 6.96288V5.27994C28.1797 3.62977 27.3485 2.83978 25.798 2.33787C25.1844 2.14806 24.5974 1.99551 24.0301 1.87247L24.0301 18.966H27.1092ZM34.3299 17.8994V0.044645C33.7642 0.167152 33.1767 0.319641 32.564 0.510039C31.011 1.01301 30.1815 1.80214 30.1815 3.45179V5.1363L30.1798 5.14172L30.1798 18.966L33.2572 18.966C33.8493 18.966 34.3299 18.4879 34.3299 17.8994ZM51.7335 14.4749L50.3223 14.8894C48.4932 15.4046 47.0093 15.7465 45.8689 15.7465C42.5559 15.7465 40.868 13.7572 40.868 9.60553C40.868 5.42187 42.5559 3.42933 45.8689 3.42933C47.0093 3.42933 48.4932 3.81013 50.3223 4.28741L51.7335 4.70307V1.1764L50.8393 0.925968C48.8038 0.343681 47.0428 0 45.4919 0C40.2803 0 36.7277 3.29335 36.7277 9.60553C36.7277 16.1586 40.2803 19.2126 45.4919 19.2126C47.0428 19.2126 48.8038 18.8343 50.8393 18.2531L51.7335 18.0002V14.4749ZM15.0059 14.4749L13.5946 14.8894C11.7663 15.4046 10.2818 15.7465 9.14143 15.7465C5.82879 15.7465 4.14062 13.7572 4.14062 9.60553C4.14062 5.42187 5.82879 3.42933 9.14143 3.42933C10.2818 3.42933 11.7663 3.81013 13.5946 4.28741L15.0059 4.70307V1.1764L14.1116 0.925968C12.0764 0.343681 10.3153 0 8.76477 0C3.55288 0 0 3.29335 0 9.60553C0 16.1586 3.55288 19.2126 8.76477 19.2126C10.3153 19.2126 12.0764 18.8343 14.1116 18.2531L15.0059 18.0002L15.0059 14.4749Z"
        fill="#101626"
      />
      <path
        d="M1.49622 29.8737H2.26845L2.26845 26.0809C2.26845 25.5246 2.21808 24.471 2.21808 24.471H2.23487C2.23487 24.471 2.49508 25.415 2.67135 25.9039L4.19063 29.8737H4.86213L6.38141 25.9039C6.56607 25.415 6.81788 24.471 6.81788 24.471H6.83467C6.83467 24.471 6.78431 25.5246 6.78431 26.0809L6.78431 29.8737H7.6069L7.6069 23.6029H6.46534L5.18949 27.008C4.93767 27.6992 4.55156 28.9719 4.55156 28.9719H4.53477C4.53477 28.9719 4.15705 27.6907 3.90524 27.008L2.6042 23.6029H1.49622L1.49622 29.8737Z"
        fill="#101626"
      />
      <path
        d="M10.0819 29.8737H10.9632L11.6012 28.0447H14.0606L14.6817 29.8737L15.6218 29.8737L13.3219 23.6029L12.365 23.6029L10.0819 29.8737ZM12.4489 25.6173C12.6504 25.0357 12.8351 24.2856 12.8351 24.2856H12.8602C12.8602 24.2856 13.0281 25.0104 13.238 25.6173L13.8339 27.3705H11.8362L12.4489 25.6173Z"
        fill="#101626"
      />
      <path
        d="M18.1084 29.8737H18.9646V27.2609H20.6265C21.4407 27.2609 21.8101 27.5812 21.8688 28.4662C21.936 29.4692 21.9528 29.7557 22.0954 29.8737H23.0104V29.8316C22.8845 29.7473 22.8005 29.4439 22.725 28.4577C22.6662 27.5559 22.4648 27.1345 21.7597 26.9237V26.8984C22.5907 26.6372 22.9013 26.0977 22.9013 25.3307C22.9013 24.294 22.1458 23.6029 21.0294 23.6029H18.1084V29.8737ZM18.9646 24.3446H20.7944C21.6758 24.3446 22.0199 24.7239 22.0199 25.4572C22.0199 26.1399 21.6002 26.5697 20.7524 26.5697H18.9646V24.3446Z"
        fill="#101626"
      />
      <path
        d="M25.6644 29.8737H26.5206V27.6065L27.4691 26.6709L29.7774 29.8737H30.7763L28.0651 26.1146L30.5748 23.5944H29.5004L26.487 26.6962C26.5038 26.3927 26.5206 25.9629 26.5206 25.5414V23.6029H25.6644V29.8737Z"
        fill="#101626"
      />
      <path
        d="M33.0976 29.8737H37.6302V29.0899H33.9537V26.9743H37.1937V26.2579H33.9537V24.3614L37.5379 24.3614V23.6029L33.0976 23.6029V29.8737Z"
        fill="#101626"
      />
      <path
        d="M39.9806 24.3614H42.0287V29.8737H42.8932V24.3614H44.9497V23.6029H39.9806V24.3614Z"
        fill="#101626"
      />
      <path
        d="M49.7061 30.0002C51.0575 30.0002 52.0311 29.3343 52.0311 28.1459C52.0311 26.7467 50.9315 26.4349 49.7228 26.1904C48.7827 25.9966 48.1028 25.8027 48.1028 25.12C48.1028 24.4963 48.64 24.1592 49.4626 24.1592C50.344 24.1592 50.8896 24.5722 50.9987 25.3307H51.8297C51.6786 24.2603 50.9903 23.4849 49.4542 23.4849C48.17 23.4849 47.2886 24.1086 47.2886 25.1622C47.2886 26.3674 48.2371 26.6877 49.3535 26.9237C50.4447 27.1682 51.1666 27.362 51.1666 28.1796C51.1666 28.9382 50.5622 29.309 49.7312 29.309C48.5981 29.309 47.9769 28.7865 47.8762 27.8425H47.0284C47.0956 29.0393 47.9601 30.0002 49.7061 30.0002Z"
        fill="#101626"
      />
    </svg>
  );
};

export default CMCLogoSVG;
