import { PILLAR_LOCALE } from "@/constants";
import { ProgressUpdate } from "@/pages/api/neptune/internal/secure/translate";
import { randomIntWith } from "@/utils/helpers";
import { Asset, createClient, Entry, PlainClientAPI } from "contentful-management";
import { DomHandler, Parser } from "htmlparser2";
import { x86 } from "murmurhash3js";
import { gzipSync } from "zlib";

class Contentful {
  public client: PlainClientAPI;

  constructor() {
    this.client = createClient(
      {
        accessToken: process.env.CONTENTFUL_MANAGEMENT_ACCESS_TOKEN ?? "",
      },
      {
        type: "plain",
        defaults: {
          spaceId: process.env.CONTENTFUL_SPACE_ID ?? "",
          environmentId: process.env.CONTENTFUL_ENV,
        },
      }
    );
  }

  /**
   * Process an array of items in batches
   * in order to avoid rate limiting
   */
  public async processInBatches<T>(
    items: T[],
    processItem: (item: T) => Promise<void | boolean>,
    progressCallback?: ProgressUpdate,
    batchSize: number = 9,
    rateLimitDelay: number = 500
  ) {
    const delay = (ms: number) => new Promise((resolve) => setTimeout(resolve, ms));

    const totalBatches = Math.ceil(items.length / batchSize);

    for (let i = 0; i < items.length; i += batchSize) {
      const currentBatch = Math.ceil(i / batchSize) + 1;
      console.log(`Processing batch: ${currentBatch}/${totalBatches}`);
      progressCallback?.(
        "progress",
        `Processing batch: ${currentBatch}/${totalBatches}`,
        (currentBatch / totalBatches) * 100 // Calculate progress percentage
      );

      const batch = items.slice(i, i + batchSize);
      // await Promise.all(batch.map(processItem));
      // Process each item and track if any operations were performed
      const batchResults = await Promise.all(batch.map(processItem));
      const shouldSkipDelay = batchResults.some(Boolean);

      if (i + batchSize < items.length && !shouldSkipDelay) {
        await delay(rateLimitDelay);
      }
    }
  }

  public async delay(ms: number) {
    new Promise((resolve) => setTimeout(resolve, ms));
  }

  /**
   * Patch with retry logic for rate limiting and version mismatch
   */
  public async patch(
    entry: {
      entryId: string;
      version: number;
      environmentId?: string;
      spaceId?: string;
    },
    operations: any[],
    headers: Record<string, any> = {},
    maxAttempts: number = 5,
    baseDelay: number = 1000
  ) {
    const delay = (ms: number) => new Promise((resolve) => setTimeout(resolve, ms));

    const exponentialBackoffWithJitter = async (baseDelay: number, attempt: number) => {
      const maxDelay = baseDelay * Math.pow(2, attempt);
      const jitter = Math.random() * baseDelay;
      const delayTime = Math.min(maxDelay, baseDelay + jitter);
      await delay(delayTime);
    };

    let version = entry.version;
    let attempt = 0;

    while (attempt < maxAttempts) {
      try {
        // Introduce jitter before making the request
        if (attempt > 0) {
          await exponentialBackoffWithJitter(baseDelay, attempt);
        }

        return await this.client.entry.patch(
          // @ts-expect-error - version is not in the type definition, but it is a valid parameter
          { ...entry, version },
          operations,
          headers
        );
      } catch (e: any) {
        if (e.status === 429) {
          attempt++;
          if (attempt >= maxAttempts) {
            console.log("Max retry attempts reached. Operation failed.");
            throw e;
          }
          console.log(`Rate limit encountered. Retrying attempt ${attempt}...`);
        } else if (e.name === "VersionMismatch") {
          attempt++;
          if (attempt >= maxAttempts) {
            console.error("Max retry attempts reached. Operation failed.");
            throw e;
          }
          console.log(
            `Version mismatch encountered. Retrying attempt ${attempt} with bumped version...`
          );
          version++; // Bump the version by one
        } else {
          console.error("An error occurred:", e);
          throw e;
        }
      }
    }
  }

  /**
   * Takes an HTML string and converts it to a Contentful rich-text JSON
   * Its like the `documentToHtmlString` but in reverse
   *
   * @param htmlString - The HTML string to convert
   * @param originalJson - The original JSON object to merge with the converted JSON
   * @returns A promise that resolves to the converted JSON object
   */
  public static htmlToDocument(htmlString: string, originalJson: Record<string, any>) {
    if (!htmlString) return;

    if (typeof htmlString !== "string") {
      console.debug(htmlString);
    }

    htmlString.replaceAll("<div>", "").replaceAll("</div>", "");

    const jsonContent = originalJson?.content;

    const tagToNodeType: any = {
      p: "paragraph",
      h2: "heading-2",
      h3: "heading-3",
      h4: "heading-4",
      ul: "unordered-list",
      ol: "ordered-list",
      li: "list-item",
      table: "table",
      tr: "table-row",
      th: "table-header-cell",
      td: "table-cell",
    };

    const tagToMarkType: any = {
      b: "bold",
      i: "italic",
      u: "underline",
    };

    const convertTagNameToNodeType = (tagName: any) => {
      if (tagName === "sup") return "text"; // Convert superscript to text node type
      if (tagName === "sub") return "text"; // Convert subscript to text node type
      if (tagName === "a") return "hyperlink";

      return tagToNodeType[tagName] || tagName;
    };

    const convertTagNameToMarkType = (tagName: any) => {
      if (tagName === "sup") return "superscript"; // Handle superscript as a mark
      if (tagName === "sub") return "subscript"; // Handle subscript as a mark

      return tagToMarkType[tagName] || null;
    };

    const domToJson = (domElements: any, parentMarks: any[] = []) => {
      return domElements
        .map((element: any) => {
          let node: any = {
            nodeType: "",
            data: {},
            content: [],
            marks: [...parentMarks],
          };

          switch (element.type) {
            case "tag":
              if (
                element.name === "span" &&
                element.children.length === 1 &&
                element.children[0].type === "text"
              ) {
                // Custom logic for embedded inline entries required
                const textContent = element.children[0].data.trim();
                const match = textContent.match(
                  /^type: embedded-entry-inline id: (\w+)$/
                );

                // console.log(match);
                if (match) {
                  node.nodeType = "embedded-entry-inline";
                  node.data = {
                    target: {
                      sys: { id: match[1], type: "Link", linkType: "Entry" },
                    },
                  };
                  node.content = [];
                  delete node.marks;
                  return node;
                }
              }

              const markType = convertTagNameToMarkType(element.name);

              if (markType) {
                // Add mark to parent marks and process children
                return domToJson(element.children, [...parentMarks, { type: markType }]);
              } else {
                node.nodeType = convertTagNameToNodeType(element.name);
                // console.log(node.nodeType);

                if (element.children) {
                  try {
                    node.content = domToJson(element.children, node.marks);
                  } catch (e) {
                    console.log(e);
                  }
                }

                // // Ensure marks is only added if there are any marks to add
                // if (node.marks.length === 0 && node.nodeType !== "text") {
                //   delete node.marks;
                // }

                // if (node.nodeType === "text" && node?.content.length === 0) {
                //   delete node?.content;
                // }

                // if (node.nodeType === "hyperlink") {
                //   node.data.uri = element.attribs.href;
                // }

                // return node;
              }
              break;
            case "text":
              node.nodeType = "text";
              node.value = element.data; // Do not trim to keep empty values
              break;
          }

          // Ensure marks is only added if there are any marks to add
          if (node.marks.length === 0 && node.nodeType !== "text") {
            delete node.marks;
          }

          if (node.nodeType === "text" && node?.content.length === 0) {
            delete node?.content;
          }

          if (node.nodeType === "hyperlink") {
            node.data.uri = element.attribs.href;
          }

          return node;
        })
        .flat(); // Flatten the array to handle nested marks correctly
    };

    const processNode = (originalNode: any, jsonNode: any) => {
      if (originalNode.nodeType === "text" && originalNode?.value) {
        const originalValue = originalNode?.value || "";
        const jsonValue = jsonNode?.value || "";

        // Check for leading and trailing newlines in the original value
        const leadingNewlines = originalValue.match(/^\n+/);
        const trailingNewlines = originalValue.match(/\n+$/);

        if (jsonNode) {
          jsonNode.value =
            (leadingNewlines ? leadingNewlines[0].split("") : "") +
            jsonValue +
            (trailingNewlines ? trailingNewlines[0].split("") : "");
        }
      }

      // Process children if they exist
      if (originalNode?.content && jsonNode?.content) {
        originalNode?.content.forEach((childOriginalNode: any, index: number) => {
          processNode(childOriginalNode, jsonNode?.content[index]);
        });
      }
    };

    const processEmptyContentValues = (originalNode: any, jsonNode: any) => {
      if (!originalNode || !jsonNode) return;

      if (!Array.isArray(jsonNode?.content)) {
        if (
          originalNode?.content &&
          originalNode?.content[0] &&
          originalNode?.content[0]?.value === ""
        ) {
          // Replace the jsonNode with the originalNode
          return originalNode;
        }
      } else {
        // Recursively process children
        jsonNode.content = jsonNode?.content.map((childNode: any, index: number) => {
          if (originalNode?.content && originalNode?.content[index]) {
            return processEmptyContentValues(originalNode?.content[index], childNode);
          }
          return childNode;
        });
      }
      return jsonNode;
    };

    return new Promise((resolve, reject) => {
      const handler = new DomHandler((error, dom) => {
        if (error) {
          console.error(error);
          reject(error);
          return;
        }

        let json = domToJson(dom);

        // Process embeds
        const allEmbeds = jsonContent
          .map((node: any, index: number) => {
            if (node.nodeType.includes("embedded")) {
              return { index, node };
            }
            return null;
          })
          .filter((item: any) => item);

        allEmbeds.forEach((embed: any) => {
          const { index, node } = embed;
          json.splice(index, 0, node);
        });

        // Process each node in the top-level content array
        jsonContent.forEach((originalNode: any, index: number) => {
          processNode(originalNode, json[index]);
        });

        // Process empty content values
        json = json.map((node: any, index: number) => {
          return processEmptyContentValues(jsonContent[index], node);
        });

        json = json.filter((node: any) => {
          if (!node) return false;

          if (node?.nodeType === "div") return false;

          if (node?.nodeType) return true;

          return false;
        });

        resolve({
          nodeType: "document",
          data: {},
          content: json,
        });
      });

      const parser = new Parser(handler);
      parser.write(htmlString);
      parser.end();
    });
  }

  public async resetStatusField(entryId: string, localeToResetStatusFor: string) {
    const entry = await this.client.entry.get({
      entryId,
      environmentId: process.env.CONTENTFUL_ENV,
    });

    await this.client.entry.patch(
      { entryId, environmentId: process.env.CONTENTFUL_ENV },
      [
        {
          op: "remove",
          path: `/fields/status/${localeToResetStatusFor}`,
        },
      ],
      { "X-Contentful-Version": entry.sys.version }
    );
  }

  public async removeAllLocalizedContent(
    pageId: string,
    localeToRemoveContentFor: string
  ) {
    const entryRefs = await this.client.entry.references({
      spaceId: process.env.CONTENTFUL_SPACE_ID,
      environmentId: process.env.CONTENTFUL_ENV,
      entryId: pageId,
      include: 10,
    });

    const entries = entryRefs.includes?.Entry || [];
    let noLocalizedContentFound = true;

    const processEntry = async (entry: any) => {
      const doesNotHaveLocalizedContent = Object.keys(entry.fields).every(
        (field) => !entry.fields[field][localeToRemoveContentFor]
      );

      if (doesNotHaveLocalizedContent) {
        console.log(
          `No localized content found for ${localeToRemoveContentFor} on entry ${entry.sys.id}`
        );
        return;
      }

      const operations = Object.keys(entry.fields)
        .filter((field) => entry.fields[field][localeToRemoveContentFor])
        .map((field) => ({
          op: "remove",
          path: `/fields/${field}/${localeToRemoveContentFor}`,
        }));

      if (operations.length === 0) return;

      noLocalizedContentFound = false;

      try {
        await this.patch(
          {
            entryId: entry.sys.id,
            environmentId: entry.sys.environment.sys.id,
            version: entry.sys.version,
          },
          operations,
          {
            "X-Contentful-Version": entry.sys.version,
          }
        );

        console.log(
          `Successfully deleted all ${localeToRemoveContentFor} content from entry ${entry.sys.id}`
        );
      } catch (e) {
        console.log(e);
      }
    };

    await this.processInBatches(entries, processEntry);

    if (!noLocalizedContentFound) {
      console.log(
        `Successfully deleted all ${localeToRemoveContentFor} content from all entries for page ${pageId}`
      );
    } else {
      console.log(
        `No localized content found for ${localeToRemoveContentFor} on page ${pageId}`
      );
    }

    const entry = await this.client.entry.get({
      entryId: pageId,
      // @ts-expect-error - include is not in the type definition, but it is a valid parameter
      include: 1,
    });

    const hasAllFieldsTranslatedField =
      entry?.fields?.status?.[localeToRemoveContentFor]?.allFieldsTranslated;

    try {
      await this.patch({ entryId: pageId, version: entry.sys.version }, [
        {
          op: hasAllFieldsTranslatedField ? "replace" : "add",
          path: `/fields/status/${localeToRemoveContentFor}/allFieldsTranslated`,
          value: false,
        },
      ]);
    } catch (e) {
      console.log(e);
    }
  }

  public async addLocalizedContent(
    entry: Entry,
    locale: string,
    field: string,
    content: any
  ) {
    console.log(entry);
    console.log(content);
    try {
      const res = await this.client.entry.patch(
        { entryId: entry.sys.id, environmentId: entry.sys.environment.sys.id },
        [
          {
            op: "add",
            path: `/fields/${field}/${locale}`,
            value: content,
          },
        ],
        {
          "X-Contentful-Version": entry.sys.version,
        }
      );

      console.log(await res);
    } catch (e) {
      console.log(e);
    }
  }

  public async setPageStatus(
    pageId: string,
    locale: string,
    {
      state,
      isLive,
      isTranslating,
      isReadyForLive,
      alreadyTranslated,
      refsHash,
      fieldsHash,
      error,
      liveData,
      randomValue,
    }: {
      state?: "Published" | "Draft" | "Changed" | "Disabled" | "Error";
      isLive?: boolean | "Loading";
      isTranslating?: boolean | "Loading";
      isReadyForLive?: boolean | "Loading";
      alreadyTranslated?: boolean | "Loading";
      refsHash?: string;
      fieldsHash?: string;
      error?: any | false;
      liveData?: any;
      randomValue?: number;
    }
  ) {
    console.log("Setting...");
    // TODO: Change .status to .state

    const entry: any = await this.client.entry.get({
      entryId: pageId,
      environmentId: process.env.CONTENTFUL_ENV,
      // @ts-expect-error - include is not in the type definition, but it is a valid parameter
      include: 1,
    });

    const currentStatus = entry.fields.status[locale];

    // !! REMOVE OLD STYLES OF CACHING !! \\
    delete currentStatus.fieldsCache;
    delete currentStatus.isDemo;
    delete currentStatus.isPrelive;
    delete currentStatus.isPreview;
    delete currentStatus.finalEnvHash;
    delete currentStatus.fieldCache;
    delete currentStatus.isFinal;
    // !! REMOVE OLD STYLES OF CACHING !! \\

    if (state !== undefined) {
      // Always set Drafts to Draft (not changed)
      entry?.sys?.fieldStatus?.["*"]?.[locale] === "draft"
        ? (currentStatus.state = "Draft")
        : (currentStatus.state = state);
    }
    if (isLive !== undefined) {
      currentStatus.isLive = isLive;
    }
    if (isReadyForLive !== undefined) {
      currentStatus.isReadyForLive = isReadyForLive;
    }
    if (isTranslating !== undefined) {
      currentStatus.isTranslating = isTranslating;
    }
    if (alreadyTranslated !== undefined) {
      currentStatus.alreadyTranslated = alreadyTranslated;
    }
    if (refsHash !== undefined) {
      currentStatus.refsHash = refsHash;
    }
    if (fieldsHash !== undefined) {
      currentStatus.fieldsHash = fieldsHash;
    }
    if (liveData !== undefined) {
      currentStatus.liveData = liveData;
    }
    if (error !== undefined) {
      currentStatus.error = error;
    }
    if (randomValue !== undefined) {
      currentStatus.randomValue = randomValue;
    }

    try {
      await this.client.entry.patch(
        {
          entryId: entry.sys.id,
          environmentId: process.env.CONTENTFUL_ENV,
          // @ts-expect-error - version is not in the type definition, but it is a valid parameter
          version: entry.sys.version,
        },
        [
          {
            op: "replace",
            path: `/fields/status/${locale}`,
            value: currentStatus,
          },
        ]
      );
    } catch (e) {
      console.log("Failed to set the page status", e);
    }

    console.log(currentStatus);
  }

  /**
   * Generates a hash that represents all the references fields values of an entry in a specific locale
   */
  public async generateHashForReferences(
    pageId: string,
    locale: string
  ): Promise<string> {
    interface LocaleEntry {
      contentType?: string;
      value?: any;
      type?: "Defaulted" | "Overridden" | "Overridden (same)" | "Something went wrong";
      field?: any;
      entry?: any;
    }

    type ReferencesFields = Record<string, LocaleEntry>;

    const references = await this.client.entry.references({
      entryId: pageId,
      environmentId: process.env.CONTENTFUL_ENV,
      include: 10,
    });

    if (!references?.includes?.Entry?.length || !references?.includes?.Asset?.length) {
      console.log(locale, "No references found");
      return "";
    }

    const referencesFields: ReferencesFields = {};

    // Extract all localized fields from referenced entries
    references?.includes?.Entry?.forEach((entry: any) => {
      const referenceContentType = entry.sys?.contentType.sys.id;

      const fields = Object.entries(entry.fields);

      const thisLocaleEntry: Record<string, LocaleEntry> = {};

      fields.forEach(([key, value]: any) => {
        if (key === "internalName") return;
        if (key === "randomValue") return;

        if (value[locale] === undefined) {
          thisLocaleEntry[key] = {
            value: value[PILLAR_LOCALE],
            type: "Defaulted",
          };
          return;
        }

        if (value[locale] !== undefined && value[locale] !== value[PILLAR_LOCALE]) {
          thisLocaleEntry[key] = { value: value[locale], type: "Overridden" };
          return;
        }

        if (value[locale] !== undefined && value[locale] === value[PILLAR_LOCALE]) {
          thisLocaleEntry[key] = {
            value: value[locale],
            type: "Overridden (same)",
          };
          return;
        }

        thisLocaleEntry[key] = {
          field: key,
          entry: entry.sys?.contentType.sys.id,
          type: "Something went wrong",
        };
      });

      referencesFields[entry.sys.id] = {
        contentType: referenceContentType,
        ...thisLocaleEntry,
      };
    });

    // Extract all localized fields from referenced assets
    references?.includes?.Asset?.forEach((asset: any) => {
      const fields = Object.entries(asset.fields);

      const thisLocaleAsset: Record<string, LocaleEntry> = {};

      fields.forEach(([key, value]: any) => {
        if (value[locale] === undefined) {
          thisLocaleAsset[key] = {
            value: value[PILLAR_LOCALE],
            type: "Defaulted",
          };
          return;
        }

        if (value[locale] !== undefined && value[locale] !== value[PILLAR_LOCALE]) {
          thisLocaleAsset[key] = { value: value[locale], type: "Overridden" };
          return;
        }

        if (value[locale] !== undefined && value[locale] === value[PILLAR_LOCALE]) {
          thisLocaleAsset[key] = {
            value: value[locale],
            type: "Overridden (same)",
          };
          return;
        }

        thisLocaleAsset[key] = {
          field: key,
          entry: asset.sys?.contentType.sys.id,
          type: "Something went wrong",
        };
      });

      referencesFields[asset.sys.id] = {
        contentType: "Asset",
        ...thisLocaleAsset,
      };
    });

    const dataString = JSON.stringify(referencesFields); // Convert JSON data to string

    // @ts-expect-error - It works, but the type definition is wrong
    const compressedData = gzipSync(Buffer.from(dataString, "utf-8")); // Compress the string

    // @ts-expect-error - It works, but the type definition is wrong
    const bufferData = Buffer.from(compressedData); // Convert Uint8Array to Buffer
    const hash = await x86.hash32(bufferData.toString("hex")); // Hash the buffer

    return hash.toString();
  }

  /**
   * Generates a hash that represents the fields in the entry in a specific locale
   */
  public async generateHashForFields(pageId: string, locale: string) {
    const entry = await this.client.entry.get({
      entryId: pageId,
      environmentId: process.env.CONTENTFUL_ENV,
      // @ts-expect-error - include is not in the type definition, but it is a valid parameter
      include: 1,
    });

    const fields = Object.entries(entry.fields)
      .map(([key, value]: any) => {
        if (key === "status") return;
        if (key === "nPageMetadata") return;

        if (value[locale] === undefined) {
          return { [key]: value[PILLAR_LOCALE] };
        }

        if (value[locale] !== value[PILLAR_LOCALE]) {
          return { [key]: value[locale] };
        }

        if (value[locale] === value[PILLAR_LOCALE]) {
          return { [key]: value[locale] };
        }

        return { [key]: "Empty value" };
      })
      .filter((field) => field);

    const dataString = JSON.stringify(fields); // Convert JSON data to string
    // @ts-expect-error - It works, but the type definition is wrong
    const compressedData = gzipSync(Buffer.from(dataString, "utf-8")); // Compress the string

    // @ts-expect-error - It works, but the type definition is wrong
    const bufferData = Buffer.from(compressedData); // Convert Uint8Array to Buffer
    const hash = await x86.hash32(bufferData.toString("hex")); // Hash the buffer

    return hash.toString();
  }

  public async publishEntry(pageId: string): Promise<any> {
    let deploymentErrors = false;
    let validationErrors: any[] = [];
    let otherErrors: any[] = [];

    const entry = await this.client.entry.get({
      entryId: pageId,
      environmentId: process.env.CONTENTFUL_ENV,
    });

    try {
      const result = await this.client.entry.publish(
        {
          entryId: pageId,
          environmentId: process.env.CONTENTFUL_ENV,
          // @ts-expect-error - version is not in the type definition, but it is a valid parameter
          version: entry.sys.version,
        },
        entry
      );

      return entry;
    } catch (e: any) {
      console.log(e);
      if (e.name === "UnresolvedLinks") {
        deploymentErrors = true;
        try {
          const message = JSON.parse(e.message);
          const localeRegex = /^[a-zA-Z]{2}(?:-[a-zA-Z]{2})?$/;

          const localesWithValidationErrors = message.details.errors.map((error: any) => {
            const locale = error.path.find((element: any) => localeRegex.test(element));
            return locale;
          });

          validationErrors.push({
            type: entry.sys.type,
            entryId: entry.sys.id,
            entryType: entry.sys?.contentType.sys.id,
            errors: message.details.errors,
            localesWithValidationErrors,
          });
        } catch (e) {
          otherErrors.push(e);
          console.log(e);
        }
      }
      // return { type: "error", message: "Failed to publish page", error: e };
      // return { type: "error", message: "Failed to publish page", error: e };
    }

    return { deploymentErrors, validationErrors, otherErrors };
  }

  public async publishAllSlavedEntries(pageId: string, locale: string) {
    let deploymentErrors = false;
    let validationErrors: any[] = [];
    let otherErrors: any[] = [];
    const notifications = [];

    const references = await this.client.entry.references({
      entryId: pageId,
      environmentId: process.env.CONTENTFUL_ENV,
      include: 10,
    });

    // Find all entries and assets that are not published in the specified locale
    const unpublishedEntries =
      references?.includes?.Entry?.filter(
        (entry: any) =>
          !entry?.sys?.fieldStatus?.["*"]?.[locale] || // No localized version
          entry?.sys?.fieldStatus?.["*"]?.["en"] !== "published" || // Pillar version not published
          entry?.sys?.fieldStatus?.["*"]?.[locale] !== "published" // Localized version not published
      ) || [];

    const unpublishedAssets =
      references?.includes?.Asset?.filter(
        (asset: any) =>
          !asset?.sys?.fieldStatus?.["*"]?.[locale] ||
          asset?.sys?.fieldStatus?.["*"]?.["en"] !== "published" ||
          asset?.sys?.fieldStatus?.["*"]?.[locale] !== "published"
      ) || [];

    let entryPatched: any = {};
    let assetPatched: any = {};

    const entryVersionMap: Record<string, number> = {};
    const assetVersionMap: Record<string, number> = {};

    // This adds a random value to a hidden field in the locale
    // because Contentful doesn't trigger a "Publish" on locales that have
    // all their fields defaulted to the pillar
    const processEntryPatch = async (unpublishedEntry: any) => {
      console.log("Processing entry patch... ", unpublishedEntry.sys.id);

      const operations = [];
      if (!unpublishedEntry?.fields?.randomValue) {
        operations.push({
          op: "add",
          path: `/fields/randomValue`,
          value: { en: randomIntWith(6) },
        });
      }

      if (!unpublishedEntry?.fields?.randomValue?.[locale]) {
        operations.push({
          op: "add",
          path: `/fields/randomValue/${locale}`,
          value: randomIntWith(6),
        });
      }

      if (operations.length !== 0) {
        try {
          // TODO: This entire flow needs to be reworked, its very inefficient
          const currentEntry = await this.client.entry.get({
            entryId: unpublishedEntry.sys.id,
            environmentId: process.env.CONTENTFUL_ENV,
          });

          await this.client.entry.patch(
            {
              entryId: unpublishedEntry.sys.id,
              // @ts-expect-error - version is not in the type definition, but it is a valid parameter
              version: currentEntry.sys.version,
              // version:
              //   unpublishedEntry.sys.version +
              //   (entryVersionMap[unpublishedEntry.sys.id] || 0),
            },
            operations
          );

          entryPatched[unpublishedEntry.sys.id] = true;
          entryVersionMap[unpublishedEntry.sys.id] =
            (entryVersionMap[unpublishedEntry.sys.id] || 0) + 1;
        } catch (e) {
          console.log(e);
        }
      }
    };

    // Copies the pillar asset to the locale if the locale asset is missing
    // this is for the same reason as above (Contentful doesn't trigger a "Publish")
    const processAssetPatch = async (unpublishedAsset: any) => {
      console.log("Processing asset patch... ", unpublishedAsset.sys.id);
      const fields: any = { ...unpublishedAsset.fields };
      let shouldUpdate = false;

      Object.entries(unpublishedAsset.fields).forEach(([field, val]: any) => {
        if (val[locale] === undefined) {
          shouldUpdate = true;
          fields[field][locale] = val[PILLAR_LOCALE];
        }
      });

      if (shouldUpdate) {
        try {
          // TODO: This entire flow needs to be reworked, its very inefficient
          const currentAsset = await this.client.asset.get({
            assetId: unpublishedAsset.sys.id,
            environmentId: process.env.CONTENTFUL_ENV,
          });

          const res = await this.client.asset.update(
            {
              spaceId: unpublishedAsset.sys.space.sys.id,
              environmentId: unpublishedAsset.sys.environment.sys.id,
              assetId: unpublishedAsset.sys.id,
              // @ts-expect-error - version is not in the type definition, but it is a valid parameter
              version: currentAsset.sys.version,
              // version:
              //   unpublishedAsset.sys.version +
              //   (assetVersionMap[unpublishedAsset.sys.id] || 0),
            },
            {
              sys: unpublishedAsset.sys,
              fields,
            }
          );

          assetPatched[unpublishedAsset.sys.id] = true;
          assetVersionMap[unpublishedAsset.sys.id] =
            (assetVersionMap[unpublishedAsset.sys.id] || 0) + 1;
        } catch (e) {
          console.log(e);
        }
      }
    };

    await this.processInBatches(unpublishedEntries, processEntryPatch);
    await this.processInBatches(unpublishedAssets, processAssetPatch);

    // const patchPromises = [...entryPatchPromises, ...assetPatchPromises];

    // await Promise.all(patchPromises);

    const unpublishedReferences = [...unpublishedEntries, ...unpublishedAssets];

    /* PUBLISH ALL REFERENCED ENTRIES  */
    const processReference = async (unpublishedRef: any) => {
      console.log(
        `Publishing referenced ${unpublishedRef.sys.type} with id: ${unpublishedRef.sys.id}`
      );

      const removeAllFieldsThatAreSameAsPillar = async () => {
        // if (unpublishedRef.sys.contentType.sys.id !== "nTab") {
        //   return;
        // }

        if (!unpublishedRef.sys.id) {
          console.log(unpublishedRef);
          console.log("No id");
        }

        const locales = Object.keys(unpublishedRef.sys.fieldStatus["*"]);

        const operations: any = [];

        for (const locale of locales) {
          if (locale === "en") continue;

          // Fix for tiles inherit field
          if (unpublishedRef?.sys?.contentType?.sys?.id === "nTile") {
            if (unpublishedRef?.fields?.theme?.[locale] === "inherit") {
              operations.push({
                op: "remove",
                path: `/fields/theme/${locale}`,
              });
            }
          }

          // Fix for buttons type field
          if (unpublishedRef.sys.contentType.sys.id === "nButton") {
            if (unpublishedRef?.fields?.type?.[locale] === "Anchor") {
              console.log("Fixing button - Removing", "type", locale);
              operations.push({
                op: "remove",
                path: `/fields/type/${locale}`,
              });
            }
          }

          // Remove duplicate fields (same in pillar and locale)
          Object.entries(unpublishedRef.fields).forEach(([field, value]: any) => {
            if (field === "internalName" || field === "randomValue" || field === "slug")
              return;

            if (value[locale] === undefined && value["en"] === undefined) return;

            if (value[locale] === undefined) return;

            if (value[locale] === value["en"]) {
              console.log("Removing", field, locale);
              console.log("Values: " + `${locale} ` + value[locale], "en " + value["en"]);

              operations.push({
                op: "remove",
                path: `/fields/${field}/${locale}`,
              });
            }
          });
        }

        if (operations.length) {
          console.log("Removing all fields that are the same as the pillar", operations);
          try {
            const res = await this.client.entry.patch(
              {
                entryId: unpublishedRef.sys.id,
                environmentId: process.env.CONTENTFUL_ENV,
                spaceId: unpublishedRef.sys.space.sys.id,
                // @ts-expect-error - version is not in the type definition, but it is a valid parameter
                version:
                  unpublishedRef.sys.version +
                  (entryVersionMap[unpublishedRef.sys.id] || 0),
              },
              operations
            );

            entryVersionMap[unpublishedRef.sys.id] =
              (entryVersionMap[unpublishedRef.sys.id] || 0) + 1;
          } catch (e: any) {
            console.log(unpublishedRef.sys.contentType.id, e);
            if (e.name === "VersionMismatch") {
              console.log("rerunning due to version mismatch");
              const res = await this.client.entry.patch(
                {
                  entryId: unpublishedRef.sys.id,
                  environmentId: process.env.CONTENTFUL_ENV,
                  spaceId: unpublishedRef.sys.space.sys.id,
                  // @ts-expect-error - version is not in the type definition, but it is a valid parameter
                  version:
                    unpublishedRef.sys.version +
                    (entryVersionMap[unpublishedRef.sys.id] || 0) +
                    1,
                },
                operations
              );
              console.log(res);
              entryVersionMap[unpublishedRef.sys.id] =
                (entryVersionMap[unpublishedRef.sys.id] || 0) + 1;
            } else {
              console.log(e);
            }
          }
        }
      };

      // Publish all unpublished entries
      if (unpublishedRef.sys.type === "Entry") {
        await removeAllFieldsThatAreSameAsPillar();

        try {
          await this.client.entry.publish(
            {
              entryId: unpublishedRef.sys.id,
              environmentId: process.env.CONTENTFUL_ENV,
              spaceId: references.items[0].sys.space.sys.id,
              // @ts-expect-error - version is not in the type definition, but it is a valid parameter
              version:
                unpublishedRef.sys.version +
                (entryVersionMap[unpublishedRef.sys.id] || 0),
            },
            {
              ...({
                ...unpublishedRef,
                sys: {
                  ...unpublishedRef.sys,
                  version:
                    unpublishedRef.sys.version +
                    (entryVersionMap[unpublishedRef.sys.id] || 0),
                },
              } as Entry),
            }
          );
        } catch (e: any) {
          deploymentErrors = true;

          console.error(unpublishedRef.sys.id, e);

          try {
            const message = JSON.parse(e?.message);

            if (message?.message === "Validation error") {
              const localesWithValidationErrors = message.details.errors.map(
                (error: any) => error.path[error.path.length - 1]
              );

              validationErrors.push({
                type: unpublishedRef.sys.type,
                entryId: unpublishedRef.sys.id,
                entryType: unpublishedRef.sys?.contentType.sys.id,
                errors: message.details.errors,
                localesWithValidationErrors,
              });
            }
          } catch (e) {
            otherErrors.push(e);
            console.log("Something went wrong while publishing a slave entry", e);
          }

          // break; // Break the outermost loop upon encountering an error
        }
      }

      // Publish all unpublished assets
      if (unpublishedRef.sys.type === "Asset") {
        try {
          await this.client.asset.publish(
            {
              assetId: unpublishedRef.sys.id,
              environmentId: process.env.CONTENTFUL_ENV,
              spaceId: references.items[0].sys.space.sys.id,
              // @ts-expect-error - version is not in the type definition, but it is a valid parameter
              version:
                unpublishedRef.sys.version +
                (assetVersionMap[unpublishedRef.sys.id] || 0),
            },
            {
              ...({
                ...unpublishedRef,
                sys: {
                  ...unpublishedRef.sys,
                  version:
                    unpublishedRef.sys.version +
                    (assetVersionMap[unpublishedRef.sys.id] || 0),
                },
              } as Asset),
            }
          );
        } catch (e: any) {
          console.log(e);
          deploymentErrors = true;

          try {
            const message = JSON.parse(e?.message);

            if (message?.message === "Validation error") {
              const localesWithValidationErrors = message.details.errors.map(
                (error: any) => error.path[error.path.length - 1]
              );

              validationErrors.push({
                type: unpublishedRef.sys.type,
                assetId: unpublishedRef.sys.id,
                entryType: unpublishedRef.sys?.contentType.sys.id,
                errors: message.details.errors,
                localesWithValidationErrors,
              });
            }
          } catch (e) {
            otherErrors.push(e);
            console.log("Something went wrong while publishing a slave entry", e);
          }
        }
      }
    };

    await this.processInBatches(unpublishedReferences, processReference);

    if (!deploymentErrors) {
      notifications.push({
        type: "success",
        message: "Successfully published all slaved entries",
      });
    }

    return { deploymentErrors, validationErrors, notifications, otherErrors };
  }

  // Deprecated
  public async getPagesForSitemap() {
    try {
      const entries = await this.client.entry.getMany({
        query: {
          content_type: "nPage",
          include: 2,
          select: "fields.slug,fields.internalName",
          limit: 1000,
          "fields.components[exists]": true,
          "fields.slug.en[exists]": true,
        },
      });
      return entries.items;
    } catch (e) {
      console.log(e);
    }
  }
}

export default Contentful;
