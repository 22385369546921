export const BADGE_VARIANTS: {
  [key: string]: "positive" | "primary-filled" | "warning" | "secondary" | "negative";
} = {
  Error: "negative",
  Published: "positive",
  Final: "primary-filled",
  "Unpublished changes on page": "warning",
  Disabled: "secondary",
  Draft: "warning",
};

export const highlightText = (element: HTMLElement) => {
  if (!element) {
    console.error("Invalid element provided");
    return;
  }

  // Create a range and select the content of the element
  const range = document.createRange();
  range.selectNodeContents(element);

  // Get the selection object and clear any existing selections
  const selection = window.getSelection();
  selection.removeAllRanges();

  // Add the new range to the selection
  selection.addRange(range);
};
