
// export default Icon;
import { cn } from "@/utils/helpers";
import { Fzf, byLengthAsc } from "fzf";
import { X } from "lucide-react";
import dynamicIconImports from "lucide-react/dynamicIconImports";
import dynamic from "next/dynamic";
import { useRef } from "react";

// "static-ribbon-small", "static-ftse-building", "static-eu-flag"
const iconPaths = new Map();
iconPaths.set("static-ribbon-small", dynamic(() => import("@/components/icons/static/ribbon-icon-small")));
iconPaths.set("static-ftse-building", dynamic(() => import("@/components/icons/static/ftse-building")));
iconPaths.set("static-eu-flag", dynamic(() => import("@/components/icons/static/eu-flag")));
iconPaths.set("static-apple", dynamic(() => import("@/components/icons/static/ios-icon-svg")));
iconPaths.set("static-windows", dynamic(() => import("@/components/icons/static/windows-icon-svg")));
iconPaths.set("static-play-store", dynamic(() => import("@/components/icons/static/play-store-svg")));
iconPaths.set("static-linux", dynamic(() => import("@/components/icons/static/linux-svg")));
iconPaths.set("static-x-social", dynamic(() => import("@/components/icons/static/x-social")));
// iconPaths.set("static-ribbon-large", dynamic(() => import("@/components/svgs/icons/ribbon-icon-large"))); // Maybe deprecated

const Icon = ({ name, className, strokeWidth = 1, ...props }: any) => {
  const nameRef = useRef(name || "");
  
  if(!name || typeof name !== "string") return null;

  name = name.toLowerCase();
  
  if (name && iconPaths.has(name)) {
    const Icon = iconPaths.get(name);
    return <Icon className={cn(className)} />;
  }

  if (name) {
    if(nameRef.current !== name){
      const allIconNames = Object.keys(dynamicIconImports);
  
      const fzf = new Fzf(allIconNames, {
        tiebreakers: [byLengthAsc],
      });
  
      const result = fzf.find(name);
      const bestMatch = result.length > 0 ? result[0].item : null;

      if (bestMatch) {
        nameRef.current = bestMatch;

        const LucideIcon = dynamic(
          dynamicIconImports[bestMatch as keyof typeof dynamicIconImports]
        );
  
        return (
          <LucideIcon
            className={cn(className)}
            strokeWidth={strokeWidth}
            {...props}
          />
        );
      }
    } else {
      const LucideIcon = dynamic(
        dynamicIconImports[name as keyof typeof dynamicIconImports]
      );

      return (
        <LucideIcon
          className={cn(className)}
          strokeWidth={strokeWidth}
          {...props}
        />
      );
    }
  }

  return (
    <X className={cn(className)} stroke="red" strokeWidth={1.5} {...props} />
  );
};

export default Icon;
