// Gets the height of the header element (containing risk warning, nav, scroll spy anchor) and returns it as a number.
// Typical use is to help with offsetting in intersection observers.

import { useState, useEffect } from "react";

const useGetHeaderHeight = (): number => {
  const [headerHeight, setHeaderHeight] = useState<number>(0);

  useEffect(() => {
    const headerElement = document.querySelector("header");

    if (headerElement instanceof HTMLElement) {
      const resizeObserver = new ResizeObserver((entries) => {
        for (let entry of entries) {
          if (entry.target === headerElement) {
            setHeaderHeight(entry.contentRect.height);
          }
        }
      });

      // Initial call to set height
      setHeaderHeight(headerElement.offsetHeight);

      resizeObserver.observe(headerElement);

      return () => {
        resizeObserver.unobserve(headerElement);
        resizeObserver.disconnect();
      };
    }
  }, []);

  return headerHeight;
};

export default useGetHeaderHeight;
