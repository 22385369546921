import { Text } from "@/types/design-system-types";
import { cn } from "@/utils/helpers";
import { HTMLProps, ReactNode } from "react";

const Paragraph = ({
  children,
  className,
  type = "text",
  fontSize = "md",
  fontWeight = "normal",
  ...props
}: {
  children: ReactNode;
  className?: string;
  type?: Text["type"];
  fontSize?: Text["fontSize"];
  fontWeight?: Text["fontWeight"];
} & HTMLProps<HTMLHeadingElement>) => {
  const textSizeMap = {
    xs: "text-xs",
    sm: "text-sm",
    md: "text-base",
    lg: "text-lg",
    xl: "text-xl",
  };

  const captionSizeMap = {
    xs: "text-xxs",
    sm: "text-xxs",
    md: "text-xs",
    lg: "text-base",
    xl: "text-base", // TODO: this is a ts fix. Need to factor as caption cant be xl
  };

  const weightClassMap = {
    thin: "font-thin",
    normal: "font-normal",
    semibold: "font-semibold",
    bold: "font-bold",
  };

  let casing: "uppercase" | "lowercase" | "normal-case" = "normal-case";
  let sizeClass = textSizeMap[fontSize];
  let weightClass = weightClassMap[fontWeight];

  if (type === "caption") {
    sizeClass = captionSizeMap[fontSize];
    casing = "uppercase";
  }

  const classes = cn([sizeClass, weightClass, casing], className);

  return (
    <div className={cn(classes)} {...props}>
      {children}
    </div>
  );
};

export default Paragraph;
