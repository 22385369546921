import { ISO3166_Alpha2_Codes } from "@/types/country-codes";

export const countries_bn: Record<ISO3166_Alpha2_Codes, string> = {
  AW: "আরুবা",
  AF: "আফগানিস্তান",
  AO: "অ্যাঙ্গোলা",
  AI: "অ্যাঙ্গুইলা",
  AX: "অ্যাল্যান্ড দ্বীপপুঞ্জ",
  AL: "আলবেনিয়া",
  AD: "অ্যান্ডোরা",
  AN: "নেদারল্যান্ডস এন্টিলস",
  AE: "সংযুক্ত আরব আমিরাত",
  AR: "আর্জেন্টিনা",
  AM: "আর্মেনিয়া",
  AS: "আমেরিকান সামোয়া",
  AQ: "অ্যান্টার্কটিকা",
  TF: "ফ্রেঞ্চ সাউদার্ন টেরিটোরিজ",
  AG: "অ্যান্টিগুয়া ও বার্বুডা",
  AU: "অস্ট্রেলিয়া",
  AT: "অস্ট্রিয়া",
  AZ: "আজারবাইজান",
  BI: "বুরুন্ডি",
  BE: "বেলজিয়াম",
  BJ: "বেনিন",
  BQ: "বোনায়ার, সিন্ট ইউস্টাটিয়াস এবং সাবা",
  BF: "বুর্কিনা ফাসো",
  BD: "বাংলাদেশ",
  BG: "বুলগেরিয়া",
  BH: "বাহরাইন",
  BS: "বাহামা",
  BA: "বসনিয়া ও হার্জেগোভিনা",
  BL: "সেন্ট বার্থলেমি",
  BY: "বেলারুশ",
  BZ: "বেলিজ",
  BM: "বারমুডা",
  BO: "বলিভিয়া (বহুজাতিক রাষ্ট্র)",
  BR: "ব্রাজিল",
  BB: "বার্বাডোস",
  BN: "ব্রুনাই দারুসসালাম",
  BT: "ভুটান",
  BV: "বোভেট দ্বীপ",
  BW: "বোতসোয়ানা",
  CF: "মধ্য আফ্রিকান প্রজাতন্ত্র",
  CA: "কানাডা",
  CC: "কোকোস (কিলিং) দ্বীপপুঞ্জ",
  CH: "সুইজারল্যান্ড",
  CL: "চিলি",
  CN: "চীন",
  CI: "কোস্টারিকা",
  CM: "ক্যামেরুন",
  CD: "কঙ্গো (গণতান্ত্রিক প্রজাতন্ত্র)",
  CG: "কঙ্গো",
  CK: "কুক দ্বীপপুঞ্জ",
  CO: "কলম্বিয়া",
  KM: "কমোরোস",
  CV: "ক্যাবো ভার্দে",
  CR: "কোস্টারিকা",
  CU: "কিউবা",
  CW: "কুরাসাও",
  CX: "ক্রিস্টমাস দ্বীপ",
  KY: "কেম্যান দ্বীপপুঞ্জ",
  CY: "সাইপ্রাস",
  CZ: "চেক প্রজাতন্ত্র",
  DE: "জার্মানি",
  DJ: "জিবুতি",
  DM: "ডোমিনিকা",
  DK: "ডেনমার্ক",
  DO: "ডোমিনিকান প্রজাতন্ত্র",
  DZ: "আলজেরিয়া",
  EC: "ইকুয়েডর",
  EG: "মিশর",
  ER: "ইরিত্রিয়া",
  EH: "পশ্চিম সাহারা",
  ES: "স্পেন",
  EE: "এস্তোনিয়া",
  ET: "ইথিওপিয়া",
  FI: "ফিনল্যান্ড",
  FJ: "ফিজি",
  FK: "ফকল্যান্ড দ্বীপপুঞ্জ (মালভিনাস)",
  FR: "ফ্রান্স",
  FO: "ফারো দ্বীপপুঞ্জ",
  FM: "মাইক্রোনেশিয়া",
  GA: "গ্যাবন",
  GB: "যুক্তরাজ্য",
  GE: "জর্জিয়া",
  GG: "গার্নসি",
  GH: "ঘানা",
  GI: "জিব্রাল্টার",
  GN: "গিনি",
  GP: "গুয়াদেলৌপ",
  GM: "গাম্বিয়া",
  GW: "গিনি-বিসাউ",
  GQ: "নিরক্ষীয় গিনি",
  GR: "গ্রিস",
  GD: "গ্রেনাডা",
  GL: "গ্রিনল্যান্ড",
  GT: "গুয়াতেমালা",
  GF: "ফরাসি গায়ানা",
  GU: "গুয়াম",
  GY: "গায়ানা",
  HK: "হংকং",
  HM: "হার্ড দ্বীপ এবং ম্যাকডোনাল্ড দ্বীপপুঞ্জ",
  HN: "হন্ডুরাস",
  HR: "ক্রোয়েশিয়া",
  HT: "হাইতি",
  HU: "হাঙ্গেরি",
  ID: "ইন্দোনেশিয়া",
  IM: "আইল অফ ম্যান",
  IN: "ভারত",
  IO: "ব্রিটিশ ভারত মহাসাগরীয় অঞ্চল",
  IE: "আয়ারল্যান্ড",
  IR: "ইরান",
  IQ: "ইরাক",
  IS: "আইসল্যান্ড",
  IL: "ইজরায়েল",
  IT: "ইতালি",
  JM: "জ্যামাইকা",
  JE: "জার্সি",
  JO: "জর্ডন",
  JP: "জাপান",
  KZ: "কাজাখস্তান",
  KE: "কেনিয়া",
  KG: "কিরগিজস্তান",
  KH: "কম্বোডিয়া",
  KI: "কিরিবাতি",
  KN: "সেন্ট কিটস ও নেভিস",
  KR: "দক্ষিণ কোরিয়া",
  KW: "কুয়েত",
  LA: "লাওস",
  LB: "লেবানন",
  LR: "লাইবেরিয়া",
  LY: "লিবিয়া",
  LC: "সেন্ট লুসিয়া",
  LI: "লিখটেনস্টাইন",
  LK: "শ্রীলঙ্কা",
  LS: "লেসোথো",
  LT: "লিথুয়ানিয়া",
  LU: "লুক্সেমবার্গ",
  LV: "লাটভিয়া",
  MO: "ম্যাকাও",
  MF: "সেন্ট মার্টিন",
  MA: "মরক্কো",
  MC: "মোনাকো",
  MD: "মোল্দোভা (প্রজাতন্ত্র)",
  MG: "মাদাগাস্কার",
  MV: "মালদ্বীপ",
  MX: "মেক্সিকো",
  MH: "মার্শাল দ্বীপপুঞ্জ",
  MK: "মেসিডোনিয়া ",
  ML: "মালি",
  MT: "মাল্টা",
  MM: "মায়ানমার",
  ME: "মন্টিনিগ্রো",
  MN: "মঙ্গোলিয়া",
  MP: "উত্তর মারিয়ানা দ্বীপপুঞ্জ",
  MZ: "মোজাম্বিক",
  MR: "মৌরিতানিয়া",
  MS: "মন্টসেরাত",
  MQ: "মার্টিনিক",
  MU: "মরিশাস",
  MW: "মালাউই",
  MY: "মালয়েশিয়া",
  YT: "মেয়োট",
  NA: "নামিবিয়া",
  NC: "নতুন ক্যালেডোনিয়া",
  NE: "নাইজার",
  NF: "নরফোক দ্বীপ",
  NG: "নাইজেরিয়া",
  NI: "নিকারাগুয়া",
  NU: "নিউ",
  NL: "নেদারল্যান্ডস",
  NO: "নরওয়ে",
  NP: "নেপাল",
  NR: "নাউরু",
  NZ: "নিউজিল্যান্ড",
  OM: "ওমান",
  PK: "পাকিস্তান",
  PA: "পানামা",
  PN: "পিটকেয়ার্ন",
  PE: "পেরু",
  PH: "ফিলিপাইন",
  PW: "পালাও",
  PG: "পাপুয়া নিউ গিনি",
  PL: "পোল্যান্ড",
  PR: "পুয়ের্তো রিকো",
  KP: "উত্তর কোরিয়া",
  PT: "পর্তুগাল",
  PY: "প্যারাগুয়ে",
  PS: "প্যালেস্টাইন রাষ্ট্র",
  PF: "ফরাসি পলিনেশিয়া",
  QA: "কাতার",
  RE: "রিইউনিয়ন",
  RO: "রোমানিয়া",
  RU: "রাশিয়া",
  RW: "রুয়ান্ডা",
  SA: "সৌদি আরব",
  SD: "সুদান",
  SN: "সেনেগাল",
  SG: "সিঙ্গাপুর",
  GS: "দক্ষিণ জর্জিয়া এবং দক্ষিণ স্যান্ডউইচ দ্বীপপুঞ্জ",
  SH: "সেন্ট হেলেনা",
  SJ: "সোভালবার্ড এবং জান মায়েন",
  SB: "সলোমান দ্বীপপুঞ্জ",
  SL: "সিয়েরা লিওন",
  SV: "এল সালভাদর",
  SM: "সান মারিনো",
  SO: "সোমালিয়া",
  PM: "সেন্ট পিয়ের এবং মিকেলন",
  RS: "সার্বিয়া",
  SS: "দক্ষিণ সুদান",
  ST: "সাও টোম এবং প্রিনসিপে",
  SR: "সুরিনাম",
  SK: "স্লোভাকিয়া",
  SI: "স্লোভেনিয়া",
  SE: "সুইডেন",
  SZ: "এস্বওয়াতিনি",
  SX: "সিন্ট মার্টেন (ডাচ অংশ)",
  SC: "সেশেলস",
  SY: "সিরিয় আরব প্রজাতন্ত্র",
  TC: "টার্কস্ ও কেইকোস দ্বীপপুঞ্জ",
  TD: "চাদ",
  TG: "টোগো",
  TH: "থাইল্যান্ড",
  TJ: "তাজিকিস্তান",
  TK: "টোকেলাউ",
  TM: "তুর্কমেনিস্তান",
  TL: "তিমুর-লেস্তে",
  TO: "টোঙ্গা",
  TT: "ত্রিনিদাদ ও টোবাগো",
  TN: "তিউনিসিয়া",
  TR: "তুরস্ক",
  TV: "টুভালু",
  TW: "তাইওয়ান",
  TZ: "তানজানিয়া, ইউনাইটেড রিপাবলিক অফ",
  UG: "উগান্ডা",
  UA: "ইউক্রেন",
  UM: "মাইনর আউটলাইং আইল্যান্ডস (মার্কিন যুক্তরাষ্ট্র)",
  UY: "উরুগুয়ে",
  US: "মার্কিন যুক্তরাষ্ট্র",
  UZ: "উজবেকিস্তান",
  VA: "ভ্যাটিকান",
  VC: "সেন্ট ভিনসেন্ট ও গ্রেনাডাইনস",
  VE: "ভেনিজুয়েলা",
  VG: "ভার্জিন দ্বীপপুঞ্জ (ব্রিটিশ)",
  VI: "ভার্জিন দ্বীপপুঞ্জ (মার্কিন)",
  VN: "ভিয়েতনাম",
  VU: "ভানুয়াতু",
  WF: "ওয়ালিস এবং ফুটুনা",
  WS: "সামোয়া",
  XK: "কসোভো",
  YE: "ইয়েমেন",
  ZA: "দক্ষিণ আফ্রিকা",
  ZM: "জাম্বিয়া",
  ZW: "জিম্বাবুয়ে",
};
