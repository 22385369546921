// utils/richTextRenderers.ts
import Table from "@/components/core/molecules/table";
import Link from "@/components/utility/link";
import OnboardingService from "@/services/onboarding-service";
import { PageMetaDataT, ThemesG } from "@/types/design-system-types";
import {
  addHttps,
  extractMetadataSlug,
  getRouteDetails,
  isAlcatraz,
} from "@/utils/helpers";
import ComponentResolver from "@/utils/system/component-resolver";
import { AssetHyperlink, EntryHyperlink, Hyperlink } from "@contentful/rich-text-types";
import { Entry } from "contentful";
import Image from "next/image";
import { useRouter } from "next/router";
import React from "react";
import ReactPlayer from "react-player";
import { cn, getContentfulEnvironment } from "../../../utils/helpers";
import { triggerOnboardingModal } from "../../modals/onboarding-modal-layer";

// NODES (e.g. renderNode {})
export const BLOCKS_EMBEDDED_ASSET = (node: any) => {
  // Render PDF
  if (node?.data?.target?.fields?.file?.contentType?.includes("pdf")) {
    return (
      <a
        href={addHttps(node.data.target.fields.file.url)}
        target="_blank"
        rel="noopener noreferrer"
      >
        {node.data.target.fields.title}
      </a>
    );
  }

  // Render video
  if (node?.data?.target?.fields?.file?.contentType?.includes("video")) {
    const { file } = node.data.target.fields;
    return <ReactPlayer controls url={file?.url} width={"100%"} height={"auto"} />;
  }

  // Render image
  return (
    <Image
      width={node?.data?.target?.fields?.file?.details?.image?.width || 0}
      height={node?.data?.target?.fields?.file?.details?.image?.height || 0}
      alt={node?.data?.target?.fields?.title || "alt"}
      src={addHttps(node?.data?.target?.fields?.file?.url)}
      className=""
    />
  );
};

export const BLOCKS_EMBEDDED_ENTRY = (node: any, theme?: ThemesG) => (
  <ComponentResolver data={node.data.target} theme={theme} />
);

export const BLOCKS_TABLE = (node: any) => {
  return <Table structure={node} />;
};

export const INLINES_EMBEDDED_ENTRY = (node: any, theme?: ThemesG) => {
  return (
    <ComponentResolver component-display="inline" data={node.data.target} theme={theme} />
  );
};

export const INLINES_HYPERLINK = (node: Hyperlink, children: React.ReactNode) => {
  const router = useRouter();
  const { locale, slug, language, region } = getRouteDetails(router);

  // Highlight the link if it's the current page
  const isThisTheCurrentPage = (locale + slug).includes(node.data.uri);

  // Replace the {{{ LOCALE }}}, {{{ LANGUAGE }}}, and {{{ REGION }}} shortcodes in the link
  if (node.data.uri.includes("{{{ LOCALE }}}")) {
    node.data.uri = node.data.uri.replace("{{{ LOCALE }}}", locale);
  }

  if (node.data.uri.includes("{{{ LANGUAGE }}}")) {
    node.data.uri = node.data.uri.replace("{{{ LANGUAGE }}}", language);
  }

  if (node.data.uri.includes("{{{ REGION }}}")) {
    node.data.uri = node.data.uri.replace("{{{ REGION }}}", region);
  }

  // e.g. Convert link to onboarding modal using following criteria: e.g. onboarding_live_cfd, onboarding_demo_mt4
  // Detect if link starts with onboarding_
  // Extract the "type" and "product" from the link
  if (node.data.uri.toLowerCase().startsWith("onboarding_")) {
    const [, type, product]: any[] = node.data.uri.toLowerCase().split("_");

    // validate account is valid
    if (!["demo", "live"].includes(type)) {
      throw new Error(`Invalid account type: ${type}`);
    }

    // validate product is valid
    if (!["cfd", "mt4", "sb", "pro", "tv", "bm"].includes(product)) {
      throw new Error(`Invalid product: ${product}`);
    }

    const onboardingService = new OnboardingService(router);
    const { onboardingUrl } = onboardingService.getOnboardingData(type, product);

    if (!onboardingUrl) {
      throw new Error("Attempted to create onboarding link with incorrect params");
    }

    const triggerModal = (e: React.MouseEvent<HTMLAnchorElement>) => {
      e.preventDefault();
      triggerOnboardingModal(onboardingUrl as string);
    };

    return (
      <a
        className="underline hover:text-azure-100"
        href={onboardingUrl}
        onClick={(e) => triggerModal(e)}
        data-anchor-type="url"
      >
        {children}
      </a>
    );
  }

  // Functionality to dynamically add the locale to the link
  // if (node.data.uri.includes("{locale}")) {
  // console.log(node.data.uri);
  // }

  return (
    <Link
      className={cn(
        "underline hover:text-azure-100",
        isThisTheCurrentPage ? "text-azure-100" : ""
      )}
      href={node.data.uri}
      data-anchor-type="url"
    >
      {children}
    </Link>
  );
};

export const INLINES_ENTRY_HYPERLINK = (
  node: EntryHyperlink & {
    data: {
      target: Entry<PageMetaDataT>;
    };
  },
  children: React.ReactNode
) => {
  const router = useRouter();
  const { isLivePreview, isLive, isLiveUncached } = getContentfulEnvironment(router);
  const { locale } = getRouteDetails(router, !isLivePreview);

  try {
    let slug = extractMetadataSlug(node?.data?.target, locale) || "no-slug-found";
    slug = slug === "home" ? "" : slug;

    // Page does not exist in that locale
    if (slug === "no-slug-found" && !isLive && !isLiveUncached) {
      return (
        <span
          data-anchor-type="entry"
          data-anchor-entry-not-found="true"
          className="text-red-600 font-bold"
        >
          Anchored page not found! Check the slug exists for {locale} locale.
        </span>
      );
    }

    const childrenString = JSON.stringify(children);

    // Handle component-based scroll targets
    const componentMatch = childrenString.match(/\{\{\{ SCROLL-TO-(?!#)(.*?) \}\}\}/);
    if (componentMatch) {
      const componentId = componentMatch[1];
      // Get component data via ID from the shortcode (e.g. 26NaaJFAbToG5jSItXHaZs)
      const component = node.data.target.fields.metadata.scrollSpies.find(
        (c: any) => c.id === componentId
      );

      // Remove shortcode from the link text
      const cleanedString = childrenString.replace(
        `{{{ SCROLL-TO-${componentId} }}}`,
        ""
      );
      let cleanedChildren;

      try {
        cleanedChildren = JSON.parse(cleanedString);
      } catch (e) {
        console.error("[RICH TEXT RENDERER] Failed to parse JSON", e);
        throw new Error("Failed to parse JSON");
      }

      // Get the scroll spy text and convert to a #hash
      const fallbackLocale = isAlcatraz(locale) ? "eo" : "en";
      const scrollSpyText =
        component?.scrollSpy[locale] || // If value already for that locale - use it
        component?.scrollSpy[fallbackLocale] || // If no value for that locale - use fallback (eo for alcatraz, en for retail)
        component?.scrollSpy["en"]; // Finally - use en
      const scrollSpyHash = scrollSpyText
        ? "#" + scrollSpyText.toLowerCase().replaceAll(" ", "-")
        : "";

      slug = `${slug}${scrollSpyHash}`;
      children = cleanedChildren;
    }

    // Handle hash-based scroll targets
    const hashMatch = childrenString.match(/\{\{\{ SCROLL-TO-#(.*?) \}\}\}/);
    if (hashMatch) {
      // Get the hash from the shortcode (e.g. #account-types)
      const hash = hashMatch[1];

      // Remove shortcode from the link text
      const cleanedString = childrenString.replace(`{{{ SCROLL-TO-#${hash} }}}`, "");

      let cleanedChildren;

      try {
        cleanedChildren = JSON.parse(cleanedString);
      } catch (e) {
        console.error("[RICH TEXT RENDERER] Failed to parse JSON", e);
        throw new Error("Failed to parse JSON");
      }

      slug = `${slug}#${hash.toLowerCase().replaceAll(" ", "-")}`;
      children = cleanedChildren;
    }

    // For LIVE - render the text of the link only if the slug is broken
    if (isLive && slug === "no-slug-found") {
      return <span>{children}</span>;
    }

    return (
      <Link
        data-anchor-type="entry"
        className="underline hover:text-azure-100"
        href={slug}
        onClick={() => {
          // Auto scroll doesn't work with next js navigation so we need to handle it manually
          if (slug.includes("#")) {
            const hash = slug.split("#")[1];
            setTimeout(() => {
              const element = document.getElementById(hash);
              if (element) {
                element.scrollIntoView({ behavior: "smooth" });
              }
            }, 1500); // Small delay to ensure page has rendered
          }
        }}
      >
        {children}
      </Link>
    );
  } catch (e) {
    console.log(e);
    return "Something went wrong...";
  }
};

export const INLINES_ASSET_HYPERLINK = (
  node: AssetHyperlink & {
    data: {
      target: Entry<{
        file: {
          url: string;
        };
      }>;
    };
  },
  children: React.ReactNode
) => {
  return (
    <a
      className="underline hover:text-azure-100"
      href={addHttps(node.data?.target?.fields?.file?.url)}
      target="_blank"
      rel="noopener noreferrer"
    >
      {children}
    </a>
  );
};

// TEXT (e.g. renderText())
export const RENDER_TEXT = (text: any) => {
  // Allows for line breaks in rich text
  return text.split("\n").reduce((children: any, textSegment: any, index: number) => {
    return [...children, index > 0 && <br key={index} />, textSegment];
  }, []);
};

// ! DEPRECATED / OLD
// export const INLINES_ENTRY_HYPERLINK = (
//   node: EntryHyperlink,
//   children: React.ReactNode
// ) => {
//   let obj = children;
//   let str = JSON.stringify(children);

//   const router = useRouter();
//   const { locale } = getRouteDetails(router, true);
//   const slugs = node.data.target.fields.metadata.slugs;

//   console.log("node", node);
//   console.log("locale", locale);

//   const matchComponent = str.match(/\{\{\{ SCROLL-TO-(?!#)(.*?) \}\}\}/);
//   const componentId = matchComponent ? matchComponent[1] : null;
//   let component: Entry<any> | null = null;
//   let slug = slugs[locale];

//   if (slug === "home") {
//     slug = "";
//   }

//   // if alcatraz, fallback to eo
//   // if alcatraz, but eo empty, fallback to en
//   // if retail, fallback to en
//   // if slug is home, change to ""

//   console.log("inline entry component id", componentId);

//   if (componentId) {
//     component = node.data.target.fields.metadata.scrollSpies.find((c: any) => {
//       return c.id === componentId;
//     });

//     str = str.replace(`{{{ SCROLL-TO-${componentId} }}}`, "");
//     obj = JSON.parse(str);

//     console.log("FOUND COMPONENT", component);

//     return (
//       <Link
//         className="underline hover:text-azure-100"
//         // @ts-ignore
//         href={`${slug}${
//           component?.scrollSpy[locale]
//             ? "#" + component.scrollSpy[locale].toLowerCase().replaceAll(" ", "-")
//             : ""
//         }`}
//       >
//         {obj}
//       </Link>
//     );
//   }

//   const matchHash = str.match(/\{\{\{ SCROLL-TO-#(.*?) \}\}\}/);

//   console.log("matchHash", matchHash);

//   if (matchHash) {
//     console.log("MATCHED HASH", matchHash[1]);
//     const hash = matchHash[1];

//     str = str.replace(`{{{ SCROLL-TO-#${hash} }}}`, "");
//     obj = JSON.parse(str);

//     console.log("HASH", hash);
//     console.log("correct slug", slugs[locale]);
//     console.log("correct scrollspy locale", component?.scrollSpy[locale]);

//     return (
//       <Link
//         className="underline hover:text-azure-100"
//         // @ts-ignore
//         href={`${slug}${"#" + hash.toLowerCase().replaceAll(" ", "-")}`}
//       >
//         {obj}
//       </Link>
//     );
//   }
// };
