import { ConfluenceFormattedPageResult } from "@/pages/api/neptune/internal/confluence";
import { Modal, Skeleton } from "@contentful/f36-components";
import { useEffect, useRef, useState } from "react";
import HelpDialogError from "./help-dialog-error";

export default function HelpDialog({
  isShown,
  setShown,
}: {
  isShown: boolean;
  setShown: (value: boolean) => void;
}) {
  const [content, setContent] = useState<Array<ConfluenceFormattedPageResult> | null>([]);
  const [currentPage, setCurrentPage] = useState<ConfluenceFormattedPageResult | null>(
    null
  );
  const [hasError, setHasError] = useState(false);
  const helpContent = useRef<HTMLDivElement>(null);

  const fetchPageContent = async () => {
    const url = `/api/neptune/internal/confluence`;

    try {
      const response = await fetch(url, {
        method: "GET",
        headers: {
          auth: process.env.APP_INTERNAL_API_KEY,
          "Content-Type": "application/json",
          "Cache-Control": "no-cache",
          Pragma: "no-cache",
        },
      });

      if (!response.ok) {
        setHasError(true);
        throw new Error(`Error: ${response.status} ${response.statusText}`);
      }

      const data: ConfluenceFormattedPageResult[] = await response.json();
      setContent(data);
      setCurrentPage(data[0]);
    } catch (error) {
      console.error("Failed to fetch page content:", error);
    }
  };

  useEffect(() => {
    setTimeout(() => {
      helpContent.current?.scrollIntoView();
    }, 10);
  }, [currentPage]);

  // Lock page scrolling when modal is open
  useEffect(() => {
    if (isShown) {
      document.documentElement.style.overflow = "hidden";
      document.body.style.overflow = "hidden";
      fetchPageContent();
    } else {
      document.documentElement.style.overflow = "";
      document.body.style.overflow = "";
      setContent([]);
    }
  }, [isShown]);

  const renderMenu = (pages: ConfluenceFormattedPageResult[] | null, level = 0) => {
    if (!pages) return null;
    return pages.map((page: ConfluenceFormattedPageResult, index: number) => (
      <div data-confluence-id={page.id} key={index}>
        <button
          className={`flex gap-x-2 stroke-black text-black items-center text-left w-full hover:bg-gray-100 rounded-lg p-2 ${
            currentPage?.id === page.id ? "bg-gray-200 font-semibold" : ""
          } ${level > 0 ? `pl-4` : ""}`}
          onClick={() => setCurrentPage(page)}
        >
          <span className="truncate">{page.title}</span>
        </button>
        {page.children && page.children.length > 0 && (
          <div>{renderMenu(page.children, level + 1)}</div>
        )}
      </div>
    ));
  };

  return (
    <Modal
      allowHeightOverflow={false}
      size="1280px"
      onClose={() => setShown(false)}
      isShown={isShown}
      className="h-screen"
    >
      {() => (
        <>
          <Modal.Header title="Help" onClose={() => setShown(false)} />
          <Modal.Content>
            {hasError && <HelpDialogError />}

            {!hasError && (
              <div className="grid grid-cols-4">
                <div className="col-span-1 flex flex-col pr-4 gap-y-1 border-r border-r-gray-200">
                  <div className="sticky top-0 overflow-y-auto max-h-[calc(100vh-200px)]">
                    {renderMenu(content)}
                    {content?.length === 0 && (
                      <>
                        <Skeleton.Container>
                          <Skeleton.BodyText numberOfLines={4} />
                        </Skeleton.Container>
                      </>
                    )}
                  </div>
                </div>
                <div id="helpContent" ref={helpContent} className="col-span-3 h-max pl-4">
                  {currentPage && (
                    <div className="max-w-xl ml-6">
                      <small className="uppercase">
                        {currentPage?.parentTitle || "General"}
                      </small>
                      <h1 className="article-title">{currentPage?.title}</h1>
                      <div
                        className="space-y-4"
                        dangerouslySetInnerHTML={{
                          __html: currentPage?.htmlContent as TrustedHTML,
                        }}
                      ></div>
                    </div>
                  )}
                  {!currentPage && (
                    <>
                      <Skeleton.Container>
                        <Skeleton.BodyText numberOfLines={4} />
                      </Skeleton.Container>
                    </>
                  )}
                </div>
              </div>
            )}
          </Modal.Content>
        </>
      )}
    </Modal>
  );
}
