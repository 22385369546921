import { getRouteDetails } from "@/utils/helpers";
import { useRouter } from "next/router";

// TODO: setup different 404 page for different locales

export default function Custom404() {
  const router = useRouter();

  const { locale } = getRouteDetails(router);

  if (locale.includes("en")) {
    // return <English404 />;
  }

  if (locale.includes("fr")) {
    // return <French404 />;
  }

  // ...etc

  return (
    <>
      <section className="Module hero-module overflow-hidden lg:relative">
        <div className="relative z-10 mx-auto max-w-screen-xl">
          <div className="pb-14 pt-10 text-center lg:pb-20 lg:pt-16 xl-max:px-6 md-max:px-4">
            <h1 className=" mb-4 text-5xl lg:mb-8">
              404 <br />
              <span className="text-5xl">Page not found</span>
            </h1>

            <p className="mb-4 text-lg lg:mb-12">
              We couldn&apos;t find what you were looking for.
            </p>
          </div>
        </div>
      </section>
    </>
  );
}
