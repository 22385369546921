import { ISO3166_Alpha2_Codes } from "@/types/country-codes";

export const countries_de: Record<ISO3166_Alpha2_Codes, string> = {
  AD: "Andorra",
  AE: "Vereinigte Arabische Emirate",
  AF: "Afghanistan",
  AG: "Antigua und Barbuda",
  AI: "Anguilla",
  AL: "Albanien",
  AM: "Armenien",
  AN: "Niederländische Antillen",
  AO: "Angola",
  AQ: "Antarktis",
  AR: "Argentinien",
  AS: "Amerikanisch Samoa",
  AT: "Österreich",
  AU: "Australien",
  AW: "Aruba",
  AX: "Öland-Inseln",
  AZ: "Aserbaidschan",
  BA: "Bosnien und Herzegowina",
  BB: "Barbados",
  BD: "Bangladesch",
  BE: "Belgien",
  BF: "Burkina Faso",
  BG: "Bulgarien",
  BH: "Bahrain",
  BI: "Burundi",
  BJ: "Benin",
  BL: "Saint Barthélemy",
  BM: "Bermuda",
  BN: "Brunei Darussalam",
  BO: "Bolivien",
  BQ: "Bonaire",
  BR: "Brasilien",
  BS: "Bahamas",
  BT: "Bhutan",
  BV: "Bouvet Island",
  BW: "Botswana",
  BY: "Bel",
  BZ: "Belize",
  CA: "Kanada",
  CC: "Cocos (Keeling) Islands",
  CD: "Demokratische Republik Kongo",
  CF: "Zentralafrikanische Republik",
  CG: "Kongo",
  CH: "Schweiz",
  CI: "Elfenbeinküste",
  CK: "Cook Islands",
  CL: "Chile",
  CM: "Kamerun",
  CN: "China",
  CO: "Kolumbien",
  CR: "Costa Rica",
  CU: "Kuba",
  CV: "Cape Verde",
  CW: "Curaçao",
  CX: "Christmas Island",
  CY: "Zypern",
  CZ: "Tschechische Republik",
  DE: "Deutschland",
  DJ: "Dschibuti",
  DK: "Dänemark",
  DM: "Dominica",
  DO: "Dominikanische Republik",
  DZ: "Algerien",
  EC: "Ecuador",
  EE: "Estland",
  EG: "Ägypten",
  EH: "Westsahara",
  ER: "Eritrea",
  ES: "Spanien",
  ET: "Äthiopien",
  FI: "Finnland",
  FJ: "Fidschi",
  FK: "Falkland Islands",
  FM: "Mikronesien",
  FO: "Färöer",
  FR: "Frankreich",
  GA: "Gabun",
  GB: "Vereinigtes Königreich",
  GD: "Grenada",
  GE: "Georgien",
  GF: "Französisch-Guayana",
  GG: "Guernsey",
  GH: "Ghana",
  GI: "Gibraltar",
  GL: "Grönland",
  GM: "Gambia",
  GN: "Guinea",
  GP: "Guadeloupe",
  GQ: "Äquatorialguinea",
  GR: "Griechenland",
  GS: "Südgeorgien und die Südlichen Sandwichinseln",
  GT: "Guatemala",
  GU: "Guam",
  GW: "Guinea-Bissau",
  GY: "Guyana",
  HK: "Hong Kong",
  HM: "Heard-und McDonald-Inseln",
  HN: "Honduras",
  HR: "Kroatien",
  HT: "Haiti",
  HU: "Ungarn",
  ID: "Indonesien",
  IE: "Irland",
  IL: "Israel",
  IM: "Isle Of Man",
  IN: "Indien",
  IO: "British Indian Ocean Territory",
  IQ: "Irak",
  IR: "Iran",
  IS: "Island",
  IT: "Italien",
  JE: "Jersey",
  JM: "Jamaica",
  JO: "Jordanien",
  JP: "Japan",
  KE: "Kenia",
  KG: "Kirgisistan",
  KH: "Kambodscha",
  KI: "Kiribati",
  KM: "Komoren",
  KN: "Saint Kitts und Nevis",
  KP: "Nordkorea",
  KR: "Südkorea",
  KW: "Kuwait",
  KY: "Cayman Islands",
  KZ: "Kasachstan",
  LA: "Laos",
  LB: "Libanon",
  LC: "Saint Lucia",
  LI: "Liechtenstein",
  LK: "Sri Lanka",
  LR: "Liberia",
  LS: "Lesotho",
  LT: "Litauen",
  LU: "Luxemburg",
  LV: "Lettland",
  LY: "Libyen",
  MA: "Marokko",
  MC: "Monaco",
  MD: "Moldawien",
  ME: "Montenegro",
  MF: "Saint Martin",
  MG: "Madagascar",
  MH: "Marshall Islands",
  MK: "Mazedonien",
  ML: "Mali",
  MM: "Myanmar",
  MN: "Mongolei",
  MO: "Macao",
  MP: "Northern Mariana Islands",
  MQ: "Martinique",
  MR: "Mauretanien",
  MS: "Montserrat",
  MT: "Malta",
  MU: "Mauritius",
  MV: "Malediven",
  MW: "Malawi",
  MX: "Mexiko",
  MY: "Malaysia",
  MZ: "Mosambik",
  NA: "Namibia",
  NC: "New Caledonia",
  NE: "Niger",
  NF: "Norfolk Island",
  NG: "Nigeria",
  NI: "Nicaragua",
  NL: "Niederlande",
  NO: "Norwegen",
  NP: "Nepal",
  NR: "Nauru",
  NU: "Niue",
  NZ: "Neuseeland",
  OM: "Oman",
  PA: "Panama",
  PE: "Peru",
  PF: "Französisch-Polynesien",
  PG: "Papua New Guinea",
  PH: "Philippinen",
  PL: "Polen",
  PM: "Saint Pierre und Miquelon",
  PN: "Pitcairn",
  PR: "Puerto Rico",
  PS: "Palästina",
  PT: "Portugal",
  PW: "Palau",
  PY: "Paraguay",
  QA: "Katar",
  RE: "Réunion",
  RO: "Rumänien",
  RS: "Serbien",
  RU: "Russische Föderation",
  RW: "Ruanda",
  SA: "Saudi Arabia",
  SB: "Salomonen",
  SC: "Seychellen",
  SD: "Sudan",
  SE: "Schweden",
  SG: "Singapore",
  SH: "Saint Helena",
  SI: "Slowenien",
  SJ: "Svalbard und Jan Mayen",
  SK: "Slowakei",
  SL: "Sierra Leone",
  SM: "San Marino",
  SN: "Senegal",
  SO: "Somalia",
  SR: "Surinam",
  SS: "Südsudan",
  ST: "Sao Tome und Principe",
  SV: "El Salvador",
  SX: "Sint Maarten",
  SY: "Syrien",
  SZ: "Swaziland",
  TC: "Turks-und Caicos-Inseln",
  TD: "Tschad",
  TF: "Französisch Südliche Territorien",
  TG: "Togo",
  TH: "Thailand",
  TJ: "Tadschikistan",
  TK: "Tokelau",
  TL: "Timor-Leste",
  TM: "Turkmenistan",
  TN: "Tunesien",
  TO: "Tonga",
  TR: "Türkei",
  TT: "Trinidad und Tobago",
  TV: "Tuvalu",
  TW: "Taiwan",
  TZ: "Tansania",
  UA: "Ukraine",
  UG: "Uganda",
  UM: "United States Minor Outlying Islands",
  US: "Vereinigte Staaten",
  UY: "Uruguay",
  UZ: "Usbekistan",
  VA: "Vatikan",
  VC: "Saint Vincent und die Grenadinen",
  VE: "Venezuela",
  VG: "Virgin Islands",
  VI: "Virgin Islands",
  VN: "Vietnam",
  VU: "Vanuatu",
  WF: "Wallis And Futuna",
  WS: "Samoa",
  YE: "Jemen",
  YT: "Mayotte",
  ZA: "Südafrika",
  ZM: "Sambia",
  XK: "Kosovo",
  ZW: "Simbabwe",
  PK: "Pakistan",
};
