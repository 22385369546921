import { ISO3166_Alpha2_Codes } from "@/types/country-codes";

export const countries_tr: Record<ISO3166_Alpha2_Codes, string> = {
  AW: "Aruba",
  AF: "Afganistan",
  AO: "Angola",
  AI: "Anguilla",
  AX: "Aland adaları",
  AL: "Arnavutluk",
  AD: "Andorra",
  AN: "Hollanda Antilleri",
  AE: "Birleşik Arap Emirlikleri",
  AR: "Arjantin",
  AM: "Ermenistan",
  AS: "Amerikan Samoası",
  AQ: "Antarktika",
  TF: "Fransız Güney Toprakları",
  AG: "Antigua ve Barbuda",
  AU: "Avustralya",
  AT: "Avusturya",
  AZ: "Azerbaycan",
  BI: "Burundi",
  BE: "Belçika",
  BJ: "Benin",
  BQ: "Bonaire, Sint Eustatius ve Saba",
  BF: "Burkina Faso",
  BD: "Bangladeş",
  BG: "Bulgaristan",
  BH: "Bahreyn",
  BS: "Bahamalar",
  BA: "Bosna Hersek",
  BL: "Saint Barthélemy",
  BY: "Belarus",
  BZ: "Belize",
  BM: "Bermuda",
  BO: "Bolivya (Çokuluslu Devleti)",
  BR: "Brezilya",
  BB: "Barbados",
  BN: "Brunei Darüsselam",
  BT: "Butan",
  BV: "Bouvet Adası",
  BW: "Botsvana",
  CF: "Orta Afrika Cumhuriyeti",
  CA: "Kanada",
  CC: "Cocos (Keeling) Adaları",
  CH: "İsviçre",
  CL: "Şili",
  CN: "Çin",
  CI: "Fildişi Sahili",
  CM: "Kamerun",
  CD: "Kongo (Demokratik Cumhuriyeti)",
  CG: "Kongo",
  CK: "Cook Adaları",
  CO: "Kolombiya",
  KM: "Komorlar",
  CV: "Cabo Verde",
  CR: "Kosta Rika",
  CU: "Küba",
  CW: "Curaçao",
  CX: "Christmas Adası",
  KY: "Cayman Adaları",
  CY: "Kıbrıs",
  CZ: "Çek Cumhuriyeti",
  DE: "Almanya",
  DJ: "Cibuti",
  DM: "Dominika",
  DK: "Danimarka",
  DO: "Dominik Cumhuriyeti",
  DZ: "Cezayir",
  EC: "Ekvador",
  EG: "Mısır",
  ER: "Eritre",
  EH: "Batı Sahra",
  ES: "İspanya",
  EE: "Estonya",
  ET: "Etiyopya",
  FI: "Finlandiya",
  FJ: "Fiji",
  FK: "Falkland Adaları (Malvinas)",
  FR: "Fransa",
  FO: "Faroe Adaları",
  FM: "Mikronezya",
  GA: "Gabon",
  GB: "Birleşik Krallık",
  GE: "Gürcistan",
  GG: "Guernsey",
  GH: "Gana",
  GI: "Cebelitarık",
  GN: "Gine",
  GP: "Guadeloupe",
  GM: "Gambiya",
  GW: "Gine-Bissau",
  GQ: "Ekvator Ginesi",
  GR: "Yunanistan",
  GD: "Grenada",
  GL: "Grönland",
  GT: "Guatemala",
  GF: "Fransız Guyanası",
  GU: "Guam",
  GY: "Guyana",
  HK: "Hong Kong",
  HM: "Heard Adası ve McDonald Adaları",
  HN: "Honduras",
  HR: "Hırvatistan",
  HT: "Haiti",
  HU: "Macaristan",
  ID: "Endonezya",
  IM: "Man Adası",
  IN: "Hindistan",
  IO: "Britanya Hint Okyanusu Toprakları",
  IE: "İrlanda",
  IR: "İran",
  IQ: "Irak",
  IS: "İzlanda",
  IL: "İsrail",
  IT: "İtalya",
  JM: "Jamaika",
  JE: "Jersey",
  JO: "Ürdün",
  JP: "Japonya",
  KZ: "Kazakistan",
  KE: "Kenya",
  KG: "Kırgızistan",
  KH: "Kamboçya",
  KI: "Kiribati",
  KN: "Saint Kitts ve Nevis",
  KR: "Güney Kore",
  KW: "Kuveyt",
  LA: "Laos",
  LB: "Lübnan",
  LR: "Liberya",
  LY: "Libya",
  LC: "Saint Lucia",
  LI: "Lihtenştayn",
  LK: "Sri Lanka",
  LS: "Lesoto",
  LT: "Litvanya",
  LU: "Lüksemburg",
  LV: "Letonya",
  MO: "Makao",
  MF: "Saint Martin",
  MA: "Fas",
  MC: "Monako",
  MD: "Moldova (Cumhuriyeti)",
  MG: "Madagaskar",
  MV: "Maldivler",
  MX: "Meksika",
  MH: "Marshall Adaları",
  MK: "Makedonya",
  ML: "Mali",
  MT: "Malta",
  MM: "Myanmar",
  ME: "Karadağ",
  MN: "Moğolistan",
  MP: "Kuzey Mariana Adaları",
  MZ: "Mozambik",
  MR: "Moritanya",
  MS: "Montserrat",
  MQ: "Martinik",
  MU: "Mauritius",
  MW: "Malavi",
  MY: "Malezya",
  YT: "Mayotte",
  NA: "Namibya",
  NC: "Yeni Kaledonya",
  NE: "Nijer",
  NF: "Norfolk Adası",
  NG: "Nijerya",
  NI: "Nikaragua",
  NU: "Niue",
  NL: "Hollanda",
  NO: "Norveç",
  NP: "Nepal",
  NR: "Nauru",
  NZ: "Yeni Zelanda",
  OM: "Umman",
  PK: "Pakistan",
  PA: "Panama",
  PN: "Pitcairn",
  PE: "Peru",
  PH: "Filipinler",
  PW: "Palau",
  PG: "Papua Yeni Gine",
  PL: "Polonya",
  PR: "Porto Riko",
  KP: "Kuzey Kore",
  PT: "Portekiz",
  PY: "Paraguay",
  PS: "Filistin Devleti",
  PF: "Fransız Polinezyası",
  QA: "Katar",
  RE: "Réunion",
  RO: "Romanya",
  RU: "Rusya",
  RW: "Ruanda",
  SA: "Suudi Arabistan",
  SD: "Sudan",
  SN: "Senegal",
  SG: "Singapur",
  GS: "Güney Georgia ve Güney Sandwich Adaları",
  SH: "Saint Helena",
  SJ: "Svalbard ve Jan Mayen",
  SB: "Solomon Adaları",
  SL: "Sierra Leone",
  SV: "El Salvador",
  SM: "San Marino",
  SO: "Somali",
  PM: "Saint Pierre ve Miquelon",
  RS: "Sırbistan",
  SS: "Güney Sudan",
  ST: "Sao Tome ve Principe",
  SR: "Surinam",
  SK: "Slovakya",
  SI: "Slovenya",
  SE: "İsveç",
  SZ: "Esvatini",
  SX: "Sint Maarten (Hollanda kısmı)",
  SC: "Seyşeller",
  SY: "Suriye Arap Cumhuriyeti",
  TC: "Turks ve Caicos Adaları",
  TD: "Çad",
  TG: "Togo",
  TH: "Tayland",
  TJ: "Tacikistan",
  TK: "Tokelau",
  TM: "Türkmenistan",
  TL: "Doğu Timor",
  TO: "Tonga",
  TT: "Trinidad ve Tobago",
  TN: "Tunus",
  TR: "Türkiye",
  TV: "Tuvalu",
  TW: "Tayvan",
  TZ: "Tanzanya Birleşik Cumhuriyeti",
  UG: "Uganda",
  UA: "Ukrayna",
  UM: "ABD Küçük Harici Adaları",
  UY: "Uruguay",
  US: "Amerika Birleşik Devletleri",
  UZ: "Özbekistan",
  VA: "Vatikan",
  VC: "Saint Vincent ve Grenadinler",
  VE: "Venezuela",
  VG: "Britanya Virjin Adaları",
  VI: "ABD Virjin Adaları",
  VN: "Vietnam",
  VU: "Vanuatu",
  WF: "Wallis ve Futuna",
  WS: "Samoa",
  XK: "Kosova",
  YE: "Yemen",
  ZA: "Güney Afrika",
  ZM: "Zambiya",
  ZW: "Zimbabve",
};
