import { useEffect, useState, useCallback } from "react";
import debounce from "lodash.debounce";

/**
 * Clean and performant hook to get the viewport size
 * or if current viewport is under or over 1024px
 *
 * @returns An object containing the width and height of viewport along with
 * boolean values for ```isMobile``` and ```isDesktop```
 *
 * @example const { screenWidth, screenHeight, isMobile, isDesktop } = useWindowSize()
 */

export const useWindowSize = () => {
  const [windowSize, setWindowSize] = useState<{
    screenWidth: number;
    screenHeight: number;
    isMobile?: boolean;
    isDesktop?: boolean;
  }>({
    screenWidth: 0,
    screenHeight: 0,
  });

  const handleResize = useCallback(() => {
    setWindowSize({
      screenWidth: window.innerWidth,
      screenHeight: window.innerHeight,
      isMobile: window.innerWidth < 1024,
      isDesktop: window.innerWidth > 1024,
    });
  }, []);

  useEffect(() => {
    const debouncedHandleResize = debounce(handleResize, 100);

    window.addEventListener("resize", debouncedHandleResize);

    // Call handler right away so state gets updated with initial window size
    handleResize();

    // Don't forget to remove event listener on cleanup
    return () => window.removeEventListener("resize", debouncedHandleResize);
  }, [handleResize]);

  return windowSize;
};

export function isTouch() {
  return (
    "ontouchstart" in window ||
    navigator.maxTouchPoints > 0 ||
    // @ts-ignore
    navigator.msMaxTouchPoints > 0
  );
}
