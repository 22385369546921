// TODO: hide alcatraz or retail if one of them is empty
// TODO: preselect retail or alcatraz based off the type of site you're on

import { AlcatrazLocales, alcatrazLocales, Locales } from "@/config/supported-countries";
import HeadlessFetcher from "@/services/fetchers/headless-fetcher";
import { Paragraph, Tabs } from "@contentful/f36-components";
import { useEffect, useState } from "react";
import LocaleCard from "./locale-card";

interface LocaleField {
  locale: string;
  site: string;
  title?: string;
  description?: string;
  slug?: string;
  status?: {
    state: string;
  };
  [key: string]: any;
}

export default function LocaleSwitcher({ pageSysId }: { pageSysId: string }) {
  const [currentTab, setCurrentTab] = useState("retail");
  const [data, setData] = useState([]);

  // Filter data by site type
  const retailData = data.filter(
    (card: LocaleField) => card.locale !== "pillar" && card.site === "retail"
  );
  const alcatrazData = data.filter(
    (card: LocaleField) => card.locale !== "pillar" && card.site === "alcatraz"
  );
  const pillarData = data.find((card: LocaleField) => card.locale === "pillar");

  // Get error count by checking the status error state
  const retailErrorCount = retailData.filter(
    (card: LocaleField) => card.status?.state === "Error"
  ).length;
  const alcatrazErrorCount = alcatrazData.filter(
    (card: LocaleField) => card.status?.state === "Error"
  ).length;

  const [internalName, setInternalName] = useState("");

  // Locale fallback titles for retail & alcatraz
  const [retailFallbackTitle, setRetailFallbackTitle] = useState("");
  const [alcatrazFallbackTitle, setAlcatrazFallbackTitle] = useState("");

  // Map required locale fields to a consistent format
  const mapLocaleFields = (
    fields: Record<string, Record<string, string>>,
    includedFields: string[]
  ): LocaleField[] => {
    const locales = Object.keys(fields.slug);
    return locales
      .map((locale): LocaleField | null => {
        if (!fields.slug[locale]) return null;

        const site =
          alcatrazLocales.includes(locale as AlcatrazLocales) && locale !== "en"
            ? "alcatraz"
            : "retail";

        const maskedLocale = (() => {
          if (locale === "en") {
            return "pillar";
          } else if (locale === "eo") {
            return "en";
          } else {
            return locale;
          }
        })();

        // Create base object with required fields
        const mappedObject: LocaleField = {
          locale: maskedLocale,
          site,
        };

        // Add included fields
        includedFields.forEach((field) => {
          if (fields[field]) {
            mappedObject[field] = fields[field][locale];
          }
        });

        return mappedObject;
      })
      .filter((item): item is LocaleField => item !== null);
  };

  useEffect(() => {
    const fetchLocales = async () => {
      const locales: any = await HeadlessFetcher.getEntry(
        "preview",
        pageSysId,
        "*" as Locales,
        {
          content_type: "nPage",
          select:
            "fields.internalName,fields.title,fields.description,fields.slug,fields.status",
        }
      );
      if (locales) {
        const includedFields = ["title", "description", "slug", "status"]; // Specify the fields you want
        const result = mapLocaleFields(locales.fields, includedFields);

        setData(result as any);
        setInternalName(locales?.fields?.internalName?.en);
        setRetailFallbackTitle(locales?.fields?.title?.en);
        setAlcatrazFallbackTitle(locales?.fields?.title?.eo);
      }
    };
    fetchLocales();
  }, [pageSysId]);

  // console.log(pillarData);

  return (
    <div className="min-h-[500px]">
      <Paragraph fontSize="fontSizeL" fontWeight="fontWeightDemiBold">
        {internalName}
      </Paragraph>
      {pillarData && <LocaleCard data={pillarData} fallbackTitle={retailFallbackTitle} />}
      {/* <Paragraph className="mt-4">Choose a locale to preview</Paragraph> */}
      <Tabs currentTab={currentTab} onTabChange={setCurrentTab}>
        <Tabs.List variant="horizontal-divider">
          {retailData.length > 0 && (
            <Tabs.Tab panelId="retail">
              Retail{" "}
              {retailErrorCount > 0 && (
                <span
                  title={`${retailErrorCount} errors`}
                  className="rounded-full h-5  w-5 flex items-center justify-center leading-none  ml-2 bg-red-500 text-white font-medium text-xxs"
                >
                  {retailErrorCount}
                </span>
              )}
            </Tabs.Tab>
          )}
          {alcatrazData.length > 0 && (
            <Tabs.Tab panelId="alcatraz">
              Alcatraz{" "}
              {alcatrazErrorCount > 0 && (
                <span
                  title={`${alcatrazErrorCount} errors`}
                  className="rounded-full h-5 w-5 flex items-center justify-center leading-none  ml-2 bg-red-500 text-white font-medium text-xxs"
                >
                  {alcatrazErrorCount}
                </span>
              )}
            </Tabs.Tab>
          )}
        </Tabs.List>
        <Tabs.Panel className="pt-4" id="retail">
          <div className="shadow max-h-[400px] overflow-y-auto rounded-t-md rounded-b-md">
            {retailData.map((card, key) => (
              <LocaleCard data={card} key={key} fallbackTitle={retailFallbackTitle} />
            ))}
          </div>
        </Tabs.Panel>

        <Tabs.Panel className="pt-4" id="alcatraz">
          <div className="shadow max-h-[400px] overflow-y-auto rounded-t-md rounded-b-md">
            {alcatrazData.map((card, key) => (
              <LocaleCard data={card} key={key} fallbackTitle={alcatrazFallbackTitle} />
            ))}
          </div>
        </Tabs.Panel>
      </Tabs>

      <p className="w-full mt-2 text-gray-500 text-right">
        {currentTab === "retail"
          ? retailData.filter(({ locale }) => locale !== "pillar").length
          : alcatrazData.filter(({ locale }) => locale !== "pillar").length}
        /{data.filter(({ locale }) => locale !== "pillar").length}
      </p>
    </div>
  );
}
