/**
 * Preview is a good name for the lowest env because it matches the
 * naming convention inside of Contentful
 *
 * ________________________________________________________
 * nonprod: (nonprod app)
 *  - preview (SSR + Contentful Preview)
 *  - final (SSG + Contentful Preview)
 *  - live    (SSG + Contentful Live)
 *
 * ________________________________________________________
 * prod: (prod app)
 *  - preview (SSR + Contentful Preview)
 *  - final (SSG + Contentful Preview)
 *  - live    (SSG + Contentful Live)
 *
 */

import HeadlessFetcher from "@/services/fetchers/headless-fetcher";
import { cn, getContentfulEnvironment, getRouteDetails } from "@/utils/helpers";
import { Badge, Notification } from "@contentful/f36-components";
import {
  BoxSelect,
  CircleCheck,
  CircleHelp,
  CircleX,
  Cog,
  Edit,
  Square,
} from "lucide-react";
import { useRouter } from "next/router";
import { ReactNode, useCallback, useEffect, useState } from "react";
import Link from "./link";
import ToolsDialog from "./tools-dialog/tools-dialog";
import HelpDialog from "./help-dialog/help-dialog";

const variants: any = {
  Disabled: "secondary",
  Published: "positive",
  Changed: "primary",
  Draft: "warning",
};

const contentBadgeMessage: any = {
  Draft: "Content of this page is in draft mode",
  Changed: "Content has been changed since this page was last publish",
  Published: "Content published and page available on both final and live",
  Disabled: "This page is disabled and not available on either final or live",
};

const CustomBadge = ({
  children,
  variant,
  className,
  title,
  onClick,
  ...props
}: {
  children: ReactNode;
  variant: "positive-filled" | "featured-filled";
  className: string;
  title: string;
  onClick?: () => void;
}) => {
  if (variant === "positive-filled") {
    return (
      <div
        {...props}
        title={title}
        className={`flex items-center justify-center overflow-hidden rounded-[4px] bg-env-live px-1.5 py-[1.25px] text-[12px] text-white ${className}`}
      >
        {children}
      </div>
    );
  }

  if (variant === "featured-filled") {
    return (
      <div
        {...props}
        title={title}
        className={`flex items-center justify-center overflow-hidden rounded-[4px] bg-env-preview px-1.5 py-[1.25px] text-[12px] text-white ${className}`}
      >
        {children}
      </div>
    );
  }
};

const LiveUncachedEnvironmentBar = ({ data }: any) => {
  const router = useRouter();

  const { locale, slug } = getRouteDetails(router);

  const [isMounted, setIsMounted] = useState(false);

  const [pageStatus, setPageStatus] = useState<any>();

  useEffect(() => {
    const getPageStatus = async () => {
      const status = await HeadlessFetcher.getPageStatus(locale, slug.replace(/^\//, ""));
      setPageStatus(status);
    };

    if (!isMounted) {
      getPageStatus();
    }
    setIsMounted(true);
  }, []);

  if (!isMounted) {
    return null;
  }

  if (!window?.location?.href?.includes("/live/")) {
    return null;
  }

  return (
    <div id="environmentBar" className="pointer-events-auto relative h-[18px] w-full">
      <div
        className={cn(
          "grid-center-parent group absolute z-[999] h-[18px] w-full justify-end bg-env-live px-2 transition-all duration-500 hover:h-[35px]"
        )}
      >
        <div className="flex w-full items-center justify-center gap-2 text-xs text-white ">
          LIVE ({locale}) uncached
        </div>
        <div className="flex w-full items-center justify-start transition-all duration-500">
          {pageStatus && (
            <div
              className={cn(
                "box-shadow z-[9999] -mt-0.5  flex h-[15px] w-min items-center gap-2 rounded-md border border-solid bg-white pl-2 pr-2 transition-all duration-500 group-hover:h-[28px]"
              )}
            >
              <div className="rounded-[4px] bg-white-90 transition-all duration-500">
                {pageStatus.isReadyForLive !== undefined && pageStatus?.isReadyForLive ? (
                  <div
                    title="Page flagged as ready for live"
                    className="h-[8px] w-[16px] cursor-context-menu overflow-hidden rounded-[4px] bg-env-live transition-all duration-500 group-hover:h-[20px] group-hover:w-min group-hover:bg-transparent"
                  >
                    <CircleCheck
                      className="hidden h-full py-[2px] group-hover:block"
                      stroke="green"
                    />
                  </div>
                ) : (
                  <div
                    title="Page flagged as NOT ready for live"
                    className="h-[8px] w-[16px] cursor-context-menu overflow-hidden rounded-[4px] bg-red-600 transition-all duration-500 group-hover:h-[20px] group-hover:w-min group-hover:bg-transparent"
                  >
                    <CircleX
                      className="hidden h-full py-[2px] group-hover:block"
                      stroke="red"
                    />
                  </div>
                )}
              </div>
              {pageStatus?.state !== undefined && (
                <Badge
                  title={
                    contentBadgeMessage[
                      pageStatus?.state as keyof typeof contentBadgeMessage
                    ]
                  }
                  className={`h-[8px] w-[2px] cursor-context-menu transition-all duration-500 group-hover:h-full group-hover:w-fit`}
                  // @ts-ignore
                  variant={variants[pageStatus?.state as BadgeVariant]}
                >
                  {pageStatus?.state}
                </Badge>
              )}
              {pageStatus?.isLive !== undefined && (
                <CustomBadge
                  className={`h-[8px] max-w-[16px] cursor-context-menu transition-all duration-500 group-hover:h-[20px] group-hover:w-min group-hover:max-w-none  ${
                    pageStatus?.isLive ? "" : "opacity-40"
                  }`}
                  variant="positive-filled"
                  title={`${
                    pageStatus?.isLive
                      ? "Live in sync with latest content."
                      : "Live out of sync with latest content."
                  }`}
                >
                  <p className="flex w-full items-center justify-center pt-0.5 text-env-live group-hover:text-white">
                    Live
                  </p>
                </CustomBadge>
              )}
            </div>
          )}
        </div>
        <div className="flex w-full justify-end py-[2px] group-hover:py-1">
          <Link
            className="h-full"
            target="_blank"
            href={
              data
                ? `https://app.contentful.com/spaces/${data?.items?.[0]?.sys?.space?.sys?.id}/entries/${data.items[0].sys.id}`
                : "#"
            }
          >
            <Edit stroke="white" className="h-full" />
          </Link>
        </div>
      </div>
    </div>
  );
};

const PreviewEnvironmentBar = ({ data, isPreview, isLivePreview }: any) => {
  const status = data?.items?.[0]?.fields?.status;

  const [showToolsDialog, setShowToolsDialog] = useState(false);
  const [showHelpDialog, setShowHelpDialog] = useState(false);

  const router = useRouter();
  let { locale, slug } = getRouteDetails(router);
  if (slug.startsWith("/home")) slug = "";

  if (locale === "en" && isLivePreview) locale = "pillar";
  if (locale === "eo" && isLivePreview) locale = "en";

  const openPageIn = (env: "preview" | "final" | "live") => {
    // console.log(
    //   `${process.env.BASE_URL}${env !== "live" ? `/${env}` : ""}/${locale}${
    //     slug ? `/${slug}` : ""
    //   }`
    // );

    window.open(
      `${process.env.BASE_URL}${
        env !== "live" ? `/${env}` : ""
      }/${locale.toLowerCase()}/${slug}`,
      "_blank"
    );
  };

  const [borders, setBorders] = useState(true);

  const toggleBorders = useCallback(() => {
    let next;

    const url = new URL(window.location.href);

    const params = new URLSearchParams(url.search);

    if (params.get("frames") === "true") {
      next = false;
    } else if (params.get("frames") === "false") {
      next = true;
    } else {
      next = false;
    }

    params.set("frames", next.toString());

    url.search = params.toString();

    router.push(url.toString(), undefined, { shallow: true });
  }, [data]);

  useEffect(() => {
    const url = new URL(window.location.href);

    const frames = url.searchParams.get("frames");

    if (frames === "true") setBorders(true);
    if (frames === "false") setBorders(false);
  }, [router.asPath]);
  // console.log(`${locale}/${slug}`);

  // console.log(`${process.env.BASE_URL}`);

  return (
    <>
      <ToolsDialog isShown={showToolsDialog} setShown={setShowToolsDialog} data={data} />
      <HelpDialog isShown={showHelpDialog} setShown={setShowHelpDialog} />

      <div
        dir="ltr"
        id="environmentBar"
        className="pointer-events-auto relative h-[18px] w-full"
      >
        <div
          className={cn(
            "grid-center-parent group absolute z-[999] h-[18px] w-full justify-end px-2 transition-all duration-500 hover:h-[35px]",
            isPreview && "bg-env-preview",
            isLivePreview && "bg-env-live-preview"
          )}
        >
          <div className="flex w-full items-center justify-center gap-2 text-xs text-white ">
            {isLivePreview ? "LIVE-PREVIEW" + ` (${locale})` : "PREVIEW" + ` (${locale})`}
          </div>
          <div className="flex w-full items-center justify-start transition-all duration-500">
            {status && (
              <div
                className={cn(
                  "box-shadow z-[9999] -mt-0.5  flex h-[15px] w-min items-center gap-2 rounded-md border border-solid bg-white pl-2 pr-2 transition-all duration-500 group-hover:h-[28px]"
                )}
              >
                <div className="rounded-[4px] bg-white-90 transition-all duration-500">
                  {status?.isReadyForLive ? (
                    <div
                      title="Page flagged as ready for live"
                      className="h-[8px] w-[16px] cursor-context-menu overflow-hidden rounded-[4px] bg-env-live transition-all duration-500 group-hover:h-[20px] group-hover:w-min group-hover:bg-transparent"
                    >
                      <CircleCheck
                        className="hidden h-full py-[2px] group-hover:block"
                        stroke="green"
                      />
                    </div>
                  ) : (
                    <div
                      title="Page flagged as NOT ready for live"
                      className="h-[8px] w-[16px] cursor-context-menu overflow-hidden rounded-[4px] bg-red-600 transition-all duration-500 group-hover:h-[20px] group-hover:w-min group-hover:bg-transparent"
                    >
                      <CircleX
                        className="hidden h-full py-[2px] group-hover:block"
                        stroke="red"
                      />
                    </div>
                  )}
                </div>
                <Badge
                  title={contentBadgeMessage[status?.state]}
                  className={`h-[8px] w-[2px] cursor-context-menu transition-all duration-500 group-hover:h-full group-hover:w-fit`}
                  variant={variants[status?.state]}
                >
                  {status?.state}
                </Badge>
                <CustomBadge
                  className={`h-[8px] max-w-[16px] cursor-context-menu transition-all duration-500 group-hover:h-[20px] group-hover:w-min group-hover:max-w-none`}
                  variant="featured-filled"
                  title="Preview in sync with latest content."
                >
                  <Link
                    href={`${process.env.BASE_URL}/preview/${locale}${
                      slug ? `${slug.split("?")[0]}` : ""
                    }`}
                    className="flex w-full items-center justify-center pt-0.5 text-env-preview no-underline group-hover:text-white"
                  >
                    Preview
                  </Link>
                </CustomBadge>
                <CustomBadge
                  className={`h-[8px] max-w-[16px] cursor-context-menu transition-all duration-500 group-hover:h-[20px] group-hover:w-min group-hover:max-w-none  ${
                    status?.isLive ? "" : "opacity-40"
                  }`}
                  variant="positive-filled"
                  title={`${
                    status?.isLive
                      ? "Live in sync with latest content."
                      : "Live out of sync with latest content."
                  }`}
                  onClick={() => openPageIn("live")}
                >
                  <Link
                    href={`${process.env.BASE_URL}/${locale}${
                      slug ? `${slug.split("?")[0]}` : ""
                    }`}
                    className="flex w-full items-center justify-center pt-0.5 text-env-live no-underline group-hover:text-white"
                  >
                    Live
                  </Link>
                </CustomBadge>
              </div>
            )}
          </div>
          <div className="flex w-full justify-end py-[2px] group-hover:py-1 gap-1">
            {/* BORDER CONTROL */}
            {isLivePreview && (
              <div
                className="cursor-pointer h-full"
                title={`${borders ? "Hide frames" : "Show frames"}`}
              >
                {borders ? (
                  <Square
                    onClick={() => toggleBorders()}
                    className={cn(
                      "h-full w-auto cursor-pointer stroke-white-100 hover:stroke-white-95"
                    )}
                    height={25}
                    width={25}
                  />
                ) : (
                  <BoxSelect
                    onClick={() => toggleBorders()}
                    className={cn(
                      "h-full w-auto cursor-pointer stroke-white-100 hover:stroke-white-95"
                    )}
                    height={20}
                    width={20}
                  />
                )}
              </div>
            )}

            <div className="h-full cursor-pointer">
              <CircleHelp
                className="h-full w-auto stroke-white-100 hover:stroke-white-95"
                height={20}
                width={20}
                onClick={() => setShowHelpDialog(true)}
              />
            </div>

            <div className="h-full cursor-pointer">
              <Cog
                className="h-full w-auto stroke-white-100 hover:stroke-white-95"
                height={20}
                width={20}
                onClick={() => setShowToolsDialog(true)}
              />
            </div>

            <Link
              className="h-full"
              target="_blank"
              as={"div"}
              href={
                data
                  ? `https://app.contentful.com/spaces/${data.items[0].sys.space.sys.id}/entries/${data.items[0].sys.id}`
                  : "#"
              }
            >
              <Edit
                width={20}
                height={20}
                stroke="white"
                className="w-auto h-full stroke-white-100 hover:stroke-white-95"
              />
            </Link>
          </div>
        </div>
      </div>
    </>
  );
};

const EnvironmentBar = ({ data }: any) => {
  const router = useRouter();

  const { isStaticRoute } = getRouteDetails(router);

  const { env } = getContentfulEnvironment(router);

  const isLiveUncached = env === "live-uncached";
  const isLive = env === "live";
  const isPreview = env === "preview";
  const isLivePreview = env === "live-preview";

  const isFooterEditor = router.query.footer;
  const isMenuEditor = router.query.menu;

  useEffect(() => {
    if (isStaticRoute && isLivePreview) {
      Notification.error(
        "Custom pages are only editable via code changes by a developer. If you'd like to make a change to this page please open a ticket.",
        { title: "This page is not content editable." }
      );
    }
  }, [isStaticRoute, isLivePreview]);

  if (isMenuEditor) {
    return (
      <div className="relative h-[18px] w-full">
        <div
          className={cn(
            "grid-center-parent group absolute z-[999] h-[18px] w-full justify-end px-2 text-white transition-all duration-500 hover:h-[35px]",
            isPreview ? "bg-env-preview" : "bg-env-final"
          )}
        >
          <div className="flex h-full items-center justify-center">
            <p className="w-full text-center text-sm">MENU EDITOR</p>
          </div>
        </div>
      </div>
    );
  }

  if (isFooterEditor) {
    return (
      <div className="relative h-[18px] w-full">
        <div
          className={cn(
            "grid-center-parent group absolute z-[999] h-[18px] w-full justify-end px-2 text-white transition-all duration-500 hover:h-[35px]",
            isPreview ? "bg-env-preview" : "bg-env-final"
          )}
        >
          <div className="flex h-full items-center justify-center">
            <p className="w-full text-center text-sm">FOOTER EDITOR</p>
          </div>
        </div>
      </div>
    );
  }

  if (isPreview || isLivePreview) {
    return (
      <PreviewEnvironmentBar
        data={data}
        isLivePreview={isLivePreview}
        isPreview={isPreview}
      />
    );
  }

  if (isLiveUncached) return <LiveUncachedEnvironmentBar data={data} />;

  if (isLive) return null;
};

export default EnvironmentBar;
