import { ISO3166_Alpha2_Codes } from "@/types/country-codes";

export const countries_pt: Record<ISO3166_Alpha2_Codes, string> = {
  AW: "Aruba",
  AF: "Afeganistão",
  AO: "Angola",
  AI: "Anguilla",
  AX: "Ilhas Aland",
  AL: "Albânia",
  AD: "Andorra",
  AN: "Antilhas Holandesas",
  AE: "Emirados Árabes Unidos",
  AR: "Argentina",
  AM: "Arménia",
  AS: "Samoa Americana",
  AQ: "Antártica",
  TF: "Territórios Franceses do Sul",
  AG: "Antigua e Barbuda",
  AU: "Austrália",
  AT: "Áustria",
  AZ: "Azerbaijão",
  BI: "Burundi",
  BE: "Bélgica",
  BJ: "Benin",
  BQ: "Bonaire, Sint Eustatius e Saba",
  BF: "Burkina Faso",
  BD: "Bangladesh",
  BG: "Bulgária",
  BH: "Bahrain",
  BS: "Baamas",
  BA: "Bósnia e Herzegovina",
  BL: "São Bartolomeu",
  BY: "Bielorrússia",
  BZ: "Belize",
  BM: "Bermudas",
  BO: "Bolívia (Estado Plurinacional da)",
  BR: "Brasil",
  BB: "Barbados",
  BN: "Brunei Darussalam",
  BT: "Butão",
  BV: "Ilha Bouvet",
  BW: "Botsuana",
  CF: "República Centro-Africana",
  CA: "Canadá",
  CC: "Ilhas Cocos (Keeling)",
  CH: "Suíça",
  CL: "Chile",
  CN: "China",
  CI: "Costa do Marfim",
  CM: "Camarões",
  CD: "Congo (República Democrática do)",
  CG: "Congo",
  CK: "Ilhas Cook",
  CO: "Colômbia",
  KM: "Comores",
  CV: "Cabo Verde",
  CR: "Costa Rica",
  CU: "Cuba",
  CW: "Curaçao",
  CX: "Ilha do Natal",
  KY: "Ilhas Caimão",
  CY: "Chipre",
  CZ: "República Checa",
  DE: "Alemanha",
  DJ: "Jibuti",
  DM: "Domínica",
  DK: "Dinamarca",
  DO: "República Dominicana",
  DZ: "Argélia",
  EC: "Equador",
  EG: "Egito",
  ER: "Eritreia",
  EH: "Saara Ocidental",
  ES: "Espanha",
  EE: "Estónia",
  ET: "Etiópia",
  FI: "Finlândia",
  FJ: "Fiji",
  FK: "Ilhas Falkland (Malvinas)",
  FR: "França",
  FO: "Ilhas Faroé",
  FM: "Micronésia",
  GA: "Gabão",
  GB: "Reino Unido",
  GE: "Geórgia",
  GG: "Guernesey",
  GH: "Gana",
  GI: "Gibraltar",
  GN: "Guiné",
  GP: "Guadalupe",
  GM: "Gâmbia",
  GW: "Guiné-Bissau",
  GQ: "Guiné Equatorial",
  GR: "Grécia",
  GD: "Granada",
  GL: "Gronelândia",
  GT: "Guatemala",
  GF: "Guiana Francesa",
  GU: "Guam",
  GY: "Guiana",
  HK: "Hong Kong",
  HM: "Ilha Heard e Ilhas McDonald",
  HN: "Honduras",
  HR: "Croácia",
  HT: "Haiti",
  HU: "Hungria",
  ID: "Indonésia",
  IM: "Ilha de Man",
  IN: "Índia",
  IO: "Território Britânico do Oceano Índico",
  IE: "Irlanda",
  IR: "Irão",
  IQ: "Iraque",
  IS: "Islândia",
  IL: "Israel",
  IT: "Itália",
  JM: "Jamaica",
  JE: "Jersey",
  JO: "Jordânia",
  JP: "Japão",
  KZ: "Cazaquistão",
  KE: "Quénia",
  KG: "Quirguistão",
  KH: "Camboja",
  KI: "Kiribati",
  KN: "São Cristóvão e Neves",
  KR: "Coreia do Sul",
  KW: "Kowait",
  LA: "Laos",
  LB: "Líbano",
  LR: "Libéria",
  LY: "Líbia",
  LC: "Santa Lúcia",
  LI: "Liechtenstein",
  LK: "Sri Lanka",
  LS: "Lesoto",
  LT: "Lituânia",
  LU: "Luxemburgo",
  LV: "Letónia",
  MO: "Macau",
  MF: "São Martinho",
  MA: "Marrocos",
  MC: "Mónaco",
  MD: "Moldávia (República da)",
  MG: "Madagáscar",
  MV: "Maldivas",
  MX: "México",
  MH: "Ilhas Marshall",
  MK: "Macedónia",
  ML: "Mali",
  MT: "Malta",
  MM: "Myanmar",
  ME: "Montenegro",
  MN: "Mongólia",
  MP: "Ilhas Marianas do Norte",
  MZ: "Moçambique",
  MR: "Mauritânia",
  MS: "Montserrat",
  MQ: "Martinica",
  MU: "Maurícia",
  MW: "Malawi",
  MY: "Malásia",
  YT: "Maiote",
  NA: "Namíbia",
  NC: "Nova Caledónia",
  NE: "Níger",
  NF: "Ilha Norfolk",
  NG: "Nigéria",
  NI: "Nicarágua",
  NU: "Niue",
  NL: "Países Baixos",
  NO: "Noruega",
  NP: "Nepal",
  NR: "Nauru",
  NZ: "Nova Zelândia",
  OM: "Omã",
  PK: "Paquistão",
  PA: "Panamá",
  PN: "Pitcairn",
  PE: "Peru",
  PH: "Filipinas",
  PW: "Palau",
  PG: "Papua Nova Guiné",
  PL: "Polónia",
  PR: "Porto Rico",
  KP: "Coreia do Norte",
  PT: "Portugal",
  PY: "Paraguai",
  PS: "Palestina, Estado da",
  PF: "Polinésia Francesa",
  QA: "Catar",
  RE: "Reunião",
  RO: "Roménia",
  RU: "Rússia",
  RW: "Ruanda",
  SA: "Arábia Saudita",
  SD: "Sudão",
  SN: "Senegal",
  SG: "Singapura",
  GS: "Geórgia do Sul e Ilhas Sandwich do Sul",
  SH: "Santa Helena",
  SJ: "Svalbard e Jan Mayen",
  SB: "Ilhas Salomão",
  SL: "Serra Leoa",
  SV: "El Salvador",
  SM: "São Marinho",
  SO: "Somália",
  PM: "São Pedro e Miquelão",
  RS: "Sérvia",
  SS: "Sudão do Sul",
  ST: "São Tomé e Príncipe",
  SR: "Suriname",
  SK: "Eslováquia",
  SI: "Eslovénia",
  SE: "Suécia",
  SZ: "Suazilândia",
  SX: "Sint Maarten (parte holandesa)",
  SC: "Seicheles",
  SY: "República Árabe da Síria",
  TC: "Ilhas Turcas e Caicos",
  TD: "Chade",
  TG: "Togo",
  TH: "Tailândia",
  TJ: "Tajiquistão",
  TK: "Toquelau",
  TM: "Turquemenistão",
  TL: "Timor-Leste",
  TO: "Tonga",
  TT: "Trindade e Tobago",
  TN: "Tunísia",
  TR: "Turquia",
  TV: "Tuvalu",
  TW: "Taiwan",
  TZ: "Tanzânia, República Unida da",
  UG: "Uganda",
  UA: "Ucrânia",
  UM: "Ilhas Menores Distantes dos Estados Unidos",
  UY: "Uruguai",
  US: "Estados Unidos da América",
  UZ: "Uzbequistão",
  VA: "Vaticano",
  VC: "São Vicente e Granadinas",
  VE: "Venezuela",
  VG: "Ilhas Virgens (britânicas)",
  VI: "Ilhas Virgens (EUA)",
  VN: "Vietname",
  VU: "Vanuatu",
  WF: "Wallis e Futuna",
  WS: "Samoa",
  XK: "Kosovo",
  YE: "Iémen",
  ZA: "África do Sul",
  ZM: "Zâmbia",
  ZW: "Zimbábue",
};
