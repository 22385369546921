import { SUFFIX } from "@/constants";
import { ThemesG } from "@/types/design-system-types";
import { Notification } from "@contentful/f36-components";
import { ContentfulLivePreview } from "@contentful/live-preview";
import { isLivePreview } from "@/utils/helpers";
import { PageInterface } from "@/types/page";
import { PageEntry } from "@/services/fetchers/headless-fetcher";
import { ContentfulCollection } from "contentful";
import { Entry } from "contentful-management";
import { ScrollSpy } from "@/components/layout/scroll-spy-toolbar";

export default class Page implements PageInterface {
  private data: ContentfulCollection<PageEntry & Entry>;

  public components: any;
  public description: string;
  public locale: string;
  public scrollSpies: any;
  public seoIndex: string;
  public slug: string;
  public title: string;

  constructor(data: ContentfulCollection<PageEntry & Entry>) {
    this.data = data;
    this.description = data?.items?.[0]?.fields?.description;
    this.locale = data?.items?.[0]?.sys?.locale ?? "en";
    this.seoIndex = data?.items?.[0].fields?.seoIndexRules;
    this.slug = data?.items?.[0].fields?.slug;
    this.title = data?.items?.[0]?.fields?.title + SUFFIX;
    this.addDepthAndFootnotes();
  }

  /**
   * Iterate through the components and add nest depth to fields
   * and redact any {{{ FOOTNOTE }}} keyword with a superscript number
   */
  addDepthAndFootnotes() {
    if (!this.data?.items) return;

    // Convert the components JSON to string
    const str = this.data.items.length
      ? JSON.stringify(this.data?.items?.[0]?.fields?.components)
      : "";

    if (!str) {
      this.components = [];
      console.warn("No components found in the page data.");
      return;
    }

    // Initialize the counter and index
    let counter = 0;
    let i = 0;
    const length = str.length;
    let output = "";
    const levels: any = {}; // Store the depth of nest level

    const footnotes = {
      1: "¹",
      2: "²",
      3: "³",
      4: "⁴",
      5: "⁵",
      6: "⁶",
      7: "⁷",
      8: "⁸",
      9: "⁹",
      10: "¹⁰",
      11: "¹¹",
      12: "¹²",
      13: "¹³",
      14: "¹⁴",
      15: "¹⁵",
    } as const;

    let footnoteIndex: number = 1;

    // TODO: Add more DIDs and fixed footnotes as well
    while (i < length) {
      const fields = str.substring(i, i + 9);
      const footnote = str.substring(i, i + 16);

      const footnoteStart = "{{{ FOOTNOTE";
      const footnoteEnd = " }}}";
      const footnoteStartStatement = str.substring(i, i + footnoteStart.length);

      // If substring is "fields":{, then increment the counter and add depth to the fields
      if (fields === 'fields":{') {
        counter++;
        levels[counter] = 0;

        // Only add coma if the object has more fields
        const addComa = str[i + 10] === "}" ? "" : ",";
        output += 'fields":{"depth":' + counter + addComa;

        i += 9;
      } else if (footnoteStartStatement === footnoteStart) {
        /**
         * If a footnote is found and equals {{{ FOOTNOTE }}} - increment
         *
         * If a footnote is found and it has page ids with specified footnotes,
         * check if the current page id is in the list
         *  - if it is, add the specified footnote
         *  - if it's not, add the next increment footnote
         */
        let startCounting = true;

        let j = i;

        while (startCounting) {
          // if (j > i + 200) break; // Prevent infinite loop
          if (j > i + 1500) break; // !- This is a temporary fix to avoid broken footnote DID - //

          const footnoteEndStatement = str.substring(j, j + footnoteEnd.length);

          if (footnoteEndStatement === footnoteEnd) {
            startCounting = false;
            const footnote = str.substring(i, j + footnoteEnd.length);

            if (footnote === "{{{ FOOTNOTE }}}") {
              // If there are no overrides specified in the DID - use the default increment
              output += `${footnotes[footnoteIndex++ as keyof typeof footnotes]}`;
              i += 15;
              break;
            } else if (footnote === "{{{ FOOTNOTE-OVERRIDE }}}") {
              const footnoteOverridesStr = str
                .substring(i)
                // .match(/"footnoteNumberOverride":(\[.*?\])/); // Old regex - this was causing brackets inside internalName fields to close the regex early
                .match(
                  /"footnoteNumberOverride":(\[(?:[^[\]]|\[(?:[^[\]]|\[(?:[^[\]])*\])*\])*(?:,\s*(?:[^[\]]|\[(?:[^[\]]|\[(?:[^[\]])*\])*\])*)*\])/
                );
              let footnoteOverrides: any[] = [];

              try {
                footnoteOverrides = JSON.parse(footnoteOverridesStr?.[1] || "[]");
              } catch (e) {
                console.error("Error parsing footnote overrides", e);
              }

              const overrideForThisPage = footnoteOverrides.find(
                (item: any) => item.id === this.data.items[0].sys.id
              );

              // If there are overrides specified in the DID and the current page has an override - use it
              if (footnoteOverrides.length > 0 && overrideForThisPage) {
                output += `${
                  footnotes[overrideForThisPage.number as keyof typeof footnotes]
                }`;

                i += footnote.length - 1;
                break;
              } else {
                // If there are overrides specified in the DID but the current page does not have an override - use the default increment
                output += `${footnotes[footnoteIndex++ as keyof typeof footnotes]}`;
                i += footnote.length - 1;
                break;
              }
            } else {
              // !! To be deprecated !! \\
              // This was the old way of doing ID = 1, ID = 2, etc.
              const obj: Record<string, number> = {};
              const thisPageId = this.data.items[0].sys.id;

              Array.from(footnote.matchAll(/(\w+) = (\d+)/g)).forEach((match) => {
                obj[match[1]] = parseInt(match[2]);
              });

              if (Object.keys(obj).includes(thisPageId)) {
                output += `${footnotes[obj[thisPageId] as keyof typeof footnotes]}`;

                i += footnote.length - 1;
              } else {
                output += `${footnotes[footnoteIndex++ as keyof typeof footnotes]}`;
                i += footnote.length - 1;
              }
              break;
              // !! To be deprecated !! \\
            }
          }
          j++;
        }
        i++;
      } else {
        // Count the open and closed bracket to know the current depth level
        if (str[i] === "{") {
          levels[counter] = levels[counter] + 1; // Increment the depth at current level for each "{"
        } else if (str[i] === "}") {
          if (levels[counter] - 1 === -1) {
            delete levels[counter]; // If all brackets are closed, remove the level
            counter--; // Drop the nest level
          } else {
            levels[counter] = levels[counter] - 1; // Decrement the depth at current level for each "}"
          }
        }

        // Process the current character even if it's another "{"
        output += str[i]; //
        i++;
      }
    }

    try {
      const final = JSON.parse(output);
      this.components = final;

      return final;
    } catch (e) {
      console.log("[UTILS | PAGE] Error parsing JSON", e);
    }
  }

  static scrollSpies(data: any): ScrollSpy[] | undefined {
    // If no scroll spies are set on any components, return
    if (!data?.items) return;

    // If scroll spies is set to disabled on page level, return
    if (data?.items[0]?.fields?.hideScrollSpy === true) return;

    return data.items[0]?.fields?.components
      ?.filter((item: any) => item?.fields?.scrollSpy)
      .map((item: any) => {
        return {
          id: item?.fields?.scrollSpy?.toLowerCase().replaceAll(" ", "-"),
          label: item?.fields?.scrollSpy,
        };
      });
  }

  /**
   * When in contentful it opens the entry in the editor.
   *
   * Just pass the current data and optionally - the fieldId.
   *
   * @param data current component data
   * @param fieldId
   */
  static editField(data: any, fieldId?: string) {
    // Prevent this from firing outside of Contentful Live Preview
    if (!isLivePreview()) return;

    if (!data?.sys) {
      console.warn("This component is missing sys and cannot be opened in the editor.");
      return Notification.warning(
        "Field not editable or click to edit not setup correctly.",
        { duration: 1500 }
      );
    }

    ContentfulLivePreview.openEntryInEditor({
      entryId: data.sys.id,
      fieldId: fieldId || data.sys.contentType.sys.id,
      locale: data?.sys?.locale,
    });

    window.postMessage(
      {
        method: "CUSTOM_EDIT_ENTRY_CLICK",
        action: "CUSTOM_EDIT_ENTRY_CLICK",
        entityId: data.sys.id,
      },
      "*"
    );
  }

  static editEntry(entryId: string, locale: string) {
    // Prevent this from firing outside of Contentful Live Preview
    if (!isLivePreview()) return;

    ContentfulLivePreview.openEntryInEditor({
      entryId,
      fieldId: "fields",
      locale,
    });
  }

  static getThemeClass(theme: string = "") {
    // Return empty string if no theme provided
    let themeClass = "";

    switch (theme) {
      case "white":
        themeClass = "theme-white-100";
        break;
      case "white-100":
        themeClass = "theme-white-100";
        break;
      case "azure-10":
        themeClass = "theme-azure-10";
        break;
      case "navy-70":
        themeClass = "theme-navy-70";
        break;
      case "navy-100":
        themeClass = "theme-navy-100";
        break;
      case "white-85":
        themeClass = "theme-white-85";
        break;
      case "white-90":
        themeClass = "theme-white-90";
        break;
      case "transparent-light":
        themeClass = "theme-transparent-light";
        break;
      case "transparent-dark":
        themeClass = "theme-transparent-dark";
        break;
      case "blur":
        themeClass = "theme-transparent-dark";
        break;
    }

    return themeClass;
  }

  // Return a complementary theme class based on the provided theme.
  // Select transparent light or dark to inherit from parent theme.
  // Use case, FAQ accordions
  // ! EXPERIMENTAL
  static getSecondaryThemeClass(theme: string = "") {
    // Return empty string if no theme provided
    let themeClass = "";

    switch (theme) {
      case "white":
        themeClass = "theme-navy-100";
        break;
      case "white-100":
        themeClass = "theme-white-90";
        break;
      case "azure-10":
        themeClass = "theme-navy-100";
        break;
      case "navy-70":
        themeClass = "theme-navy-100";
        break;
      case "navy-100":
        themeClass = "theme-navy-70";
        break;
      case "white-85":
        themeClass = "theme-white-100";
        break;
      case "white-90":
        themeClass = "theme-white-100";
        break;
      case "transparent-light":
        themeClass = "theme-transparent-light";
        break;
      case "transparent-dark":
        themeClass = "theme-transparent-dark";
        break;
      case "blur":
        themeClass = "theme-transparent-dark";
        break;
    }

    return themeClass;
  }

  static getThemeBrightness(theme: ThemesG) {
    let brightness: "light" | "dark" = "light";

    switch (theme) {
      case "white":
      case "white-90":
      case "white-85":
      case "azure-10":
      case "transparent-light":
        brightness = "light";
        break;
      case "navy-70":
      case "navy-100":
      case "transparent-dark":
        brightness = "dark";
        break;
    }

    return brightness;
  }
}
