/**
 * This module defines various constants used throughout the application.
 *
 * Constants:
 * - SUFFIX: A string appended to the end of page titles.
 * - ONE_LINK: A URL for the CMC Markets OneLink.
 * - FALLBACK_LINK: A fallback URL for the CMC Markets mobile trading app.
 * - ENABLED_LOCALES: An array of locale strings that are enabled for LIVE publishing.
 * - isProd: A boolean indicating if the environment is either LIVE or PREVIEW.
 * - isLocal: A boolean indicating if the environment is LOCAL.
 * - PILLAR_LOCALE: The default root locale
 */

import { Locales } from "./config/supported-countries";

// export const basePath = process.env.ENV === "LOCAL" ? "" : `/neptune`;

export const ONE_LINK = "https://cmcmarkets.onelink.me/3oZk/";

export const FALLBACK_LINK = "https://www.cmcmarkets.com/en-gb/trading-platforms/mobile-trading-app";

export const isProd = process.env.ENV === "PROD";

export const isNonprod = process.env.ENV === "NONPROD";

export const isLocal = process.env.ENV === "LOCAL";

export const SUFFIX = " | CMC Markets";

export const PILLAR_LOCALE = "en";

export const ENABLED_LOCALES: Locales[] = [
  "en-GB", // English (UK)
  // "en-AU", // English (Australia) // !! Still under Bonsai
  "en-CA", // English (Canada)
  "fr-CA", // French (Canada)
  "zh-CA", // Chinese (Canada)
  "en-SG", // English (Singapore)
  // "en-NZ", // English (New Zealand) // !! Still under Bonsai
  "en-IE", // English (Ireland)
  "en-DE", // English (Germany)
  // "en-BM", // English (Bermuda) // !! Current Alcatraz pillar is "eo"
  "de-DE", // German (Germany)
  "de-AT", // German (Austria)
  "it-IT", // Italian (Italy)
  "es-ES", // Spanish (Spain)
  "zh-AU", // Chinese (Australia)
  "fr-FR", // French (France)
  // "zh-SG", // Chinese (Singapore)
  // "zh-NZ", // Chinese (New Zealand) // !! Still under Bonsai
  "zh-CA", // Chinese (Canada)
  "sv-SE", // Swedish (Sweden)
  "nb-NO", // Norwegian (Norway)
  "pl-PL", // Polish (Poland)

  "eo", // Alcatraz Pillar
  "ar", // Arabic
  "bn", // Bengali
  // "ct", // Cantonese // !! Replaced by zh-HK/zh-MY
  "tl", // Tagalog
  "fr", // French
  "hi", // Hindi
  "id", // Indonesian
  "ko", // Korean
  "ms", // Malay
  "pt", // Portuguese
  "es", // Spanish
  "th", // Thai
  "tr", // Turkish
  "vi", // Vietnamese;
  "zh", // Chinese
  "zh-HK", // Chinese (Hong Kong)
  "zh-MO", // Chinese (Macau) )
  "zh-TW", // Chinese (Taiwan)
  // "zh-MY", // Chinese (Hong Kong) // !! Replaced by zh-TW/zh-MO
];
