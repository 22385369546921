import { RetailLocales } from "@/config/supported-countries";
import OnboardingService from "@/services/onboarding-service";
import { ButtonT } from "@/types/design-system-types";
import { cn, getContentfulEnvironment, getRouteDetails } from "@/utils/helpers";
import { Undo2, X } from "lucide-react";
import { useRouter } from "next/router";
import { ReactNode, useCallback, useEffect, useState } from "react";
import Button from "../core/atoms/button";
import Tile from "../core/molecules/tile";
import CMCNextGenerationLogo from "../icons/static/cmc-nextgeneration";
import MetatraderLogo from "../icons/static/metatrader-logo";

// Types
type OnboardingType = NonNullable<ButtonT["onboardingType"]> | "";
type OnboardingProduct = "cfd" | "mt4";
type Content = Record<
  RetailLocales | string,
  {
    enabledProducts: OnboardingProduct[];
    selectPlatform: string;
    registerAccount: string;
    productConfig: {
      cfd: {
        title: string;
        description: ReactNode;
        liveButtonText?: string;
        demoButtonText?: string;
      };
      mt4: {
        title: string;
        description: ReactNode;
        liveButtonText?: string;
        demoButtonText?: string;
      };
    };
  }
>;

const productImage = {
  cfd: <CMCNextGenerationLogo />,
  mt4: <MetatraderLogo />,
};

const content: Content = {
  en: {
    enabledProducts: ["cfd", "mt4"],
    selectPlatform: "Choose your trading platform",
    registerAccount: "Web-Based Platform (NGEN)",
    productConfig: {
      cfd: {
        title: "CMC Next Generation",
        description: (
          <>
            <p>
              Our Next Generation trading platform combines institutional-grade features
              and security, with lightning-fast execution and best-in-class insight and
              analysis.
            </p>

            <p>
              Trade contracts for difference (CFDs) on over 12,000+ products including FX
              Pairs, Indices, Commodities, Shares and Treasuries.
            </p>
          </>
        ),
      },
      mt4: {
        title: "MetaTrader",
        description: (
          <>
            <p>
              Competitive spreads on 200+ instruments including FX pairs, indices and
              commodities
            </p>

            <p>Fast, automated execution, with tier-one market liquidity</p>
            <p>Advanced charting tools, EA&apos;s and algorithmic trading</p>
          </>
        ),
      },
    },
  },
  "en-CA": {
    enabledProducts: ["cfd", "mt4"],
    selectPlatform: "Choose your trading platform",
    registerAccount: "Web-Based Platform (NGEN)",
    productConfig: {
      cfd: {
        title: "CMC Next Generation",
        description: (
          <>
            <p>
              Our Next Generation trading platform combines institutional-grade features
              and security, with lightning-fast execution and best-in-class insight and
              analysis.
            </p>

            <p>
              Trade contracts for difference (CFDs) on over 12,000+ products including FX
              Pairs, Indices, Commodities, Shares and Treasuries.
            </p>
          </>
        ),
        liveButtonText: "CFD account",
        demoButtonText: "Demo account",
      },
      mt4: {
        title: "MetaTrader",
        description: (
          <>
            <p>
              Competitive spreads on 200+ instruments including FX pairs, indices and
              commodities
            </p>

            <p>Fast, automated execution, with tier-one market liquidity</p>
            <p>Advanced charting tools, EA&apos;s and algorithmic trading</p>
          </>
        ),
        liveButtonText: "MT4 account",
        demoButtonText: "Demo account",
      },
    },
  },
};

// Use this function to trigger onboarding modal from anywhere in the app
export const triggerRetailOnboardingModal = (onboardingType: "") => {
  const event = new CustomEvent("RetailOnboardingModal", {
    detail: { onboardingType },
  });

  window.dispatchEvent(event);
};

const RetailOnboardingModalLayer = () => {
  return <RetailOnboardingModal />;
};

const RetailOnboardingModal = () => {
  let lang = "en";
  const [isInitialized, setIsInitialized] = useState(false);
  const [isOverlaying, setIsOverlaying] = useState(false);

  const router = useRouter();
  const { isLivePreview } = getContentfulEnvironment(router);
  const { locale } = getRouteDetails(router, isLivePreview);

  // Steps state and control
  const [step, setStep] = useState(1);
  const nextStep = useCallback(() => setStep(step + 1), [step]);
  const prevStep = useCallback(() => setStep(step - 1), [step]);

  // State for the onboarding process
  const [state, setState] = useState<{
    account: OnboardingType;
    product: OnboardingProduct;
  }>({
    // default to Demo CFD
    account: "demo", // Account type (e.g. live, demo)
    product: "cfd", // Product (e.g. cfd/nextgen, mt4)
  });

  const updateState = (key: string, value: any) =>
    setState((prev) => ({ ...prev, [key]: value }));

  const openModal = useCallback((event: Event) => {
    setIsOverlaying(true);
  }, []);

  const closeModal = () => {
    setIsOverlaying(false);
    setStep(1);
  };

  useEffect(() => {
    setIsInitialized(true);

    if (typeof window !== "undefined") {
      window.addEventListener("RetailOnboardingModal", openModal);

      return () => {
        window.removeEventListener("RetailOnboardingModal", openModal);
      };
    }
  }, [prevStep, locale, lang, openModal]);

  if (!isInitialized) {
    return <div>Loading...</div>;
  }

  const StepSwitcher = () => {
    switch (step) {
      case 1:
        return <Step_1 nextStep={nextStep} state={state} updateState={updateState} />;
      case 2:
        return <Step_2 state={state} prevStep={prevStep} closeModal={closeModal} />;
      default:
        return null;
    }
  };

  return (
    <div
      className={cn(
        "top-0 flex h-screen w-screen items-center justify-center bg-black/20 px-4 backdrop-blur-md overflow-hidden",
        isOverlaying ? "fixed z-[999999]" : "-z-10 hidden"
      )}
    >
      <div className="relative flex w-full items-center justify-center overflow-hidden">
        <div
          className={`max-h-[90dvh] ${
            step === 1 ? "pt-4" : ""
          } overflow-hidden theme-white-100 w-full max-w-4xl overflow-y-auto overflow-x-hidden rounded-md  md:relative md:max-h-none`}
        >
          <div
            className={`absolute right-[13px] z-[999] ${
              step === 1 ? "top-4" : "top-[13px]" // adjust X to cover up the X inside the iframe
            }`}
          >
            <X
              size={30}
              className="cursor-pointer transition-transform duration-500 hover:scale-115 ml-auto"
              stroke="black"
              onClick={closeModal}
            />
          </div>
          <StepSwitcher />
        </div>
      </div>
    </div>
  );
};

/**
 * Step 1
 * --------------------------------
 *  - Select onboarding type
 */
const Step_1 = ({ debug, nextStep, updateState }: any) => {
  const handleContinue = ({
    account,
    product,
  }: {
    account: OnboardingType;
    product: OnboardingProduct;
  }) => {
    updateState("account", account);
    updateState("product", product);
    nextStep();
    return;
  };

  const router = useRouter();
  const { locale } = getRouteDetails(router);
  const contentLocale = content[locale];
  if (!contentLocale) {
    return;
  }

  return (
    <div className="relative min-h-[400px] flex items-center justify-center flex-col px-6 py-8">
      {debug}
      <h2 className="text-2xl mb-8">{content[locale].selectPlatform}</h2>
      <div className="grid md:grid-cols-2 gap-4">
        {contentLocale.enabledProducts.map((product: OnboardingProduct) => (
          <Tile
            key={product}
            title={contentLocale.productConfig[product].title}
            titleSize="2xl"
            theme="white-100"
            size="sm"
            image={productImage[product]}
            className="border border-white-95 h-full"
            description={
              <>
                {contentLocale.productConfig[product].description}
                <div className="pt-4 flex flex-col space-y-2">
                  <Button
                    title={
                      contentLocale.productConfig[product].liveButtonText ||
                      "Create account"
                    }
                    type="primary"
                    onClick={() =>
                      handleContinue({
                        account: "live",
                        product: product,
                      })
                    }
                  />

                  <Button
                    title={
                      contentLocale.productConfig[product].demoButtonText ||
                      "Open demo account"
                    }
                    type="secondary"
                    variant="secondary"
                    onClick={() =>
                      handleContinue({
                        account: "demo",
                        product: product,
                      })
                    }
                  />
                </div>
              </>
            }
          />
        ))}
      </div>
    </div>
  );
};

/**
 * Step 2
 * --------------------------------
 * - Display iFrame with the onboarding modal inside it
 */
const Step_2 = ({
  state,
  prevStep,
  closeModal,
}: {
  state: {
    account: OnboardingType;
    product: OnboardingProduct;
  };
  prevStep: () => void;
  closeModal: () => void;
}) => {
  const router = useRouter();

  const onboardingService = new OnboardingService(router);
  const { onboardingUrl } = onboardingService.getOnboardingData(
    state.account as any,
    state.product as any
  );

  useEffect(() => {
    if (typeof window !== "undefined") {
      const onboardingOrigins = [
        "https://signup.cmcmarkets.com",
        "https://uat-signup.cmcmarkets.com",
      ];

      window.addEventListener("message", function (e) {
        if (onboardingOrigins.includes(e.origin)) {
          // Close the modal if close message is received
          if (e.data.closeApplicationForm === true) {
            closeModal();
          }

          // Push to dataLayer if pushToDataLayer is true & dataLayer is available
          if (e.data.pushToDataLayer && window.dataLayer) {
            window.dataLayer.push(e.data.pushToDataLayer.data);
          }

          // Redirect to a new page if url is provided
          if (e.data.url) {
            window.location.href = e.data.url;
          }
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!onboardingUrl) return;

  return (
    <div className="flex items-center justify-center grid-center-parent overflow-hidden">
      <iframe
        src={onboardingUrl}
        className="w-full h-screen md:h-[600px] 2xl:h-[740px]"
      />

      <small
        onClick={prevStep}
        className="absolute cursor-pointer left-0 bottom-0 p-2 bg-white-95 hover:bg-white-90 text-xxs w-full text-center flex justify-center items-center h-4"
      >
        <span>Restart</span>
      </small>
    </div>
  );
};

export default RetailOnboardingModalLayer;
