/**
 * This module defines the configuration for static custom pages, including the menus and footers to be used.
 *
 * Types:
 * - Menus: Defines the possible menu options.
 * - Footers: Defines the possible footer options.
 * - PageConfig: Represents the configuration for a single page, specifying the menu and footer to be used.
 * - Config: Represents the overall configuration, including default menu and footer, and specific configurations for individual pages.
 *
 * The `config` object provides the default menu and footer settings, as well as specific configurations for individual pages.
 *
 * Example:
 * The configuration for the "/en/custom-page" specifies that it should use the "Retail Main Menu" and "Retail Main Footer".
 */

export type Menus =
  | "None"
  | "Retail Main Menu"
  | "Landing Page Menu"
  | "Alcatraz Main Menu";
export type Footers = "None" | "Retail Main Footer" | "Alcatraz Main Footer";

export type PageConfig = {
  menu: Menus;
  footer: Footers;
};

export type Config = {
  retail: {
    defaultMenu: Menus;
    defaultFooter: Footers;
  };
  alcatraz: {
    defaultMenu: Menus;
    defaultFooter: Footers;
  };
  pages: {
    [key: string]: PageConfig;
  };
};

const config: Config = {
  retail: {
    defaultMenu: "Retail Main Menu",
    defaultFooter: "Retail Main Footer",
  },
  alcatraz: {
    defaultMenu: "Alcatraz Main Menu",
    defaultFooter: "Alcatraz Main Footer",
  },
  pages: {
    "/en/custom-page": {
      menu: "Retail Main Menu",
      footer: "Retail Main Footer",
    },
  },
};

export default config;
