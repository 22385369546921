// TODO: pin favourite tools (tiles on home screen)

import { Modal, Note } from "@contentful/f36-components";
import { ArrowDownWideNarrow, FileSearch, Globe, Link2, Table } from "lucide-react";
import { useEffect, useState } from "react";
import InstrumentTableBuilder from "../tools/instrument-table-builder/instrument-table-builder";
import ScrollToFinderModal from "../tools/scroll-to-finder/scroll-to-finder";
import DIDFinder from "../tools/did-finder/did-finder";
import LocaleSwitcher from "../tools/locale-switcher/locale-switcher";
import BrokenLinkChecker from "../tools/broken-link-checker/broken-link-checker";

export default function ToolsDialog({
  isShown,
  setShown,
  data,
}: {
  isShown: boolean;
  setShown: (value: boolean) => void;
  data: any;
}) {
  const IntroComponent = {
    component: <Welcome />,
  };
  const components = [
    {
      name: "DID Finder",
      component: <DIDFinder />,
      icon: <FileSearch className="h-full w-auto " height={8} width={8} />,
    },
    {
      name: "Instruments Table Builder",
      component: <InstrumentTableBuilder />,
      icon: <Table className="h-full w-auto " height={8} width={8} />,
    },
    {
      name: "Locale Switcher",
      component: <LocaleSwitcher pageSysId={data?.items?.[0].sys.id} />,
      icon: <Globe className="h-full w-auto " height={8} width={8} />,
    },
    {
      name: "Scroll to Finder",
      component: <ScrollToFinderModal />,
      icon: <ArrowDownWideNarrow className="h-full w-auto " height={8} width={8} />,
    },
    {
      name: "Broken Link Checker",
      component: <BrokenLinkChecker />,
      icon: <Link2 className="h-full w-auto " height={8} width={8} />,
    },
  ];

  const [selectedComponent, setSelectedComponent] = useState<any>(IntroComponent);

  // Lock page scrolling when modal is open
  useEffect(() => {
    if (isShown) {
      document.documentElement.style.overflow = "hidden";
      document.body.style.overflow = "hidden";
    } else {
      document.documentElement.style.overflow = "";
      document.body.style.overflow = "";
    }
  }, [isShown]);

  return (
    <Modal
      allowHeightOverflow={false}
      size="1280px"
      onClose={() => setShown(false)}
      isShown={isShown}
      className="h-screen"
    >
      {() => (
        <>
          <Modal.Header title="Toolbox" onClose={() => setShown(false)} />
          <Modal.Content>
            <div className="grid grid-cols-4">
              <div className="col-span-1 flex flex-col pr-4 gap-y-1 border-r border-r-gray-200">
                {components.map((component, index) => (
                  <button
                    key={index}
                    className={`flex gap-x-2 stroke-black text-black text-left hover:bg-gray-100 rounded-lg p-2 ${
                      selectedComponent.name === component.name ? "bg-gray-200" : ""
                    }`}
                    onClick={() => setSelectedComponent(component)}
                  >
                    {component.icon}
                    <span className="truncate">{component.name}</span>
                  </button>
                ))}
              </div>
              <div className="col-span-3 h-max pl-4">{selectedComponent.component}</div>
            </div>
          </Modal.Content>
        </>
      )}
    </Modal>
  );
}

const Welcome = () => {
  return (
    <div>
      <Note>
        Welcome to the toolbox. Please select a tool from the left to get started.
      </Note>
    </div>
  );
};
