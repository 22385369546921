import { BLCLinkCheckResult, BLCLinkType } from "@/types/broken-link-checker";
import Page from "@/utils/system/page";
import { Badge, Subheading, Table, Tooltip } from "@contentful/f36-components";
import LinkCheckerTableErrorTooltip from "./link-checker-table-error-tooltip";
import { BrokenLinkCheckerUtils } from "./link-checker-utils";

export default function LinkCheckerTable({
  filteredResults,
  setResults,
  locale,
}: {
  filteredResults: any;
  setResults: any;
  locale: any;
}) {
  // Scrolls to the anchor and opens entry in editor
  const handleViewAnchor = (result: BLCLinkCheckResult) => {
    Page.editEntry(
      result.source.parentComponent.node?.getAttribute("component-id") as string,
      locale
    );

    result.source.anchor.node.scrollIntoView({
      behavior: "smooth",
    });
    result.source.anchor.node.style.backgroundColor = "yellow";
    result.source.anchor.node.style.boxShadow = "0 0 10px yellow";
    result.source.anchor.node.style.animation = "pulse 2s infinite";

    setTimeout(() => {
      result.source.anchor.node.style.backgroundColor = "";
      result.source.anchor.node.style.boxShadow = "";
      result.source.anchor.node.style.animation = "";
    }, 10000);
  };

  // Scrolls to the component containing the anchor and opens entry in editor
  const handleViewComponent = (result: BLCLinkCheckResult) => {
    Page.editEntry(
      result.source.parentComponent.node?.getAttribute("component-id") as string,
      locale
    );

    result.source.parentComponent.node?.scrollIntoView({
      behavior: "smooth",
    });

    // If anchor is FAQ, open the accordion
    if (result.source.parentComponent.node?.classList.contains("n-accordion")) {
      const accordionTitle = result.source.parentComponent.node?.querySelector(
        ".n-accordion-title"
      ) as HTMLElement;
      accordionTitle?.click();
    }
  };

  return (
    <Table>
      <Table.Head isSticky={true}>
        <Table.Row>
          <Table.Cell
            style={{
              width: "50%",
            }}
          >
            Anchor
          </Table.Cell>
          <Table.Cell>Component</Table.Cell>
          <Table.Cell>Status</Table.Cell>
        </Table.Row>
      </Table.Head>
      <Table.Body>
        {filteredResults.map((result: BLCLinkCheckResult) => (
          <Table.Row
            key={result.id}
            style={{
              animation: "fadeIn 0.3s ease-in",
            }}
          >
            <Table.Cell>
              <div className="mb-1 font-semibold flex gap-x-2 items-start">
                <Tooltip placement="top" content="Click to scroll to anchor">
                  <Subheading
                    fontSize="fontSizeM"
                    className="cursor-pointer mb-0 hover:underline"
                    onClick={() => handleViewAnchor(result)}
                  >
                    {result.source.anchor.text || "No text"}
                  </Subheading>
                </Tooltip>
                <div className="flex gap-x-1">
                  {result.source.linkTypes.map((type: BLCLinkType) => (
                    <Tooltip
                      placement="top"
                      content={BrokenLinkCheckerUtils.LINK_TYPE_DESCRIPTIONS[type]}
                      key={type}
                    >
                      <Badge size="small" variant="secondary" key={type}>
                        <span className="capitalize">{type}</span>
                      </Badge>
                    </Tooltip>
                  ))}
                  <LinkCheckerTableErrorTooltip result={result} setResults={setResults} />
                </div>
              </div>
              <a
                target="_blank"
                href={result.url}
                className="block text-xs text-gray-500 mb-1 hover:underline"
              >
                {result.url}
              </a>
            </Table.Cell>
            <Table.Cell>
              <p className="mb-0">
                <span
                  title="Click to scroll to component"
                  onClick={() => handleViewComponent(result)}
                  className="block mb-1 cursor-pointer hover:underline"
                >
                  {result.source.parentComponent.title}
                </span>
                <span className="block text-xs text-gray-500">
                  {result.source.parentComponent.node?.getAttribute("component-name")} -{" "}
                  {result.source.parentSection.title}
                </span>
              </p>
            </Table.Cell>
            <Table.Cell>
              <Badge
                variant={
                  result.status === "Fetch error"
                    ? "warning"
                    : result.status === "Entry not found"
                    ? "negative"
                    : typeof result.status === "number"
                    ? result.status >= 400
                      ? "negative"
                      : "primary"
                    : "warning"
                }
              >
                {result.status}
              </Badge>
            </Table.Cell>
          </Table.Row>
        ))}
      </Table.Body>
    </Table>
  );
}
