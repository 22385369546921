import { BADGE_VARIANTS, highlightText } from "@/utils/internal-tools";
import { Badge, Tooltip } from "@contentful/f36-components";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import { SquareArrowOutUpRight } from "lucide-react";

export default function DIDFinderCard({ result }: { result: any }) {
  return (
    <div className="flex first:rounded-t-md last:rounded-b-md relative transition bg-white hover:bg-gray-100">
      <div className="flex w-full min-h-16 p-2 m-0 border-b border-b-gray-100 text-left">
        <div className="flex w-full relative">
          <div className="w-full space-y-2">
            <h3 className="text-base font-medium truncate">
              <span>{result.fields.internalName}</span>
              <a
                className="hover:text-gray-500"
                href={`https://app.contentful.com/spaces/${result.sys.space.sys.id}/entries/${result.sys.id}`}
                target="_blank"
                title="Open in Contentful"
              >
                {" "}
                <SquareArrowOutUpRight className="inline-block align-top h-3 w-3" />
              </a>
            </h3>
            <Tooltip placement="top" content="Click & copy">
              <span
                onClick={(e) => highlightText(e.target as HTMLElement)}
                className="text-gray-400 w-auto"
              >
                {"{{{ DID-" + result.fields.internalName + " }}}"}
              </span>
            </Tooltip>
            <div className="bg-gray-50 w-full max-h-36 overflow-y-auto p-2">
              {documentToReactComponents(result?.fields?.value)}
            </div>
          </div>
        </div>
        <div className="flex items-start pl-2 ml-auto">
          {result.status && (
            <Tooltip
              placement="top"
              content={
                result.status.state === "Error" && result.status.error
                  ? JSON.stringify(result.status.error)
                  : ""
              }
            >
              <Badge variant={BADGE_VARIANTS[result.status.state]}>
                {result.status.state}
              </Badge>
            </Tooltip>
          )}
        </div>
      </div>
    </div>
  );
}
