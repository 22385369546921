import { BLCLinkCheckResult } from "@/types/broken-link-checker";
import { pluralizeWord } from "@/utils/helpers";
import { Note, Text } from "@contentful/f36-components";

export default function LinkCheckerResultsSummary({
  results,
}: {
  results: BLCLinkCheckResult[];
}) {
  // Count of all entries which have at least 1 error
  const resultsErrorCount = results.filter((result) => result.errors.length > 0).length;

  // Amount of 404 errors
  const results404sCount = results.filter(
    (result) => Number(result.status) >= 400
  ).length;

  const noteVariant = resultsErrorCount > 0 ? "negative" : "positive";

  return (
    <Note variant={noteVariant}>
      {results.length > 0 && (
        <Text fontSize="fontSizeM" className="mb-4 mr-4">
          <span className="font-semibold">{results.length}</span>{" "}
          {pluralizeWord("link", results.length)} checked
        </Text>
      )}

      {resultsErrorCount > 0 && (
        <Text fontColor="colorNegative" fontSize="fontSizeM" className="mb-4 mr-4">
          <span className="font-semibold">{resultsErrorCount}</span>{" "}
          {pluralizeWord("error", resultsErrorCount)}
        </Text>
      )}

      {results404sCount > 0 && (
        <Text fontColor="colorNegative" fontSize="fontSizeM" className="mb-4 mr-4">
          <span className="font-semibold">{results404sCount}</span> broken{" "}
          {pluralizeWord("link", results404sCount)}
        </Text>
      )}
    </Note>
  );
}
