import Layout from "@/components/layout/layout";
import HeadlessFetcher from "@/services/fetchers/headless-fetcher";
import InternalAccessService from "@/services/internal-access-service";
import { getRouteDetails, isAlcatraz } from "@/utils/helpers";
import type { AppContext } from "next/app";
import NextApp from "next/app";
import customPagesConfig from "../custom-pages-config";
import "../styles/style.scss";
import Custom404 from "./404";

const App = ({ Component, pageProps, menu, footer, isPreview, isLiveUncached, isInternal, router, locale }: any ) => {
  /* This allows the 404 page to be rendered with the layout */
  const is404 = router?.state?.route?.includes("404") || false

  if(isPreview && !is404){
    return <Component {...pageProps} locale={locale}  />
  }

  if(isLiveUncached && !isInternal && !is404){
    console.log("[LIVE] No internal access - no page. Returning 404");
    return <Custom404 />
  }

  return (
    <Layout menu={menu} footer={footer} data={pageProps.data} >
      <Component {...pageProps} locale={locale} />
    </Layout>
  )
}

// Fetch the menu data from Contentful and pass it to the page
App.getInitialProps = async (props: AppContext) => {
  const initialProps = await NextApp.getInitialProps(props);

  const isLiveUncached = props.ctx.req?.headers["live-uncached"] === "true";
  const isInternal = props.ctx.req ? InternalAccessService.isInternal(props.ctx.req) : false
  const isLivePreview = props.ctx?.query?.live_preview === "true"

  const { locale, slug, isStaticRoute } = getRouteDetails(props.router, !isLivePreview);

  const siteType = isAlcatraz(locale) ? "alcatraz":  "retail"

  // Extract flag from middleware header
  const shouldHideMenuAndFooter =
    props.ctx.req?.headers['x-should-hide-menu-footer'] === 'true' ||
    props.router?.query?.d === 'hide';

  const isPreview = props.ctx.pathname.includes("/preview/")

  let menu;
  let footer;

  // IS REPO PAGE OR 404
  if (isStaticRoute && !isLiveUncached && !shouldHideMenuAndFooter) {
    // @ts-ignore
    const menuName = customPagesConfig.pages[props.router.pathname]?.menu || customPagesConfig[siteType]?.defaultMenu
    const menuData: any = await HeadlessFetcher.getMenuByName(menuName, locale);
    menu = menuData?.items?.[0];

    // @ts-ignore
    const footerName = customPagesConfig.pages[props.router.pathname]?.footer || customPagesConfig[siteType]?.defaultFooter
    const footerData: any = await HeadlessFetcher.getFooterByName(footerName, locale);
    footer = footerData?.items?.[0];
  }

  // IS CONTENTFUL PAGE
  else if(!shouldHideMenuAndFooter) {
    const menuData: any = await HeadlessFetcher.getMenuByPageSlug(locale, slug, "published");
    menu = menuData?.items?.[0];

    const footerData: any = await HeadlessFetcher.getFooterByPageSlug(locale, slug, "published");
    footer = footerData?.items?.[0];
  }

  else {
    menu = null;
    footer = null;
  }

  return { ...initialProps, menu, footer, isPreview, isLiveUncached, isInternal, locale };
};

export default App;
