import { ISO3166_Alpha2_Codes } from "@/types/country-codes";

export const countries_id: Record<ISO3166_Alpha2_Codes, string> = {
  AW: "Aruba",
  AF: "Afganistan",
  AO: "Angola",
  AI: "Anguila",
  AX: "Kepulauan Åland",
  AL: "Albania",
  AD: "Andorra",
  AN: "Antilla Belanda",
  AE: "Uni Emirat Arab",
  AR: "Argentina",
  AM: "Armenia",
  AS: "Samoa Amerika",
  AQ: "Antarktika",
  TF: "Wilayah Selatan Prancis",
  AG: "Antigua dan Barbuda",
  AU: "Australia",
  AT: "Austria",
  AZ: "Azerbaijan",
  BI: "Burundi",
  BE: "Belgia",
  BJ: "Benin",
  BQ: "Bonaire, Sint Eustatius, dan Saba",
  BF: "Burkina Faso",
  BD: "Bangladesh",
  BG: "Bulgaria",
  BH: "Bahrain",
  BS: "Bahama",
  BA: "Bosnia dan Herzegovina",
  BL: "Saint Barthélemy",
  BY: "Belarusia",
  BZ: "Belize",
  BM: "Bermuda",
  BO: "Bolivia (Negara Plurinasional)",
  BR: "Brasil",
  BB: "Barbados",
  BN: "Brunei Darussalam",
  BT: "Bhutan",
  BV: "Pulau Bouvet",
  BW: "Botswana",
  CF: "Republik Afrika Tengah",
  CA: "Kanada",
  CC: "Kepulauan Cocos (Keeling)",
  CH: "Swiss",
  CL: "Cile",
  CN: "Tiongkok",
  CI: "Côte d'Ivoire (Pantai Gading)",
  CM: "Kamerun",
  CD: "Kongo (Republik Demokratik)",
  CG: "Kongo",
  CK: "Kepulauan Cook",
  CO: "Kolombia",
  KM: "Komoro",
  CV: "Cabo Verde",
  CR: "Kosta Rika",
  CU: "Kuba",
  CW: "Kurasao",
  CX: "Pulau Natal",
  KY: "Kepulauan Cayman",
  CY: "Siprus",
  CZ: "Republik Cheska",
  DE: "Jerman",
  DJ: "Jibuti",
  DM: "Dominika",
  DK: "Denmark",
  DO: "Republik Dominika",
  DZ: "Aljazair",
  EC: "Ekuador",
  EG: "Mesir",
  ER: "Eritrea",
  EH: "Sahara Barat",
  ES: "Spanyol",
  EE: "Estonia",
  ET: "Etiopia",
  FI: "Finlandia",
  FJ: "Fiji",
  FK: "Kepulauan Falkland (Malvinas)",
  FR: "Prancis",
  FO: "Kepulauan Faroe",
  FM: "Mikronesia",
  GA: "Gabon",
  GB: "Britania Raya",
  GE: "Georgia",
  GG: "Guernsey",
  GH: "Ghana",
  GI: "Gibraltar",
  GN: "Guinea",
  GP: "Guadeloupe",
  GM: "Gambia",
  GW: "Guinea-Bissau",
  GQ: "Guinea Khatulistiwa",
  GR: "Yunani",
  GD: "Grenada",
  GL: "Grinlandia",
  GT: "Guatemala",
  GF: "Guyana Prancis",
  GU: "Guam",
  GY: "Guyana",
  HK: "Hong Kong",
  HM: "Pulau Heard dan Kepulauan McDonald",
  HN: "Honduras",
  HR: "Kroasia",
  HT: "Haiti",
  HU: "Hungaria",
  ID: "Indonesia",
  IM: "Pulau Man",
  IN: "India",
  IO: "Wilayah Samudra Hindia Britania",
  IE: "Irlandia",
  IR: "Iran",
  IQ: "Irak",
  IS: "Islandia",
  IL: "Israel",
  IT: "Italia",
  JM: "Jamaika",
  JE: "Jersey",
  JO: "Yordania",
  JP: "Jepang",
  KZ: "Kazakstan",
  KE: "Kenya",
  KG: "Kirgistan",
  KH: "Kamboja",
  KI: "Kiribati",
  KN: "Saint Kitts dan Nevis",
  KR: "Korea Selatan",
  KW: "Kuwait",
  LA: "Laos",
  LB: "Lebanon",
  LR: "Liberia",
  LY: "Libia",
  LC: "Santa Lusia",
  LI: "Liechtenstein",
  LK: "Sri Lanka",
  LS: "Lesotho",
  LT: "Lituania",
  LU: "Luksemburg",
  LV: "Latvia",
  MO: "Makau",
  MF: "Saint Martin",
  MA: "Maroko",
  MC: "Monako",
  MD: "Moldova (Republik)",
  MG: "Madagaskar",
  MV: "Maladewa",
  MX: "Meksiko",
  MH: "Kepulauan Marshall",
  MK: "Makedonia ",
  ML: "Mali",
  MT: "Malta",
  MM: "Myanmar",
  ME: "Montenegro",
  MN: "Mongolia",
  MP: "Kepulauan Mariana Utara",
  MZ: "Mozambik",
  MR: "Mauritania",
  MS: "Montserrat",
  MQ: "Martinik",
  MU: "Mauritius",
  MW: "Malawi",
  MY: "Malaysia",
  YT: "Mayotte",
  NA: "Namibia",
  NC: "Kaledonia Baru",
  NE: "Niger",
  NF: "Pulau Norfolk",
  NG: "Nigeria",
  NI: "Nikaragua",
  NU: "Niue",
  NL: "Belanda",
  NO: "Norwegia",
  NP: "Nepal",
  NR: "Nauru",
  NZ: "Selandia Baru",
  OM: "Oman",
  PK: "Pakistan",
  PA: "Panama",
  PN: "Pitcairn",
  PE: "Peru",
  PH: "Filipina",
  PW: "Palau",
  PG: "Papua Nugini",
  PL: "Polandia",
  PR: "Puerto Riko",
  KP: "Korea Utara",
  PT: "Portugal",
  PY: "Paraguay",
  PS: "Palestina, Negara",
  PF: "Polinesia Prancis",
  QA: "Qatar",
  RE: "Réunion",
  RO: "Rumania",
  RU: "Rusia",
  RW: "Rwanda",
  SA: "Arab Saudi",
  SD: "Sudan",
  SN: "Senegal",
  SG: "Singapura",
  GS: "Georgia Selatan dan Kepulauan Sandwich Selatan",
  SH: "Saint Helena",
  SJ: "Svalbard dan Jan Mayen",
  SB: "Kepulauan Solomon",
  SL: "Sierra Leone",
  SV: "El Salvador",
  SM: "San Marino",
  SO: "Somalia",
  PM: "Saint Pierre dan Miquelon",
  RS: "Serbia",
  SS: "Sudan Selatan",
  ST: "Sao Tome dan Principe",
  SR: "Suriname",
  SK: "Slovakia",
  SI: "Slovenia",
  SE: "Swedia",
  SZ: "Eswatini",
  SX: "Sint Maarten (bagian Belanda)",
  SC: "Seychelles",
  SY: "Republik Arab Suriah",
  TC: "Kepulauan Turks dan Caicos",
  TD: "Chad",
  TG: "Togo",
  TH: "Thailand",
  TJ: "Tajikistan",
  TK: "Tokelau",
  TM: "Turkmenistan",
  TL: "Timor-Leste",
  TO: "Tonga",
  TT: "Trinidad dan Tobago",
  TN: "Tunisia",
  TR: "Turki",
  TV: "Tuvalu",
  TW: "Taiwan",
  TZ: "Tanzania, Republik Bersatu",
  UG: "Uganda",
  UA: "Ukraina",
  UM: "Kepulauan Terluar Kecil (Amerika Serikat)",
  UY: "Uruguay",
  US: "Amerika Serikat",
  UZ: "Uzbekistan",
  VA: "Vatikan",
  VC: "Saint Vincent dan Grenadines",
  VE: "Venezuela",
  VG: "Kepulauan Virgin (Britania)",
  VI: "Kepulauan Virgin (AS)",
  VN: "Vietnam",
  VU: "Vanuatu",
  WF: "Wallis dan Futuna",
  WS: "Samoa",
  XK: "Kosovo",
  YE: "Yaman",
  ZA: "Afrika Selatan",
  ZM: "Zambia",
  ZW: "Zimbabwe",
};
