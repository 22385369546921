import { ISO3166_Alpha2_Codes } from "@/types/country-codes";

export const countries_hi: Record<ISO3166_Alpha2_Codes, string> = {
  AW: "अरूबा",
  AF: "अफ़ग़ानिस्तान",
  AO: "अंगोला",
  AI: "अंग्विला",
  AX: "आलैंड द्वीप समूह",
  AL: "अल्बानिया",
  AD: "अंडोरा",
  AN: "नीदरलैंड्स एंटिलीस",
  AE: "संयुक्त अरब अमीरात",
  AR: "अर्जेंटीना",
  AM: "आर्मेनिया",
  AS: "अमेरिकन समोआ",
  AQ: "अंटार्कटिका",
  TF: "दक्षिणी फ्रांसिसी क्षेत्र",
  AG: "एंटीगुआ और बारबूडा",
  AU: "ऑस्ट्रेलिया",
  AT: "ऑस्ट्रिया",
  AZ: "आज़रबाइजान",
  BI: "बुरुंडी",
  BE: "बेल्जियम",
  BJ: "बेनिन",
  BQ: "बोनेयर, सिंट आस्टाटियस और सबा",
  BF: "बुर्किना फासो",
  BD: "बांग्लादेश",
  BG: "बुल्गारिया",
  BH: "बहरीन",
  BS: "बहामास",
  BA: "बोस्निया और हर्जेगोविना",
  BL: "सेंट बार्थेलेमी",
  BY: "बेलारूस",
  BZ: "बेलीज़",
  BM: "बरमूडा",
  BO: "बोलीविया (बहुराष्ट्रीय राज्य)",
  BR: "ब्राज़ील",
  BB: "बारबाडोस",
  BN: "ब्रूनेई दारएस्सलाम",
  BT: "भूटान",
  BV: "बोवेट द्वीप",
  BW: "बोत्सवाना",
  CF: "केंद्रीय अफ्रीकी गणराज्य",
  CA: "कनाडा",
  CC: "कोकोस (कीलिंग) द्वीप समूह",
  CH: "स्विट्ज़रलैंड",
  CL: "चिली",
  CN: "चीन",
  CI: "आइवरी कोस्ट",
  CM: "कैमरून",
  CD: "कांगो (लोकतांत्रिक गणराज्य)",
  CG: "कांगो",
  CK: "कुक द्वीपसमूह",
  CO: "कोलंबिया",
  KM: "कोमोरोस",
  CV: "काबो वेर्दे",
  CR: "कोस्टा रिका",
  CU: "क्यूबा",
  CW: "कुराकाओ",
  CX: "क्रिसमस द्वीप",
  KY: "केमैन द्वीप",
  CY: "साइप्रस",
  CZ: "चेक गणतंत्र",
  DE: "जर्मनी",
  DJ: "जिबूती",
  DM: "डोमिनिका",
  DK: "डेनमार्क",
  DO: "डोमिनिकन गणराज्य",
  DZ: "अल्जीरिया",
  EC: "इक्वाडोर",
  EG: "मिस्र",
  ER: "एरिट्रिया",
  EH: "पश्चिमी सहारा",
  ES: "स्पेन",
  EE: "एस्तोनिया",
  ET: "इथियोपिया",
  FI: "फिनलैंड",
  FJ: "फ़िजी",
  FK: "फ़ॉकलैंड द्वीप (माल्विनास)",
  FR: "फ्रांस",
  FO: "फ़ैरो द्वीप",
  FM: "माइक्रोनेशिया",
  GA: "गाबों",
  GB: "यूनाइटेड किंगडम",
  GE: "जॉर्जिया",
  GG: "गर्नसी",
  GH: "घाना",
  GI: "जिब्राल्टर",
  GN: "गिनी",
  GP: "ग्वादलूप",
  GM: "गैम्बिया",
  GW: "गिनी-बिसाऊ",
  GQ: "इक्वेटोरियल गिनी",
  GR: "यूनान",
  GD: "ग्रेनेडा",
  GL: "ग्रीनलैंड",
  GT: "ग्वाटेमाला",
  GF: "फ्रेंच गयाना",
  GU: "ग्वाम",
  GY: "गुयाना",
  HK: "हांगकांग",
  HM: "हर्ड आइलैंड और मैकडोनाल्ड आइलैंड्स",
  HN: "होंडुरस",
  HR: "क्रोएशिया",
  HT: "हैती",
  HU: "हंगरी",
  ID: "इंडोनेशिया",
  IM: "आइल ऑफ़ मैन",
  IN: "भारत",
  IO: "ब्रिटेन और भारतीय समुद्री क्षेत्र",
  IE: "आयरलैंड",
  IR: "ईरान",
  IQ: "इराक",
  IS: "आइसलैंड",
  IL: "इजराइल",
  IT: "इटली",
  JM: "जमैका",
  JE: "जर्सी",
  JO: "जॉर्डन",
  JP: "जापान",
  KZ: "कजाखस्तान",
  KE: "केन्या",
  KG: "किर्गिज़स्तान",
  KH: "कंबोडिया",
  KI: "किरिबाती",
  KN: "संत किट्स और नेविस",
  KR: "दक्षिण कोरिया",
  KW: "कुवैत",
  LA: "लाओस",
  LB: "लेबनान",
  LR: "लाइबेरिया",
  LY: "लीबिया",
  LC: "सेंट लूसिया",
  LI: "लिकटेंस्टाइन",
  LK: "श्रीलंका",
  LS: "लेजोतो",
  LT: "लिथुआनिया",
  LU: "लक्समबर्ग",
  LV: "लातविया",
  MO: "मकाओ",
  MF: "सेंट मार्टिन",
  MA: "मोरक्को",
  MC: "मोनाको",
  MD: "मोल्दोवा (गणतंत्र)",
  MG: "मेडागास्कर",
  MV: "मालदीव",
  MX: "मेक्सिको",
  MH: "मार्शल द्वीपसमूह",
  MK: "मैसेडोनिया ",
  ML: "माली",
  MT: "माल्टा",
  MM: "म्यांमार",
  ME: "मोंटेनेग्रो",
  MN: "मंगोलिया",
  MP: "उत्तरी मरीयाना द्वीप समूह",
  MZ: "मोजाम्बिक",
  MR: "मॉरिटानिया",
  MS: "मोंसेरेत",
  MQ: "मार्टीनीक",
  MU: "मॉरीशस",
  MW: "मलावी",
  MY: "मलेशिया",
  YT: "मायोत",
  NA: "नामीबिया",
  NC: "न्यू कैलडोनिया",
  NE: "नाइजर",
  NF: "नॉरफ़ॉक आइलैंड",
  NG: "नाइजीरिया",
  NI: "निकारागुआ",
  NU: "नियूए",
  NL: "नीदरलैंड",
  NO: "नॉर्वे",
  NP: "नेपाल",
  NR: "नाउरू",
  NZ: "न्यूज़ीलैंड",
  OM: "ओमान",
  PK: "पाकिस्तान",
  PA: "पनामा",
  PN: "पिटकेर्न",
  PE: "पेरू",
  PH: "फिलीपींस",
  PW: "पलाउ",
  PG: "पापुआ न्यू गिनी",
  PL: "पोलैंड",
  PR: "प्यूर्टो रिको",
  KP: "उत्तर कोरिया",
  PT: "पुर्तगाल",
  PY: "पैराग्वे",
  PS: "फिलिस्तीनी राज्य",
  PF: "फ़्रेंच पोलिनेशिया",
  QA: "कतर",
  RE: "रियूनियन",
  RO: "रोमानिया",
  RU: "रूस",
  RW: "रवांडा",
  SA: "सऊदी अरब",
  SD: "सूडान",
  SN: "सेनेगल",
  SG: "सिंगापुर",
  GS: "साउथ जॉर्जिया और साउथ सैंडविच द्वीप समूह",
  SH: "सेंट हेलेना",
  SJ: "स्वालबार्ड और यान मायेन",
  SB: "सोलोमन द्वीपसमूह",
  SL: "सियेरा लिओन",
  SV: "एल साल्वाडोर",
  SM: "सैन मैरीनो",
  SO: "सोमालिया",
  PM: "सेंट पियरे एंड मिकेलन",
  RS: "सर्बिया",
  SS: "दक्षिण सूडान",
  ST: "साओ टोम और प्रिंसिपे",
  SR: "सूरीनाम",
  SK: "स्लोवाकिया",
  SI: "स्लोवेनिया",
  SE: "स्वीडन",
  SZ: "स्वाजीलैंड",
  SX: "सिंट मार्टेन (डच भाग)",
  SC: "सेशल्स",
  SY: "सीरियाई अरब गणराज्य",
  TC: "तुर्क और कैकोस द्वीप समूह",
  TD: "चाड",
  TG: "टोगो",
  TH: "थाईलैंड",
  TJ: "तजाकिस्तान",
  TK: "टोकेलाऊ",
  TM: "तुर्कमेनिस्तान",
  TL: "तिमोर-लेस्ते",
  TO: "टोंगा",
  TT: "त्रिनिदाद और टोबैगो",
  TN: "ट्यूनीशिया",
  TR: "तुर्की",
  TV: "तुवालू",
  TW: "ताइवान",
  TZ: "संयुक्त गणराज्य तंजानिया",
  UG: "युगांडा",
  UA: "यूक्रेन",
  UM: "माइनर आउटलाइंग आईलैंड (यूनाइटेड स्टेट्स)",
  UY: "उरुग्वे",
  US: "संयुक्त राज्य अमेरिका",
  UZ: "उज़्बेकिस्तान",
  VA: "वेटिकन",
  VC: "सेंट विंसेंट और ग्रेनेडाइंस",
  VE: "वेनेजुएला",
  VG: "वर्जिन आइलैंड्स (ब्रिटिश)",
  VI: "वर्जिन आइलैंड्स (यूएस)",
  VN: "वियतनाम",
  VU: "वानुअतु",
  WF: "वाली और फ़्युटुना",
  WS: "समोआ",
  XK: "कोसोवो",
  YE: "यमन",
  ZA: "दक्षिण अफ्रीका",
  ZM: "जाम्बिया",
  ZW: "जिम्बाब्वे",
};
