// ? Detects when an element is in view. Requires a single id string or an array of id strings.

import { useEffect, useState } from "react";

// Takes a single id string or an array of id strings and returns the one that is currently in view
const useInView = (ids: string | string[]) => {
  const [currentInViewId, setCurrentInViewId] = useState<string | null>(null);

  // Convert ids to an array if it's a string

  useEffect(() => {
    const idsArray = Array.isArray(ids) ? ids : [ids];

    const observers = idsArray
      .map((id) => {
        const element = document.getElementById(id);

        if (!element) {
          console.warn(`Element with id "${id}" not found`);
          return null;
        }

        const observer = new IntersectionObserver(([entry]) => {
          if (entry.isIntersecting) {
            setCurrentInViewId(id);
          }
        });

        observer.observe(element);

        // Return observer for cleanup
        return observer;
      })
      .filter(Boolean); // Filter out null values

    // Clean up on unmount
    return () => {
      observers.forEach((observer) => observer?.disconnect());
    };
  }, [ids]);

  return currentInViewId;
};

export default useInView;
