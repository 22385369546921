import { isLocal } from "@/constants";
import {
  BLCAnchorSource,
  BLCCustomWindow,
  BLCLinkCheckResult,
} from "@/types/broken-link-checker";
import { getRouteDetails } from "@/utils/helpers";
import { Button, Checkbox, Note, Text } from "@contentful/f36-components";
import { useRouter } from "next/router";
import { useEffect, useMemo, useState } from "react";
import LinkCheckerResultsSummary from "./link-checker-results-summary";
import LinkCheckerTable from "./link-checker-table";
import { BrokenLinkCheckerUtils } from "./link-checker-utils";

export default function BrokenLinkChecker() {
  const router = useRouter();
  const { locale } = getRouteDetails(router);

  // State management
  const [anchors, setAnchors] = useState<BLCAnchorSource[]>([]);
  const [externalAnchors, setExternalAnchors] = useState<BLCAnchorSource[]>([]);
  const [brokenEntries, setBrokenEntries] = useState<HTMLSpanElement[]>([]);
  const [results, setResults] = useState<BLCLinkCheckResult[]>([]);
  const [filters, setFilters] = useState({
    onlyShowErrors: false,
    onlyShowInternalNotUsingMetadata: false,
    excludeFetchErrors: false,
  });
  const [isChecking, setIsChecking] = useState(false);
  const [isProcessingExternal, setIsProcessingExternal] = useState(false);
  const [progress, setProgress] = useState({
    checked: 0,
    total: 0,
    external: 0,
    externalTotal: 0,
  });

  // Filter results based on user selection
  const filteredResults = useMemo(() => {
    if (!results.length) return [];

    let filtered = [...results];

    if (filters.onlyShowErrors) {
      filtered = filtered.filter((result) => result?.errors?.length > 0);
    }

    if (filters.onlyShowInternalNotUsingMetadata) {
      filtered = filtered.filter((result) =>
        result?.errors?.some((error) => error.type === "internal_link_not_metadata")
      );
    }

    if (filters.excludeFetchErrors) {
      filtered = filtered.filter((result) => result.status !== "Fetch error");
    }

    return filtered;
  }, [results, filters]);

  // * Initialize anchors and broken entries from the DOM
  const initialiseApp = () => {
    if (typeof window === "undefined") return;

    // Collect and process anchors
    const anchorElements = document.querySelectorAll(
      "section a"
    ) as NodeListOf<HTMLAnchorElement>;

    // Remove invalid anchors (tel, mailto, email)
    const validAnchors = Array.from(anchorElements).filter(
      (anchor) =>
        !anchor.href.startsWith("tel:") &&
        !anchor.href.startsWith("mailto:") &&
        !anchor.href.includes("@")
    );

    const internalAnchors: BLCAnchorSource[] = [];
    const externalAnchors: BLCAnchorSource[] = [];

    // Map anchors and place into internal or external url arrays
    validAnchors.forEach((anchor: HTMLAnchorElement) => {
      const parentSection = BrokenLinkCheckerUtils.findParentSection(anchor);
      const parentComponent = BrokenLinkCheckerUtils.findParentComponent(anchor);
      const isExternalLink = BrokenLinkCheckerUtils.isExternalUrl(anchor.href);
      const url = BrokenLinkCheckerUtils.normalizeUrl(anchor.href);

      const anchorSource: BLCAnchorSource = {
        linkTypes: BrokenLinkCheckerUtils.getLinkTypes(anchor),
        anchor: {
          node: anchor,
          url,
          text: anchor.textContent || "",
          type: anchor.dataset.anchorType as "entry" | "url",
          isExternal: isExternalLink,
        },
        parentSection,
        parentComponent,
      };

      if (isExternalLink) {
        externalAnchors.push(anchorSource);
      } else {
        internalAnchors.push(anchorSource);
      }
    });

    setAnchors(internalAnchors);
    setExternalAnchors(externalAnchors);

    // Additionally, collect broken entries (spans with data-anchor-entry-not-found="true")
    const brokenEntryElements = document.querySelectorAll(
      '[data-anchor-entry-not-found="true"]'
    ) as NodeListOf<HTMLSpanElement>;

    setBrokenEntries(Array.from(brokenEntryElements));
  };

  // Create result entries for broken entries (spans) (e.g. No page with this anchor found error)
  const processBrokenEntries = () => {
    if (!brokenEntries.length) return [];

    return brokenEntries.map((entry): BLCLinkCheckResult => {
      const parentSection = BrokenLinkCheckerUtils.findParentSection(entry);
      const parentComponent = BrokenLinkCheckerUtils.findParentComponent(entry);

      return {
        id: BrokenLinkCheckerUtils.generateId(),
        timestamp: BrokenLinkCheckerUtils.createTimestamp(),
        source: {
          linkTypes: [],
          anchor: {
            node: entry,
            text: "Entry not found for this locale",
            type: "entry",
            isSpan: true,
          },
          parentSection,
          parentComponent,
        },
        status: "Entry not found",
        url: "",
        errorTooltipOpen: false,
        errors: [
          {
            type: "entry_not_found",
            message: "Entry not found",
          },
        ],
      };
    });
  };

  // Check a single URL and return result
  const checkUrl = async (source: BLCAnchorSource): Promise<BLCLinkCheckResult> => {
    const errors: Array<{ type: string; message: string }> = [];

    // Early return for span elements (they don't have href)
    if (source.anchor.isSpan || !(source.anchor.node instanceof HTMLAnchorElement)) {
      return {
        id: BrokenLinkCheckerUtils.generateId(),
        timestamp: BrokenLinkCheckerUtils.createTimestamp(),
        source,
        status: "Entry not found",
        url: "",
        errorTooltipOpen: false,
        errors: [
          {
            type: "entry_not_found",
            message: "Entry not found",
          },
        ],
      };
    }

    const anchorNode = source.anchor.node;
    let url = source.anchor.url as string; // url should always be defined when not a span

    // Check if internal link is using NOT using nPageMetadata
    if (BrokenLinkCheckerUtils.getIsCMCUrlAnchor(anchorNode)) {
      errors.push({
        type: "internal_link_not_metadata",
        message:
          "Internal page using a URL instead of a linked entry. Please use a linked entry instead.",
      });
    }

    try {
      const response = await fetch(anchorNode.href, { method: "HEAD" });

      // Add error for HTTP status errors
      if (response.status >= 400) {
        errors.push({
          type: "page_not_found",
          message: `Page fails to load with status code: ${response.status}`,
        });
      }

      return {
        id: BrokenLinkCheckerUtils.generateId(),
        timestamp: BrokenLinkCheckerUtils.createTimestamp(),
        source,
        url,
        status: response.status,
        errorTooltipOpen: false,
        errors,
      };
    } catch (error) {
      console.error("Error checking link:", { source, error });

      return {
        id: BrokenLinkCheckerUtils.generateId(),
        timestamp: BrokenLinkCheckerUtils.createTimestamp(),
        source,
        status: "Fetch error",
        url,
        errorTooltipOpen: false,
        errors,
      };
    }
  };

  // Process internal URLs with concurrency limit
  const processUrlBatch = async (urls: BLCAnchorSource[], limit: number) => {
    const pending = [...urls];

    while (pending.length > 0) {
      const batch = pending.splice(0, limit);
      const checkPromises = batch.map(async (urlSource) => {
        const result = await checkUrl(urlSource);

        setResults((prev) => {
          return [...prev, result].sort((a, b) => b.timestamp.localeCompare(a.timestamp));
        });

        setProgress((prev) => ({
          ...prev,
          checked: prev.checked + 1,
        }));

        return result;
      });

      await Promise.all(checkPromises);
    }
  };

  // Process external URLs using the API endpoint
  const processExternalUrls = async (externalSources: BLCAnchorSource[]) => {
    if (!externalSources.length) return;

    setIsProcessingExternal(true);
    setProgress((prev) => ({
      ...prev,
      externalTotal: externalSources.length,
    }));

    try {
      // Track which source each URL belongs to using a Map
      const sourceIdToSourceMap = new Map<string, BLCAnchorSource>();

      // Prepare URL objects for API with a unique ID for each source
      const urls = externalSources.map((source, index) => {
        // Generate a truly unique ID for this specific source
        const sourceId = `src_${index}_${BrokenLinkCheckerUtils.generateId()}`;

        // Store the mapping from this ID to the source
        sourceIdToSourceMap.set(sourceId, source);

        return {
          id: BrokenLinkCheckerUtils.generateId(), // API result ID
          url: source.anchor.url,
          sourceId, // Our reference to track which source this belongs to
        };
      });

      // Call the API endpoint
      const response = await fetch("/api/neptune/internal/broken-link-checker", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          auth: process.env.APP_INTERNAL_API_KEY,
        },
        body: JSON.stringify({ urls }),
      });

      if (!response.ok) {
        throw new Error(`API responded with status: ${response.status}`);
      }

      const data = await response.json();
      const apiResults = data.results;

      // Map API results back to BLCLinkCheckResult format
      const externalResults = apiResults
        .map((apiResult: any) => {
          // Find the matching URL object from our original request
          const matchingUrl = urls.find((u) => u.id === apiResult.id);

          if (!matchingUrl) {
            console.error("No matching URL found for API result:", apiResult);
            return null;
          }

          // Use the sourceId to look up the original source
          const source = sourceIdToSourceMap.get(matchingUrl.sourceId);

          if (!source) {
            console.error("No source found for sourceId:", matchingUrl.sourceId);
            return null;
          }

          const errors: Array<{ type: string; message: string }> = [];

          // Add error for HTTP status errors
          if (typeof apiResult.status === "number" && apiResult.status >= 400) {
            errors.push({
              type: "page_not_found",
              message: `Page fails to load with status code: ${apiResult.status}`,
            });
          } else if (apiResult.status === "error" || apiResult.error) {
            errors.push({
              type: "fetch_error",
              message: apiResult.error || "Failed to fetch external URL",
            });
          }

          setProgress((prev) => ({
            ...prev,
            external: prev.external + 1,
          }));

          return {
            id: apiResult.id,
            timestamp: BrokenLinkCheckerUtils.createTimestamp(),
            source,
            url: apiResult.url,
            status: apiResult.status,
            errorTooltipOpen: false,
            errors,
          };
        })
        .filter(Boolean) as BLCLinkCheckResult[];

      // Add external results to results
      setResults((prev) => {
        return [...prev, ...externalResults].sort((a, b) =>
          b.timestamp.localeCompare(a.timestamp)
        );
      });
    } catch (error) {
      console.error("Error processing external URLs:", error);

      // Create error results for all external URLs
      const errorResults = externalSources.map((source): BLCLinkCheckResult => {
        return {
          id: BrokenLinkCheckerUtils.generateId(),
          timestamp: BrokenLinkCheckerUtils.createTimestamp(),
          source,
          url: (source.anchor.node as HTMLAnchorElement).href,
          status: "API Error",
          errorTooltipOpen: false,
          errors: [
            {
              type: "api_error",
              message:
                error instanceof Error
                  ? error.message
                  : "API error processing external URLs",
            },
          ],
        };
      });

      setResults((prev) => {
        return [...prev, ...errorResults].sort((a, b) =>
          b.timestamp.localeCompare(a.timestamp)
        );
      });

      setProgress((prev) => ({
        ...prev,
        external: externalSources.length,
      }));
    } finally {
      setIsProcessingExternal(false);
    }
  };

  // * Main function to run the link checker
  const runChecker = async () => {
    // Exit early if no elements to check
    if (!anchors.length && !brokenEntries.length && !externalAnchors.length) return;

    setIsChecking(true);
    setIsProcessingExternal(false);
    setResults([]);
    setProgress({
      checked: 0,
      total: anchors.length,
      external: 0,
      externalTotal: externalAnchors.length,
    });

    // First process broken entries (spans)
    const brokenResults = processBrokenEntries();
    if (brokenResults.length) {
      setResults(brokenResults);
    }

    // Process internal links
    if (anchors.length) {
      try {
        // Process URLs concurrently with a limit of 5 at a time
        await processUrlBatch(anchors, 5);
      } catch (error) {
        console.error("Error checking internal links:", error);
      }
    }

    // Process external links
    if (externalAnchors.length) {
      processExternalUrls(externalAnchors);
    } else {
      setIsChecking(false);
    }
  };

  // Toggler for isChecking state
  useEffect(() => {
    if (
      progress.checked === progress.total &&
      progress.external === progress.externalTotal
    ) {
      setIsChecking(false);
    }
  }, [progress]);

  // Persist results to window for later retrieval
  const storeResultsInWindow = () => {
    if (typeof window === "undefined" || !results.length) return;

    const customWindow = window as BLCCustomWindow;
    customWindow.brokenLinksResults = results;
  };

  // Load results from window if available
  const loadResultsFromWindow = () => {
    if (typeof window === "undefined") return;

    const customWindow = window as BLCCustomWindow;
    if (customWindow.brokenLinksResults?.length) {
      setResults(customWindow.brokenLinksResults);
    }
  };

  // Toggle filter state
  const toggleFilter = (filterName: keyof typeof filters) => {
    setFilters((prev) => ({
      ...prev,
      [filterName]: !prev[filterName],
    }));
  };

  // Initialize on component mount
  useEffect(() => {
    loadResultsFromWindow();

    // Only initialize elements if no results loaded from window
    if (!results.length) {
      initialiseApp();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Store results when they change
  useEffect(() => {
    storeResultsInWindow();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [results]);

  // Calculate if "Run Checker" button should be disabled
  const isRunButtonDisabled = useMemo(() => {
    return (
      isChecking || (!anchors.length && !brokenEntries.length && !externalAnchors.length)
    );
  }, [isChecking, anchors.length, brokenEntries.length, externalAnchors.length]);

  return (
    <div>
      {/* Controls */}
      <div className="mb-4 flex gap-x-4 items-center flex-wrap">
        <Button
          onClick={runChecker}
          variant="positive"
          isLoading={isChecking}
          isDisabled={isRunButtonDisabled}
        >
          {isChecking
            ? `Checking Links (${progress.checked + progress.external}/${
                progress.total + progress.externalTotal
              })`
            : "Run Checker"}
        </Button>

        <Checkbox
          isChecked={filters.onlyShowErrors}
          onChange={() => toggleFilter("onlyShowErrors")}
        >
          Only show errors
        </Checkbox>

        <Checkbox
          isChecked={filters.onlyShowInternalNotUsingMetadata}
          onChange={() => toggleFilter("onlyShowInternalNotUsingMetadata")}
        >
          Only show internal links not using metadata
        </Checkbox>

        <Checkbox
          isChecked={filters.excludeFetchErrors}
          onChange={() => toggleFilter("excludeFetchErrors")}
        >
          Exclude fetch errors
        </Checkbox>
      </div>

      {/* Note when page has no anchors to scan */}
      {anchors.length === 0 && (
        <Note variant="primary">
          <Text fontWeight="fontWeightMedium">No anchors detected in page content.</Text>
        </Note>
      )}

      {/* Status message for external links */}
      {isProcessingExternal && (
        <div className="mb-4 p-3 bg-blue-50 border border-blue-200 rounded text-blue-700">
          <Text fontWeight="fontWeightMedium">
            Processing external links ({progress.external}/{progress.externalTotal})...
            External links are being checked via API and may take longer to complete.
          </Text>
        </div>
      )}

      {/* Results */}
      {results.length > 0 && (
        <>
          {/* Summary */}
          <LinkCheckerResultsSummary results={results} />

          {/* Table */}
          <div className="mt-4 mb-4 max-h-[500px] overflow-y-auto">
            <LinkCheckerTable
              filteredResults={filteredResults}
              setResults={setResults}
              locale={locale}
            />
          </div>
        </>
      )}

      {/* Animation styles */}
      <style>
        {`
          @keyframes fadeIn {
            from { opacity: 0; transform: translateY(-10px); }
            to { opacity: 1; transform: translateY(0); }
          }
        `}
      </style>
    </div>
  );
}
