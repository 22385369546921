"use client";
import { getContentfulEnvironment, getCookie, getRouteDetails } from "@/utils/helpers";
import { X } from "lucide-react";
import { useRouter } from "next/router";
import { useEffect, useState } from "react";
import Button from "../core/atoms/button";

const excludedUrls = [
  "/universal/privacy-policy",
  "/universal/cookie-policy",
  "/universal/contact-us",
  "/universal/platform/mobile-trading",
];

const GeoRestrictionModal = () => {
  const [show, setShow] = useState(false);
  const [isInternalCMCUser, setIsInternalCMCUser] = useState(false);
  const router = useRouter();
  const { locale } = getRouteDetails(router);
  const { isLive } = getContentfulEnvironment(router);

  useEffect(() => {
    const regionCookie = getCookie("cncode");
    if (regionCookie === "GB") {
      setShow(true);
    }

    // Check if the user is an internal CMC user
    setIsInternalCMCUser(getCookie("gtm_container") === "cmc");

    // If user presses esc key, close the modal
    const handleKeyPress = (e: KeyboardEvent) => {
      if (e.key === "Escape") {
        setShow(false);
      }
    };

    window.addEventListener("keydown", handleKeyPress);
  }, []);

  useEffect(() => {
    // If environment is not live, don't display modal
    if (locale === "en-GB") return;
    if (!isLive) return;

    if (show) {
      document.body.style.overflow = "hidden";
      document.body.style.touchAction = "none";
    } else {
      document.body.style.overflow = "";
      document.body.style.touchAction = "";
    }
  }, [show, isInternalCMCUser, isLive, locale]);

  // If locale is en-GB or environment is not live, don't display modal
  // If environment is not live, don't display modal
  // If the current page is in the excluded list, don't display modal
  if (locale === "en-GB") return <></>;
  if (!isLive) return <></>;
  if (excludedUrls.some((url) => router.pathname.includes(url))) return <></>;
  if (!show) return <></>;

  return (
    <div
      className="fixed top-0 left-0 w-full h-full bg-white/50 px-6 items-center justify-center backdrop-blur-sm z-[999] transition-opacity flex"
      dir="ltr"
    >
      <div className="theme-navy-100 text-white rounded-2xl px-14 py-16 relative max-w-4xl w-full shadow-lg text-center">
        <button
          type="button"
          onClick={() => setShow(false)}
          className={`w-8 h-8 absolute stroke-white top-6 right-6 cursor-pointer bg-none border-none ${
            isInternalCMCUser && "block"
          }`}
        >
          <X className="border-2 rounded-full stroke-[4px] p-1.5 w-7 h-7" />
        </button>
        <p className="text-white font-heading font-semibold text-3xl leading-normal mb-6 text-center">
          Hello, we noticed that you’re in the UK.
        </p>
        <p className="max-w-xl mt-0 mx-auto mb-12 text-xl leading-normal">
          The content on this page is not intended for UK customers and you will not be
          able to open an account.
        </p>
        <div className="flex justify-center">
          <Button
            title="Go to UK site"
            variant="primary"
            url="https://www.cmcmarkets.com/en-gb"
          ></Button>
        </div>
      </div>
    </div>
  );
};

export default GeoRestrictionModal;
