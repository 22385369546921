import { isLocal } from "@/constants";
import { getContentfulEnvironment, getRouteDetails } from "@/utils/helpers";
import NextLink from "next/link";
import { useRouter } from "next/router";
import { ComponentProps, FC } from "react";

export interface ILinkProps extends ComponentProps<typeof NextLink> {}

// TODO: Definitely sort out the environments!!!

const isExternal = (href: string) => {
  if (!href) return false;

  if (href.startsWith("http")) return true;
  if (href.startsWith("mailto:")) return true;
  if (href.startsWith("tel:")) return true;

  try {
    const url = new URL(href as string);
    return url.hostname !== window.location.hostname;
  } catch (e) {
    // If URL constructor fails, it's likely a relative URL
    return false;
  }
};

export const getURL = (
  env: "live" | "live-uncached" | "final" | "preview" | "live-preview",
  pathname: string,
  locale: string
) => {
  if (!pathname) return "#";

  if (isExternal(pathname)) return pathname;

  // Previous regex - 25/02/2025
  // const localeRegex = /(?<=\/|^)[a-zA-Z]{2}(?:-[a-zA-Z]{2})?(?=\/|$)/g;
  const localeRegex = /(^|\/)[a-zA-Z]{2}(?:-[a-zA-Z]{2})?(?=\/|$)/g;

  // New regex addition - 25/02/2025
  if (pathname.match(localeRegex)) {
    pathname = pathname.replace(localeRegex, "");
    // Remove any double slashes that might result
    pathname = pathname.replace("//", "/");
  }

  // Prevent a forward slash from getting added to same-page links
  if (!pathname.startsWith("/") && !pathname.startsWith("#")) pathname = "/" + pathname;

  if (pathname.startsWith("//")) pathname = pathname.replace("//", "/");

  if (pathname.endsWith("/")) pathname = pathname.slice(0, -1);

  if (env === "live-preview") {
    return "/preview" + `/${locale}` + pathname + `?live_preview=true`;
  }

  /* Preview and Final */
  if (env === "preview") {
    return `/preview/${locale}` + pathname;
  }

  /* Live-Uncached */
  // if (env === "live-uncached") {
  //   return `/live/${locale}` + pathname;
  // }

  /* Live-Cached */
  if (env === "live") {
    return `/${locale}` + pathname;
  }

  if (typeof pathname === "string") {
    return `/${locale}` + pathname;
  }

  return `/${locale}` + pathname;
};

const Link: FC<ILinkProps> = ({ children, href, ...props }) => {
  const router = useRouter();

  const { env } = getContentfulEnvironment(router);

  const { locale } = getRouteDetails(router);

  let url = getURL(env, href as string, locale.toLowerCase());
  url = encodeURI(url); // Prevent hydration errors and issues with special characters

  if (isExternal(href as string)) {
    return (
      <a {...props} href={url} target={"_blank"} rel="noopener noreferrer">
        {children}
      </a>
    );
  }

  return (
    <NextLink {...props} href={url}>
      {children}
    </NextLink>
  );
};

export default Link;
