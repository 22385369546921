import { useWindowSize } from "@/hooks/useWindowSize";
import {
  cn,
  getContentfulEnvironment,
  getLanguageFromLocale,
  getRouteDetails,
} from "@/utils/helpers";
import { Languages, X } from "lucide-react";
import { useRouter } from "next/router";
import { useEffect, useState } from "react";

const langsMap = {
  en: "English",
  // eo: "English",
  "zh-HK": "繁体中文",
  th: "ภาษาไทย",
  tl: "Filipino",
  vi: "Tiếng Việt",
  ar: "العربية",
  bn: "বাংলা",
  fr: "Français",
  hi: "हिंदी",
  id: "Indonesian",
  ko: "한국어",
  ms: "Melayu",
  pt: "Português",
  es: "Español",
  tr: "Türkçe",
};

// Map of the word "Language" in different languages
const languageWord = {
  eo: "Language",
  en: "Language",
  "zh-HK": "語言",
  th: "ภาษา",
  tl: "Wika",
  vi: "Ngôn ngữ",
  ar: "لغة",
  bn: "ভাষা",
  fr: "Langue",
  hi: "भाषा",
  id: "Bahasa",
  ko: "언어",
  ms: "Bahasa",
  pt: "Língua",
  es: "Idioma",
  tr: "Dil",
};

const LanguageSelectorMenu = ({ border = true }: { border?: boolean }) => {
  const router = useRouter();

  const { locale } = getRouteDetails(router);

  const [isOpen, setIsOpen] = useState(false);

  const { isMobile } = useWindowSize();

  const [selected, setSelected] = useState(
    languageWord[locale as keyof typeof languageWord]
  );

  const { isPreview } = getContentfulEnvironment(router);

  const forwardTo = (lang: string) => {
    const target = (lang: string) =>
      isPreview
        ? `${process.env.BASE_URL}/preview/${lang === "eo" ? "en" : lang}`.toLowerCase()
        : `${process.env.BASE_URL}/${lang === "eo" ? "en" : lang}`.toLowerCase();

    const t: any = Object.entries(languageWord).find(([k, v]) => k === lang) || langsMap;
    if (t) window.location.href = target(t[0]);
  };

  useEffect(() => {
    // If the browser language is one of the languages in the map - set the selected to that
    const browserLang = getLanguageFromLocale(navigator.language);
    if (Object.keys(languageWord).includes(browserLang)) {
      setSelected(languageWord[browserLang as keyof typeof languageWord]);
    } else {
      setSelected(languageWord["en"]);
    }

    const handleEscape = (e: KeyboardEvent) => {
      if (e.key === "Escape") setIsOpen(false);
    };

    const handleClickOutside = (e: MouseEvent) => {
      if (!(e.target as HTMLElement).closest("#lang-selector")) {
        setIsOpen(false);
      }
    };

    const handleTouchOutside = (e: TouchEvent) => {
      if (
        !(e.target as HTMLElement).closest("#lang-selector") ||
        // @ts-ignore
        e?.target?.id === "lang-selector-wrapper"
      ) {
        setIsOpen(false);
      }
    };

    document.addEventListener("click", handleClickOutside);
    document.addEventListener("keydown", handleEscape);
    document.addEventListener("touchstart", handleTouchOutside);

    return () => {
      document.removeEventListener("keydown", handleEscape);
      document.removeEventListener("click", handleClickOutside);
      document.removeEventListener("touchstart", handleTouchOutside);
    };
  }, [locale, router.asPath]);

  return (
    <div id="lang-selector" className="relative group">
      {border ? (
        <div
          onMouseEnter={() => setIsOpen(true)}
          className="hover:cursor-pointer w-min h-[36px] px-3 flex items-center justify-center border border-solid border-azure-40 rounded-full group-hover:cursor-pointer"
        >
          <span className="text-xs px-2 font-bold hover:cursor-pointer text-nowrap flex group-hover:cursor-pointer">
            {selected}
          </span>{" "}
          <Languages height={20} className="hover:cursor-pointer" strokeWidth={2} />
        </div>
      ) : (
        <div
          onMouseEnter={() => setIsOpen(true)}
          className="hover:bg-azure-10 cursor-pointer w-min h-[36px] px-1 flex items-center justify-center rounded-full"
        >
          <span className="text-xs px-2 font-bold hover:cursor-pointer text-nowrap flex group-hover:text-azure-100 group-hover:cursor-pointer">
            {selected}
          </span>{" "}
          <Languages
            height={20}
            className="hover:cursor-pointer stroke-azure-100"
            strokeWidth={2}
          />
        </div>
      )}

      {isMobile ? (
        <div
          id="lang-selector-wrapper"
          className={cn(
            "w-screen h-[calc(100vh-12px)] justify-center items-center top-0 start-0 px-[12px] m-auto bottom-0 flex  bg-glass z-[55]",
            isOpen ? "fixed flex" : "hidden"
          )}
        >
          <div className="flex flex-col pl-6 pr-2 pt-2 pb-4 border border-solid w-full h-min my-auto shadow-lg bg-white-100 rounded-lg border-azure-40">
            <X
              className="ms-auto -mb-4 relative z-[2]"
              onClick={() => setIsOpen(false)}
            />
            {Object.entries(langsMap).map(([key, value]) => (
              <p
                key={key}
                id={key}
                onClick={() => forwardTo(key)}
                className={cn(
                  "hover:text-azure-100 cursor-pointer",
                  locale === key
                    ? "font-bold text-azure-40 cursor-not-allowed hover:text-navy-100"
                    : ""
                )}
              >
                {value}
              </p>
            ))}
          </div>
        </div>
      ) : (
        <div
          onMouseLeave={() => setIsOpen(false)}
          className={cn(
            "absolute z-[21] rounded-lg flex w-[375px] top-0 right-0 rtl:left-0",
            isOpen ? "max-h-[1000px]" : "max-h-0 overflow-hidden"
          )}
        >
          <div className="flex gap-8 mt-11 px-6 py-4 border border-solid w-full h-full shadow-lg bg-white-100 rounded-lg border-azure-40">
            <div>
              {Object.entries(langsMap)
                .slice(0, 5)
                .map(([key, value]) => (
                  <p
                    key={key}
                    id={key}
                    onClick={() => forwardTo(key)}
                    className={cn(
                      "hover:text-azure-100 cursor-pointer",
                      locale === key
                        ? "font-bold text-azure-40 cursor-not-allowed hover:text-azure-40"
                        : ""
                    )}
                  >
                    {value}
                  </p>
                ))}
            </div>
            <div>
              {Object.entries(langsMap)
                .slice(5, 10)
                .map(([key, value]) => (
                  <p
                    key={key}
                    id={key}
                    onClick={() => forwardTo(key)}
                    className={cn(
                      "hover:text-azure-100 cursor-pointer",
                      locale === key
                        ? "font-bold text-azure-40 cursor-not-allowed hover:text-azure-40"
                        : ""
                    )}
                  >
                    {value}
                  </p>
                ))}
            </div>
            <div>
              {Object.entries(langsMap)
                .slice(10)
                .map(([key, value]) => (
                  <p
                    key={key}
                    id={key}
                    onClick={() => forwardTo(key)}
                    className={cn(
                      "hover:text-azure-100 cursor-pointer",
                      locale === key
                        ? "font-bold text-azure-40 cursor-not-allowed hover:text-azure-40"
                        : ""
                    )}
                  >
                    {value}
                  </p>
                ))}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default LanguageSelectorMenu;

//   "🇬🇧 ENG",
//   "🇹🇼 繁体",
//   "🇹🇭 THA",
//   "🇵🇭 FIL",
//   "🇻🇳 VIE",
//   "🇸🇦 ARA",
//   "🇧🇩 BEN",
//   "🇫🇷 FRA",
//   "🇮🇳 HIN",
//   "🇮🇩 IND",
//   "🇰🇷 KOR",
//   "🇲🇾 MAY",
//   "🇵🇹 POR",
//   "🇪🇸 SPA",
//   "🇹🇷 TUR",
