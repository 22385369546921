import { ISO3166_Alpha2_Codes } from "@/types/country-codes";

export const countries_pl: Record<ISO3166_Alpha2_Codes, string> = {
  AD: "Andora",
  AE: "Zjednoczone Emiraty Arabskie",
  AF: "Afganistan",
  AG: "Antigua i Barbuda",
  AI: "Anguilla",
  AL: "Albania",
  AM: "Armenia",
  AN: "Antyle Holenderskie",
  AO: "Angola",
  AQ: "Antarktyka",
  AR: "Argentyna",
  AS: "Samoa Amerykańskie",
  AT: "Austria",
  AU: "Australia",
  AW: "Aruba",
  AX: "Wyspy Alandzkie",
  AZ: "Azerbejdżan",
  BA: "Bośnia i Hercegowina",
  BB: "Barbados",
  BD: "Bangladesz",
  BE: "Belgia",
  BF: "Burkina Faso",
  BG: "Bułgaria",
  BH: "Bahrajn",
  BI: "Burundi",
  BJ: "Benin",
  BL: "Saint-Barthélemy",
  BM: "Bermudy",
  BN: "Brunei Darussalam",
  BO: "Boliwia",
  BQ: "Bonaire",
  BR: "Brazylia",
  BS: "Bahamy",
  BT: "Bhutan",
  BV: "Wyspa Bouvet",
  BW: "Botswana",
  BY: "Białoruś",
  BZ: "Belize",
  CA: "Kanada",
  CC: "Wyspy Kokosowe (Wyspy Keelinga)",
  CD: "Kongo (Demokratyczna Republika Konga)",
  CF: "Republika Środkowoafrykańska",
  CG: "Kongo",
  CH: "Szwajcaria",
  CI: "Wybrzeże Kości Słoniowej (Côte d'Ivoire)",
  CK: "Wyspy Cooka",
  CL: "Chile",
  CM: "Kamerun",
  CN: "Chiny",
  CO: "Kolumbia",
  CR: "Kostaryka",
  CU: "Kuba",
  CV: "Wyspy Zielonego Przylądka",
  CW: "Curaçao",
  CX: "Wyspa Bożego Narodzenia",
  CY: "Cypr",
  CZ: "Czechy",
  DE: "Niemcy",
  DJ: "Dżibuti",
  DK: "Dania",
  DM: "Dominika",
  DO: "Dominikana",
  DZ: "Algieria",
  EC: "Ekwador",
  EE: "Estonia",
  EG: "Egipt",
  EH: "Sahara Zachodnia",
  ER: "Erytrea",
  ES: "Hiszpania",
  ET: "Etiopia",
  FI: "Finlandia",
  FJ: "Fidżi",
  FK: "Falklandy (Malwiny)",
  FM: "Mikronezja, Sfederowane Stany",
  FO: "Wyspy Owcze",
  FR: "Francja",
  GA: "Gabon",
  GB: "Wielka Brytania",
  GD: "Grenada",
  GE: "Gruzja",
  GF: "Gujana Francuska",
  GG: "Guernsey",
  GH: "Ghana",
  GI: "Gibraltar",
  GL: "Grenlandia",
  GM: "Gambia",
  GN: "Gwinea",
  GP: "Gwadelupa",
  GQ: "Gwinea Równikowa",
  GR: "Grecja",
  GS: "Wyspy South Georgia i The South Sandwich",
  GT: "Gwatemala",
  GU: "Guam",
  GW: "Gwinea-Bissau",
  GY: "Gujana",
  HK: "Hongkong SAR",
  HM: "Wyspy Heard i Mc Donald",
  HN: "Honduras",
  HR: "Chorwacja",
  HT: "Haiti",
  HU: "Węgry",
  ID: "Indonezja",
  IE: "Irlandia",
  IL: "Izrael",
  IM: "Wyspa Man",
  IN: "Indie",
  IO: "Brytyjskie Terytorium Oceanu Indyjskiego",
  IQ: "Irak",
  IR: "Iran (Islamska Republika)",
  IS: "Islandia",
  IT: "Włochy",
  JE: "Jersey",
  JM: "Jamajka",
  JO: "Jordania",
  JP: "Japonia",
  KE: "Kenia",
  KG: "Kirgistan",
  KH: "Kambodża",
  KI: "Kiribati",
  KM: "Komory",
  KN: "Saint Kitts i Nevis",
  KP: "Koreańska Republika Ludowo-Demokratyczna",
  KR: "Korea, Republika",
  KW: "Kuwejt",
  KY: "Kajmany",
  KZ: "Kazachstan",
  LA: "Laotańska Republika Ludowo-Demokratyczna",
  LB: "Liban",
  LC: "Saint Lucia",
  LI: "Liechtenstein",
  LK: "Sri Lanka",
  LR: "Liberia",
  LS: "Lesotho",
  LT: "Litwa",
  LU: "Luksemburg",
  LV: "Łotwa",
  LY: "Libijska Arabska Dżamahiria",
  MA: "Maroko",
  MC: "Monako",
  MD: "Mołdawia, Republika",
  ME: "Czarnogóra",
  MF: "Saint Martin",
  MG: "Madagaskar",
  MH: "Wyspy Marshalla",
  MK: "Macedonia, Była Jugosłowiańska Republika",
  ML: "Mali",
  MM: "Myanmar",
  MN: "Mongolia",
  MO: "Makau SAR",
  MP: "Wyspy Mariany Północne",
  MQ: "Martynika",
  MR: "Mauretania",
  MS: "Montserrat",
  MT: "Malta",
  MU: "Mauritius",
  MV: "Malediwy",
  MW: "Malawi",
  MX: "Meksyk",
  MY: "Malezja",
  MZ: "Mozambik",
  NA: "Namibia",
  NC: "Nowa Kaledonia",
  NE: "Niger",
  NF: "Wyspa Norfolk",
  NG: "Nigeria",
  NI: "Nikaragua",
  NL: "Holandia",
  NO: "Norwegia",
  NP: "Nepal",
  NR: "Nauru",
  NU: "Niue",
  NZ: "Nowa Zelandia",
  OM: "Oman",
  PA: "Panama",
  PE: "Peru",
  PF: "Polinezja Francuska",
  PG: "Papua Nowa Gwinea",
  PH: "Filipiny",
  PL: "Polska",
  PM: "St. Pierre i Miquelon",
  PN: "Pitcairn",
  PR: "Portoryko",
  PS: "Palestyna",
  PT: "Portugalia",
  PW: "Palau",
  PY: "Paragwaj",
  QA: "Katar",
  RE: "Wyspa Reunion",
  RO: "Rumunia",
  RS: "Serbia",
  RU: "Federacja Rosyjska",
  RW: "Rwanda",
  SA: "Arabia Saudyjska",
  SB: "Wyspy Salomona",
  SC: "Seszele",
  SD: "Sudan",
  SE: "Szwecja",
  SG: "Singapur",
  SH: "Święta Helena",
  SI: "Słowenia",
  SJ: "Wyspy Svalbard i Jan Mayen",
  SK: "Słowacja (Republika Słowacka)",
  SL: "Sierra Leone",
  SM: "San Marino",
  SN: "Senegal",
  SO: "Somalia",
  SR: "Surinam",
  SS: "Sudan Południowy",
  ST: "Wyspy Świętego Tomasza i Książęca",
  SV: "Salwador",
  SX: "Sint Maarten",
  SY: "Syryjska Republika Arabska",
  SZ: "Suazi",
  TC: "Wyspy Turks i Caicos",
  TD: "Czad",
  TF: "Francuskie Terytoria Południowe",
  TG: "Togo",
  TH: "Tajlandia",
  TJ: "Tadżykistan",
  TK: "Tokelau",
  TL: "Timor Wschodni",
  TM: "Turkmenistan",
  TN: "Tunezja",
  TO: "Tonga",
  TR: "Turcja",
  TT: "Trynidad i Tobago",
  TV: "Tuvalu",
  TW: "Tajwan",
  TZ: "Tanzania, Zjednoczona Republika",
  UA: "Ukraina",
  UG: "Uganda",
  UM: "Stany Zjednoczone - wyspy zewnętrzne",
  US: "Stany Zjednoczone",
  UY: "Urugwaj",
  UZ: "Uzbekistan",
  VA: "Stolica Apostolska (Państwo Watykańskie)",
  VC: "Saint Vincent i Grenadyny",
  VE: "Wenezuela",
  VG: "Wyspy Dziewicze (Brytyjskie)",
  VI: "Wyspy Dziewicze (Stanów Zjednoczonych)",
  VN: "Wietnam",
  VU: "Vanuatu",
  WF: "Wyspy Wallis i Futuna",
  WS: "Samoa",
  YE: "Jemen",
  YT: "Wyspa Majotta",
  ZA: "Republika Południowej Afryki",
  ZM: "Zambia",
  ZW: "Zimbabwe",
  XK: "Kosovo",
  PK: "Pakistan",
};
