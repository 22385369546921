import Icon from "@/components/icons/icons";
import Link from "@/components/utility/link";
import Paragraph from "@/components/utility/paragraph";
import { triggerModal } from "@/components/utility/video-modal-layer";
import { DesignSystemComponent, TileT } from "@/types/design-system-types";
import { addHttps, cn } from "@/utils/helpers";
import { ComponentError } from "@/utils/system/component-error";
import Page from "@/utils/system/page";
import Image from "next/image";
import { ElementType } from "react";
import Button from "../atoms/button";
import RichText from "../atoms/rich-text";

const Tile = ({
  data,
  id,
  debug,
  size = data?.fields.size ?? "md",
  image = data?.fields?.image,
  icon = data?.fields?.icon,
  smallText = data?.fields?.smallText,
  title = data?.fields?.title,
  titleSize = data?.fields?.titleSize ?? "md",
  description = data?.fields?.description,
  bulletPointType_v2 = data?.fields?.bulletPointType_v2,
  primaryButton = data?.fields?.primaryButton,
  secondaryButton = data?.fields?.secondaryButton,
  alignment = data?.fields?.alignment ?? "left",
  theme = data?.fields?.theme,
  badge = data?.fields?.badge,
  imageSize = data?.fields?.imageSize ?? "icon",
  url = data?.fields?.url,
  className,
  ...props
}: DesignSystemComponent<TileT>) => {
  try {
    const themeClass = Page.getThemeClass(data?.fields.theme || theme);
    const sizeClass = `n-tile-${size}`;

    let borderClasses = "shadow-lg rounded-lg";

    // TODO: Review as it's very specific. Main use case is tile within a nCard.
    if (theme === "azure-10") {
      borderClasses = "rounded-lg";
    }

    // Remove border classes for transparent themes
    if ([undefined, "transparent-light", "transparent-dark"].includes(theme)) {
      borderClasses = "";
    }

    // If an icon is provided, prioritize over image
    if (icon) {
      image = undefined;
    }

    const imageSizeClass = `n-tile-image-${imageSize}`;
    const iconSizeClass = `n-tile-icon-${imageSize}`;

    // Check if the URL is a video URL. If it is, open the video modal
    const videoUrls = ["youtube", "videos.fxbluelabs.com"];

    const isVideoUrl = (url: string) =>
      videoUrls.some((videoUrl) => url.includes(videoUrl));

    // Set tile element to div if it's a video URL
    const ParentComponent = url && !isVideoUrl(url) ? Link : ("div" as ElementType);
    const parentProps = url && !isVideoUrl(url) ? { href: url, ref: id } : { ref: id };

    // Trigger video modal on click if the URL is a video URL & the tile button wasn't clicked
    const triggerVideoModal = (event: Event) => {
      const target = event.target as HTMLElement;

      // Recursively check if the target is a button
      const targetIsButton = (element: HTMLElement | null): boolean => {
        if (!element) return false;
        if (element.classList.contains("n-button")) return true;
        return targetIsButton(element.parentElement);
      };

      // Return early if the target is a button
      if (targetIsButton(target)) return;

      if (url && isVideoUrl(url)) {
        triggerModal(url);
      }
    };

    if (data?.fields?.internalName === "DISABLE FALLBACK") return <></>;

    return (
      <>
        <ParentComponent
          {...parentProps}
          className={cn(`n-tile n-tile-alignment-${alignment}`, [
            borderClasses,
            themeClass,
            sizeClass,
            className,
            url && "cursor-pointer hover:scale-[1.02]",
          ])}
          onClick={(event: Event) => {
            triggerVideoModal(event);
          }}
          {...props}
        >
          {debug}

          {/* Icon */}
          {icon && (
            <div
              className={cn(
                "n-tile-iconslot",
                "accent-stroke",
                alignment === "left" || alignment === "center" ? "mb-2" : ""
              )}
            >
              {/* n-tile-icon-icon n-tile-icon-max */}
              <Icon
                name={icon}
                className={cn("n-tile-icon", iconSizeClass)}
                onClick={() => Page.editField(data, "icon")}
              />
            </div>
          )}

          {/* Image */}
          {image && image.fields?.file?.details?.image ? (
            <div
              className={cn(
                "n-tile-imageslot",
                alignment === "left" || alignment === "center" ? "mb-2" : ""
              )}
            >
              <Image
                src={addHttps(image.fields?.file?.url)}
                alt=""
                width={image.fields?.file?.details?.image.width}
                height={image.fields?.file?.details?.image.height}
                className={cn("n-tile-image accent-stroke", imageSizeClass)}
                onClick={() => Page.editField(data)}
              />
            </div>
          ) : (
            image
          )}

          {/* Main Content */}
          <div
            className={cn(
              "n-tile-content flex h-full flex-col justify-between",
              badge ? "space-y-1" : "space-y-2",
              alignment === "left" && "text-start",
              alignment === "center" && "text-center",
              alignment === "icon-on-right" && "text-start",
              alignment === "icon-on-left" && "text-start"
            )}
          >
            <div>
              {/* Small Text */}
              {smallText && (
                <Paragraph
                  onClick={() => Page.editField(data, "smallText")}
                  type="caption"
                  className="n-tile-smalltext"
                >
                  {smallText as string}
                </Paragraph>
              )}

              {/* Title */}
              {title && (
                <Paragraph
                  onClick={() => Page.editField(data, "title")}
                  fontSize={badge ? "md" : "lg"}
                  fontWeight="semibold"
                  className={cn("n-tile-title", `text-${titleSize}`)}
                >
                  {title as string}
                </Paragraph>
              )}

              {/* Dynamic description */}
              {/* @ts-ignore */}
              {description && description.content && (
                <RichText
                  data={description as any}
                  theme={theme}
                  sys={data?.sys}
                  fieldName="description"
                  className="n-tile-description"
                  bulletPointType_v2={bulletPointType_v2}
                  alignment={
                    alignment === "left" || alignment === "icon-on-right"
                      ? "start"
                      : alignment === "icon-on-left"
                      ? "end"
                      : alignment === "center"
                      ? "center"
                      : "start"
                  }
                />
              )}

              {/* Static description */}
              {/* @ts-ignore */}
              {description && !description.content && description}
            </div>

            {/* Buttons */}
            {(primaryButton || secondaryButton) && (
              <div className="n-tile-buttons">
                {primaryButton && <Button data={primaryButton} />}
                {secondaryButton && <Button data={secondaryButton} />}
              </div>
            )}
          </div>
        </ParentComponent>
      </>
    );
  } catch (error) {
    return <ComponentError error={error} data={data} />;
  }
};

export default Tile;

// n-tile-xss n-tile-xs n-tile-sm n-tile-md n-tile-lg n-tile-xl n-tile-alignment-left n-tile-alignment-center n-tile-alignment-icon-on-left n-tile-alignment-icon-on-right
// n-tile-image-auto n-tile-image-full n-tile-image-icon n-tile-image-fill
