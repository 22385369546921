import { ISO3166_Alpha2_Codes } from "@/types/country-codes";

export const countries_it: Record<ISO3166_Alpha2_Codes, string> = {
  AD: "Andorra",
  AE: "Emirati Arabi Uniti",
  AF: "Afghanistan",
  AG: "Antigua e Bermuda",
  AI: "Anguilla",
  AL: "Albania",
  AM: "Armenia",
  AN: "Antille Olandesi",
  AO: "Angola",
  AQ: "Antartide",
  AR: "Argentina",
  AS: "Samoa Americane",
  AT: "Austria",
  AU: "Australia",
  AW: "Aruba",
  AX: "Isole Aland",
  AZ: "Azerbaijan",
  BA: "Bosnia-Herzegovina",
  BB: "Barbados",
  BD: "Bangladesh",
  BE: "Belgio",
  BF: "Burkina Faso",
  BG: "Bulgaria",
  BH: "Bahrain",
  BI: "Burundi",
  BJ: "Benin",
  BL: "Saint Barthélemy",
  BM: "Bermuda",
  BN: "Brunei Darussalam",
  BO: "Bolivia",
  BQ: "Bonaire, Sint Eustatius and Saba",
  BR: "Brasile",
  BS: "Bahamas",
  BT: "Bhutan",
  BV: "Isola Bouvet",
  BW: "Botswana",
  BY: "Biellorussia",
  BZ: "Belize",
  CA: "Canada",
  CC: "Isole Cocos (Keeling)",
  CD: "Congo",
  CF: "Repubblica Centrale Africana",
  CG: "Congo",
  CH: "Svizzera",
  CI: "Costa d'Avorio",
  CK: "Isole Cook",
  CL: "Cile",
  CM: "Cameroon",
  CN: "Cina",
  CO: "Colombia",
  CR: "Costa Rica",
  CU: "Cuba",
  CV: "Capo Verde",
  CW: "Curaçao",
  CX: "Isola Christmas",
  CY: "Cipro",
  CZ: "Repubblica Ceca",
  DE: "Germania",
  DJ: "Gibuti",
  DK: "Danimarca",
  DM: "Dominica",
  DO: "Repubblica Dominicana",
  DZ: "Algeria",
  EC: "Equador",
  EE: "Estonia",
  EG: "Egitto",
  EH: "Sahara Occidentale",
  ER: "Eritrea",
  ES: "Spagna",
  ET: "Etiopia",
  FI: "Finlandia",
  FJ: "Fiji",
  FK: "Isole Falkland (Malvine)",
  FM: "Stati Federati di Micronesia",
  FO: "Isole Faroe",
  FR: "Francia",
  GA: "Gabon",
  GB: "Regno Unito",
  GD: "Grenada",
  GE: "Georgia",
  GF: "Guinea Francese",
  GG: "Guernsey",
  GH: "Ghana",
  GI: "Gibilterra",
  GL: "Groenlandia",
  GM: "Gambia",
  GN: "Guinea",
  GP: "Guadalupe",
  GQ: "Guinea Equatoriale",
  GR: "Grecia",
  GS: "Georgia del Sud e Isole Sandwich Meridionali",
  GT: "Guatemala",
  GU: "Guam",
  GW: "Guinea-Bissau",
  GY: "Guyana",
  HK: "Hong Kong",
  HM: "Isole Heard e McDonald",
  HN: "Hounduras",
  HR: "Croazia",
  HT: "Haiti",
  HU: "Ungheria",
  ID: "Indonesia",
  IE: "Irlanda",
  IL: "Israele",
  IM: "Isola di Man",
  IN: "India",
  IO: "Territorio Britannico dell'Oceano Indiano",
  IQ: "Iraq",
  IR: "Iran",
  IS: "Islanda",
  IT: "Italia",
  JE: "Jersey",
  JM: "Jamaica",
  JO: "Giordania",
  JP: "Giappone",
  KE: "Kenya",
  KG: "Kirghizistan",
  KH: "Cambogia",
  KI: "Kiribati",
  KM: "Comore",
  KN: "Saint Kitts e Nevis",
  KP: "Corea del Nord",
  KR: "Corea del Sud",
  KW: "Kuwait",
  KY: "Isole Cayman",
  KZ: "Kazakistan",
  LA: "Laos",
  LB: "Libano",
  LC: "Saint Lucia",
  LI: "Liechtenstein",
  LK: "Sri Lanka",
  LR: "Liberia",
  LS: "Lesotho",
  LT: "Lituania",
  LU: "Lussemburgo",
  LV: "Lettonia",
  LY: "Libia",
  MA: "Marocco",
  MC: "Principato di Monaco",
  MD: "Moldavia",
  ME: "Montenegro",
  MF: "Saint Martin",
  MG: "Madagascar",
  MH: "Isole Marshall",
  MK: "Macedonia",
  ML: "Mali",
  MM: "Birmania",
  MN: "Mongolia",
  MO: "Macao",
  MP: "Isole Marianne Settentrionali",
  MQ: "Martinica",
  MR: "Mauritania",
  MS: "Montserrat",
  MT: "Malta",
  MU: "Mauritius",
  MV: "Maldive",
  MW: "Malawi",
  MX: "Messico",
  MY: "Malesia",
  MZ: "Mozambico",
  NA: "Namibia",
  NC: "Nuova Caledonia",
  NE: "Niger",
  NF: "Isola Norfolk",
  NG: "Nigeria",
  NI: "Nicaragua",
  NL: "Olanda",
  NO: "Norvegia",
  NP: "Napal",
  NR: "Nauru",
  NU: "Niue",
  NZ: "Nuova Zelanda",
  OM: "Oman",
  PA: "Panama",
  PE: "Perù",
  PF: "Polinesia Francese",
  PG: "Papua Nuova Guinea",
  PH: "Filippine",
  PL: "Polonia",
  PM: "Saint Pierre e Miquelon",
  PN: "Isola Pitcairn",
  PR: "Porto Rico",
  PS: "Palestina",
  PT: "Portogallo",
  PW: "Palau",
  PY: "Paraguay",
  QA: "Qatar",
  RE: "Réunion",
  RO: "Romania",
  RS: "Serbia",
  RU: "Russia",
  RW: "Rwanda",
  SA: "Arabia Saudita",
  SB: "Isole Salomone",
  SC: "Seychelles",
  SD: "Sudan",
  SE: "Svezia",
  SG: "Singapore",
  SH: "Saint Helena",
  SI: "Slovenia",
  SJ: "Svalbard e Jan Mayen",
  SK: "Slovacchia",
  SL: "Sierra Leone",
  SM: "San Marino",
  SN: "Senegal",
  SO: "Somalia",
  SR: "Suriname",
  SS: "Sud Sudan",
  ST: "Sao Tomè e Principe",
  SV: "El Salvador",
  SX: "Sint Maarten (Dutch part)",
  SY: "Siria",
  SZ: "Swaziland",
  TC: "Turks e Caicos",
  TD: "Chad",
  TF: "Terre Australi e Artiche Francesi",
  TG: "Togo",
  TH: "Tailandia",
  TJ: "Tagikistan",
  TK: "Tokelau",
  TL: "Timor Orientale",
  TM: "Turkmenistan",
  TN: "Tunisia",
  TO: "Tonga",
  TR: "Turchia",
  TT: "Trinidad e Tobago",
  TV: "Tuvalu",
  TW: "Taiwan",
  TZ: "Tanzania",
  UA: "Ucraina",
  UG: "Uganda",
  UM: "Isole Minori Esterne degli Stati Uniti d'America",
  US: "Stati Uniti",
  UY: "Uruguay",
  UZ: "Uzbekistan",
  VA: "Città del Vaticano",
  VC: "Saint Vincent e Granadine",
  VE: "Venezuela",
  VG: "Isole Vergini Britanniche",
  VI: "Isole vergini Americane",
  VN: "Vietnam",
  VU: "Vanuatu",
  WF: "Wallis e Futuna",
  WS: "Samoa",
  YE: "Yemen",
  YT: "Mayotte",
  ZA: "Sud Africa",
  ZM: "Zambia",
  ZW: "Zimbabwe",
  XK: "Kosovo",
  PK: "Pakistan",
};
