import { ISO3166_Alpha2_Codes } from "@/types/country-codes";

export const countries_th: Record<ISO3166_Alpha2_Codes, string> = {
  AW: "อารูบา",
  AF: "อัฟกานิสถาน",
  AO: "แองโกลา",
  AI: "แองกวิลลา",
  AX: "หมู่เกาะโอลันด์",
  AL: "แอลเบเนีย",
  AD: "อันดอร์รา",
  AN: "เนเธอร์แลนด์แอนทิลลีส",
  AE: "สหรัฐอาหรับเอมิเรตส์",
  AR: "อาร์เจนตินา",
  AM: "อาร์มีเนีย",
  AS: "อเมริกันซามัว",
  AQ: "แอนตาร์กติกา",
  TF: "เฟรนช์เซาเทิร์นเทร์ริทอรีส์",
  AG: "แอนติกาและบาร์บูดา",
  AU: "ออสเตรเลีย",
  AT: "ออสเตรีย",
  AZ: "อาเซอร์ไบจาน",
  BI: "บุรุนดี",
  BE: "เบลเยียม",
  BJ: "เบนิน",
  BQ: "โบแนเรอ, ซินต์เอิสตาซียึสและซาบา",
  BF: "บูร์กินาฟาโซ",
  BD: "บังกลาเทศ",
  BG: "บัลแกเรีย",
  BH: "บาห์เรน",
  BS: "บาฮามาส",
  BA: "บอสเนียและเฮอร์เซโกวีนา",
  BL: "แซ็ง-บาร์เตเลมี",
  BY: "เบลารุส",
  BZ: "เบลีซ",
  BM: "เบอร์มิวดา",
  BO: "โบลิเวีย (รัฐพหุชนชาติแห่งโบลิเวีย)",
  BR: "บราซิล",
  BB: "บาร์เบโดส",
  BN: "บรูไนดารุซซาลาม",
  BT: "ภูฏาน",
  BV: "เกาะบูเว",
  BW: "บอตสวานา",
  CF: "สาธารณรัฐแอฟริกากลาง",
  CA: "แคนาดา",
  CC: "หมู่เกาะโคโคส (คีลิง)",
  CH: "สวิตเซอร์แลนด์",
  CL: "ชิลี",
  CN: "จีน",
  CI: "โกตดิวัวร์",
  CM: "แคเมอรูน",
  CD: "คองโก (สาธารณรัฐประชาธิปไตย)",
  CG: "คองโก",
  CK: "หมู่เกาะคุก",
  CO: "โคลอมเบีย",
  KM: "คอโมโรส",
  CV: "กาบูเวร์ดี",
  CR: "คอสตาริกา",
  CU: "คิวบา",
  CW: "กือราเซา",
  CX: "เกาะคริสต์มาส",
  KY: "หมู่เกาะเคย์เเมน",
  CY: "ไซปรัส",
  CZ: "สาธารณรัฐเช็ก",
  DE: "เยอรมนี",
  DJ: "จิบูตี",
  DM: "ดอมินีกา",
  DK: "เดนมาร์ก",
  DO: "สาธารณรัฐโดมินิกัน",
  DZ: "แอลจีเรีย",
  EC: "เอกวาดอร์",
  EG: "อียิปต์",
  ER: "เอริเทรีย",
  EH: "เวสเทิร์นสะฮารา",
  ES: "สเปน",
  EE: "เอสโตเนีย",
  ET: "เอธิโอเปีย",
  FI: "ฟินแลนด์",
  FJ: "ฟีจี",
  FK: "หมู่เกาะฟอล์กแลนด์ (มัลบีนัส)",
  FR: "ฝรั่งเศส",
  FO: "หมู่เกาะแฟโร",
  FM: "ไมโครนีเซีย",
  GA: "กาบอง",
  GB: "สหราชอาณาจักร",
  GE: "จอร์เจีย",
  GG: "เกิร์นซีย์",
  GH: "กานา",
  GI: "ยิบรอลตาร์",
  GN: "กินี",
  GP: "กัวเดอลุป",
  GM: "แกมเบีย",
  GW: "กินี-บิสเซา",
  GQ: "อิเควทอเรียลกินี",
  GR: "กรีซ",
  GD: "เกรเนดา",
  GL: "กรีนแลนด์",
  GT: "กัวเตมาลา",
  GF: "เฟรนช์เกียนา",
  GU: "กวม",
  GY: "กายอานา",
  HK: "ฮ่องกง",
  HM: "เกาะเฮิร์ดและหมู่เกาะแมกดอนัลด์",
  HN: "ฮอนดูรัส",
  HR: "โครเอเชีย",
  HT: "เฮติ",
  HU: "ฮังการี",
  ID: "อินโดนีเซีย",
  IM: "ไอล์ออฟแมน",
  IN: "อินเดีย",
  IO: "บริติชอินเดียนโอเชียนเทร์ริทอรี",
  IE: "ไอร์แลนด์",
  IR: "อิหร่าน",
  IQ: "อิรัก",
  IS: "ไอซ์แลนด์",
  IL: "อิสราเอล",
  IT: "อิตาลี",
  JM: "จาเมกา",
  JE: "เจอร์ซีย์",
  JO: "จอร์แดน",
  JP: "ญี่ปุ่น",
  KZ: "คาซัคสถาน",
  KE: "เคนยา",
  KG: "คีร์กีซสถาน",
  KH: "กัมพูชา",
  KI: "คิริบาส",
  KN: "เซนต์คิตส์และเนวิส",
  KR: "เกาหลีใต้",
  KW: "คูเวต",
  LA: "ลาว",
  LB: "เลบานอน",
  LR: "ไลบีเรีย",
  LY: "ลิเบีย",
  LC: "เซนต์ลูเชีย",
  LI: "ลิกเตนสไตน์",
  LK: "ศรีลังกา",
  LS: "เลโซโท",
  LT: "ลิทัวเนีย",
  LU: "ลักเซมเบิร์ก",
  LV: "ลัตเวีย",
  MO: "มาเก๊า",
  MF: "เซนต์มาร์ติน",
  MA: "โมร็อกโก",
  MC: "โมนาโก",
  MD: "มอลโดวา (สาธารณรัฐ)",
  MG: "มาดากัสการ์",
  MV: "มัลดีฟส์",
  MX: "เม็กซิโก",
  MH: "หมู่เกาะมาร์แชลล์",
  MK: "มาซิโดเนีย",
  ML: "มาลี",
  MT: "มอลตา",
  MM: "พม่า",
  ME: "มอนเตเนโกร",
  MN: "มองโกเลีย",
  MP: "หมู่เกาะนอร์เทิร์นมาเรียนา",
  MZ: "โมซัมบิก",
  MR: "มอริเตเนีย",
  MS: "มอนต์เซอร์รัต",
  MQ: "มาร์ตีนิก",
  MU: "มอริเชียส",
  MW: "มาลาวี",
  MY: "มาเลเซีย",
  YT: "มายอต",
  NA: "นามิเบีย",
  NC: "นิวแคลิโดเนีย",
  NE: "ไนเจอร์",
  NF: "เกาะนอร์ฟอล์ก",
  NG: "ไนจีเรีย",
  NI: "นิการากัว",
  NU: "นีวเว",
  NL: "เนเธอร์แลนด์",
  NO: "นอร์เวย์",
  NP: "เนปาล",
  NR: "นาอูรู",
  NZ: "นิวซีแลนด์",
  OM: "โอมาน",
  PK: "ปากีสถาน",
  PA: "ปานามา",
  PN: "พิตแคร์น",
  PE: "เปรู",
  PH: "ฟิลิปปินส์",
  PW: "ปาเลา",
  PG: "ปาปัวนิวกินี",
  PL: "โปแลนด์",
  PR: "ปวยร์โตรีโก",
  KP: "เกาหลีเหนือ",
  PT: "โปรตุเกส",
  PY: "ปารากวัย",
  PS: "รัฐปาเลสไตน์",
  PF: "เฟรนช์พอลินีเชีย",
  QA: "กาตาร์",
  RE: "เรอูว์นียง",
  RO: "โรมาเนีย",
  RU: "รัสเซีย",
  RW: "รวันดา",
  SA: "ซาอุดีอาระเบีย",
  SD: "ซูดาน",
  SN: "เซเนกัล",
  SG: "สิงคโปร์",
  GS: "เกาะเซาท์จอร์เจียและหมู่เกาะเซาท์แซนวิช",
  SH: "เซนต์เฮเลนา",
  SJ: "สฟาลบาร์และยานไมเอน",
  SB: "หมู่เกาะโซโลมอน",
  SL: "เซียร์ราลีโอน",
  SV: "เอลซัลวาดอร์",
  SM: "ซานมารีโน",
  SO: "โซมาเลีย",
  PM: "แซงปีแยร์และมีเกอลง",
  RS: "เซอร์เบีย",
  SS: "เซาท์ซูดาน",
  ST: "เซาตูเมและปรินซิปี",
  SR: "ซูรินาม",
  SK: "สโลวาเกีย",
  SI: "สโลวีเนีย",
  SE: "สวีเดน",
  SZ: "เอสวาตินี",
  SX: "ซินต์มาร์เติน (ส่วนของดัตช์)",
  SC: "เซเชลส์",
  SY: "สาธารณรัฐอาหรับซีเรีย",
  TC: "หมู่เกาะเติกส์และเคคอส",
  TD: "ชาด",
  TG: "โตโก",
  TH: "ไทย",
  TJ: "ทาจิกิสถาน",
  TK: "โทเคอเลา",
  TM: "เติร์กเมนิสถาน",
  TL: "ติมอร์-เลสเต",
  TO: "ตองงา",
  TT: "ตรินิแดดและโตเบโก",
  TN: "ตูนิเซีย",
  TR: "ตุรกี",
  TV: "ตูวาลู",
  TW: "ไต้หวัน",
  TZ: "สหสาธารณรัฐแทนซาเนีย",
  UG: "ยูกันดา",
  UA: "ยูเครน",
  UM: "เกาะเล็กรอบนอกของสหรัฐอเมริกา",
  UY: "อุรุกวัย",
  US: "สหรัฐอเมริกา",
  UZ: "อุซเบกิสถาน",
  VA: "วาติกัน",
  VC: "เซนต์วินเซนต์และเกรนาดีนส์",
  VE: "เวเนซุเอลา",
  VG: "หมู่เกาะเวอร์จิน (อังกฤษ)",
  VI: "หมู่เกาะเวอร์จิน (สหรัฐอเมริกา)",
  VN: "เวียดนาม",
  VU: "วานูอาตู",
  WF: "วาลิสและฟูตูนา",
  WS: "ซามัว",
  XK: "คอซอวอ",
  YE: "เยเมน",
  ZA: "แอฟริกาใต้",
  ZM: "แซมเบีย",
  ZW: "ซิมบับเว",
};
