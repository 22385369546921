// Endpoints
const WS_KEY = process.env.WS_KEY;
const WS_DOMAIN = process.env.WS_DOMAIN; // hdev is broken af so we're using the prod endpoint
const WS_ASSETS = process.env.WS_ASSETS;

export type Endpoints =
  | "INFO"
  | "EVERYTHING"
  | "PRICE_CHANGE"
  | "PRICES"
  | "PRICE"
  | "ALL_INSTRUMENTS"
  | "BASKET"
  | "NEWS_ARTICLE"
  | "NEWS_IMAGE";

export type RegionAndType =
  | "gb"
  | "au"
  | "fr"
  | "es"
  | "de"
  | "pl"
  | "de-mt4"
  | "gb-mt4";

export type News =
  | "news"
  | "indices"
  | "forex"
  | "shares"
  | "commodities"
  | "etfs"
  | "bonds";

const INFO = (apiCode: string, region: RegionAndType = "gb") => `https://${WS_DOMAIN}/json/instruments/${apiCode}_${region}.json`;
const EVERYTHING = (apiCode: string) => `https://${WS_DOMAIN}/instruments/getEverything/${apiCode}?key=${WS_KEY}`;
const PRICE_CHANGE = (apiCode: string) => `https://${WS_DOMAIN}/instruments/priceChange/${apiCode}?key=${WS_KEY}`;
const ALL_INSTRUMENTS = (region: RegionAndType = "gb") => `https://${WS_DOMAIN}/json/instruments_${region}.json`;
const PRICES = (apiCode: string) =>  `https://${WS_DOMAIN}/instruments/prices/${apiCode}?key=${WS_KEY}`;
const PRICE = (apiCode: string) => `https://${WS_DOMAIN}/instruments/price/${apiCode}?key=${WS_KEY}`;
const NEWS_ARTICLE = (type: string, locale: string = "en-gb") => `https://${WS_ASSETS}/json/news_article_${type}_${locale}.json`;
const BASKET = () => `https://${WS_DOMAIN}/api/v2/instruments/basket/?key=${WS_KEY}`;
const NEWS_IMAGE = (image: string) => `https://${WS_ASSETS}/images/${image}`;


export default class WSFetcher {
  private static async fetchData(
    apiEndpoint: any,
    params: any[],
    call:
      | "info"
      | "everything"
      | "priceChange"
      | "prices"
      | "price"
      | "allInstruments"
      | "basket"
      | "newsArticle"
      | "newsImage"
  ): Promise<any> {
    try {
      // console.log(apiEndpoint(...params));
      const response = await fetch(apiEndpoint(...params));
      if (!response.ok) {
        console.error(`Failed to fetch [${call}] from WS.`, params);
        throw new Error("Failed to fetch data");
      }
      return response.json();
    } catch (error) {
      console.error(`Failed to fetch [${call}] from WS.`, params, error);
      throw error;
    }
  }

  static getInfo(apiCode: string, region: RegionAndType = "gb"): Promise<any> {
    return this.fetchData(INFO, [apiCode, region], "info");
  }

  static getEverything(apiCode: string): Promise<any> {
    return this.fetchData(EVERYTHING, [apiCode], "everything");
  }

  static async getPriceChange(apiCode: string): Promise<any> {
    const priceChange: any = await this.fetchData(PRICE_CHANGE, [apiCode], "priceChange");
    return priceChange[apiCode];
  }

  static getPrices(apiCode: string): Promise<any> {
    return this.fetchData(PRICES, [apiCode], "prices");
  }

  static getPrice(apiCode: string): Promise<any> {
    return this.fetchData(PRICE, [apiCode], "price");
  }

  static getAllInstruments(region: RegionAndType): Promise<any> {
    return this.fetchData(ALL_INSTRUMENTS, [region], "allInstruments");
  }

  static getBasket(): Promise<any> {
    return this.fetchData(BASKET, [], "basket");
  }

  static getNewsArticles(type: News, locale: string = "en-gb"): Promise<any> {
    if (locale === "pillar") locale = "en";
    if (locale === "eo") locale = "en";
    return this.fetchData(NEWS_ARTICLE, [type, locale], "newsArticle");
  }

  static getNewsArticleById(id: string): Promise<any> {
    return this.fetchData(NEWS_ARTICLE, [id], "newsArticle");
  }

  static getNewsImageByFilename(filename: string): Promise<any> {
    return this.fetchData(NEWS_IMAGE, [filename], "newsImage");
  }

  static getAll(apiCode: string, region: RegionAndType) {
    return Promise.all([
      this.getInfo(apiCode, region),
      this.getEverything(apiCode),
      this.getPriceChange(apiCode),
      this.getPrices(apiCode),
      this.getPrice(apiCode),
    ]);
  }
}
