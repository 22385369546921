import { TextKey } from "@/config/i18n";
import useGetHeaderHeight from "@/hooks/useGetHeaderHeight";
import useIsHighlightedView from "@/hooks/useIsHighlightedView";
import useLocalizedText from "@/hooks/useLocalisedText";
import { ChevronDown } from "lucide-react";
import { useEffect, useState } from "react";

export type ScrollSpy = {
  id: string;
  label: string;
};

export type LinkTypes = "anchor" | "nextLink";

const ScrollSpyToolbar = ({
  scrollSpies,
  pageData,
}: {
  scrollSpies: ScrollSpy[];
  pageData: any;
}) => {
  scrollSpies = scrollSpies.filter((spy) => spy.label !== "disable");

  const headerHeight = useGetHeaderHeight(); // for offsetting viewport

  const closeText = useLocalizedText(TextKey.Close);

  const menuText = useLocalizedText(TextKey.Menu);

  // Check if a legacy header is being used via the sidecar
  const hasLegacyHeader = pageData?.items?.[0].fields?.components.some(
    (component: any) => component?.fields?.component === "LegacyHeader"
  );

  // Returns the id of the current section in view
  const currentInViewId = useIsHighlightedView(
    scrollSpies?.map((spy: ScrollSpy) => spy.id),
    headerHeight
  );

  // Get the current section in view via the id
  const currentInView = scrollSpies?.find((spy: ScrollSpy) => spy.id === currentInViewId);

  const [mobileDropdownOpen, setMobileDropdownOpen] = useState(false);
  const [mobileDropdownText, setMobileDropdownText] = useState(menuText);

  const scrollToSection = (id: string) => {
    if (typeof window !== "undefined" && typeof document !== "undefined") {
      const element = document.getElementById(id);

      if (element && headerHeight) {
        const y = element.getBoundingClientRect().top + window.scrollY - headerHeight + 2; // +2 to ensure the section is fully in view

        window.scrollTo({ top: y, behavior: "smooth" });
      }
    }
  };

  // Toggle mobile dropdown
  const toggleMobileDropdown = () => {
    setMobileDropdownOpen(!mobileDropdownOpen);
  };

  // Update mobile dropdown text when toggling
  useEffect(() => {
    // If the dropdown is open, set the text to closeText, otherwise set it to the current section in view or menuText
    setMobileDropdownText(
      mobileDropdownOpen ? closeText : currentInView?.label || menuText
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mobileDropdownOpen]);

  // Update mobile dropdown text when scrolling
  useEffect(() => {
    if (mobileDropdownOpen) {
      return;
    }
    setMobileDropdownText(
      currentInView ? currentInView?.label : mobileDropdownOpen ? closeText : menuText
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentInView]);

  return (
    <div
      id="scroll-spy-toolbar"
      className={`pointer-events-auto sticky top-[64px] z-[20] w-full ${
        hasLegacyHeader ? "hidden" : ""
      }`}
    >
      {/* Desktop nav */}
      <div className="menu-scroll-links hidden justify-end gap-x-1 bg-white-95 py-2 pr-4 lg:flex lg:justify-center lg:pl-0">
        <ul className="flex items-center gap-x-2">
          {scrollSpies?.map((spy: ScrollSpy, key: number) => {
            return (
              <li
                onClick={() => {
                  scrollToSection(spy.id);
                }}
                className={
                  "btn btn-sm mb-0 hidden cursor-pointer lg:block " +
                  (currentInViewId === spy.id
                    ? " btn-azure-light"
                    : "btn-primary-outline")
                }
                key={key}
              >
                {spy.label}
              </li>
            );
          })}
        </ul>
      </div>

      {/* Mobile nav */}
      <nav className="menu-scroll-links-mob flex justify-end bg-white-95 py-2 pr-4 lg:hidden">
        <div className="relative lg:hidden">
          <button
            onClick={() => toggleMobileDropdown()}
            type="button"
            className="btn btn-sm btn-azure-light btn-with-icon"
          >
            <span>{mobileDropdownText}</span>
            <ChevronDown size={20} />
          </button>
          <ul
            className={
              "absolute right-0 flex flex-col rounded-lg bg-white text-left shadow rtl:left-0 rtl:right-auto " +
              (mobileDropdownOpen ? "block" : "hidden")
            }
          >
            {scrollSpies?.map((spy: any, key: any) => {
              return (
                <li
                  onClick={() => {
                    scrollToSection(spy.id);
                    setMobileDropdownOpen(false);
                    setMobileDropdownText(spy.label);
                  }}
                  className="block w-max min-w-[200px] cursor-pointer px-4 py-3 text-left text-xs font-semibold text-navy-100"
                  key={key}
                >
                  {spy.label}
                </li>
              );
            })}
          </ul>
        </div>
      </nav>
    </div>
  );
};

export default ScrollSpyToolbar;
