import { ISO3166_Alpha2_Codes } from "@/types/country-codes";
import { countries_fr } from "@/config/countries/countries_fr";
import { countries_hi } from "@/config/countries/countries_hi";
import { countries_ar } from "@/config/countries/countries_ar";
import { countries_bn } from "@/config/countries/countries_bn";
import { countries_ct } from "@/config/countries/countries_ct";
import { countries_de } from "@/config/countries/countries_de";
import { countries_en } from "@/config/countries/countries_en";
import { countries_es } from "@/config/countries/countries_es";
import { countries_hu } from "@/config/countries/countries_hu";
import { countries_id } from "@/config/countries/countries_id";
import { countries_it } from "@/config/countries/countries_it";
import { countries_ko } from "@/config/countries/countries_ko";
import { countries_ms } from "@/config/countries/countries_ms";
import { countries_no } from "@/config/countries/countries_no";
import { countries_pl } from "@/config/countries/countries_pl";
import { countries_pt } from "@/config/countries/countries_pt";
import { countries_sv } from "@/config/countries/countries_sv";
import { countries_th } from "@/config/countries/countries_th";
import { countries_tl } from "@/config/countries/countries_tl";
import { countries_tr } from "@/config/countries/countries_tr";
import { countries_vi } from "@/config/countries/countries_vi";
import { countries_zh } from "@/config/countries/countries_zh";

export type CountryLocales =
  | "ar"
  | "bn"
  | "ct"
  | "de"
  | "en"
  | "es"
  | "fr"
  | "hi"
  | "hu"
  | "id"
  | "it"
  | "ko"
  | "ms"
  | "no"
  | "pl"
  | "pt"
  | "sv"
  | "th"
  | "tl"
  | "tr"
  | "vi"
  | "zh";

export const countries: {
  [key in CountryLocales]?: Record<ISO3166_Alpha2_Codes, string>;
} = {
  ar: countries_ar,
  bn: countries_bn,
  ct: countries_ct,
  de: countries_de,
  en: countries_en,
  es: countries_es,
  fr: countries_fr,
  hi: countries_hi,
  hu: countries_hu,
  id: countries_id,
  it: countries_it,
  ko: countries_ko,
  ms: countries_ms,
  no: countries_no,
  pl: countries_pl,
  pt: countries_pt,
  sv: countries_sv,
  th: countries_th,
  tl: countries_tl,
  tr: countries_tr,
  vi: countries_vi,
  zh: countries_zh,
};
