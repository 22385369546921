import { IncomingMessage } from "http";
import { GetServerSidePropsContext, NextApiRequest } from "next";
import { NextRequest } from "next/server";
import { ParsedUrlQuery } from "querystring";
import { inRange } from "range_check";

type Request =
  | GetServerSidePropsContext["req"]
  | NextApiRequest
  | IncomingMessage
  | NextRequest;

export default class InternalAccessService {
  private static readonly IS_LOCAL = process.env.ENV === "LOCAL";
  private static readonly IS_NONPROD = process.env.ENV === "NONPROD";
  private static readonly INTERNAL_IPS = process.env.INTERNAL_IPS || "";
  private static readonly INTERNAL_IP_RANGES = process.env.INTERNAL_IP_RANGES || "";

  static isInternal(req: Request, query?: ParsedUrlQuery): boolean {
    const isBypassedWithKey = query?.bps === process.env.APP_INTERNAL_API_KEY;

    if (this.IS_LOCAL || this.IS_NONPROD || isBypassedWithKey) {
      return true;
    }

    // Convert comma-separated IPs to array of IPs
    const internalIPs = this.INTERNAL_IPS.split(",").map((ip) => ip.trim());
    const internalIPRanges = this.INTERNAL_IP_RANGES.split(",").map((ip) => ip.trim());

    const ip = this.getRealIP(req);
    console.log("[I/O DEBUGGING] Real IP: ", ip);

    // Check if the IP is in the internal IP list or range
    const isInternalIp = internalIPs.includes(ip);
    const isInInternalIPRange = internalIPRanges.some((ipRange) => inRange(ip, ipRange));

    return isInternalIp || isInInternalIPRange;
  }

  static getRealIP(req: Request): string {
    // Handle NextRequest (middleware) separately
    if ("nextUrl" in req) {
      return (req as NextRequest).headers.get("true-client-ip") || "unknown";
    }

    // Handle Node.js IncomingMessage/NextApiRequest
    return (
      (req.headers as Record<string, string | string[] | undefined>)[
        "true-client-ip"
      ]?.toString() || "unknown"
    );
  }
}
