import { ISO3166_Alpha2_Codes } from "@/types/country-codes";

export const countries_hu: Record<ISO3166_Alpha2_Codes, string> = {
  AD: "Andorra",
  AE: "Egyesült Arab Emirátusok",
  AF: "Afganisztán",
  AG: "Antigua és Barbuda",
  AI: "Anguilla",
  AL: "Albánia",
  AM: "Örményország",
  AN: "Holland Antillák",
  AO: "Angola",
  AQ: "Antarktika",
  AR: "Argentína",
  AS: "Amerikai Samoa",
  AT: "Ausztria",
  AU: "Ausztrália",
  AW: "Aruba",
  AX: "Åland-szigetek",
  AZ: "Azerbajdzsán",
  BA: "Bosznia és Hercegovina",
  BB: "Barbados",
  BD: "Bangladesh",
  BE: "Belgium",
  BF: "Burkina Faso",
  BG: "Bulgária",
  BH: "Bahrain",
  BI: "Burundi",
  BJ: "Benin",
  BL: "Saint Barthélemy",
  BM: "Bermuda",
  BN: "Brunei Darussalam",
  BO: "Bolívia",
  BQ: "Bonaire",
  BR: "Brazília",
  BS: "Bahamák",
  BT: "Bhutan",
  BV: "Bouvet-sziget",
  BW: "Botswana",
  BY: "Fehéroroszország",
  BZ: "Belize",
  CA: "Kanada",
  CC: "Kókusz (Keeling)-szigetek",
  CD: "Kongói Demokratikus Köztársaság",
  CF: "Közép Afrikai Köztársaság",
  CG: "Kongói Köztársaság",
  CH: "Svájc",
  CI: "Elefántcsontpart",
  CK: "Cook-szigetek",
  CL: "Chile",
  CM: "Kamerun",
  CN: "Kína",
  CO: "Kolumbia",
  CR: "Costa Rica",
  CU: "Kuba",
  CV: "Cape Verde",
  CW: "Curaçao",
  CX: "Karácsony-sziget",
  CY: "Ciprus",
  CZ: "Csehország",
  DE: "Németország",
  DJ: "Djibouti",
  DK: "Dánia",
  DM: "Dominika",
  DO: "Dominikai Köztársaság",
  DZ: "Algéria",
  EC: "Ecuádor",
  EE: "Észtország",
  EG: "Egyiptom",
  EH: "Nyugat-Szahara",
  ER: "Eritrea",
  ES: "Spanyolország",
  ET: "Etiópia",
  FI: "Finnország",
  FJ: "Fidzsi-szigetek",
  FK: "Falkland-szigetek",
  FM: "Mikronézia",
  FO: "Feröer-szigetek",
  FR: "Franciaország",
  GA: "Gabon",
  GB: "Egyesült Királyság",
  GD: "Grenada",
  GE: "Grúzia",
  GF: "Francia Guyana",
  GG: "Guernsey",
  GH: "Gána",
  GI: "Gibraltár",
  GL: "Grönland",
  GM: "Gambia",
  GN: "Guinea",
  GP: "Guadeloupe",
  GQ: "Egyenlítői Guinea",
  GR: "Görögország",
  GS: "Déli-Georgia és Déli-Sandwich-szigetek",
  GT: "Guatemala",
  GU: "Guam",
  GW: "Bissau-Guinea",
  GY: "Guyana",
  HK: "Hong Kong",
  HM: "Heard-sziget és Mcdonald-szigetek",
  HN: "Honduras",
  HR: "Horvátország",
  HT: "Haiti",
  HU: "Magyarország",
  ID: "Indonézia",
  IE: "Írország",
  IL: "Izrael",
  IM: "Man-sziget",
  IN: "India",
  IO: "Brit Indiai-óceáni Területek",
  IQ: "Irak",
  IR: "Irán",
  IS: "Izland",
  IT: "Olaszország",
  JE: "Jersey",
  JM: "Jamaica",
  JO: "Jordánia",
  JP: "Japán",
  KE: "Kenya",
  KG: "Kirgizisztán",
  KH: "Kambodzsa",
  KI: "Kiribati",
  KM: "Comore-szigetek",
  KN: "Saint Kitts és  Nevis",
  KP: "Észak-Korea",
  KR: "Dél-Korea",
  KW: "Kuwait",
  KY: "Kajmán-szigetek",
  KZ: "Kazahsztán",
  LA: "Laosz",
  LB: "Libanon",
  LC: "Saint Lucia",
  LI: "Liechtenstein",
  LK: "Sri Lanka",
  LR: "Libéria",
  LS: "Lesotho",
  LT: "Litvánia",
  LU: "Luxembourg",
  LV: "Lettország",
  LY: "Líbia",
  MA: "Marokkó",
  MC: "Monaco",
  MD: "Moldova",
  ME: "Montenegro",
  MF: "Saint Martin",
  MG: "Madagaszkár",
  MH: "Marshall-szigetek",
  MK: "Macedónia",
  ML: "Mali",
  MM: "Myanmar",
  MN: "Mongólia",
  MO: "Macao",
  MP: "Északi-Mariana-szigetek",
  MQ: "Martinique",
  MR: "Mauritánia",
  MS: "Montserrat",
  MT: "Málta",
  MU: "Mauritius",
  MV: "Maldiv-szigetek",
  MW: "Malawi",
  MX: "Mexikó",
  MY: "Malajzia",
  MZ: "Mozambik",
  NA: "Namíbia",
  NC: "Új-Kaledónia",
  NE: "Niger",
  NF: "Norfolk-sziget",
  NG: "Nigéria",
  NI: "Nicaragua",
  NL: "Hollandia",
  NO: "Norvégia",
  NP: "Nepál",
  NR: "Nauru",
  NU: "Niue",
  NZ: "Új Zéland",
  OM: "Omán",
  PA: "Panama",
  PE: "Peru",
  PF: "Francia Polinézia",
  PG: "Papua Új-Guinea",
  PH: "Fülöp-szigetek",
  PK: "Pakisztán",
  PL: "Lengyelország",
  PM: "Saint Pierre és Miquelon",
  PN: "Pitcairn-szigetek",
  PR: "Puerto Rico",
  PS: "Palesztina",
  PT: "Portugália",
  PW: "Palau",
  PY: "Paraguay",
  QA: "Qatar",
  RE: "Réunion",
  RO: "Románia",
  RS: "Szerbia",
  RU: "Oroszország",
  RW: "Ruanda",
  SA: "Szaud-Arábia",
  SB: "Salamon-szigetek",
  SC: "Seychelle-szigetek",
  SD: "Szudán",
  SE: "Svédország",
  SG: "Szingapúr",
  SH: "Szent Ilona-sziget",
  SI: "Szlovénia",
  SJ: "Spitzbergák (Jan Mayen-sziget)",
  SK: "Szlovákia",
  SL: "Sierra Leone",
  SM: "San Marino",
  SN: "Szenegál",
  SO: "Szomália",
  SR: "Suriname",
  SS: "Dél-Szudán",
  ST: "Sao Tome és Principe",
  SV: "El Salvador",
  SX: "Sint Maarten",
  SY: "Szíria",
  SZ: "Szváziföld",
  TC: "Turks és Caicos-szigetek",
  TD: "Csád",
  TF: "Francia déli és antarktiszi területek",
  TG: "Togo",
  TH: "Thaiföld",
  TJ: "Tadzsikisztán",
  TK: "Tokelau-szigetek",
  TL: "Kelet-Timor",
  TM: "Türkmenisztán",
  TN: "Tunézia",
  TO: "Tonga",
  TR: "Törökország",
  TT: "Trinidad és Tobago",
  TV: "Tuvalu",
  TW: "Taiwan",
  TZ: "Tanzánia",
  UA: "Ukrajna",
  UG: "Uganda",
  UM: "Az Amerikai Egyesült Államok lakatlan külbirtokai",
  US: "Egyesült Államok",
  UY: "Uruguay",
  UZ: "Üzbegisztán",
  VA: "Vatikán",
  VC: "Saint Vincent és a Grenadine-szigetek",
  VE: "Venezuela",
  VG: "Brit Virgin-szigetek",
  VI: "Amerikai Virgin-szigetek",
  VN: "Vietnám",
  VU: "Vanuatu",
  WF: "Wallis And Futuna",
  WS: "Samoa",
  XK: "Koszovó",
  YE: "Jemen",
  YT: "Mayotte",
  ZA: "Dél-Afrika",
  ZM: "Zambia",
  ZW: "Zimbabwe",
};
