import { X } from "lucide-react";
import { Component, ReactNode } from "react";

export const ComponentError = ({ error, data }: { error?: unknown; data?: unknown }) => {
  console.log(error);
  console.log(data);

  // TODO: Add error logging

  return (
    <div className="h-full w-full rounded-lg shadow-lg opacity-75 border border-solid border-red-600 flex justify-center items-center text-center flex-col gap-2 text-red-600">
      <X />
      Component failed to load
    </div>
  );
};
