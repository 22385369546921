export default function AWSImage(filename: AWSImages) {
  return `https://cdn.cmcmarkets.com/neptune/images/${filename}`;
}

export type AWSImages =
  | "about-us_cmc-way-lead-with-quality.png"
  | "about-us_cmc-way-put-clients-first.png"
  | "about-us_cmc-way-set-the-standards.png"
  | "about-us_company-stat-bg-1.svg"
  | "about-us_company-stat-bg-2.svg"
  | "about-us_company-stat-bg-3.svg"
  | "about-us_company-stat-bg-4.svg"
  | "about-us_hero-bg.jpg"
  | "about-us_hero-mob.jpg"
  | "account-type__cfd-gfx.png"
  | "account-type__forex-gfx.png"
  | "account-type__spreadbetting-gfx.png"
  | "alpha-img-mob.png"
  | "alpha-img.png"
  | "alpha-module_bg-mob.png"
  | "alpha-module_bg.png"
  | "alpha-module_phone-img.png"
  | "bond-trading_hero-bg.png"
  | "bond-trading_hero-bg2.png"
  | "bond-trading_ready-to-find-your-flow-bg-mob.png"
  | "bond-trading_ready-to-find-your-flow-bg.png"
  | "bond-trading_side-bg.png"
  | "cash-secured-put-example.png"
  | "cfds_advantages-of-cfd-trading-thumbnail.png"
  | "cfds_bottom-platforms-img-mob.png"
  | "cfds_bottom-platforms-img.png"
  | "cfds_platform-hero-mob.png"
  | "cfds_platform-hero.png"
  | "cfds_what-are-cfds-thumbnail.png"
  | "cfds_what-is-side-img.png"
  | "circle-tick-azure.svg"
  | "cmc-logo-navy-square.png"
  | "cmc-trading-masterclass_brian-shannon-thumbnail.png"
  | "cmc-trading-masterclass_tom-hougaard-thumbnail.png"
  | "commodity-animated-icon.gif"
  | "commodity-still-icon.png"
  | "commodity-trading_hero-bg.png"
  | "commodity-trading_hero-bg2.png"
  | "commodity-trading_ready-to-find-your-flow-bg-mob.png"
  | "commodity-trading_ready-to-find-your-flow-bg.png"
  | "commodity-trading_side-bg.png"
  | "common_cmc-markets-logo-white.svg"
  | "common_FCA-navy.svg"
  | "common_FSCS-navy.svg"
  | "common_FTSE-navy.svg"
  | "common_generic-platform-hero-img.png"
  | "common_globe-azure.svg"
  | "common_logo-alpha.svg"
  | "common_podcast-cmc-logo.svg"
  | "common_ribbon-azure.svg"
  | "common_ribbon-navy.svg"
  | "covered-call-example.png"
  | "diagram-of-the-options-greeks.png"
  | "digram-of-the-options-greeks-v2.png"
  | "etf-trading_hero-bg.png"
  | "etf-trading_hero-bg2.png"
  | "etf-trading_ready-to-find-your-flow-bg-mob.png"
  | "etf-trading_ready-to-find-your-flow-bg.png"
  | "etf-trading_side-bg.png"
  | "etf-trading-animated-icon.gif"
  | "favicon-azure-transparent.svg"
  | "finding-options.png"
  | "flag_AT.png"
  | "flag_AU.png"
  | "flag_CA.png"
  | "flag_DE.png"
  | "flag_ES.png"
  | "flag_EU.svg"
  | "flag_FR.png"
  | "flag_GB.png"
  | "flag_IE.png"
  | "flag_international.png"
  | "flag_IT.png"
  | "flag_NO.png"
  | "flag_NZ.png"
  | "flag_PO.png"
  | "flag_SE.png"
  | "flag_SG.png"
  | "forex-animated-icon.gif"
  | "forex-still-icon.png"
  | "forex-trading_hero-bg.png"
  | "forex-trading_hero-bg2.png"
  | "forex-trading_hero-img.png"
  | "forex-trading_hero-mob.png"
  | "forex-trading_side-bg.png"
  | "formula-for-option-contract-value.png"
  | "fx-active_hero-bg.jpg"
  | "fx-active_hero-img.png"
  | "fx-active_hero-mob.png"
  | "fx-active_ready-to-start-trading-img-b.png"
  | "fx-active_sign-up-bg.jpg"
  | "fx-active_sign-up-img.png"
  | "fx-active_sign-up-mob.png"
  | "fx-active_why-become-side-bg.jpg"
  | "fx-active_why-become-side-img-2.png"
  | "fx-active_why-become-side-img.png"
  | "fx-active_why-become-side-mob.png"
  | "home_alpha-hero-bg.png"
  | "home-platform-slider_android-platform.png"
  | "home-platform-slider_desktop-platform.png"
  | "home-platform-slider_ios-platform.png"
  | "home-platform-slider_mt4-platform.png"
  | "icon_adjustments-48.svg"
  | "icon_award-badge-48.svg"
  | "icon_bell-48.svg"
  | "icon_bonds-gfx-120.png"
  | "icon_briefcase-48.svg"
  | "icon_building-48.svg"
  | "icon_cash-48.svg"
  | "icon_chart-pie-48.svg"
  | "icon_chart-square-bar-48-black.svg"
  | "icon_chart-square-bar-48.svg"
  | "icon_check-circle-48.svg"
  | "icon_clock-48.svg"
  | "icon_commodities-gfx-120.png"
  | "icon_cursor-click-48-black.svg"
  | "icon_customer-service-48.svg"
  | "icon_document-download-48.svg"
  | "icon_favourites-48.svg"
  | "icon_fire-48-black.svg"
  | "icon_fire-48.svg"
  | "icon_forex-48.svg"
  | "icon_forex-gfx-120.png"
  | "icon_heart-48-black-2.svg"
  | "icon_indices-gfx-240.png"
  | "icon_laurel_wreath.svg"
  | "icon_light-bulb-48-black.svg"
  | "icon_lightning-bolt-48.svg"
  | "icon_line-grow-magnifying-glass-48.svg"
  | "icon_lock-closed-48.svg"
  | "icon_mobile-48.svg"
  | "icon_newspaper-48.svg"
  | "icon_pound-dollar-48.svg"
  | "icon_pound-magnifying-glass-48-black.svg"
  | "icon_pound-magnifying-glass-48.svg"
  | "icon_precision-pricing-48-black.svg"
  | "icon_precision-pricing-48.svg"
  | "icon_presentation-chart-line-48.svg"
  | "icon_receipt-tax-48-black.svg"
  | "icon_receipt-tax-48.svg"
  | "icon_server-48.svg"
  | "icon_share-baskets-gfx-240.png"
  | "icon_shares-etfs-gfx-240.png"
  | "icon_shield-check-48-black.svg"
  | "icon_shield-check-48.svg"
  | "icon_sparkles-48.svg"
  | "icon_speaker-phone-48-black.svg"
  | "icon_switch-arrows-horizontal-48.svg"
  | "icon_switch-vertical-48-black.svg"
  | "icon_tag-48.svg"
  | "icon_up-down-48.svg"
  | "icon_up-down-tick-48-black.svg"
  | "icon_up-down-tick-48.svg"
  | "icon_user-group-48-black.svg"
  | "indices_hero-bg-2.png"
  | "indices_ready-to-find-your-flow-bg-mob.png"
  | "indices_ready-to-find-your-flow-bg.png"
  | "indices-animated-icon.gif"
  | "indices-hero-bg.png"
  | "indices-hero-mob.png"
  | "indices-side-bg.png"
  | "long-call-example.png"
  | "long-put-example.png"
  | "long-straddle-example.png"
  | "long-straddle-example-2.png"
  | "long-strangle-example.png"
  | "mobile-trading_hero-bg.jpg"
  | "mobile-trading_hero-img.png"
  | "mobile-trading_hero-mob.png"
  | "mobile-trading-app_news-and-insight-bg.png"
  | "mobile-trading-app_news-and-insight-img.png"
  | "mobile-trading-app_news-and-insight-mob.png"
  | "mobile-trading-app_ready-to-find-your-flow-img.png"
  | "mt4_addons-cmc-connect-thumbnail.png"
  | "mt4_advanced-mt4-functionality.png"
  | "mt4_hero-bg-mob.jpg"
  | "mt4_hero-bg.jpg"
  | "mt4_what-is-platform-img.png"
  | "mt4_what-is-side-bg-img.png"
  | "mt4-addons_alarm-manager.png"
  | "mt4-addons_alert-manager.png"
  | "mt4-addons_autochartist.png"
  | "mt4-addons_cmc-connect.png"
  | "mt4-addons_cmc-vps.png"
  | "mt4-addons_correlation-matrix.png"
  | "mt4-addons_correlation-trader.png"
  | "mt4-addons_excel-rtd-plugin.png"
  | "mt4-addons_mini-terminal.png"
  | "mt4-addons_sentiment-trader.png"
  | "mt4-addons_session-map.png"
  | "mt4-addons_stealth-orders.png"
  | "mt4-addons_tick-chart-trader.png"
  | "mt4-addons_trade-terminal.png"
  | "mt4-indicators_bar-changer.png"
  | "mt4-indicators_candle-countdown.png"
  | "mt4-indicators_chart-groups.png"
  | "mt4-indicators_chart-in-chart.png"
  | "mt4-indicators_donchian-channel.png"
  | "mt4-indicators_freehand-drawing.png"
  | "mt4-indicators_gravity.png"
  | "mt4-indicators_high-low.png"
  | "mt4-indicators_keltner-channel.png"
  | "mt4-indicators_magnifier.png"
  | "mt4-indicators_mini-chart.png"
  | "mt4-indicators_order-history.png"
  | "mt4-indicators_pivot-points.png"
  | "mt4-indicators_renko.png"
  | "mt4-indicators_symbol-info.png"
  | "options-animated-icon.gif"
  | "options-available.png"
  | "options-bottom-img-bg.png"
  | "options-bottom-img.png"
  | "options-bottom-trading-img-mob.png"
  | "options-chain-example.png"
  | "options-extrinsic-value-formula.png"
  | "options-greeks-delta-example.png"
  | "options-greeks-theta-example.png"
  | "options-greeks.png"
  | "options-hero-bg.png"
  | "options-hero-img.png"
  | "options-hero-mob.png"
  | "options-Intrinsic-value-example.png"
  | "options-intrinsic-value-chart.png"
  | "options-open-position-example.png"
  | "options-order-conformation.png"
  | "options-trade-order-ticket.png"
  | "options-trading.png"
  | "options-valuation-factors.png"
  | "options-valuation-factors-v2.png"
  | "options-volatility.png"
  | "order-type-example.png"
  | "platforms_mobile-trading-080124.png"
  | "platforms_mobile-trading.png"
  | "platforms_mt4.png"
  | "platforms_web-platform.png"
  | "ready-to-start-trading-bg.png"
  | "ready-to-start-trading-forex-trading-bg-mob.jpg"
  | "ready-to-start-trading-forex-trading-bg.jpg"
  | "ready-to-start-trading-forex-trading-img.png"
  | "ready-to-start-trading-forex-trading-mob.png"
  | "ready-to-start-trading-img-2.png"
  | "ready-to-start-trading-img-mob-2.png"
  | "ready-to-start-trading-img-mob.png"
  | "ready-to-start-trading-img.png"
  | "ready-to-start-trading-mt4-bg-mob.png"
  | "ready-to-start-trading-mt4-bg.png"
  | "ready-to-start-trading-mt4-img-mob.png"
  | "ready-to-start-trading-mt4-img.png"
  | "share-baskets-animated-icon.gif"
  | "share-baskets-trading_hero-bg.png"
  | "share-baskets-trading_hero-bg2.png"
  | "share-baskets-trading_hero-mob.png"
  | "share-baskets-trading_product-bg-1.png"
  | "share-baskets-trading_product-bg-2.png"
  | "share-baskets-trading_product-bg-3.png"
  | "share-baskets-trading_ready-to-find-your-flow-bg-mob.png"
  | "share-baskets-trading_ready-to-find-your-flow-bg.png"
  | "share-baskets-trading_side-bg.png"
  | "share-baskets-trading_step-1.png"
  | "share-baskets-trading_step-2.png"
  | "share-baskets-trading_step-3.png"
  | "shares-trading_hero-bg.png"
  | "shares-trading_hero-bg2.png"
  | "shares-trading_hero-mob.png"
  | "shares-trading_ready-to-find-your-flow-bg-mob.png"
  | "shares-trading_ready-to-find-your-flow-bg.png"
  | "shares-trading_side-bg.png"
  | "shares-trading-animated-icon.gif"
  | "shares-trading-still-icon.png"
  | "short-call-example.png"
  | "short-put-example.png"
  | "short-strangle-example.png"
  | "social_facebook_outline.svg"
  | "social_linkedin_outline.svg"
  | "social_twitter_outline.svg"
  | "social_youtube_outline.svg"
  | "spread-betting_bottom-platforms-img.png"
  | "spread-betting_hero-bg.png"
  | "spread-betting_hero-img.png"
  | "spread-betting_hero-mob.png"
  | "spread-betting_spreadbetting-vs-cfds-thumbnail.png"
  | "spread-betting_spreadbetting-vs-share-dealing-thumbnail.png"
  | "spread-betting_what-is-side-bg.png"
  | "spread-betting_what-is-side-img.png"
  | "spread-betting_what-is-spreadbetting-thumbnail.png"
  | "symbol_cfd-88.png"
  | "symbol_fx-active-88.png"
  | "symbol_spread-betting-88.png"
  | "tools-built_advanced-order-execution.png"
  | "tools-built_client-sentiment-tracker-mob.png"
  | "tools-built_customisable-dashboard-mob.png"
  | "tools-built_industry-leading-charting.png"
  | "tools-built_mobile-optimised-charts-mob.png"
  | "tools-built_pattern-recognition-scanner-2.png"
  | "tools-built_reuters-news-and-analysis.png"
  | "tools-built_side-bg.jpg"
  | "tools-built_topical-watchlists-mob.png"
  | "trading-platforms_hero-img-mob.png"
  | "trading-platforms_hero-img.png"
  | "trading-platforms_mt4-platform-img.png"
  | "trading-platforms_mt4-platform-mob.png"
  | "trading-platforms_mt4-platform-side-bg.png"
  | "trading-platforms_web-platform-mob.png"
  | "web-platform_analytics-side-bg.png"
  | "web-platform_hero-img.png"
  | "web-platform_hero-side-bg.png"
  | "web-platform_mid-img-v2.png"
  | "web-platform_mid-img.png"
  | "web-platform_performance-analytics-img.png"
  | "web-platform_your-space-img.png"
  | "web-platform_your-space-side-bg.png"
  | "trading-platforms_web-platform-side-bg.jpg"
  | "bonds-trading_hero-mob-160224.png"
  | "commodity-trading_hero-mob-160224.png"
  | "etf-trading_hero-mob-160224.png"
  | "forex-trading_hero-mob-160224.png"
  | "indices_hero-mob-160224.png"
  | "share-baskets_hero-mob-160224.png"
  | "shares-trading_hero-mob-160224.png"
  | "cash-secured-put-example-2.png"
  | "covered-call-example-2.png"
  | "finding-options-v2.png"
  | "options-chain-example-v2.png"
  | "option-chain-example-v3.png"
  | "options-open-position-example-v2.png"
  | "options-order-conformation-v2.png"
  | "options-trade-order-ticket-v2.png"
  | "options-trade-order-ticket-v3.png"
  | "options-open-position-example-v3.png"
  | "order-type-example-v2.png"
  | "options-order-conformation-v3.png"
  | "options-static-3d.png"
  | "options-hero-bg-v2.png"
  | "options-hero-mob-v2.png"
  | "options-long-straddle-chart-v2.png"
  | "options-trading-bottom-img-v2.png"
  | "Retail-trading-started-ebook-iq.png"
  | "Retail-trading-trends-started-bg-image.png"
  | "DownloadReport.png"
  | "RetailTrading-tactics.png"
  | "RetailTrading-Influences.png"
  | "RetailTrading-Risk.png"
  | "RetailTrading-MovingForward.png"
  | "ReportTradingTrendsHeroBg.png"
  | "RetailTradingTrends-ReadyToGetStarted.png"
  | string;

// TO UPDATE THE LIST OF IMAGES, RUN THE FOLLOWING CODE IN THE BROWSER CONSOLE IN AWS S3
// let nameElements = document.querySelectorAll('.name.object.object-name');

// // Initialize an empty string to store the extracted strings
// let namesString = "export type AWSImages = ";

// // Loop through each element and append its text content to the string
// nameElements.forEach(function(element, index) {
//   // Add the pipe separator for all elements except the first one
//   if (index > 0) {
//     namesString += ' | ';
//   }

//   // Append the text content (enclosed in double quotes) to the string
//   namesString += `"${element.textContent.trim()}"`;
// });

// // Add a semicolon at the end
// namesString += ";";

// // Now, the namesString contains all the strings from the selected elements with double quotes
// console.log(namesString);
