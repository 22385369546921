import { useIsRefOverflowing } from "@/hooks/useIsOverflowing";
import {
  addHttps,
  cn,
  extractMetadataSlug,
  getContentfulEnvironment,
  getRouteDetails,
} from "@/utils/helpers";
import Page from "@/utils/system/page";
import { ChevronDown } from "lucide-react";
import Image from "next/image";
import { useRouter } from "next/router";
import { useRef, useState } from "react";
import RichText from "../core/atoms/rich-text";
import Link from "../utility/link";
import LanguageSelector from "./language-selector";

const Footer = ({ footer }: any) => {
  const router = useRouter();

  const { locale } = getRouteDetails(router);
  const { isLivePreview, isPreview } = getContentfulEnvironment(router);

  const isFooterEditor = router?.query?.footer;

  const offerings = footer?.fields?.offerings ?? false;
  const logo = footer?.fields?.logo ?? false;
  const title = footer?.fields?.title ?? false;
  const languageSelector = footer?.fields?.languageSelector ?? false;
  const links = footer?.fields?.links ?? false;
  const contact = footer?.fields?.contact ?? false;
  const address = footer?.fields?.address ?? false;
  const socials = footer?.fields?.socials ?? false;
  const apps = footer?.fields?.apps ?? false;
  const disclaimer = footer?.fields?.disclaimer ?? false;
  const legalAndInfo = footer?.fields?.legalAndInfo ?? false;

  const footerLinksRef = useRef(null);
  const legalAndInfoRef = useRef(null);

  const { horizontally: linksAreOverflowingHorizontally } =
    useIsRefOverflowing(footerLinksRef);

  const { horizontally: legalAndInfoIsOverflowingHorizontally } =
    useIsRefOverflowing(legalAndInfoRef);

  // Mobile only
  const [openAccordions, setOpenAccordions] = useState<{
    [key: number]: boolean;
  }>({});

  const toggleAccordion = (index: number) => {
    setOpenAccordions((prevState) => ({
      ...prevState,
      [index]: !prevState[index],
    }));
  };

  return (
    <footer className="bg-navy-100" id="footer">
      <div
        className="main-xl-container theme-navy-100 footer flex flex-col gap-10 bg-navy-100 py-8 text-white lg:gap-12 lg:py-14"
        dir="ltr"
      >
        <div className="flex w-full flex-col gap-1 ">
          {languageSelector && (
            <div className="border-b border-solid border-navy-20">
              <LanguageSelector selected={locale as any} />
            </div>
          )}
          <div className="flex gap-4 pb-6 pt-2 text-xs no-underline lg:text-lg">
            {offerings &&
              offerings.map((item: any, index: number) => {
                return (
                  <a
                    key={index}
                    className={cn(
                      "uppercase text-navy-20 no-underline",
                      item.fields.title.toUpperCase() === "PERSONAL" && "text-white"
                    )}
                    target="_blank"
                    href={item.fields.anchor}
                  >
                    {item.fields.title}
                  </a>
                );
              })}
          </div>
          <div className="flex items-center">
            {logo && (
              <Image
                src={addHttps(logo.fields?.file?.url)}
                alt=""
                width={logo.fields?.file?.details?.image.width}
                height={logo.fields?.file?.details?.image.height}
                className="max-h-[45px] w-auto pr-4"
                onClick={() => Page.editField(footer, "logo")}
              />
            )}
            {title && (
              <span
                onClick={() => isFooterEditor && Page.editField(footer, "title")}
                className="block h-min max-w-[18ch] border-l border-solid border-navy-20 pl-4 text-navy-20"
              >
                {title}
              </span>
            )}
          </div>
        </div>

        <div
          ref={footerLinksRef}
          className={cn(
            "grid-center-parent flex w-full justify-between gap-4 overflow-hidden"
          )}
        >
          {linksAreOverflowingHorizontally && (
            <div className="flex w-full flex-col">
              {links &&
                links.map((link: any, index: number) => {
                  return (
                    <div
                      key={index}
                      className="w-full border-t border-solid border-navy-20 pb-3 pt-4"
                    >
                      <button
                        className="flex w-full items-center justify-between whitespace-nowrap text-left uppercase"
                        onClick={() => toggleAccordion(index)}
                      >
                        {link.fields.title}
                        <ChevronDown
                          height={18}
                          className={cn(
                            "transform transition-all duration-500",
                            openAccordions[index] && "rotate-180"
                          )}
                        />
                      </button>
                      <div
                        className={`transition-max-height overflow-hidden duration-500 ${
                          openAccordions[index] ? "max-h-96" : "max-h-0"
                        }`}
                      >
                        <ul className="pt-4">
                          {link.fields?.links?.map((item: any, itemIndex: number) => {
                            item.fields.url =
                              item.fields.url || extractMetadataSlug(item, locale) || "#";

                            if (!item.fields.url && (isLivePreview || isPreview)) {
                              return (
                                <p key={itemIndex} className="text-red-600 font-bold">
                                  No page with this anchor found!
                                </p>
                              );
                            }

                            if (item.fields.url) {
                              return (
                                <li key={itemIndex}>
                                  <Link
                                    href={item.fields.url}
                                    className="whitespace-nowrap text-white no-underline"
                                  >
                                    {item.fields.title}
                                  </Link>
                                </li>
                              );
                            }

                            return null;
                          })}
                        </ul>
                      </div>
                    </div>
                  );
                })}
            </div>
          )}
          <div
            className={cn(
              "flex w-full gap-4",
              linksAreOverflowingHorizontally && "invisible"
            )}
          >
            {links &&
              links?.map((link: any, index: number) => {
                return (
                  <div
                    key={index}
                    onClick={() => Page.editField(link)}
                    className="w-full border-t border-solid border-navy-20 pt-3"
                  >
                    <p className="whitespace-nowrap uppercase">{link.fields.title}</p>
                    <ul>
                      {link.fields?.links?.map((item: any, index: number) => {
                        item.fields.anchor =
                          item.fields.anchor ||
                          extractMetadataSlug(item, locale, isLivePreview) ||
                          false;

                        if (!item.fields.anchor && (isLivePreview || isPreview)) {
                          return (
                            <p key={index} className="text-red-600 font-bold">
                              No page with this anchor found!
                            </p>
                          );
                        }

                        if (item.fields.anchor) {
                          return (
                            <li key={index}>
                              <Link
                                href={item.fields.anchor}
                                className="whitespace-nowrap pr-6 text-white no-underline"
                              >
                                {item.fields.title}
                              </Link>
                            </li>
                          );
                        }

                        return null;
                      })}
                    </ul>
                  </div>
                );
              })}
          </div>
        </div>

        <div
          id="footer-contact"
          className="flex w-full flex-col gap-10 lg:flex-row lg:gap-4"
        >
          <div className="w-full border-t border-solid border-navy-20 pt-3 lg:max-w-[33%]">
            {contact && <RichText data={contact} fieldName="contact" sys={footer.sys} />}
          </div>

          <div className="w-full border-t border-solid border-navy-20 pt-3">
            {address && <RichText data={address} fieldName="address" sys={footer.sys} />}
          </div>
        </div>

        <div className="flex w-full flex-col gap-10 lg:flex-row lg:gap-4">
          <div className="w-full border-t border-solid border-navy-20 pt-3 lg:max-w-[33%]">
            {socials && <RichText data={socials} fieldName="socials" sys={footer.sys} />}
          </div>

          <div className="w-full border-t border-solid border-navy-20 pt-3">
            {apps && <RichText data={apps} fieldName="apps" sys={footer.sys} />}
          </div>
        </div>

        <div className="flex w-full gap-4 border-t border-solid border-navy-20 pt-3">
          {disclaimer && (
            <RichText data={disclaimer} fieldName="disclaimer" sys={footer.sys} />
          )}
        </div>

        <div
          ref={legalAndInfoRef}
          onClick={() => isFooterEditor && Page.editField(footer, "legalAndInfo")}
          className="grid-center-parent flex w-full justify-between overflow-hidden border-t border-solid border-navy-20 pt-3 text-xs"
        >
          {/* Desktop */}
          <div
            className={cn(
              "flex w-full gap-4",
              legalAndInfoIsOverflowingHorizontally && "invisible"
            )}
          >
            {legalAndInfo &&
              legalAndInfo.map((item: any, index: number) => {
                // Link metadata slugs
                item.fields.anchor =
                  item.fields.anchor ||
                  extractMetadataSlug(item, locale, isLivePreview) ||
                  false;

                if (!item.fields.anchor && (isLivePreview || isPreview)) {
                  return (
                    <p key={index} className="text-red-600 font-bold">
                      No page with this anchor found!
                    </p>
                  );
                }

                if (item.fields.anchor) {
                  return (
                    <Link
                      key={index}
                      href={item.fields.anchor}
                      className="whitespace-nowrap no-underline"
                    >
                      {item.fields.title}
                    </Link>
                  );
                }

                return null;
              })}
            <div className="flex w-full items-end justify-end self-end whitespace-nowrap">
              © {new Date().getFullYear()} CMC Markets
            </div>
          </div>
          {/* Mobile */}
          {legalAndInfoIsOverflowingHorizontally && (
            <div className={cn("flex w-full flex-col gap-4")}>
              {legalAndInfo &&
                legalAndInfo.map((item: any, index: number) => {
                  // Link metadata slugs
                  item.fields.anchor =
                    item.fields.anchor ||
                    extractMetadataSlug(item, locale, isLivePreview) ||
                    false;

                  if (!item.fields.anchor && (isLivePreview || isPreview)) {
                    return (
                      <p key={index} className="text-red-600 font-bold">
                        No page with this anchor found!
                      </p>
                    );
                  }

                  if (item.fields.anchor) {
                    return (
                      <Link
                        key={index}
                        href={item.fields.anchor}
                        className="whitespace-nowrap no-underline"
                      >
                        {item.fields.title}
                      </Link>
                    );
                  }

                  return null;
                })}
              <div className="flex w-full items-end whitespace-nowrap">
                © {new Date().getFullYear()} CMC Markets
              </div>
            </div>
          )}
        </div>
      </div>
    </footer>
  );
};

export default Footer;
