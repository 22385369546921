export enum TextKey {
  Swipe = "Swipe",
  TryTheseNext = "Try these next",
  ShowMore = "Show more",
  ContentLinks = "Content links",
  ReadMore = "Read more",
  Menu = "Menu",
  Close = "Close",
  RegisterAccount = "Register account",
  RegisterAccountDescription = "Register account description",
  SelectCountry = "Select country",
  MetaTraderDescription = "MetaTrader description",
  CmcNextGenerationDescription = "CMC NextGeneration description",
  UnsupportedCountry = "Unsupported country",
  Continue = "Continue",

  TitleRequired = "TitleRequired",
  MinTwoCharacters = "MinTwoCharacters",
  EmailInvalid = "EmailInvalid",
  ConfirmEmail = "ConfirmEmail",
  DigitsOnly = "DigitsOnly",
  RegionRequired = "RegionRequired",
  OrganisationRequired = "OrganisationRequired",
  JobTitleRequired = "JobTitleRequired",
  RelationshipRequired = "RelationshipRequired",
  IncludeMessage = "IncludeMessage",
  SelectTime = "SelectTime",
  ValidationFailed = "ValidationFailed",
}
