import { ISO3166_Alpha2_Codes } from "@/types/country-codes";

export const countries_vi: Record<ISO3166_Alpha2_Codes, string> = {
  AW: "Aruba",
  AF: "Afghanistan",
  AO: "Angola",
  AI: "Anguilla",
  AX: "Quần đảo Aland",
  AL: "Albania",
  AD: "Andorra",
  AN: "Đảo Antilles thuộc Hà Lan",
  AE: "Các Tiểu Vương quốc Ả Rập Thống nhất",
  AR: "Argentina",
  AM: "Armenia",
  AS: "Samoa thuộc Hoa Kỳ",
  AQ: "Nam Cực",
  TF: "Lãnh thổ phía Nam của Pháp",
  AG: "Antigua và Barbuda",
  AU: "Úc",
  AT: "Áo",
  AZ: "Azerbaijan",
  BI: "Burundi",
  BE: "Bỉ",
  BJ: "Benin",
  BQ: "Bonaire, Sint Eustatius và Saba",
  BF: "Burkina Faso",
  BD: "Bangladesh",
  BG: "Bungari",
  BH: "Bahrain",
  BS: "Bahamas",
  BA: "Bosnia và Herzegovina",
  BL: "Saint Barthélemy",
  BY: "Belarus",
  BZ: "Belize",
  BM: "Bermuda",
  BO: "Bolivia (Nhà nước Đa dân tộc)",
  BR: "Brazil",
  BB: "Barbados",
  BN: "Brunei Darussalam",
  BT: "Bhutan",
  BV: "Đảo Bouvet",
  BW: "Botswana",
  CF: "Cộng hòa Trung Phi",
  CA: "Canada",
  CC: "Quần đảo Cocos (Keeling)",
  CH: "Thụy Sĩ",
  CL: "Chile",
  CN: "Trung Quốc",
  CI: "Bờ Biển Ngà",
  CM: "Cameroon",
  CD: "Congo (Cộng hòa Dân chủ)",
  CG: "Congo",
  CK: "Quần đảo Cook",
  CO: "Colombia",
  KM: "Comoros",
  CV: "Cabo Verde",
  CR: "Costa Rica",
  CU: "Cuba",
  CW: "Curacao",
  CX: "Đảo Giáng Sinh",
  KY: "Quần đảo Cayman",
  CY: "Síp",
  CZ: "Cộng hòa Séc",
  DE: "Đức",
  DJ: "Djibouti",
  DM: "Dominica",
  DK: "Đan Mạch",
  DO: "Cộng hòa Dominica",
  DZ: "Algeria",
  EC: "Ecuador",
  EG: "Ai Cập",
  ER: "Eritrea",
  EH: "Phía tây Sahara",
  ES: "Tây Ban Nha",
  EE: "Estonia",
  ET: "Ethiopia",
  FI: "Phần Lan",
  FJ: "Fiji",
  FK: "Quần đảo Falkland (Malvinas)",
  FR: "Pháp",
  FO: "Quần đảo Faroe",
  FM: "Micronesia",
  GA: "Gabon",
  GB: "Vương quốc Anh",
  GE: "Gruzia",
  GG: "Guernsey",
  GH: "Ghana",
  GI: "Gibraltar",
  GN: "Ghine",
  GP: "Guadeloupe",
  GM: "Gambia",
  GW: "Ghine Bissau",
  GQ: "Ghine Xích Đạo",
  GR: "Hy Lạp",
  GD: "Grenada",
  GL: "Greenland",
  GT: "Guatemala",
  GF: "Guiana thuộc Pháp",
  GU: "Guam",
  GY: "Guyana",
  HK: "Hồng Kông",
  HM: "Đảo Heard và Quần đảo McDonald",
  HN: "Honduras",
  HR: "Croatia",
  HT: "Haiti",
  HU: "Hungary",
  ID: "Indonesia",
  IM: "Đảo Man",
  IN: "Ấn Độ",
  IO: "Lãnh thổ Ấn Độ Dương thuộc Anh",
  IE: "Ireland",
  IR: "Iran",
  IQ: "Iraq",
  IS: "Iceland",
  IL: "Israel",
  IT: "Ý",
  JM: "Jamaica",
  JE: "Jersey",
  JO: "Jordan",
  JP: "Nhật Bản",
  KZ: "Kazakhstan",
  KE: "Kenya",
  KG: "Kyrgyzstan",
  KH: "Campuchia",
  KI: "Kiribati",
  KN: "Saint Kitts và Nevis",
  KR: "Hàn Quốc",
  KW: "Kuwait",
  LA: "Lào",
  LB: "Liban",
  LR: "Liberia",
  LY: "Libya",
  LC: "Saint Lucia",
  LI: "Liechtenstein",
  LK: "Sri Lanka",
  LS: "Lesotho",
  LT: "Lithuania",
  LU: "Luxembourg",
  LV: "Latvia",
  MO: "Macao",
  MF: "Saint Martin",
  MA: "Maroc",
  MC: "Monaco",
  MD: "Moldova (Cộng hòa)",
  MG: "Madagascar",
  MV: "Maldives",
  MX: "Mexico",
  MH: "Quần đảo Marshall",
  MK: "Macedonia ",
  ML: "Mali",
  MT: "Malta",
  MM: "Myanmar",
  ME: "Montenegro",
  MN: "Mông Cổ",
  MP: "Quần đảo Bắc Mariana",
  MZ: "Mozambique",
  MR: "Mauritania",
  MS: "Montserrat",
  MQ: "Martinique",
  MU: "Mauritius",
  MW: "Malawi",
  MY: "Malaysia",
  YT: "Mayotte",
  NA: "Namibia",
  NC: "New Caledonia",
  NE: "Niger",
  NF: "Đảo Norfolk",
  NG: "Nigeria",
  NI: "Nicaragua",
  NU: "Niue",
  NL: "Hà Lan",
  NO: "Na Uy",
  NP: "Nepal",
  NR: "Nauru",
  NZ: "New Zealand",
  OM: "Oman",
  PK: "Pakistan",
  PA: "Panama",
  PN: "Pitcairn",
  PE: "Peru",
  PH: "Philippines",
  PW: "Palau",
  PG: "Papua New Ghine",
  PL: "Ba Lan",
  PR: "Puerto Rico",
  KP: "Bắc Triều Tiên",
  PT: "Bồ Đào Nha",
  PY: "Paraguay",
  PS: "Palestine, Nhà nước",
  PF: "Polynesia thuộc Pháp",
  QA: "Qatar",
  RE: "Reunion",
  RO: "Rumani",
  RU: "Nga",
  RW: "Rwanda",
  SA: "Ả Rập Xê Út",
  SD: "Sudan",
  SN: "Senegal",
  SG: "Singapore",
  GS: "Nam Georgia và Quần đảo Nam Sandwich",
  SH: "Saint Helena",
  SJ: "Svalbard và Jan Mayen",
  SB: "Quần đảo Solomon",
  SL: "Sierra Leone",
  SV: "El Salvador",
  SM: "San Marino",
  SO: "Somalia",
  PM: "Saint Pierre và Miquelon",
  RS: "Serbia",
  SS: "Nam Sudan",
  ST: "Sao Tome và Principe",
  SR: "Suriname",
  SK: "Slovakia",
  SI: "Slovenia",
  SE: "Thụy Điển",
  SZ: "Eswatini",
  SX: "Sint Maarten (phần tiếng Hà Lan)",
  SC: "Seychelles",
  SY: "Cộng hòa Ả Rập Syria",
  TC: "Quần đảo Turks và Caicos",
  TD: "Chad",
  TG: "Togo",
  TH: "Thái Lan",
  TJ: "Tajikistan",
  TK: "Tokelau",
  TM: "Turkmenistan",
  TL: "Đông Timor",
  TO: "Tonga",
  TT: "Trinidad và Tobago",
  TN: "Tunisia",
  TR: "Thổ Nhĩ Kỳ",
  TV: "Tuvalu",
  TW: "Đài loan",
  TZ: "Tanzania, Cộng hòa Thống nhất",
  UG: "Uganda",
  UA: "Ukraina",
  UM: "Các tiểu đảo xa (Hoa Kỳ)",
  UY: "Uruguay",
  US: "Hoa Kỳ",
  UZ: "Uzbekistan",
  VA: "Vatican",
  VC: "Saint Vincent và Grenadines",
  VE: "Venezuela",
  VG: "Quần đảo Virgin (thuộc Anh)",
  VI: "Quần đảo Virgin (thuộc Hoa Kỳ)",
  VN: "Việt Nam",
  VU: "Vanuatu",
  WF: "Wallis và Futuna",
  WS: "Samoa",
  XK: "Kosovo",
  YE: "Yemen",
  ZA: "Nam Phi",
  ZM: "Zambia",
  ZW: "Zimbabwe",
};
